import PDF_ACTION_TYPES from "./pdf.type";

export const PDF_INITIAL_STATE = {
  orderReceipt: null,
  orderNotes: null,
  renewalReceipt: null,

  pdfOrderReceiptIsMultiLang: null,
  pdfOrderReceiptLoading: false,
  pdfOrderReceiptSuccess: null,
  pdfOrderReceiptFailed: null,

  pdfOrderNotesIsMultiLang: null,
  pdfOrderNotesLoading: false,
  pdfOrderNotesSuccess: null,
  pdfOrderNotesFailed: null,

  pdfRenewalReceiptLoading: false,
  pdfRenewalReceiptSuccess: null,
  pdfRenewalReceiptFailed: null,

  isPdfOrderReceiptHitted: false,
  isPdfOrderNotesHitted: false,
  isPdfRenewalReceiptHitted: false,
};

export const pdfReducer = (state = PDF_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PDF_ACTION_TYPES.SET_ORDER_RECEIPT:
      return { ...state, orderReceipt: payload };
    case PDF_ACTION_TYPES.SET_ORDER_NOTES:
      return { ...state, orderNotes: payload };
    case PDF_ACTION_TYPES.SET_RENEWAL_RECEIPT:
      return { ...state, renewalReceipt: payload };

    case PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_IS_MULTI_LANG:
      return { ...state, pdfOrderReceiptIsMultiLang: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_LOADING:
      return { ...state, pdfOrderReceiptLoading: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_SUCCESS:
      return { ...state, pdfOrderReceiptSuccess: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_FAILED:
      return { ...state, pdfOrderReceiptFailed: payload };

    case PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_IS_MULTI_LANG:
      return { ...state, pdfOrderNotesIsMultiLang: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_LOADING:
      return { ...state, pdfOrderNotesLoading: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_SUCCESS:
      return { ...state, pdfOrderNotesSuccess: payload };
    case PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_FAILED:
      return { ...state, pdfOrderNotesFailed: payload };

    case PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_LOADING:
      return { ...state, pdfRenewalReceiptLoading: payload };
    case PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_SUCCESS:
      return { ...state, pdfRenewalReceiptSuccess: payload };
    case PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_FAILED:
      return { ...state, pdfRenewalReceiptFailed: payload };

    case PDF_ACTION_TYPES.SET_IS_PDF_ORDER_RECEIPT_HITTED:
      return { ...state, isPdfOrderReceiptHitted: payload };
    case PDF_ACTION_TYPES.SET_IS_PDF_ORDER_NOTES_HITTED:
      return { ...state, isPdfOrderNotesHitted: payload };
    case PDF_ACTION_TYPES.SET_IS_PDF_RENEWAL_RECEIPT_HITTED:
      return { ...state, isPdfRenewalReceiptHitted: payload };

    case PDF_ACTION_TYPES.RESET_PDF_REDUCER:
      return PDF_INITIAL_STATE;
    default:
      return state;
  }
};
