import { useTranslation } from "react-i18next";

import {
  useCenterDialog,
  useLeftDialog,
  useRightDialog,
} from "../../hooks/component.hook";

import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import {
  DialogContainer,
  DialogGroup,
  DialogItemAccept,
  DialogItemCancel,
  DialogItemClose,
  DialogItemContainer,
  DialogItemDescription,
  DialogItemIcon,
  DialogItemInfo,
  DialogItemLower,
  DialogItemTitle,
  DialogItemUpper,
} from "./dialog.style";

import { Alert02Icon, Cancel01Icon } from "hugeicons-react";
import { useSelector } from "react-redux";
import {
  getCenterDialogs,
  getLeftDialogs,
  getRightDialogs,
} from "../../store/component/component.selector";

export const DIALOG_STYLES = {
  STYLE_1: "STYLE_1",
  STYLE_2: "STYLE_2",
};

export const DIALOG_COLORS = {
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
  LIGHT: "LIGHT",
  SECONDARY: "SECONDARY",
};

const DIALOG_POSITIONS = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  CENTER: "CENTER",
};

const DialogItem = ({
  dialogKey,
  dialogPosition,
  dialogStyle = DIALOG_STYLES.STYLE_2,
  dialogColor = DIALOG_COLORS.SECONDARY,
  title,
  description,
  isConfirmation = false,
  acceptLabel,
  cancelLabel,
  handleDialogAccept,
  handleDialogCancel,
}) => {
  const { t } = useTranslation();

  const { removeDialog: removeLeftDialog } = useLeftDialog();
  const { removeDialog: removeRightDialog } = useRightDialog();
  const { removeDialog: removeCenterDialog } = useCenterDialog();

  const handleDialogClose = () => {
    switch (dialogPosition) {
      case DIALOG_POSITIONS.LEFT:
        removeLeftDialog(dialogKey);
        break;
      case DIALOG_POSITIONS.RIGHT:
        removeRightDialog(dialogKey);
        break;
      case DIALOG_POSITIONS.CENTER:
        removeCenterDialog(dialogKey);
        break;
      default:
        break;
    }
  };

  const handleAcceptClick = () => {
    handleDialogAccept?.();
    handleDialogClose();
  };
  const handleCancelClick = () => {
    handleDialogCancel?.();
    handleDialogClose();
  };

  return (
    <DialogItemContainer dialogStyle={dialogStyle} dialogColor={dialogColor}>
      <DialogItemIcon>
        <Alert02Icon />
      </DialogItemIcon>
      <DialogItemInfo>
        <DialogItemUpper>
          <DialogItemTitle>
            {!isEmpty(title) ? title : t("No title")}
          </DialogItemTitle>
          <DialogItemDescription>
            {!isEmpty(description) ? description : t("No information")}
          </DialogItemDescription>
        </DialogItemUpper>
        {parseToBoolean(isConfirmation) && (
          <DialogItemLower>
            <DialogItemAccept onClick={handleAcceptClick}>
              {!isEmpty(acceptLabel) ? acceptLabel : t("Yes")}
            </DialogItemAccept>
            <DialogItemCancel onClick={handleCancelClick}>
              {!isEmpty(cancelLabel) ? cancelLabel : t("Cancel")}
            </DialogItemCancel>
          </DialogItemLower>
        )}
      </DialogItemInfo>
      <DialogItemClose onClick={handleDialogClose}>
        <Cancel01Icon />
      </DialogItemClose>
    </DialogItemContainer>
  );
};

const Dialog = () => {
  const leftDialogs = useSelector(getLeftDialogs);
  const rightDialogs = useSelector(getRightDialogs);
  const centerDialogs = useSelector(getCenterDialogs);

  return (
    <DialogContainer>
      <DialogGroup>
        {Object.values(leftDialogs).map((leftDialog) => (
          <DialogItem
            key={leftDialog.dialogKey}
            dialogPosition={DIALOG_POSITIONS.LEFT}
            {...leftDialog}
          />
        ))}
      </DialogGroup>
      <DialogGroup>
        {Object.values(centerDialogs).map((centerDialog) => (
          <DialogItem
            key={centerDialog.dialogKey}
            dialogPosition={DIALOG_POSITIONS.CENTER}
            {...centerDialog}
          />
        ))}
      </DialogGroup>
      <DialogGroup>
        {Object.values(rightDialogs).map((rightDialog) => (
          <DialogItem
            key={rightDialog.dialogKey}
            dialogPosition={DIALOG_POSITIONS.RIGHT}
            {...rightDialog}
          />
        ))}
      </DialogGroup>
    </DialogContainer>
  );
};

export default Dialog;
