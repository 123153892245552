export const PURCHASE_TABS = {
  INGREDIENTS: "INGREDIENTS",
  EXPENSES: "EXPENSES",
};

export const PURCHASE_TYPES = {
  TYPE_INGREDIENT: "INGREDIENT",
  TYPE_EXPENSE: "EXPENSE",
};

export const PURCHASE_STATUSES = {
  STATUS_PAID: "PAID",
  STATUS_UNPAID: "UNPAID",
};
