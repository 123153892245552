import styled, { css } from "styled-components";

import { COLORS, FORM_INPUT_STYLES } from "../../utils/styles.utils";

export const FormFileAreaIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
  height: 3.5rem;
  min-height: 3.5rem;
  max-height: 3.5rem;

  border-width: 2px;
  border-style: solid;
  border-radius: 1rem;
  margin-bottom: 0.75rem;

  svg {
    width: 1.75rem;
    min-width: 1.75rem;
    max-width: 1.75rem;
    height: 1.75rem;
    min-height: 1.75rem;
    max-height: 1.75rem;

    path {
      stroke-width: 2;

      &[fill] {
        fill: #0e072f;
      }
      &[stroke] {
        stroke: #0e072f;
      }
    }
  }
`;

export const FormFileAreaTitle = styled.h5`
  font-size: 1rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  text-align: center;
  margin-bottom: 0.15rem;
`;

export const FormFileAreaDescription = styled.p`
  max-width: 24rem;
  font-size: 1rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  text-align: center;
  margin: 0;
`;

export const FormFileAreaContent = styled.div`
  user-select: none;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  aspect-ratio: 2.5 / 1;
  padding: 1.75rem 1.5rem;

  border-width: 1.5px;
  border-style: dashed;
  border-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};

  @media (max-width: 768px) {
    aspect-ratio: 2.75 / 1;
  }
  @media (max-width: 576px) {
    aspect-ratio: 2 / 1;
  }

  ${({ isDraging }) =>
    isDraging &&
    css`
      background-color: ${COLORS.WHITE_HOVER};
    `}
`;

export const FormFileAreaImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
`;

export const FormFileAreaRemove = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const FormFileAreaInput = styled.input.attrs({
  type: "file",
  accept: "images/*",
})`
  display: none;
`;

export const FormFileAreaContainer = styled.div`
  position: relative;

  ${FormFileAreaIcon} {
    border-color: ${({ isSolid, isDisabled }) =>
      isDisabled ? "#d1d5db" : isSolid ? "#e9e9ee" : "#e0e0e0"};
    background-color: ${({ isSolid, isDisabled }) =>
      isDisabled ? "#e9e9ee" : isSolid ? "#ffffff" : "#f9f9f9"};
  }
  ${FormFileAreaContent} {
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};

    background-color: ${({ isSolid }) => (isSolid ? "#f2f2f4" : "#ffffff")};
    border-color: ${({ isSolid }) => (isSolid ? "#bababe" : "#d1d1d6")};
  }
`;
