import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { parseToFloat } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";
import {
  setCurrentModal,
  setIsModalOpen,
} from "../../store/component/component.action";
import {
  getCurrentModal,
  getIsModalOpen,
} from "../../store/component/component.selector";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../button/button.component";

import {
  ModalActionGroup,
  ModalBody,
  ModalContainer,
  ModalDescription,
  ModalHeader,
  ModalIcon,
  ModalInfo,
  ModalTitle,
  ModalWrapper,
} from "./modal.style";

import { ReactComponent as CloseOnlySvg } from "../../assets/icons/CloseOnly.svg";

export const MODAL_POSITIONS = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  CENTER: "CENTER",
};

export const MODAL_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
  EXTRA_LARGE: "EXTRA_LARGE",
};

const Modal = ({
  modalPosition = MODAL_POSITIONS.RIGHT,
  modalSize = MODAL_SIZES.NORMAL,
  name,
  leading,
  icon,
  title,
  description,
  action,
  children,
  onModalClose,
}) => {
  const dispatch = useDispatch();

  const modalContainerRef = useRef();

  const isRightToLeft = useSelector(getIsRightToLeft);
  const isModalOpen = useSelector(getIsModalOpen);
  const currentModal = useSelector(getCurrentModal);

  // const handleWrapperClick = ({ target }) => {
  //   if (!modalContainerRef?.current?.contains(target)) {
  //     dispatch(setCurrentModal(name));
  //     dispatch(setIsModalOpen(false));
  //   }
  // };

  const handleCloseClick = () => {
    document.body.click();
    dispatch(setCurrentModal(name));
    dispatch(setIsModalOpen(false));
  };

  useEffect(() => {
    if (!isModalOpen && currentModal === name) onModalClose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, currentModal]);

  useEffect(() => {
    const handleKeyDown = ({ key }) => {
      if (key === "Escape") handleCloseClick();
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const handleMouseDown = (event) => {
  //     if (!(isModalOpen && currentModal === name)) return;

  //     const clientRect = modalContainerRef.current.getBoundingClientRect();

  //     const { left, right, top, bottom } = clientRect ?? {};
  //     const { clientX, clientY } = event ?? {};

  //     if (
  //       parseToFloat(clientX) < parseToFloat(left) ||
  //       parseToFloat(clientX) > parseToFloat(right) ||
  //       parseToFloat(clientY) < parseToFloat(top) ||
  //       parseToFloat(clientY) > parseToFloat(bottom)
  //     ) {
  //       dispatch(setIsModalOpen(false));
  //     }
  //   };

  //   document.addEventListener("mousedown", handleMouseDown);

  //   return () => document.removeEventListener("mousedown", handleMouseDown);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isModalOpen, currentModal]);

  return createPortal(
    <ModalWrapper
      isRightToLeft={isRightToLeft}
      isActive={isModalOpen && currentModal === name}
      // onClick={handleWrapperClick}
      modalPosition={modalPosition}
    >
      <ModalContainer ref={modalContainerRef} modalSize={modalSize}>
        <ModalHeader>
          {isEmpty(leading) ? (
            <>
              {!isEmpty(icon) && (
                <ModalIcon isLarge={!isEmpty(title) && !isEmpty(description)}>
                  {icon}
                </ModalIcon>
              )}
              {(!isEmpty(title) || !isEmpty(description)) && (
                <ModalInfo>
                  {!isEmpty(title) && <ModalTitle>{title}</ModalTitle>}
                  {!isEmpty(description) && (
                    <ModalDescription>{description}</ModalDescription>
                  )}
                </ModalInfo>
              )}
            </>
          ) : (
            leading
          )}
          <ModalActionGroup>
            {action}
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.SECONDARY}
              buttonSize={BUTTON_SIZES.PX48}
              buttonType={BUTTON_TYPES.ICON}
              onClick={handleCloseClick}
              isCircle
            >
              <CloseOnlySvg />
            </Button>
          </ModalActionGroup>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </ModalContainer>
    </ModalWrapper>,
    document.body
  );
};

export default Modal;
