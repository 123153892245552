const RENEWAL_ADMIN_ACTION_TYPES = {
  RESET_RENEWAL_ADMIN_REDUCER: "renewal-admin/RESET_RENEWAL_ADMIN_REDUCER",

  SET_RENEWALS: "renewal-admin/SET_RENEWALS",
  SET_RENEWAL: "renewal-admin/SET_RENEWAL",
  SET_RENEWAL_MARKET: "renewal-admin/SET_RENEWAL_MARKET",
  SET_RENEWAL_BRANCHES: "renewal-admin/SET_RENEWAL_BRANCHES",
  SET_RENEWAL_CREATE: "renewal-admin/SET_RENEWAL_CREATE",
  SET_RENEWAL_CREATE_CALCULATE: "renewal-admin/SET_RENEWAL_CREATE_CALCULATE",
  SET_RENEWAL_CUSTOM: "renewal-admin/SET_RENEWAL_CUSTOM",
  SET_RENEWAL_CUSTOM_CALCULATE: "renewal-admin/SET_RENEWAL_CUSTOM_CALCULATE",
  SET_RENEWAL_UPDATE: "renewal-admin/SET_RENEWAL_UPDATE",
  SET_RENEWAL_UPDATE_CALCULATE: "renewal-admin/SET_RENEWAL_UPDATE_CALCULATE",

  APPEND_RENEWALS: "renewal-admin/APPEND_RENEWALS",
  APPEND_RENEWAL_BRANCHES: "renewal-admin/APPEND_RENEWAL_BRANCHES",

  SET_IS_RENEWALS_HAS_MORE: "renewal-admin/SET_IS_RENEWALS_HAS_MORE",
  SET_IS_RENEWAL_BRANCHES_HAS_MORE:
    "renewal-admin/SET_IS_RENEWAL_BRANCHES_HAS_MORE",

  SET_FETCH_RENEWALS_SEARCH: "renewal-admin/SET_FETCH_RENEWALS_SEARCH",
  SET_FETCH_RENEWALS_SORT: "renewal-admin/SET_FETCH_RENEWALS_SORT",
  SET_FETCH_RENEWALS_KEY_BY: "renewal-admin/SET_FETCH_RENEWALS_KEY_BY",
  SET_FETCH_RENEWALS_PAGE: "renewal-admin/SET_FETCH_RENEWALS_PAGE",
  SET_FETCH_RENEWALS_PER_PAGE: "renewal-admin/SET_FETCH_RENEWALS_PER_PAGE",
  SET_FETCH_RENEWALS_INCLUDES: "renewal-admin/SET_FETCH_RENEWALS_INCLUDES",
  SET_FETCH_RENEWALS_FILTER_MARKET_ID:
    "renewal-admin/SET_FETCH_RENEWALS_FILTER_MARKET_ID",
  SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS:
    "renewal-admin/SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS",
  SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS:
    "renewal-admin/SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS",
  SET_FETCH_RENEWALS_FILTER_STATUSES:
    "renewal-admin/SET_FETCH_RENEWALS_FILTER_STATUSES",
  SET_FETCH_RENEWALS_FILTER_CREATED_BY:
    "renewal-admin/SET_FETCH_RENEWALS_FILTER_CREATED_BY",
  SET_FETCH_RENEWALS_LOADING: "renewal-admin/SET_FETCH_RENEWALS_LOADING",
  SET_FETCH_RENEWALS_SUCCESS: "renewal-admin/SET_FETCH_RENEWALS_SUCCESS",
  SET_FETCH_RENEWALS_FAILED: "renewal-admin/SET_FETCH_RENEWALS_FAILED",

  SET_FETCH_RENEWAL_LOADING: "renewal-admin/SET_FETCH_RENEWAL_LOADING",
  SET_FETCH_RENEWAL_SUCCESS: "renewal-admin/SET_FETCH_RENEWAL_SUCCESS",
  SET_FETCH_RENEWAL_FAILED: "renewal-admin/SET_FETCH_RENEWAL_FAILED",

  SET_FETCH_RENEWAL_MARKET_PARAMS:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_PARAMS",
  SET_FETCH_RENEWAL_MARKET_LOADING:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_LOADING",
  SET_FETCH_RENEWAL_MARKET_SUCCESS:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_SUCCESS",
  SET_FETCH_RENEWAL_MARKET_FAILED:
    "renewal-admin/SET_FETCH_RENEWAL_MARKET_FAILED",

  SET_FETCH_RENEWAL_BRANCHES_PARAMS:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_PARAMS",
  SET_FETCH_RENEWAL_BRANCHES_LOADING:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_LOADING",
  SET_FETCH_RENEWAL_BRANCHES_SUCCESS:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_SUCCESS",
  SET_FETCH_RENEWAL_BRANCHES_FAILED:
    "renewal-admin/SET_FETCH_RENEWAL_BRANCHES_FAILED",

  SET_CREATE_RENEWAL_LOADING: "renewal-admin/SET_CREATE_RENEWAL_LOADING",
  SET_CREATE_RENEWAL_SUCCESS: "renewal-admin/SET_CREATE_RENEWAL_SUCCESS",
  SET_CREATE_RENEWAL_FAILED: "renewal-admin/SET_CREATE_RENEWAL_FAILED",

  SET_CREATE_CALCULATE_RENEWAL_LOADING:
    "renewal-admin/SET_CREATE_CALCULATE_RENEWAL_LOADING",
  SET_CREATE_CALCULATE_RENEWAL_SUCCESS:
    "renewal-admin/SET_CREATE_CALCULATE_RENEWAL_SUCCESS",
  SET_CREATE_CALCULATE_RENEWAL_FAILED:
    "renewal-admin/SET_CREATE_CALCULATE_RENEWAL_FAILED",

  SET_CUSTOM_RENEWAL_LOADING: "renewal-admin/SET_CUSTOM_RENEWAL_LOADING",
  SET_CUSTOM_RENEWAL_SUCCESS: "renewal-admin/SET_CUSTOM_RENEWAL_SUCCESS",
  SET_CUSTOM_RENEWAL_FAILED: "renewal-admin/SET_CUSTOM_RENEWAL_FAILED",

  SET_CUSTOM_CALCULATE_RENEWAL_LOADING:
    "renewal-admin/SET_CUSTOM_CALCULATE_RENEWAL_LOADING",
  SET_CUSTOM_CALCULATE_RENEWAL_SUCCESS:
    "renewal-admin/SET_CUSTOM_CALCULATE_RENEWAL_SUCCESS",
  SET_CUSTOM_CALCULATE_RENEWAL_FAILED:
    "renewal-admin/SET_CUSTOM_CALCULATE_RENEWAL_FAILED",

  SET_UPDATE_RENEWAL_LOADING: "renewal-admin/SET_UPDATE_RENEWAL_LOADING",
  SET_UPDATE_RENEWAL_SUCCESS: "renewal-admin/SET_UPDATE_RENEWAL_SUCCESS",
  SET_UPDATE_RENEWAL_FAILED: "renewal-admin/SET_UPDATE_RENEWAL_FAILED",

  SET_UPDATE_CALCULATE_RENEWAL_LOADING:
    "renewal-admin/SET_UPDATE_CALCULATE_RENEWAL_LOADING",
  SET_UPDATE_CALCULATE_RENEWAL_SUCCESS:
    "renewal-admin/SET_UPDATE_CALCULATE_RENEWAL_SUCCESS",
  SET_UPDATE_CALCULATE_RENEWAL_FAILED:
    "renewal-admin/SET_UPDATE_CALCULATE_RENEWAL_FAILED",

  SET_IS_FETCH_RENEWALS_HITTED: "renewal-admin/SET_IS_FETCH_RENEWALS_HITTED",
  SET_IS_FETCH_RENEWAL_HITTED: "renewal-admin/SET_IS_FETCH_RENEWAL_HITTED",
  SET_IS_FETCH_RENEWAL_MARKET_HITTED:
    "renewal-admin/SET_IS_FETCH_RENEWAL_MARKET_HITTED",
  SET_IS_FETCH_RENEWAL_BRANCHES_HITTED:
    "renewal-admin/SET_IS_FETCH_RENEWAL_BRANCHES_HITTED",
  SET_IS_CREATE_RENEWAL_HITTED: "renewal-admin/SET_IS_CREATE_RENEWAL_HITTED",
  SET_IS_CREATE_CALCULATE_RENEWAL_HITTED:
    "renewal-admin/SET_IS_CREATE_CALCULATE_RENEWAL_HITTED",
  SET_IS_CUSTOM_RENEWAL_HITTED: "renewal-admin/SET_IS_CUSTOM_RENEWAL_HITTED",
  SET_IS_CUSTOM_CALCULATE_RENEWAL_HITTED:
    "renewal-admin/SET_IS_CUSTOM_CALCULATE_RENEWAL_HITTED",
  SET_IS_UPDATE_RENEWAL_HITTED: "renewal-admin/SET_IS_UPDATE_RENEWAL_HITTED",
  SET_IS_UPDATE_CALCULATE_RENEWAL_HITTED:
    "renewal-admin/SET_IS_UPDATE_CALCULATE_RENEWAL_HITTED",

  FETCH_RENEWALS_START: "renewal-admin/FETCH_RENEWALS_START",
  FETCH_RENEWAL_START: "renewal-admin/FETCH_RENEWAL_START",
  FETCH_RENEWAL_MARKET_START: "renewal-admin/FETCH_RENEWAL_MARKET_START",
  FETCH_RENEWAL_BRANCHES_START: "renewal-admin/FETCH_RENEWAL_BRANCHES_START",
  CREATE_RENEWAL_START: "renewal-admin/CREATE_RENEWAL_START",
  CREATE_CALCULATE_RENEWAL_START:
    "renewal-admin/CREATE_CALCULATE_RENEWAL_START",
  CUSTOM_RENEWAL_START: "renewal-admin/CUSTOM_RENEWAL_START",
  CUSTOM_CALCULATE_RENEWAL_START:
    "renewal-admin/CUSTOM_CALCULATE_RENEWAL_START",
  UPDATE_RENEWAL_START: "renewal-admin/UPDATE_RENEWAL_START",
  UPDATE_CALCULATE_RENEWAL_START:
    "renewal-admin/UPDATE_CALCULATE_RENEWAL_START",
};

export default RENEWAL_ADMIN_ACTION_TYPES;
