import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setFetchReportPurchaseFailed,
  setFetchReportPurchasesFailed,
  setFetchReportTotalsFailed,
} from "../../store/report-purchase/report-purchase.action";
import {
  getFetchReportPurchaseFailed,
  getFetchReportPurchasesFailed,
  getFetchReportTotalsFailed,
} from "../../store/report-purchase/report-purchase.selector";

const ReportPurchasePopup = () => {
  const dispatch = useDispatch();

  const fetchReportTotalsFailed = useSelector(getFetchReportTotalsFailed);
  const fetchReportPurchasesFailed = useSelector(getFetchReportPurchasesFailed);
  const fetchReportPurchaseFailed = useSelector(getFetchReportPurchaseFailed);

  useEffect(() => {
    if (
      fetchReportTotalsFailed !== null ||
      fetchReportPurchasesFailed !== null ||
      fetchReportPurchaseFailed !== null
    ) {
      showErrorMessage(
        fetchReportTotalsFailed ??
          fetchReportPurchasesFailed ??
          fetchReportPurchaseFailed
      );

      if (fetchReportTotalsFailed !== null)
        dispatch(setFetchReportTotalsFailed(null));
      if (fetchReportPurchasesFailed !== null)
        dispatch(setFetchReportPurchasesFailed(null));
      if (fetchReportPurchaseFailed !== null)
        dispatch(setFetchReportPurchaseFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchReportTotalsFailed,
    fetchReportPurchasesFailed,
    fetchReportPurchaseFailed,
  ]);

  return <></>;
};

export default ReportPurchasePopup;
