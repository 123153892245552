import { useTranslation } from "react-i18next";

import { parseToInteger } from "../../utils/parser.utils";

import { PURCHASE_TYPES } from "../../constants/purchase.constant";

import Table, {
  TABLE_COLUMN_ALIGNS,
  TABLE_COLUMN_BACKGROUNDS,
  TABLE_STYLES,
} from "../../components/table-v2/table.component";
import EmptyStateTable from "../empty-state-table/empty-state-table.widget";
import PurchaseFormTableQty from "../purchase-form-table-qty/purchase-form-table-qty.widget";
import PurchaseFormTableTotal from "../purchase-form-table-total/purchase-form-table-total.widget";
import PurchaseFormTableIngredientDetails from "../purchase-form-table-ingredient-details/purchase-form-table-ingredient-details.widget";
import PurchaseFormTableIngredientAction from "../purchase-form-table-ingredient-action/purchase-form-table-ingredient-action.widget";
import PurchaseFormTableItemDetails from "../purchase-form-table-item-details/purchase-form-table-item-details.widget";
import PurchaseFormTableItemAction from "../purchase-form-table-item-action/purchase-form-table-item-action.widget";

const TableRowIngredient = ({ index, ingredient }) => {
  const { qty, total } = ingredient ?? {};

  return (
    <Table.Row>
      <Table.Col>
        <PurchaseFormTableIngredientDetails ingredient={ingredient} />
      </Table.Col>
      <Table.Col>
        <PurchaseFormTableQty quantity={qty} />
      </Table.Col>
      <Table.Col>
        <PurchaseFormTableTotal total={total} />
      </Table.Col>
      <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.END}>
        <PurchaseFormTableIngredientAction
          index={index}
          ingredient={ingredient}
        />
      </Table.Col>
    </Table.Row>
  );
};
const TableRowItem = ({ index, item }) => {
  const { qty, total } = item ?? {};

  return (
    <Table.Row>
      <Table.Col>
        <PurchaseFormTableItemDetails item={item} />
      </Table.Col>
      <Table.Col>
        <PurchaseFormTableQty quantity={qty} />
      </Table.Col>
      <Table.Col>
        <PurchaseFormTableTotal total={total} />
      </Table.Col>
      <Table.Col colHorAlign={TABLE_COLUMN_ALIGNS.END}>
        <PurchaseFormTableItemAction index={index} item={item} />
      </Table.Col>
    </Table.Row>
  );
};

const PurchaseFormTable = ({ type, ingredients = [], items = [] }) => {
  const { t } = useTranslation();

  return (
    <Table tableStyle={TABLE_STYLES.STYLE_2}>
      <Table.Head>
        <Table.Row>
          <Table.Col colBackground={TABLE_COLUMN_BACKGROUNDS.LIGHT}>
            {t("Item Details")}
          </Table.Col>
          <Table.Col colBackground={TABLE_COLUMN_BACKGROUNDS.LIGHT}>
            {t("Item Quantity")}
          </Table.Col>
          <Table.Col colBackground={TABLE_COLUMN_BACKGROUNDS.LIGHT}>
            {t("Item Total")}
          </Table.Col>
          <Table.Col colBackground={TABLE_COLUMN_BACKGROUNDS.LIGHT}></Table.Col>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {type === PURCHASE_TYPES.TYPE_INGREDIENT &&
        parseToInteger(ingredients?.length) > 0 ? (
          ingredients.map((ingredient, index) => (
            <TableRowIngredient
              key={index}
              index={index}
              ingredient={ingredient}
            />
          ))
        ) : type === PURCHASE_TYPES.TYPE_EXPENSE &&
          parseToInteger(items?.length) > 0 ? (
          items.map((item, index) => (
            <TableRowItem key={index} index={index} item={item} />
          ))
        ) : (
          <Table.Row>
            <Table.Col colSpan={4}>
              <EmptyStateTable />
            </Table.Col>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
};

export default PurchaseFormTable;
