import STATE_RECIPE_ACTION_TYPES from "./state-recipe.type";

export const STATE_RECIPE_INITIAL_STATE = {
  recipeIngredients: [],
};

export const stateRecipeReducer = (
  state = STATE_RECIPE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  const recipeIngredients = [...state.recipeIngredients];

  switch (type) {
    case STATE_RECIPE_ACTION_TYPES.SET_RECIPE_INGREDIENTS:
      return { ...state, recipeIngredients: payload };

    case STATE_RECIPE_ACTION_TYPES.APPEND_RECIPE_INGREDIENT:
      recipeIngredients.push(payload);
      return { ...state, recipeIngredients };
    case STATE_RECIPE_ACTION_TYPES.UPDATE_RECIPE_INGREDIENT:
      if (payload.index in recipeIngredients) {
        recipeIngredients[payload.index] = payload.value;
      }
      return { ...state, recipeIngredients };
    case STATE_RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT:
      if (payload in recipeIngredients) {
        recipeIngredients.splice(payload, 1);
      }
      return { ...state, recipeIngredients };

    case STATE_RECIPE_ACTION_TYPES.RESET_STATE_RECIPE_REDUCER:
      return STATE_RECIPE_INITIAL_STATE;
    default:
      return state;
  }
};
