import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import {
  appendCategoriesStart,
  appendSearchCategories,
  fetchCategoriesStart,
  searchCategoriesStart,
  setAppendCategoriesParams,
  setFetchCategoriesParams,
  setSearchCategoriesParams,
} from "../../store/select-category-purchase/select-category-purchase.action";
import {
  getAppendCategoriesLoading,
  getAppendCategoriesParams,
  getCategories,
  getFetchCategoriesLoading,
  getFetchCategoriesParams,
  getIsCategoriesHasMore,
  getIsFetchCategoriesHitted,
  getIsSearchCategoriesHasMore,
  getIsSearchCategoriesHitted,
  getSearchCategories,
  getSearchCategoriesLoading,
  getSearchCategoriesParams,
} from "../../store/select-category-purchase/select-category-purchase.selector";

import FormSelectAsync from "../../components/form-select-async/form-select-async.component";

const SelectCategoryPurchase = ({
  selectSize,
  label,
  name,
  value,
  placeholder,
  onChange,
  isDisabled = false,
  isMultiple = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const [options, setOptions] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const appendCategoriesParams = useSelector(getAppendCategoriesParams);
  const appendCategoriesLoading = useSelector(getAppendCategoriesLoading);

  const isFetchCategoriesHitted = useSelector(getIsFetchCategoriesHitted);
  const isCategoriesHasMore = useSelector(getIsCategoriesHasMore);
  const fetchCategoriesParams = useSelector(getFetchCategoriesParams);
  const fetchCategoriesLoading = useSelector(getFetchCategoriesLoading);
  const categories = useSelector(getCategories);

  const isSearchCategoriesHitted = useSelector(getIsSearchCategoriesHitted);
  const isSearchCategoriesHasMore = useSelector(getIsSearchCategoriesHasMore);
  const searchCategoriesParams = useSelector(getSearchCategoriesParams);
  const searchCategoriesLoading = useSelector(getSearchCategoriesLoading);
  const searchCategories = useSelector(getSearchCategories);

  const getParams = (parameters) => {
    const params = { ...parameters };
    params.page = 1;
    params.per_page = 20;
    params.key_by = "id";
    params.filter = params?.filter ?? {};

    return params;
  };

  const handleSearchChange = (searchKey) => setSearchKey(searchKey);

  const handleMainFetch = () => {
    const params = getParams(fetchCategoriesParams);
    dispatch(setFetchCategoriesParams(params));
    dispatch(fetchCategoriesStart());
  };
  const handleSearchFetch = () => {
    if (isEmpty(searchKey)) {
      setIsLoading(false);
      return;
    }

    const params = getParams(searchCategoriesParams);
    params.search = searchKey;

    dispatch(setSearchCategoriesParams(params));
    dispatch(searchCategoriesStart());
  };
  const handleAppendValue = () => {
    const isValueExist = isMultiple
      ? isEmpty(value?.filter((val) => isEmpty(categories?.[val])))
      : !isEmpty(categories?.[value]);

    if (!isEmpty(value) && isValueExist) {
      setIsLoading(false);
      return;
    }

    const params = getParams(appendCategoriesParams);
    params.filter.categories_ids = isMultiple ? value : [value];

    dispatch(setAppendCategoriesParams(params));
    dispatch(appendCategoriesStart());
  };

  const handleMainBottomScroll = () => {
    if (
      !isFetchCategoriesHitted ||
      !isCategoriesHasMore ||
      fetchCategoriesLoading
    ) {
      return;
    }

    const params = { ...fetchCategoriesParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setFetchCategoriesParams(params));
    dispatch(fetchCategoriesStart());
  };
  const handleSearchBottomScroll = () => {
    if (
      !isSearchCategoriesHitted ||
      !isSearchCategoriesHasMore ||
      searchCategoriesLoading
    ) {
      return;
    }

    const params = { ...searchCategoriesParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setSearchCategoriesParams(params));
    dispatch(searchCategoriesStart());
  };

  useEffect(() => {
    setIsLoading(
      fetchCategoriesLoading ||
        searchCategoriesLoading ||
        appendCategoriesLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCategoriesLoading,
    searchCategoriesLoading,
    appendCategoriesLoading,
  ]);

  useEffect(() => {
    const optionsTimeout = setTimeout(() => {
      const options = isSearching ? searchCategories : categories;
      setOptions(Object.values(options));
    }, 300);

    return () => clearTimeout(optionsTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, searchCategories, isSearching]);

  useEffect(() => {
    setIsLoading(true);

    const initializeTimeout = setTimeout(() => handleMainFetch(), 300);
    return () => clearTimeout(initializeTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(appendSearchCategories(categories));

    setIsLoading(true);
    setIsSearching(!isEmpty(searchKey));

    const searchTimeout = setTimeout(() => handleSearchFetch(), 1500);
    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey]);

  useEffect(() => {
    if (!isEmpty(value)) {
      setIsLoading(true);

      const appendTimeout = setTimeout(() => handleAppendValue(), 300);
      return () => clearTimeout(appendTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormSelectAsync
      selectSize={selectSize}
      label={label}
      labelKey={`name_${i18n.language}`}
      valueKey="id"
      name={name}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      onSearchChange={handleSearchChange}
      onBottomScroll={
        isSearching ? handleSearchBottomScroll : handleMainBottomScroll
      }
      value={value}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isMultiple={isMultiple}
      {...otherProps}
    />
  );
};

export default SelectCategoryPurchase;
