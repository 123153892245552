import styled, { css } from "styled-components";

import { BUTTON_SIZES } from "../../components/button/button.component";

const getContainerSize = ({ buttonSize }) => {
  switch (buttonSize) {
    case BUTTON_SIZES.LARGE:
      return `
        width: 1.35rem;
        min-width: 1.35rem;
        max-width: 1.35rem;
        height: 1.35rem;
        min-height: 1.35rem;
        max-height: 1.35rem;
      `;
    case BUTTON_SIZES.SMALL:
      return `
        width: 1.15rem;
        min-width: 1.15rem;
        max-width: 1.15rem;
        height: 1.15rem;
        min-height: 1.15rem;
        max-height: 1.15rem;
      `;
    case BUTTON_SIZES.NORMAL:
    default:
      return `
        width: 1.25rem;
        min-width: 1.25rem;
        max-width: 1.25rem;
        height: 1.25rem;
        min-height: 1.25rem;
        max-height: 1.25rem;
      `;
  }
};
const getIconSize = ({ buttonSize }) => {
  switch (buttonSize) {
    case BUTTON_SIZES.LARGE:
      return `
        stroke-width: 3.5;
        width: 0.875rem !important;
        min-width: 0.875rem !important;
        max-width: 0.875rem !important;
        height: 0.875rem !important;
        min-height: 0.875rem !important;
        max-height: 0.875rem !important;
      `;
    case BUTTON_SIZES.SMALL:
      return `
        stroke-width: 4;
        width: 0.65rem !important;
        min-width: 0.65rem !important;
        max-width: 0.65rem !important;
        height: 0.65rem !important;
        min-height: 0.65rem !important;
        max-height: 0.65rem !important;
      `;
    case BUTTON_SIZES.NORMAL:
    default:
      return `
        stroke-width: 3.5;
        width: 0.75rem !important;
        min-width: 0.75rem !important;
        max-width: 0.75rem !important;
        height: 0.75rem !important;
        min-height: 0.75rem !important;
        max-height: 0.75rem !important;
      `;
  }
};

export const ButtonCheckToggleIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #dbdbe3;
  background-color: #ffffff;

  ${getContainerSize}

  svg {
    display: none;
    stroke: #ffffff;

    ${getIconSize}
  }

  ${({ isChecked }) =>
    isChecked &&
    css`
      border-color: #2900ee;
      background-color: #2900ee;

      svg {
        display: inline-block;
      }
    `}
`;
