import { createSelector } from "reselect";

const renewalSubscriberSelector = ({ renewalSubscriber }) => renewalSubscriber;

export const getRenewals = createSelector(
  [renewalSubscriberSelector],
  ({ renewals }) => renewals
);
export const getRenewal = createSelector(
  [renewalSubscriberSelector],
  ({ renewal }) => renewal
);
export const getRenewalBranches = createSelector(
  [renewalSubscriberSelector],
  ({ renewalBranches }) => renewalBranches
);
export const getRenewalCreate = createSelector(
  [renewalSubscriberSelector],
  ({ renewalCreate }) => renewalCreate
);
export const getRenewalCreateCalculate = createSelector(
  [renewalSubscriberSelector],
  ({ renewalCreateCalculate }) => renewalCreateCalculate
);

export const getIsRenewalsHasMore = createSelector(
  [renewalSubscriberSelector],
  ({ isRenewalsHasMore }) => isRenewalsHasMore
);
export const getIsRenewalBranchesHasMore = createSelector(
  [renewalSubscriberSelector],
  ({ isRenewalBranchesHasMore }) => isRenewalBranchesHasMore
);

export const getFetchRenewalsSearch = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsSearch }) => fetchRenewalsSearch
);
export const getFetchRenewalsSort = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsSort }) => fetchRenewalsSort
);
export const getFetchRenewalsKeyBy = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsKeyBy }) => fetchRenewalsKeyBy
);
export const getFetchRenewalsPage = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsPage }) => fetchRenewalsPage
);
export const getFetchRenewalsPerPage = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsPerPage }) => fetchRenewalsPerPage
);
export const getFetchRenewalsIncludes = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsIncludes }) => fetchRenewalsIncludes
);
export const getFetchRenewalsFilterMarketId = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFilterMarketId }) => fetchRenewalsFilterMarketId
);
export const getFetchRenewalsFilterSubscriptionKeys = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFilterSubscriptionKeys }) =>
    fetchRenewalsFilterSubscriptionKeys
);
export const getFetchRenewalsFilterSubscriptionOptionKeys = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFilterSubscriptionOptionKeys }) =>
    fetchRenewalsFilterSubscriptionOptionKeys
);
export const getFetchRenewalsFilterStatuses = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFilterStatuses }) => fetchRenewalsFilterStatuses
);
export const getFetchRenewalsFilterCreatedBy = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFilterCreatedBy }) => fetchRenewalsFilterCreatedBy
);
export const getFetchRenewalsLoading = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsLoading }) => fetchRenewalsLoading
);
export const getFetchRenewalsSuccess = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsSuccess }) => fetchRenewalsSuccess
);
export const getFetchRenewalsFailed = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalsFailed }) => fetchRenewalsFailed
);

export const getFetchRenewalLoading = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalLoading }) => fetchRenewalLoading
);
export const getFetchRenewalSuccess = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalSuccess }) => fetchRenewalSuccess
);
export const getFetchRenewalFailed = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalFailed }) => fetchRenewalFailed
);

export const getFetchRenewalBranchesParams = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalBranchesParams }) => fetchRenewalBranchesParams
);
export const getFetchRenewalBranchesLoading = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalBranchesLoading }) => fetchRenewalBranchesLoading
);
export const getFetchRenewalBranchesSuccess = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalBranchesSuccess }) => fetchRenewalBranchesSuccess
);
export const getFetchRenewalBranchesFailed = createSelector(
  [renewalSubscriberSelector],
  ({ fetchRenewalBranchesFailed }) => fetchRenewalBranchesFailed
);

export const getCreateRenewalLoading = createSelector(
  [renewalSubscriberSelector],
  ({ createRenewalLoading }) => createRenewalLoading
);
export const getCreateRenewalSuccess = createSelector(
  [renewalSubscriberSelector],
  ({ createRenewalSuccess }) => createRenewalSuccess
);
export const getCreateRenewalFailed = createSelector(
  [renewalSubscriberSelector],
  ({ createRenewalFailed }) => createRenewalFailed
);

export const getCreateCalculateRenewalLoading = createSelector(
  [renewalSubscriberSelector],
  ({ createCalculateRenewalLoading }) => createCalculateRenewalLoading
);
export const getCreateCalculateRenewalSuccess = createSelector(
  [renewalSubscriberSelector],
  ({ createCalculateRenewalSuccess }) => createCalculateRenewalSuccess
);
export const getCreateCalculateRenewalFailed = createSelector(
  [renewalSubscriberSelector],
  ({ createCalculateRenewalFailed }) => createCalculateRenewalFailed
);

export const getIsFetchRenewalsHitted = createSelector(
  [renewalSubscriberSelector],
  ({ isFetchRenewalsHitted }) => isFetchRenewalsHitted
);
export const getIsFetchRenewalHitted = createSelector(
  [renewalSubscriberSelector],
  ({ isFetchRenewalHitted }) => isFetchRenewalHitted
);
export const getIsFetchRenewalBranchesHitted = createSelector(
  [renewalSubscriberSelector],
  ({ isFetchRenewalBranchesHitted }) => isFetchRenewalBranchesHitted
);
export const getIsCreateRenewalHitted = createSelector(
  [renewalSubscriberSelector],
  ({ isCreateRenewalHitted }) => isCreateRenewalHitted
);
export const getIsCreateCalculateRenewalHitted = createSelector(
  [renewalSubscriberSelector],
  ({ isCreateCalculateRenewalHitted }) => isCreateCalculateRenewalHitted
);
