import RENEWAL_TRANSFER_ACTION_TYPES from "./renewal-transfer.type";

export const RENEWAL_TRANSFER_INITIAL_STATE = {
  renewalTransfers: [],
  renewalTransfer: null,

  isRenewalTransfersHasMore: true,

  fetchRenewalTransfersParams: null,
  fetchRenewalTransfersLoading: false,
  fetchRenewalTransfersSuccess: null,
  fetchRenewalTransfersFailed: null,

  fetchRenewalTransferParams: null,
  fetchRenewalTransferLoading: false,
  fetchRenewalTransferSuccess: null,
  fetchRenewalTransferFailed: null,

  createRenewalTransferLoading: false,
  createRenewalTransferSuccess: null,
  createRenewalTransferFailed: null,

  updateRenewalTransferLoading: false,
  updateRenewalTransferSuccess: null,
  updateRenewalTransferFailed: null,

  deleteRenewalTransferLoading: false,
  deleteRenewalTransferSuccess: null,
  deleteRenewalTransferFailed: null,

  syncRateRenewalTransferLoading: false,
  syncRateRenewalTransferSuccess: null,
  syncRateRenewalTransferFailed: null,

  isFetchRenewalTransfersHitted: false,
  isFetchRenewalTransferHitted: false,
  isCreateRenewalTransferHitted: false,
  isUpdateRenewalTransferHitted: false,
  isDeleteRenewalTransferHitted: false,
  isSyncRateRenewalTransferHitted: false,
};

export const renewalTransferReducer = (
  state = RENEWAL_TRANSFER_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_RENEWAL_TRANSFERS:
      return { ...state, renewalTransfers: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_RENEWAL_TRANSFER:
      return { ...state, renewalTransfer: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_RENEWAL_TRANSFERS_HAS_MORE:
      return { ...state, isRenewalTransfersHasMore: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_PARAMS:
      return { ...state, fetchRenewalTransfersParams: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_LOADING:
      return { ...state, fetchRenewalTransfersLoading: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_SUCCESS:
      return { ...state, fetchRenewalTransfersSuccess: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_FAILED:
      return { ...state, fetchRenewalTransfersFailed: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_PARAMS:
      return { ...state, fetchRenewalTransferParams: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_LOADING:
      return { ...state, fetchRenewalTransferLoading: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_SUCCESS:
      return { ...state, fetchRenewalTransferSuccess: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_FAILED:
      return { ...state, fetchRenewalTransferFailed: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_LOADING:
      return { ...state, createRenewalTransferLoading: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_SUCCESS:
      return { ...state, createRenewalTransferSuccess: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_FAILED:
      return { ...state, createRenewalTransferFailed: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_LOADING:
      return { ...state, updateRenewalTransferLoading: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_SUCCESS:
      return { ...state, updateRenewalTransferSuccess: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_FAILED:
      return { ...state, updateRenewalTransferFailed: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_LOADING:
      return { ...state, deleteRenewalTransferLoading: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_SUCCESS:
      return { ...state, deleteRenewalTransferSuccess: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_FAILED:
      return { ...state, deleteRenewalTransferFailed: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_TRANSFERS_HITTED:
      return { ...state, isFetchRenewalTransfersHitted: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_TRANSFER_HITTED:
      return { ...state, isFetchRenewalTransferHitted: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_CREATE_RENEWAL_TRANSFER_HITTED:
      return { ...state, isCreateRenewalTransferHitted: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_UPDATE_RENEWAL_TRANSFER_HITTED:
      return { ...state, isUpdateRenewalTransferHitted: payload };
    case RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_DELETE_RENEWAL_TRANSFER_HITTED:
      return { ...state, isDeleteRenewalTransferHitted: payload };

    case RENEWAL_TRANSFER_ACTION_TYPES.APPEND_RENEWAL_TRANSFERS:
      return {
        ...state,
        renewalTransfers: [...state.renewalTransfers, ...payload],
      };

    case RENEWAL_TRANSFER_ACTION_TYPES.RESET_RENEWAL_TRANSFER_REDUCER:
      return RENEWAL_TRANSFER_INITIAL_STATE;
    default:
      return state;
  }
};
