const ORDER_FORM_CATEGORY_ACTION_TYPES = {
  RESET_ORDER_FORM_CATEGORY_REDUCER:
    "order-form-category/RESET_ORDER_FORM_CATEGORY_REDUCER",

  SET_CATEGORIES: "order-form-category/SET_CATEGORIES",
  APPEND_CATEGORIES: "order-form-category/APPEND_CATEGORIES",

  SET_IS_CATEGORIES_HAS_MORE: "order-form-category/SET_IS_CATEGORIES_HAS_MORE",

  SET_FETCH_CATEGORIES_PARAMS:
    "order-form-category/SET_FETCH_CATEGORIES_PARAMS",
  SET_FETCH_CATEGORIES_LOADING:
    "order-form-category/SET_FETCH_CATEGORIES_LOADING",
  SET_FETCH_CATEGORIES_SUCCESS:
    "order-form-category/SET_FETCH_CATEGORIES_SUCCESS",
  SET_FETCH_CATEGORIES_FAILED:
    "order-form-category/SET_FETCH_CATEGORIES_FAILED",

  SET_IS_FETCH_CATEGORIES_HITTED:
    "order-form-category/SET_IS_FETCH_CATEGORIES_HITTED",

  FETCH_CATEGORIES_START: "order-form-category/FETCH_CATEGORIES_START",
};

export default ORDER_FORM_CATEGORY_ACTION_TYPES;
