const ORDER_FORM_PRODUCT_ACTION_TYPES = {
  RESET_ORDER_FORM_PRODUCT_REDUCER:
    "order-form-product/RESET_ORDER_FORM_PRODUCT_REDUCER",

  SET_BASE_PRODUCTS: "order-form-product/SET_BASE_PRODUCTS",
  SET_SORT_PRODUCTS: "order-form-product/SET_SORT_PRODUCTS",

  APPEND_BASE_PRODUCTS: "order-form-product/APPEND_BASE_PRODUCTS",

  SET_IS_PRODUCTS_HAS_MORE: "order-form-product/SET_IS_PRODUCTS_HAS_MORE",

  SET_FETCH_PRODUCTS_PARAMS: "order-form-product/SET_FETCH_PRODUCTS_PARAMS",
  SET_FETCH_PRODUCTS_LOADING: "order-form-product/SET_FETCH_PRODUCTS_LOADING",
  SET_FETCH_PRODUCTS_SUCCESS: "order-form-product/SET_FETCH_PRODUCTS_SUCCESS",
  SET_FETCH_PRODUCTS_FAILED: "order-form-product/SET_FETCH_PRODUCTS_FAILED",

  SET_SORT_PRODUCTS_LOADING: "order-form-product/SET_SORT_PRODUCTS_LOADING",
  SET_SORT_PRODUCTS_SUCCESS: "order-form-product/SET_SORT_PRODUCTS_SUCCESS",
  SET_SORT_PRODUCTS_FAILED: "order-form-product/SET_SORT_PRODUCTS_FAILED",

  SET_IS_FETCH_PRODUCTS_HITTED:
    "order-form-product/SET_IS_FETCH_PRODUCTS_HITTED",
  SET_IS_SORT_PRODUCTS_HITTED: "order-form-product/SET_IS_SORT_PRODUCTS_HITTED",

  SWITCH_SORT_VALUE: "order-form-product/SWITCH_SORT_VALUE",
  REMOVE_SORT_VALUE: "order-form-product/REMOVE_SORT_VALUE",

  FETCH_PRODUCTS_START: "order-form-product/FETCH_PRODUCTS_START",
  SORT_PRODUCTS_START: "order-form-product/SORT_PRODUCTS_START",
};

export default ORDER_FORM_PRODUCT_ACTION_TYPES;
