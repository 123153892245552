import { createSelector } from "reselect";

const orderFormProductSelector = ({ orderFormProduct }) => orderFormProduct;

export const getBaseProducts = createSelector(
  [orderFormProductSelector],
  ({ baseProducts }) => baseProducts
);
export const getSortProducts = createSelector(
  [orderFormProductSelector],
  ({ sortProducts }) => sortProducts
);
export const getSortValues = createSelector(
  [orderFormProductSelector],
  ({ sortValues }) => sortValues
);

export const getIsProductsHasMore = createSelector(
  [orderFormProductSelector],
  ({ isProductsHasMore }) => isProductsHasMore
);

export const getFetchProductsParams = createSelector(
  [orderFormProductSelector],
  ({ fetchProductsParams }) => fetchProductsParams
);
export const getFetchProductsLoading = createSelector(
  [orderFormProductSelector],
  ({ fetchProductsLoading }) => fetchProductsLoading
);
export const getFetchProductsSuccess = createSelector(
  [orderFormProductSelector],
  ({ fetchProductsSuccess }) => fetchProductsSuccess
);
export const getFetchProductsFailed = createSelector(
  [orderFormProductSelector],
  ({ fetchProductsFailed }) => fetchProductsFailed
);

export const getSortProductsLoading = createSelector(
  [orderFormProductSelector],
  ({ sortProductsLoading }) => sortProductsLoading
);
export const getSortProductsSuccess = createSelector(
  [orderFormProductSelector],
  ({ sortProductsSuccess }) => sortProductsSuccess
);
export const getSortProductsFailed = createSelector(
  [orderFormProductSelector],
  ({ sortProductsFailed }) => sortProductsFailed
);

export const getIsFetchProductsHitted = createSelector(
  [orderFormProductSelector],
  ({ isFetchProductsHitted }) => isFetchProductsHitted
);
export const getIsSortProductsHitted = createSelector(
  [orderFormProductSelector],
  ({ isSortProductsHitted }) => isSortProductsHitted
);
