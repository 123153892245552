import { createSelector } from "reselect";

const selectSectorSelector = ({ selectSector }) => selectSector;

export const getSectors = createSelector(
  [selectSectorSelector],
  ({ sectors }) => sectors
);
export const getSearchSectors = createSelector(
  [selectSectorSelector],
  ({ searchSectors }) => searchSectors
);

export const getIsSectorsHasMore = createSelector(
  [selectSectorSelector],
  ({ isSectorsHasMore }) => isSectorsHasMore
);
export const getIsSearchSectorsHasMore = createSelector(
  [selectSectorSelector],
  ({ isSearchSectorsHasMore }) => isSearchSectorsHasMore
);

export const getFetchSectorsParams = createSelector(
  [selectSectorSelector],
  ({ fetchSectorsParams }) => fetchSectorsParams
);
export const getFetchSectorsLoading = createSelector(
  [selectSectorSelector],
  ({ fetchSectorsLoading }) => fetchSectorsLoading
);
export const getFetchSectorsSuccess = createSelector(
  [selectSectorSelector],
  ({ fetchSectorsSuccess }) => fetchSectorsSuccess
);
export const getFetchSectorsFailed = createSelector(
  [selectSectorSelector],
  ({ fetchSectorsFailed }) => fetchSectorsFailed
);

export const getSearchSectorsParams = createSelector(
  [selectSectorSelector],
  ({ searchSectorsParams }) => searchSectorsParams
);
export const getSearchSectorsLoading = createSelector(
  [selectSectorSelector],
  ({ searchSectorsLoading }) => searchSectorsLoading
);
export const getSearchSectorsSuccess = createSelector(
  [selectSectorSelector],
  ({ searchSectorsSuccess }) => searchSectorsSuccess
);
export const getSearchSectorsFailed = createSelector(
  [selectSectorSelector],
  ({ searchSectorsFailed }) => searchSectorsFailed
);

export const getAppendSectorsParams = createSelector(
  [selectSectorSelector],
  ({ appendSectorsParams }) => appendSectorsParams
);
export const getAppendSectorsLoading = createSelector(
  [selectSectorSelector],
  ({ appendSectorsLoading }) => appendSectorsLoading
);
export const getAppendSectorsSuccess = createSelector(
  [selectSectorSelector],
  ({ appendSectorsSuccess }) => appendSectorsSuccess
);
export const getAppendSectorsFailed = createSelector(
  [selectSectorSelector],
  ({ appendSectorsFailed }) => appendSectorsFailed
);

export const getIsFetchSectorsHitted = createSelector(
  [selectSectorSelector],
  ({ isFetchSectorsHitted }) => isFetchSectorsHitted
);
export const getIsSearchSectorsHitted = createSelector(
  [selectSectorSelector],
  ({ isSearchSectorsHitted }) => isSearchSectorsHitted
);
export const getIsAppendSectorsHitted = createSelector(
  [selectSectorSelector],
  ({ isAppendSectorsHitted }) => isAppendSectorsHitted
);
