import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";

const RENEWAL_ADMIN_INITIAL_STATE = {
  renewals: [],
  renewal: null,
  renewalMarket: null,
  renewalBranches: [],
  renewalCreate: null,
  renewalCreateCalculate: null,
  renewalCustom: null,
  renewalCustomCalculate: null,
  renewalUpdate: null,
  renewalUpdateCalculate: null,

  isRenewalsHasMore: true,
  isRenewalBranchesHasMore: true,

  fetchRenewalsSearch: null,
  fetchRenewalsSort: null,
  fetchRenewalsKeyBy: null,
  fetchRenewalsPage: 1,
  fetchRenewalsPerPage: null,
  fetchRenewalsIncludes: null,
  fetchRenewalsFilterMarketId: null,
  fetchRenewalsFilterSubscriptionKeys: null,
  fetchRenewalsFilterSubscriptionOptionKeys: null,
  fetchRenewalsFilterStatuses: null,
  fetchRenewalsFilterCreatedBy: null,
  fetchRenewalsLoading: false,
  fetchRenewalsSuccess: null,
  fetchRenewalsFailed: null,

  fetchRenewalLoading: false,
  fetchRenewalSuccess: null,
  fetchRenewalFailed: null,

  fetchRenewalMarketParams: {},
  fetchRenewalMarketLoading: false,
  fetchRenewalMarketSuccess: null,
  fetchRenewalMarketFailed: null,

  fetchRenewalBranchesParams: {},
  fetchRenewalBranchesLoading: false,
  fetchRenewalBranchesSuccess: null,
  fetchRenewalBranchesFailed: null,

  createRenewalLoading: false,
  createRenewalSuccess: null,
  createRenewalFailed: null,

  createCalculateRenewalLoading: false,
  createCalculateRenewalSuccess: null,
  createCalculateRenewalFailed: null,

  customRenewalLoading: false,
  customRenewalSuccess: null,
  customRenewalFailed: null,

  customCalculateRenewalLoading: false,
  customCalculateRenewalSuccess: null,
  customCalculateRenewalFailed: null,

  updateRenewalLoading: false,
  updateRenewalSuccess: null,
  updateRenewalFailed: null,

  updateCalculateRenewalLoading: false,
  updateCalculateRenewalSuccess: null,
  updateCalculateRenewalFailed: null,

  isFetchRenewalsHitted: false,
  isFetchRenewalHitted: false,
  isFetchRenewalMarketHitted: false,
  isFetchRenewalBranchesHitted: false,
  isCreateRenewalHitted: false,
  isCreateCalculateRenewalHitted: false,
  isCustomRenewalHitted: false,
  isCustomCalculateRenewalHitted: false,
  isUpdateRenewalHitted: false,
  isUpdateCalculateRenewalHitted: false,
};

export const renewalAdminReducer = (
  state = RENEWAL_ADMIN_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWALS:
      return { ...state, renewals: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL:
      return { ...state, renewal: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_MARKET:
      return { ...state, renewalMarket: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_BRANCHES:
      return { ...state, renewalBranches: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE:
      return { ...state, renewalCreate: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_CALCULATE:
      return { ...state, renewalCreateCalculate: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CUSTOM:
      return { ...state, renewalCustom: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CUSTOM_CALCULATE:
      return { ...state, renewalCustomCalculate: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_UPDATE:
      return { ...state, renewalUpdate: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_UPDATE_CALCULATE:
      return { ...state, renewalUpdateCalculate: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWALS:
      return {
        ...state,
        renewals: [...state.renewals, ...payload],
      };
    case RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWAL_BRANCHES:
      return {
        ...state,
        renewalBranches: [...state.renewalBranches, ...payload],
      };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWALS_HAS_MORE:
      return { ...state, isRenewalsHasMore: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_BRANCHES_HAS_MORE:
      return { ...state, isRenewalBranchesHasMore: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SEARCH:
      return { ...state, fetchRenewalsSearch: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SORT:
      return { ...state, fetchRenewalsSort: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_KEY_BY:
      return { ...state, fetchRenewalsKeyBy: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_PAGE:
      return { ...state, fetchRenewalsPage: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_PER_PAGE:
      return { ...state, fetchRenewalsPerPage: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_INCLUDES:
      return { ...state, fetchRenewalsIncludes: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_MARKET_ID:
      return { ...state, fetchRenewalsFilterMarketId: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS:
      return { ...state, fetchRenewalsFilterSubscriptionKeys: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS:
      return { ...state, fetchRenewalsFilterSubscriptionOptionKeys: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_STATUSES:
      return { ...state, fetchRenewalsFilterStatuses: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_CREATED_BY:
      return { ...state, fetchRenewalsFilterCreatedBy: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_LOADING:
      return { ...state, fetchRenewalsLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SUCCESS:
      return { ...state, fetchRenewalsSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FAILED:
      return { ...state, fetchRenewalsFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_LOADING:
      return { ...state, fetchRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_SUCCESS:
      return { ...state, fetchRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_FAILED:
      return { ...state, fetchRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_PARAMS:
      return { ...state, fetchRenewalMarketParams: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_LOADING:
      return { ...state, fetchRenewalMarketLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_SUCCESS:
      return { ...state, fetchRenewalMarketSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_FAILED:
      return { ...state, fetchRenewalMarketFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_PARAMS:
      return { ...state, fetchRenewalBranchesParams: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_LOADING:
      return { ...state, fetchRenewalBranchesLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_SUCCESS:
      return { ...state, fetchRenewalBranchesSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_FAILED:
      return { ...state, fetchRenewalBranchesFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_LOADING:
      return { ...state, createRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_SUCCESS:
      return { ...state, createRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_FAILED:
      return { ...state, createRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_LOADING:
      return { ...state, createCalculateRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_SUCCESS:
      return { ...state, createCalculateRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_FAILED:
      return { ...state, createCalculateRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_LOADING:
      return { ...state, customRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_SUCCESS:
      return { ...state, customRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_FAILED:
      return { ...state, customRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_LOADING:
      return { ...state, customCalculateRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_SUCCESS:
      return { ...state, customCalculateRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_FAILED:
      return { ...state, customCalculateRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_LOADING:
      return { ...state, updateRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_SUCCESS:
      return { ...state, updateRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_FAILED:
      return { ...state, updateRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_LOADING:
      return { ...state, updateCalculateRenewalLoading: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_SUCCESS:
      return { ...state, updateCalculateRenewalSuccess: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_FAILED:
      return { ...state, updateCalculateRenewalFailed: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWALS_HITTED:
      return { ...state, isFetchRenewalsHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_HITTED:
      return { ...state, isFetchRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_MARKET_HITTED:
      return { ...state, isFetchRenewalMarketHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_BRANCHES_HITTED:
      return { ...state, isFetchRenewalBranchesHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CREATE_RENEWAL_HITTED:
      return { ...state, isCreateRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CREATE_CALCULATE_RENEWAL_HITTED:
      return { ...state, isCreateCalculateRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CUSTOM_RENEWAL_HITTED:
      return { ...state, isCustomRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CUSTOM_CALCULATE_RENEWAL_HITTED:
      return { ...state, isCustomCalculateRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_UPDATE_RENEWAL_HITTED:
      return { ...state, isUpdateRenewalHitted: payload };
    case RENEWAL_ADMIN_ACTION_TYPES.SET_IS_UPDATE_CALCULATE_RENEWAL_HITTED:
      return { ...state, isUpdateCalculateRenewalHitted: payload };

    case RENEWAL_ADMIN_ACTION_TYPES.RESET_RENEWAL_ADMIN_REDUCER:
      return RENEWAL_ADMIN_INITIAL_STATE;
    default:
      return state;
  }
};
