import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./App";
import { store, persistor } from "./store/store";
import reportWebVitals from "./reportWebVitals";

import "./i18n";
import "mapbox-gl/dist/mapbox-gl.css";
import "./assets/css/style.css";

if (process.env.REACT_APP_NODE_ENV === "production") {
  TagManager.initialize({ gtmId: "GTM-MC6TMT4" });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
