const STATE_SUBSCRIPTION_ACTION_TYPES = {
  RESET_STATE_SUBSCRIPTION_REDUCER:
    "state-subscription/RESET_STATE_SUBSCRIPTION_REDUCER",

  SET_SUBSCRIPTION_ADMIN_TAB: "state-subscription/SET_SUBSCRIPTION_ADMIN_TAB",
  SET_SUBSCRIPTION_SUBSCRIBER_TAB:
    "state-subscription/SET_SUBSCRIPTION_SUBSCRIBER_TAB",

  SET_BILLING_PLAN: "state-subscription/SET_BILLING_PLAN",
  SET_BILLING_CYCLE: "state-subscription/SET_BILLING_CYCLE",

  SET_IS_BRANCHES_CHANGING: "state-subscription/SET_IS_BRANCHES_CHANGING",
  SET_IS_CALCULATE_LOADING: "state-subscription/SET_IS_CALCULATE_LOADING",
  SET_IS_TRANSFORM_LOADING: "state-subscription/SET_IS_TRANSFORM_LOADING",

  SET_UPDATING_BRANCH_KEY: "state-subscription/SET_UPDATING_BRANCH_KEY",
  SET_UPDATING_ITEM_INDEX: "state-subscription/SET_UPDATING_ITEM_INDEX",

  SET_RENEWAL_BRANCHES_IDS: "state-subscription/SET_RENEWAL_BRANCHES_IDS",
  SET_RENEWAL_PLAN: "state-subscription/SET_RENEWAL_PLAN",
  SET_RENEWAL_CYCLE: "state-subscription/SET_RENEWAL_CYCLE",
  SET_RENEWAL_BRANCHES: "state-subscription/SET_RENEWAL_BRANCHES",
  SET_RENEWAL_ITEMS: "state-subscription/SET_RENEWAL_ITEMS",
  SET_RENEWAL_DISCOUNT: "state-subscription/SET_RENEWAL_DISCOUNT",

  TOGGLE_RENEWAL_BRANCH: "state-subscription/TOGGLE_RENEWAL_BRANCH",
  UPDATE_RENEWAL_BRANCH: "state-subscription/UPDATE_RENEWAL_BRANCH",

  APPEND_RENEWAL_ITEM: "state-subscription/APPEND_RENEWAL_ITEM",
  UPDATE_RENEWAL_ITEM: "state-subscription/UPDATE_RENEWAL_ITEM",
  DELETE_RENEWAL_ITEM: "state-subscription/DELETE_RENEWAL_ITEM",

  RESET_RENEWAL_VALUES: "state-subscription/RESET_RENEWAL_VALUES",
};

export default STATE_SUBSCRIPTION_ACTION_TYPES;
