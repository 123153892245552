import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCalculateCreatePurchaseFailed,
  setCalculateUpdatePurchaseFailed,
  setCreatePurchaseFailed,
  setCreatePurchaseSuccess,
  setDeletePurchaseFailed,
  setDeletePurchaseSuccess,
  setFetchIngredientPurchasesFailed,
  setFetchItemPurchasesFailed,
  setFetchPurchaseFailed,
  setUpdatePurchaseFailed,
  setUpdatePurchaseSuccess,
} from "../../store/purchase/purchase.action";
import {
  getCalculateCreatePurchaseFailed,
  getCalculateUpdatePurchaseFailed,
  getCreatePurchaseFailed,
  getCreatePurchaseSuccess,
  getDeletePurchaseFailed,
  getDeletePurchaseSuccess,
  getFetchIngredientPurchasesFailed,
  getFetchItemPurchasesFailed,
  getFetchPurchaseFailed,
  getUpdatePurchaseFailed,
  getUpdatePurchaseSuccess,
} from "../../store/purchase/purchase.selector";

const PurchasePopup = () => {
  const dispatch = useDispatch();

  const createPurchaseSuccess = useSelector(getCreatePurchaseSuccess);
  const updatePurchaseSuccess = useSelector(getUpdatePurchaseSuccess);
  const deletePurchaseSuccess = useSelector(getDeletePurchaseSuccess);

  const fetchIngredientPurchasesFailed = useSelector(
    getFetchIngredientPurchasesFailed
  );
  const fetchItemPurchasesFailed = useSelector(getFetchItemPurchasesFailed);
  const fetchPurchaseFailed = useSelector(getFetchPurchaseFailed);
  const calculateCreatePurchaseFailed = useSelector(
    getCalculateCreatePurchaseFailed
  );
  const calculateUpdatePurchaseFailed = useSelector(
    getCalculateUpdatePurchaseFailed
  );
  const createPurchaseFailed = useSelector(getCreatePurchaseFailed);
  const updatePurchaseFailed = useSelector(getUpdatePurchaseFailed);
  const deletePurchaseFailed = useSelector(getDeletePurchaseFailed);

  useEffect(() => {
    if (
      createPurchaseSuccess !== null ||
      updatePurchaseSuccess !== null ||
      deletePurchaseSuccess !== null
    ) {
      showSuccessMessage(
        createPurchaseSuccess ?? updatePurchaseSuccess ?? deletePurchaseSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createPurchaseSuccess !== null)
        dispatch(setCreatePurchaseSuccess(null));
      if (updatePurchaseSuccess !== null)
        dispatch(setUpdatePurchaseSuccess(null));
      if (deletePurchaseSuccess !== null)
        dispatch(setDeletePurchaseSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPurchaseSuccess, updatePurchaseSuccess, deletePurchaseSuccess]);

  useEffect(() => {
    if (
      fetchIngredientPurchasesFailed !== null ||
      fetchItemPurchasesFailed !== null ||
      fetchPurchaseFailed !== null ||
      calculateCreatePurchaseFailed !== null ||
      calculateUpdatePurchaseFailed !== null ||
      createPurchaseFailed !== null ||
      updatePurchaseFailed !== null ||
      deletePurchaseFailed !== null
    ) {
      showErrorMessage(
        fetchIngredientPurchasesFailed ??
          fetchItemPurchasesFailed ??
          fetchPurchaseFailed ??
          calculateCreatePurchaseFailed ??
          calculateUpdatePurchaseFailed ??
          createPurchaseFailed ??
          updatePurchaseFailed ??
          deletePurchaseFailed
      );

      if (fetchIngredientPurchasesFailed !== null)
        dispatch(setFetchIngredientPurchasesFailed(null));
      if (fetchItemPurchasesFailed !== null)
        dispatch(setFetchItemPurchasesFailed(null));
      if (fetchPurchaseFailed !== null) dispatch(setFetchPurchaseFailed(null));
      if (calculateCreatePurchaseFailed !== null)
        dispatch(setCalculateCreatePurchaseFailed(null));
      if (calculateUpdatePurchaseFailed !== null)
        dispatch(setCalculateUpdatePurchaseFailed(null));
      if (createPurchaseFailed !== null)
        dispatch(setCreatePurchaseFailed(null));
      if (updatePurchaseFailed !== null)
        dispatch(setUpdatePurchaseFailed(null));
      if (deletePurchaseFailed !== null)
        dispatch(setDeletePurchaseFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIngredientPurchasesFailed,
    fetchItemPurchasesFailed,
    fetchPurchaseFailed,
    calculateCreatePurchaseFailed,
    calculateUpdatePurchaseFailed,
    createPurchaseFailed,
    updatePurchaseFailed,
    deletePurchaseFailed,
  ]);

  return <></>;
};

export default PurchasePopup;
