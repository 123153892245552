import EXPORT_EXCEL_ACTION_TYPES from "./export-excel.type";

export const EXPORT_EXCEL_INITIAL_STATE = {
  exportExcelReports: null,
  exportExcelPurchase: null,

  exportExcelReportsLoading: false,
  exportExcelReportsSuccess: null,
  exportExcelReportsFailed: null,

  exportExcelPurchaseLoading: false,
  exportExcelPurchaseSuccess: null,
  exportExcelPurchaseFailed: null,

  isExportExcelReportsHitted: false,
  isExportExcelPurchaseHitted: false,
};

export const exportExcelReducer = (
  state = EXPORT_EXCEL_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS:
      return { ...state, exportExcelReports: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE:
      return { ...state, exportExcelPurchase: payload };

    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_LOADING:
      return { ...state, exportExcelReportsLoading: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_SUCCESS:
      return { ...state, exportExcelReportsSuccess: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_FAILED:
      return { ...state, exportExcelReportsFailed: payload };

    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_LOADING:
      return { ...state, exportExcelPurchaseLoading: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_SUCCESS:
      return { ...state, exportExcelPurchaseSuccess: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_FAILED:
      return { ...state, exportExcelPurchaseFailed: payload };

    case EXPORT_EXCEL_ACTION_TYPES.SET_IS_EXPORT_EXCEL_REPORTS_HITTED:
      return { ...state, isExportExcelReportsHitted: payload };
    case EXPORT_EXCEL_ACTION_TYPES.SET_IS_EXPORT_EXCEL_PURCHASE_HITTED:
      return { ...state, isExportExcelPurchaseHitted: payload };

    case EXPORT_EXCEL_ACTION_TYPES.RESET_EXPORT_EXCEL_REDUCER:
      return EXPORT_EXCEL_INITIAL_STATE;
    default:
      return state;
  }
};
