import PURCHASE_ACTION_TYPES from "./purchase.type";

export const PURCHASE_INITIAL_STATE = {
  ingredientPurchases: [],
  itemPurchases: [],
  purchase: null,
  calculateCreatePurchase: null,
  calculateUpdatePurchase: null,
  createPurchase: null,
  updatePurchase: null,

  isIngredientPurchasesHasMore: true,
  isItemPurchasesHasMore: true,

  fetchIngredientPurchasesSearch: null,
  fetchIngredientPurchasesSort: null,
  fetchIngredientPurchasesKeyBy: null,
  fetchIngredientPurchasesPage: 1,
  fetchIngredientPurchasesPerPage: null,
  fetchIngredientPurchasesIncludes: null,
  fetchIngredientPurchasesFilterMarketId: null,
  fetchIngredientPurchasesFilterBranchId: null,
  fetchIngredientPurchasesFilterCategoryId: null,
  fetchIngredientPurchasesFilterSupplierId: null,
  fetchIngredientPurchasesFilterStatus: null,
  fetchIngredientPurchasesLoading: false,
  fetchIngredientPurchasesSuccess: null,
  fetchIngredientPurchasesFailed: null,

  fetchItemPurchasesSearch: null,
  fetchItemPurchasesSort: null,
  fetchItemPurchasesKeyBy: null,
  fetchItemPurchasesPage: 1,
  fetchItemPurchasesPerPage: null,
  fetchItemPurchasesIncludes: null,
  fetchItemPurchasesFilterMarketId: null,
  fetchItemPurchasesFilterBranchId: null,
  fetchItemPurchasesFilterCategoryId: null,
  fetchItemPurchasesFilterSupplierId: null,
  fetchItemPurchasesFilterStatus: null,
  fetchItemPurchasesLoading: false,
  fetchItemPurchasesSuccess: null,
  fetchItemPurchasesFailed: null,

  fetchPurchaseLoading: false,
  fetchPurchaseSuccess: null,
  fetchPurchaseFailed: null,

  calculateCreatePurchaseLoading: false,
  calculateCreatePurchaseSuccess: null,
  calculateCreatePurchaseFailed: null,

  calculateUpdatePurchaseLoading: false,
  calculateUpdatePurchaseSuccess: null,
  calculateUpdatePurchaseFailed: null,

  createPurchaseLoading: false,
  createPurchaseSuccess: null,
  createPurchaseFailed: null,

  updatePurchaseLoading: false,
  updatePurchaseSuccess: null,
  updatePurchaseFailed: null,

  deletePurchaseLoading: false,
  deletePurchaseSuccess: null,
  deletePurchaseFailed: null,

  isFetchIngredientPurchasesHitted: false,
  isFetchItemPurchasesHitted: false,
  isFetchPurchaseHitted: false,
  isCalculateCreatePurchaseHitted: false,
  isCalculateUpdatePurchaseHitted: false,
  isCreatePurchaseHitted: false,
  isUpdatePurchaseHitted: false,
  isDeletePurchaseHitted: false,
};

export const purchaseReducer = (state = PURCHASE_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case PURCHASE_ACTION_TYPES.SET_INGREDIENT_PURCHASES:
      return { ...state, ingredientPurchases: payload };
    case PURCHASE_ACTION_TYPES.SET_ITEM_PURCHASES:
      return { ...state, itemPurchases: payload };
    case PURCHASE_ACTION_TYPES.SET_PURCHASE:
      return { ...state, purchase: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE:
      return { ...state, calculateCreatePurchase: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE:
      return { ...state, calculateUpdatePurchase: payload };
    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE:
      return { ...state, createPurchase: payload };
    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE:
      return { ...state, updatePurchase: payload };

    case PURCHASE_ACTION_TYPES.SET_IS_INGREDIENT_PURCHASES_HAS_MORE:
      return { ...state, isIngredientPurchasesHasMore: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_ITEM_PURCHASES_HAS_MORE:
      return { ...state, isItemPurchasesHasMore: payload };

    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SEARCH:
      return { ...state, fetchIngredientPurchasesSearch: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SORT:
      return { ...state, fetchIngredientPurchasesSort: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_KEY_BY:
      return { ...state, fetchIngredientPurchasesKeyBy: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_PAGE:
      return { ...state, fetchIngredientPurchasesPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_PER_PAGE:
      return { ...state, fetchIngredientPurchasesPerPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_INCLUDES:
      return { ...state, fetchIngredientPurchasesIncludes: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_MARKET_ID:
      return { ...state, fetchIngredientPurchasesFilterMarketId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_BRANCH_ID:
      return { ...state, fetchIngredientPurchasesFilterBranchId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_CATEGORY_ID:
      return { ...state, fetchIngredientPurchasesFilterCategoryId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_SUPPLIER_ID:
      return { ...state, fetchIngredientPurchasesFilterSupplierId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_STATUS:
      return { ...state, fetchIngredientPurchasesFilterStatus: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_LOADING:
      return { ...state, fetchIngredientPurchasesLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SUCCESS:
      return { ...state, fetchIngredientPurchasesSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FAILED:
      return { ...state, fetchIngredientPurchasesFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SEARCH:
      return { ...state, fetchItemPurchasesSearch: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SORT:
      return { ...state, fetchItemPurchasesSort: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_KEY_BY:
      return { ...state, fetchItemPurchasesKeyBy: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_PAGE:
      return { ...state, fetchItemPurchasesPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_PER_PAGE:
      return { ...state, fetchItemPurchasesPerPage: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_INCLUDES:
      return { ...state, fetchItemPurchasesIncludes: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_MARKET_ID:
      return { ...state, fetchItemPurchasesFilterMarketId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_BRANCH_ID:
      return { ...state, fetchItemPurchasesFilterBranchId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_CATEGORY_ID:
      return { ...state, fetchItemPurchasesFilterCategoryId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_SUPPLIER_ID:
      return { ...state, fetchItemPurchasesFilterSupplierId: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_STATUS:
      return { ...state, fetchItemPurchasesFilterStatus: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_LOADING:
      return { ...state, fetchItemPurchasesLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SUCCESS:
      return { ...state, fetchItemPurchasesSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FAILED:
      return { ...state, fetchItemPurchasesFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_LOADING:
      return { ...state, fetchPurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_SUCCESS:
      return { ...state, fetchPurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_FAILED:
      return { ...state, fetchPurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_LOADING:
      return { ...state, calculateCreatePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_SUCCESS:
      return { ...state, calculateCreatePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_FAILED:
      return { ...state, calculateCreatePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_LOADING:
      return { ...state, calculateUpdatePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_SUCCESS:
      return { ...state, calculateUpdatePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_FAILED:
      return { ...state, calculateUpdatePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_LOADING:
      return { ...state, createPurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_SUCCESS:
      return { ...state, createPurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_FAILED:
      return { ...state, createPurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_LOADING:
      return { ...state, updatePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_SUCCESS:
      return { ...state, updatePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_FAILED:
      return { ...state, updatePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_LOADING:
      return { ...state, deletePurchaseLoading: payload };
    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_SUCCESS:
      return { ...state, deletePurchaseSuccess: payload };
    case PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_FAILED:
      return { ...state, deletePurchaseFailed: payload };

    case PURCHASE_ACTION_TYPES.APPEND_INGREDIENT_PURCHASES:
      return {
        ...state,
        ingredientPurchases: [...state.ingredientPurchases, ...payload],
      };
    case PURCHASE_ACTION_TYPES.APPEND_ITEM_PURCHASES:
      return {
        ...state,
        itemPurchases: [...state.itemPurchases, ...payload],
      };

    case PURCHASE_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_PURCHASES_HITTED:
      return { ...state, isFetchIngredientPurchasesHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_FETCH_ITEM_PURCHASES_HITTED:
      return { ...state, isFetchItemPurchasesHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASE_HITTED:
      return { ...state, isFetchPurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_CREATE_PURCHASE_HITTED:
      return { ...state, isCalculateCreatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_UPDATE_PURCHASE_HITTED:
      return { ...state, isCalculateUpdatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_CREATE_PURCHASE_HITTED:
      return { ...state, isCreatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_UPDATE_PURCHASE_HITTED:
      return { ...state, isUpdatePurchaseHitted: payload };
    case PURCHASE_ACTION_TYPES.SET_IS_DELETE_PURCHASE_HITTED:
      return { ...state, isDeletePurchaseHitted: payload };

    case PURCHASE_ACTION_TYPES.RESET_PURCHASE_REDUCER:
      return PURCHASE_INITIAL_STATE;
    default:
      return state;
  }
};
