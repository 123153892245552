import apiService from "./api";

export const getReportTotals = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/totals", params })).data;
export const getReportCategories = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/categories", params }))
    .data;
export const getReportProducts = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/products", params })).data;
export const getReportOptions = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/options", params })).data;
export const getReportAdditionals = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/additionals", params }))
    .data;
export const getReportCashiers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/cashiers", params })).data;
export const getReportPayments = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/payments", params })).data;
export const getReportTables = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/reports/tables", params })).data;
