import { takeLatest, put, all, call, select } from "redux-saga/effects";

import PDF_ACTION_TYPES from "./pdf.type";

import {
  setRenewalReceipt,
  setIsPdfRenewalReceiptHitted,
  setIsPdfOrderNotesHitted,
  setIsPdfOrderReceiptHitted,
  setOrderNotes,
  setOrderReceipt,
  setPdfRenewalReceiptFailed,
  setPdfRenewalReceiptLoading,
  setPdfRenewalReceiptSuccess,
  setPdfOrderNotesFailed,
  setPdfOrderNotesLoading,
  setPdfOrderNotesSuccess,
  setPdfOrderReceiptFailed,
  setPdfOrderReceiptLoading,
  setPdfOrderReceiptSuccess,
} from "./pdf.action";
import { getPdfOrderNotesIsMultiLang, getPdfOrderReceiptIsMultiLang } from "./pdf.selector";

import { getRenewalReceipt, getOrderNotes, getOrderReceipt } from "../../api/pdf.api";

export function* _getPdfOrderReceipt({ payload: id }) {
  try {
    yield put(setPdfOrderReceiptLoading(true));

    const is_multi_lang = yield select(getPdfOrderReceiptIsMultiLang);

    const { config, headers, data } = yield call(getOrderReceipt, id, { is_multi_lang });
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const orderReceipt = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfOrderReceiptHitted(true));
    yield put(setOrderReceipt(orderReceipt));

    yield put(setPdfOrderReceiptSuccess("Export receipt success"));
    yield put(setPdfOrderReceiptLoading(false));
  } catch (error) {
    yield put(setPdfOrderReceiptFailed(error));
    yield put(setPdfOrderReceiptLoading(false));
  }
}

export function* _getPdfOrderNotes({ payload: id }) {
  try {
    yield put(setPdfOrderNotesLoading(true));

    const is_multi_lang = yield select(getPdfOrderNotesIsMultiLang);

    const { config, headers, data } = yield call(getOrderNotes, id, { is_multi_lang });
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const orderNotes = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfOrderNotesHitted(true));
    yield put(setOrderNotes(orderNotes));

    yield put(setPdfOrderNotesSuccess("Export notes success"));
    yield put(setPdfOrderNotesLoading(false));
  } catch (error) {
    yield put(setPdfOrderNotesFailed(error));
    yield put(setPdfOrderNotesLoading(false));
  }
}

export function* _getPdfRenewalReceipt({ payload: id }) {
  try {
    yield put(setPdfRenewalReceiptLoading(true));

    const { config, headers, data } = yield call(getRenewalReceipt, id);
    const match = headers?.get("content-disposition")?.match(/filename="(.+)"/);
    const filename = match ? match?.[1] : null;

    const renewalReceipt = {
      filename,
      url: config?.url,
      file: new Blob([data]),
    };

    yield put(setIsPdfRenewalReceiptHitted(true));
    yield put(setRenewalReceipt(renewalReceipt));

    yield put(setPdfRenewalReceiptSuccess("Export receipt success"));
    yield put(setPdfRenewalReceiptLoading(false));
  } catch (error) {
    yield put(setPdfRenewalReceiptFailed(error));
    yield put(setPdfRenewalReceiptLoading(false));
  }
}

export function* onPdfOrderReceiptStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_ORDER_RECEIPT_START, _getPdfOrderReceipt);
}

export function* onPdfOrderNotesStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_ORDER_NOTES_START, _getPdfOrderNotes);
}

export function* onPdfRenewalReceiptStart() {
  yield takeLatest(PDF_ACTION_TYPES.EXPORT_RENEWAL_RECEIPT_START, _getPdfRenewalReceipt);
}

export function* pdfSaga() {
  yield all([call(onPdfOrderReceiptStart), call(onPdfOrderNotesStart), call(onPdfRenewalReceiptStart)]);
}
