const STATE_PURCHASE_ACTION_TYPES = {
  RESET_STATE_PURCHASE_REDUCER: "state-purchase/RESET_STATE_PURCHASE_REDUCER",

  SET_PURCHASE_TAB: "state-purchase/SET_PURCHASE_TAB",

  SET_CREATE_STEP: "state-purchase/SET_CREATE_STEP",
  SET_CREATE_VALUES: "state-purchase/SET_CREATE_VALUES",
  RESET_CREATE_VALUES: "state-purchase/RESET_CREATE_VALUES",

  SET_FORM_INGREDIENTS: "state-purchase/SET_FORM_INGREDIENTS",
  APPEND_FORM_INGREDIENT: "state-purchase/APPEND_FORM_INGREDIENT",
  UPDATE_FORM_INGREDIENT: "state-purchase/UPDATE_FORM_INGREDIENT",
  DELETE_FORM_INGREDIENT: "state-purchase/DELETE_FORM_INGREDIENT",

  SET_FORM_ITEMS: "state-purchase/SET_FORM_ITEMS",
  APPEND_FORM_ITEM: "state-purchase/APPEND_FORM_ITEM",
  UPDATE_FORM_ITEM: "state-purchase/UPDATE_FORM_ITEM",
  DELETE_FORM_ITEM: "state-purchase/DELETE_FORM_ITEM",

  SET_IS_CALCULATE_LOADING: "state-purchase/SET_IS_CALCULATE_LOADING",

  SET_UPDATING_INGREDIENT_INDEX: "state-purchase/SET_UPDATING_INGREDIENT_INDEX",
  SET_UPDATING_ITEM_INDEX: "state-purchase/SET_UPDATING_ITEM_INDEX",
};

export default STATE_PURCHASE_ACTION_TYPES;
