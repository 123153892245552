import styled, { css } from "styled-components";

import { STATUS_BADGE_SIZES, STATUS_BADGE_TYPES } from "./status-badge.widget";

export const StatusBadgeContainer = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0.65rem 1.25rem;
  border-radius: 12px;

  font-weight: 600;
  font-size: 0.875rem;
  white-space: nowrap;

  ${({ size }) =>
    size === STATUS_BADGE_SIZES.NORMAL &&
    css`
      font-size: 0.875rem;
    `}

  ${({ size }) =>
    size === STATUS_BADGE_SIZES.SMALL &&
    css`
      font-size: 0.75rem;
    `}

  svg {
    ${({ size }) =>
      size === STATUS_BADGE_SIZES.NORMAL &&
      css`
        width: 1.5rem;
        min-width: 1.5rem;
        max-width: 1.5rem;
        height: 1.5rem;
        min-height: 1.5rem;
        max-height: 1.5rem;
      `}

    ${({ size }) =>
      size === STATUS_BADGE_SIZES.SMALL &&
      css`
        width: 1.25rem;
        min-width: 1.25rem;
        max-width: 1.25rem;
        height: 1.25rem;
        min-height: 1.25rem;
        max-height: 1.25rem;
      `}

    ${({ isRightToLeft }) =>
      isRightToLeft
        ? css`
            margin-left: 0.5rem;
          `
        : css`
            margin-right: 0.5rem;
          `}

    path {
      ${({ type }) =>
        type === STATUS_BADGE_TYPES.PRIMARY &&
        css`
          &[fill] {
            fill: #2900ee;
          }
          &[stroke] {
            stroke: #2900ee;
          }
        `}

      ${({ type }) =>
        type === STATUS_BADGE_TYPES.WARNING &&
        css`
          &[fill] {
            fill: #fc9114;
          }
          &[stroke] {
            stroke: #fc9114;
          }
        `}

      ${({ type }) =>
        type === STATUS_BADGE_TYPES.SUCCESS &&
        css`
          &[fill] {
            fill: #008d0e;
          }
          &[stroke] {
            stroke: #008d0e;
          }
        `}

      ${({ type }) =>
        type === STATUS_BADGE_TYPES.DANGER &&
        css`
          &[fill] {
            fill: #f02c2c;
          }
          &[stroke] {
            stroke: #f02c2c;
          }
        `}
    }
  }

  ${({ isHasIcon, isRightToLeft }) =>
    isHasIcon &&
    !isRightToLeft &&
    css`
      padding: 0.65rem 1.25rem 0.65rem 0.875rem;
    `}

  ${({ isHasIcon, isRightToLeft }) =>
    isHasIcon &&
    isRightToLeft &&
    css`
      padding: 0.65rem 0.875rem 0.65rem 1.25rem;
    `}

  ${({ type }) =>
    type === STATUS_BADGE_TYPES.LIGHT &&
    css`
      background-color: #f2f2f4;
    `}

  ${({ type }) =>
    type === STATUS_BADGE_TYPES.PRIMARY &&
    css`
      background-color: rgba(41, 0, 238, 0.12);
    `}

  ${({ type }) =>
    type === STATUS_BADGE_TYPES.WARNING &&
    css`
      background-color: rgba(252, 145, 20, 0.12);
    `}

  ${({ type }) =>
    type === STATUS_BADGE_TYPES.SUCCESS &&
    css`
      background-color: rgba(0, 141, 14, 0.12);
    `}

  ${({ type }) =>
    type === STATUS_BADGE_TYPES.DANGER &&
    css`
      background-color: #fddfde;
    `}

  ${({ isTextColor, type }) =>
    isTextColor &&
    type === STATUS_BADGE_TYPES.LIGHT &&
    css`
      color: #0e072f;
    `}

  ${({ isTextColor, type }) =>
    isTextColor &&
    type === STATUS_BADGE_TYPES.PRIMARY &&
    css`
      color: #2900ee;
    `}

  ${({ isTextColor, type }) =>
    isTextColor &&
    type === STATUS_BADGE_TYPES.WARNING &&
    css`
      color: #fc9114;
    `}

  ${({ isTextColor, type }) =>
    isTextColor &&
    type === STATUS_BADGE_TYPES.SUCCESS &&
    css`
      color: #008d0e;
    `}

  ${({ isTextColor, type }) =>
    isTextColor &&
    type === STATUS_BADGE_TYPES.DANGER &&
    css`
      color: #f02c2c;
    `}
`;
