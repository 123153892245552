import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setApproveResupplyFailed,
  setApproveResupplySuccess,
  setCreateResupplyFailed,
  setCreateResupplySuccess,
  setDeclineResupplyFailed,
  setDeclineResupplySuccess,
  setDeleteResupplyFailed,
  setDeleteResupplySuccess,
  setFetchResuppliesFailed,
  setFetchResupplyFailed,
  setReceiveResupplyFailed,
  setReceiveResupplySuccess,
  setUpdateResupplyFailed,
  setUpdateResupplySuccess,
} from "../../store/resupply/resupply.action";
import {
  getApproveResupplyFailed,
  getApproveResupplySuccess,
  getCreateResupplyFailed,
  getCreateResupplySuccess,
  getDeclineResupplyFailed,
  getDeclineResupplySuccess,
  getDeleteResupplyFailed,
  getDeleteResupplySuccess,
  getFetchResuppliesFailed,
  getFetchResupplyFailed,
  getReceiveResupplyFailed,
  getReceiveResupplySuccess,
  getUpdateResupplyFailed,
  getUpdateResupplySuccess,
} from "../../store/resupply/resupply.selector";

const ResupplyPopup = () => {
  const dispatch = useDispatch();

  const createResupplySuccess = useSelector(getCreateResupplySuccess);
  const updateResupplySuccess = useSelector(getUpdateResupplySuccess);
  const deleteResupplySuccess = useSelector(getDeleteResupplySuccess);
  const receiveResupplySuccess = useSelector(getReceiveResupplySuccess);
  const approveResupplySuccess = useSelector(getApproveResupplySuccess);
  const declineResupplySuccess = useSelector(getDeclineResupplySuccess);

  const fetchResuppliesFailed = useSelector(getFetchResuppliesFailed);
  const fetchResupplyFailed = useSelector(getFetchResupplyFailed);
  const createResupplyFailed = useSelector(getCreateResupplyFailed);
  const updateResupplyFailed = useSelector(getUpdateResupplyFailed);
  const deleteResupplyFailed = useSelector(getDeleteResupplyFailed);
  const receiveResupplyFailed = useSelector(getReceiveResupplyFailed);
  const approveResupplyFailed = useSelector(getApproveResupplyFailed);
  const declineResupplyFailed = useSelector(getDeclineResupplyFailed);

  useEffect(() => {
    if (
      createResupplySuccess !== null ||
      updateResupplySuccess !== null ||
      deleteResupplySuccess !== null ||
      receiveResupplySuccess !== null ||
      approveResupplySuccess !== null ||
      declineResupplySuccess !== null
    ) {
      showSuccessMessage(
        createResupplySuccess ??
          updateResupplySuccess ??
          deleteResupplySuccess ??
          receiveResupplySuccess ??
          approveResupplySuccess ??
          declineResupplySuccess
      );
      dispatch(setIsModalOpen(false));

      if (createResupplySuccess !== null)
        dispatch(setCreateResupplySuccess(null));
      if (updateResupplySuccess !== null)
        dispatch(setUpdateResupplySuccess(null));
      if (deleteResupplySuccess !== null)
        dispatch(setDeleteResupplySuccess(null));
      if (receiveResupplySuccess !== null)
        dispatch(setReceiveResupplySuccess(null));
      if (approveResupplySuccess !== null)
        dispatch(setApproveResupplySuccess(null));
      if (declineResupplySuccess !== null)
        dispatch(setDeclineResupplySuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createResupplySuccess,
    updateResupplySuccess,
    deleteResupplySuccess,
    receiveResupplySuccess,
    approveResupplySuccess,
    declineResupplySuccess,
  ]);

  useEffect(() => {
    if (
      fetchResuppliesFailed !== null ||
      fetchResupplyFailed !== null ||
      createResupplyFailed !== null ||
      updateResupplyFailed !== null ||
      deleteResupplyFailed !== null ||
      receiveResupplyFailed !== null ||
      approveResupplyFailed !== null ||
      declineResupplyFailed !== null
    ) {
      showErrorMessage(
        fetchResuppliesFailed ??
          fetchResupplyFailed ??
          createResupplyFailed ??
          updateResupplyFailed ??
          deleteResupplyFailed ??
          receiveResupplyFailed ??
          approveResupplyFailed ??
          declineResupplyFailed
      );

      if (fetchResuppliesFailed !== null)
        dispatch(setFetchResuppliesFailed(null));
      if (fetchResupplyFailed !== null) dispatch(setFetchResupplyFailed(null));
      if (createResupplyFailed !== null)
        dispatch(setCreateResupplyFailed(null));
      if (updateResupplyFailed !== null)
        dispatch(setUpdateResupplyFailed(null));
      if (deleteResupplyFailed !== null)
        dispatch(setDeleteResupplyFailed(null));
      if (receiveResupplyFailed !== null)
        dispatch(setReceiveResupplyFailed(null));
      if (approveResupplyFailed !== null)
        dispatch(setApproveResupplyFailed(null));
      if (declineResupplyFailed !== null)
        dispatch(setDeclineResupplyFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchResuppliesFailed,
    fetchResupplyFailed,
    createResupplyFailed,
    updateResupplyFailed,
    deleteResupplyFailed,
    receiveResupplyFailed,
    approveResupplyFailed,
    declineResupplyFailed,
  ]);

  return <></>;
};

export default ResupplyPopup;
