import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendSectorsFailed,
  setFetchSectorsFailed,
  setSearchSectorsFailed,
} from "../../store/select-sector/select-sector.action";
import {
  getAppendSectorsFailed,
  getFetchSectorsFailed,
  getSearchSectorsFailed,
} from "../../store/select-sector/select-sector.selector";

const SelectSectorPopup = () => {
  const dispatch = useDispatch();

  const fetchSectorsFailed = useSelector(getFetchSectorsFailed);
  const searchSectorsFailed = useSelector(getSearchSectorsFailed);
  const appendSectorsFailed = useSelector(getAppendSectorsFailed);

  useEffect(() => {
    if (
      fetchSectorsFailed !== null ||
      searchSectorsFailed !== null ||
      appendSectorsFailed !== null
    ) {
      showErrorMessage(
        fetchSectorsFailed ?? searchSectorsFailed ?? appendSectorsFailed
      );

      if (fetchSectorsFailed !== null) dispatch(setFetchSectorsFailed(null));
      if (searchSectorsFailed !== null) dispatch(setSearchSectorsFailed(null));
      if (appendSectorsFailed !== null) dispatch(setAppendSectorsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSectorsFailed, searchSectorsFailed, appendSectorsFailed]);

  return <></>;
};

export default SelectSectorPopup;
