import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  getRecipes,
  getRecipe,
  createRecipe,
  updateRecipe,
  deleteRecipe,
} from "../../api/recipe.api";

import RECIPE_ACTION_TYPES from "./recipe.type";
import {
  appendRecipes,
  setCreateRecipeFailed,
  setCreateRecipeLoading,
  setCreateRecipeSuccess,
  setDeleteRecipeFailed,
  setDeleteRecipeLoading,
  setDeleteRecipeSuccess,
  setFetchRecipeFailed,
  setFetchRecipeLoading,
  setFetchRecipesFailed,
  setFetchRecipesLoading,
  setFetchRecipesPage,
  setFetchRecipesSuccess,
  setFetchRecipeSuccess,
  setIsCreateRecipeHitted,
  setIsDeleteRecipeHitted,
  setIsFetchRecipeHitted,
  setIsFetchRecipesHitted,
  setIsRecipesHasMore,
  setIsUpdateRecipeHitted,
  setRecipe,
  setRecipes,
  setUpdateRecipeFailed,
  setUpdateRecipeLoading,
  setUpdateRecipeSuccess,
} from "./recipe.action";
import {
  getFetchRecipesFilterBranchId,
  getFetchRecipesFilterMarketId,
  getFetchRecipesIncludes,
  getFetchRecipesKeyBy,
  getFetchRecipesPage,
  getFetchRecipesPerPage,
  getFetchRecipesSearch,
  getFetchRecipesSort,
  getIsFetchRecipesHitted,
} from "./recipe.selector";

export function* _getRecipes() {
  try {
    yield put(setFetchRecipesLoading(true));

    const search = yield select(getFetchRecipesSearch);
    const sort = yield select(getFetchRecipesSort);
    const key_by = yield select(getFetchRecipesKeyBy);
    const page = yield select(getFetchRecipesPage);
    const per_page = yield select(getFetchRecipesPerPage);
    const includes = yield select(getFetchRecipesIncludes);
    const market_id = yield select(getFetchRecipesFilterMarketId);
    const branch_id = yield select(getFetchRecipesFilterBranchId);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: { data: recipes },
    } = yield call(getRecipes, parameters);

    yield put(setIsFetchRecipesHitted(true));
    yield put(setIsRecipesHasMore(recipes.length > 0));

    if (page > 1) {
      yield put(appendRecipes(recipes));
    } else {
      yield put(setRecipes(recipes));
    }

    yield put(setFetchRecipesSuccess(message));
    yield put(setFetchRecipesLoading(false));
  } catch (error) {
    yield put(setFetchRecipesFailed(error));
    yield put(setFetchRecipesLoading(false));
  }
}
export function* _getRecipe({ payload: recipeId }) {
  try {
    yield put(setFetchRecipeLoading(true));

    const {
      meta: { message },
      data: recipe,
    } = yield call(getRecipe, recipeId);

    yield put(setIsFetchRecipeHitted(true));
    yield put(setRecipe(recipe));

    yield put(setFetchRecipeSuccess(message));
    yield put(setFetchRecipeLoading(false));
  } catch (error) {
    yield put(setFetchRecipeFailed(error));
    yield put(setFetchRecipeLoading(false));
  }
}
export function* _createRecipe({ payload: request }) {
  try {
    yield put(setCreateRecipeLoading(true));

    const {
      meta: { message },
    } = yield call(createRecipe, request);

    yield put(setIsCreateRecipeHitted(true));

    const isFetchRecipesHitted = yield select(getIsFetchRecipesHitted);

    if (isFetchRecipesHitted) {
      yield put(setFetchRecipesPage(1));
      yield call(_getRecipes);
    }

    yield put(setCreateRecipeSuccess(message));
    yield put(setCreateRecipeLoading(false));
  } catch (error) {
    yield put(setCreateRecipeFailed(error));
    yield put(setCreateRecipeLoading(false));
  }
}
export function* _updateRecipe({ payload: { recipeId, request } }) {
  try {
    yield put(setUpdateRecipeLoading(true));

    const {
      meta: { message },
    } = yield call(updateRecipe, recipeId, request);

    yield put(setIsUpdateRecipeHitted(true));

    const isFetchRecipesHitted = yield select(getIsFetchRecipesHitted);

    if (isFetchRecipesHitted) {
      yield put(setFetchRecipesPage(1));
      yield call(_getRecipes);
    }

    yield put(setUpdateRecipeSuccess(message));
    yield put(setUpdateRecipeLoading(false));
  } catch (error) {
    yield put(setUpdateRecipeFailed(error));
    yield put(setUpdateRecipeLoading(false));
  }
}
export function* _deleteRecipe({ payload: recipeId }) {
  try {
    yield put(setDeleteRecipeLoading(true));

    const {
      meta: { message },
    } = yield call(deleteRecipe, recipeId);

    yield put(setIsDeleteRecipeHitted(true));

    const isFetchRecipesHitted = yield select(getIsFetchRecipesHitted);

    if (isFetchRecipesHitted) {
      yield put(setFetchRecipesPage(1));
      yield call(_getRecipes);
    }

    yield put(setDeleteRecipeSuccess(message));
    yield put(setDeleteRecipeLoading(false));
  } catch (error) {
    yield put(setDeleteRecipeFailed(error));
    yield put(setDeleteRecipeLoading(false));
  }
}

export function* onFetchRecipesStart() {
  yield takeLatest(RECIPE_ACTION_TYPES.FETCH_RECIPES_START, _getRecipes);
}
export function* onFetchRecipeStart() {
  yield takeLatest(RECIPE_ACTION_TYPES.FETCH_RECIPE_START, _getRecipe);
}
export function* onCreateRecipeStart() {
  yield takeLatest(RECIPE_ACTION_TYPES.CREATE_RECIPE_START, _createRecipe);
}
export function* onUpdateRecipeStart() {
  yield takeLatest(RECIPE_ACTION_TYPES.UPDATE_RECIPE_START, _updateRecipe);
}
export function* onDeleteRecipeStart() {
  yield takeLatest(RECIPE_ACTION_TYPES.DELETE_RECIPE_START, _deleteRecipe);
}

export function* recipeSaga() {
  yield all([
    call(onFetchRecipesStart),
    call(onFetchRecipeStart),
    call(onCreateRecipeStart),
    call(onUpdateRecipeStart),
    call(onDeleteRecipeStart),
  ]);
}
