import { takeLatest, put, all, call, select } from "redux-saga/effects";

import GLOBAL_ACTION_TYPES from "./global.type";

import { ArrayExtension } from "../../utils/extension.utils";
import { getValidSectorPages } from "../../utils/permission.utils";
import { clearAuthToken, getAuthToken } from "../../utils/storage.utils";
import { getActivePlans } from "../../utils/subscription.utils";
import { isEmpty } from "../../utils/validation.utils";

import { USER_ROLES } from "../../constants/user.constant";
import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";

import {
  appendGlobalBanners,
  appendGlobalBannerTargets,
  appendGlobalFeatureSubscriptions,
  appendGlobalRestrictionNotices,
  appendGlobalSubscriptions,
  appendGlobalFeatureSystems,
  setBranchPlans,
  setCurrentBranch,
  setCurrentBranchId,
  setCurrentMarket,
  setCurrentMarketId,
  setCurrentMerchant,
  setCurrentMerchantId,
  setCurrentSchedules,
  setFetchCurrentBranchFailed,
  setFetchCurrentBranchLoading,
  setFetchCurrentBranchSuccess,
  setFetchCurrentMarketFailed,
  setFetchCurrentMarketLoading,
  setFetchCurrentMarketSuccess,
  setFetchCurrentMerchantFailed,
  setFetchCurrentMerchantLoading,
  setFetchCurrentMerchantSuccess,
  setFetchCurrentSchedulesFailed,
  setFetchCurrentSchedulesLoading,
  setFetchCurrentSchedulesSuccess,
  setFetchGlobalBannersFailed,
  setFetchGlobalBannersLoading,
  setFetchGlobalBannersSuccess,
  setFetchGlobalBannerTargetsFailed,
  setFetchGlobalBannerTargetsLoading,
  setFetchGlobalBannerTargetsSuccess,
  setFetchGlobalFeatureSubscriptionsFailed,
  setFetchGlobalFeatureSubscriptionsLoading,
  setFetchGlobalFeatureSubscriptionsSuccess,
  setFetchGlobalRestrictionNoticesFailed,
  setFetchGlobalRestrictionNoticesLoading,
  setFetchGlobalRestrictionNoticesSuccess,
  setFetchGlobalSubscriptionsFailed,
  setFetchGlobalSubscriptionsLoading,
  setFetchGlobalSubscriptionsSuccess,
  setFetchGlobalFeatureSystemsFailed,
  setFetchGlobalFeatureSystemsLoading,
  setFetchGlobalFeatureSystemsSuccess,
  setFetchInitializeFailed,
  setFetchInitializeLoading,
  setFetchInitializeSuccess,
  setFetchMasterPermissionFailed,
  setFetchMasterPermissionLoading,
  setFetchMasterPermissionSuccess,
  setFetchSecondPermissionFailed,
  setFetchSecondPermissionLoading,
  setFetchSecondPermissionSuccess,
  setFetchTodayAttendanceFailed,
  setFetchTodayAttendanceLoading,
  setFetchTodayAttendanceSuccess,
  setGlobalBanners,
  setGlobalBannerTargets,
  setGlobalFeatureSubscriptions,
  setGlobalRestrictionNotices,
  setGlobalSubscriptions,
  setGlobalFeatureSystems,
  setIsBranchHasAdvancedPlan,
  setIsBranchHasBasicPlan,
  setIsBranchHasPlan,
  setIsBranchHasTrialPlan,
  setIsCenterAdministrator,
  setIsFetchCurrentBranchHitted,
  setIsFetchCurrentMarketHitted,
  setIsFetchCurrentMerchantHitted,
  setIsFetchCurrentSchedulesHitted,
  setIsFetchGlobalBannersHitted,
  setIsFetchGlobalBannerTargetsHitted,
  setIsFetchGlobalFeatureSubscriptionsHitted,
  setIsFetchGlobalRestrictionNoticesHitted,
  setIsFetchGlobalSubscriptionsHitted,
  setIsFetchGlobalFeatureSystemsHitted,
  setIsFetchInitializeHitted,
  setIsFetchMasterPermissionHitted,
  setIsFetchSecondPermissionHitted,
  setIsFetchTodayAttendanceHitted,
  setIsGlobalBannersHasMore,
  setIsGlobalBannerTargetsHasMore,
  setIsGlobalFeatureSubscriptionsHasMore,
  setIsGlobalRestrictionNoticesHasMore,
  setIsGlobalSubscriptionsHasMore,
  setIsGlobalFeatureSystemsHasMore,
  setIsHasActiveAdvancedPlan,
  setIsHasActiveBasicPlan,
  setIsHasActivePlan,
  setIsHasActiveTrialPlan,
  setIsMarketAdministrator,
  setIsMarketHasAdvancedPlan,
  setIsMarketHasBasicPlan,
  setIsMarketHasPlan,
  setIsMarketHasTrialPlan,
  setMarketPlans,
  setMasterAccessPages,
  setMasterForbidPages,
  setMasterPermission,
  setMasterPermissionId,
  setMasterPermitPages,
  setSecondAccessPages,
  setSecondForbidPages,
  setSecondPermission,
  setSecondPermissionId,
  setSecondPermitPages,
  setTodayAttendance,
} from "./global.action";
import {
  getFetchCurrentSchedulesFilterBranchId,
  getFetchCurrentSchedulesFilterMarketId,
  getFetchCurrentSchedulesIncludes,
  getFetchGlobalBannersIncludes,
  getFetchGlobalBannersKeyBy,
  getFetchGlobalBannersPage,
  getFetchGlobalBannersPerPage,
  getFetchGlobalBannersSearch,
  getFetchGlobalBannersSort,
  getFetchGlobalBannerTargetsFilterRole,
  getFetchGlobalBannerTargetsIncludes,
  getFetchGlobalBannerTargetsKeyBy,
  getFetchGlobalBannerTargetsPage,
  getFetchGlobalBannerTargetsPerPage,
  getFetchGlobalBannerTargetsSearch,
  getFetchGlobalBannerTargetsSort,
  getFetchGlobalFeatureSubscriptionsFilterIsHighlight,
  getFetchGlobalFeatureSubscriptionsIncludes,
  getFetchGlobalFeatureSubscriptionsKeyBy,
  getFetchGlobalFeatureSubscriptionsPage,
  getFetchGlobalFeatureSubscriptionsPerPage,
  getFetchGlobalFeatureSubscriptionsSearch,
  getFetchGlobalFeatureSubscriptionsSort,
  getFetchGlobalRestrictionNoticesIncludes,
  getFetchGlobalRestrictionNoticesKeyBy,
  getFetchGlobalRestrictionNoticesPage,
  getFetchGlobalRestrictionNoticesPerPage,
  getFetchGlobalRestrictionNoticesSearch,
  getFetchGlobalRestrictionNoticesSort,
  getFetchGlobalSubscriptionsIncludes,
  getFetchGlobalSubscriptionsKeyBy,
  getFetchGlobalSubscriptionsPage,
  getFetchGlobalSubscriptionsPerPage,
  getFetchGlobalSubscriptionsSearch,
  getFetchGlobalSubscriptionsSort,
  getFetchGlobalFeatureSystemsFilterRoles,
  getFetchGlobalFeatureSystemsIncludes,
  getFetchGlobalFeatureSystemsKeyBy,
  getFetchGlobalFeatureSystemsPage,
  getFetchGlobalFeatureSystemsPerPage,
  getFetchGlobalFeatureSystemsSearch,
  getFetchGlobalFeatureSystemsSort,
  getFetchTodayAttendanceBranchId,
  getFetchTodayAttendanceMarketId,
} from "./global.selector";
import {
  setAuthCheckFailed,
  setAuthCheckLoading,
  setAuthCheckSuccess,
  setFetchMeFailed,
  setFetchMeLoading,
  setFetchMeSuccess,
  setIsAuthCheckHitted,
  setIsFetchMeHitted,
  setUser,
} from "../authentication/authentication.action";

import { getSchedules } from "../../api/schedule.api";
import { getCurrentAttendance } from "../../api/attendance.api";
import { getMarket } from "../../api/market.api";
import { getBranch } from "../../api/branch.api";
import { getMerchant } from "../../api/merchant.api";
import { getPermission } from "../../api/permission.api";
import { getPermissionAdmin } from "../../api/permission-admin.api";
import { getPermissionMerchant } from "../../api/permission-merchant.api";
import { getPermissionSubscriber } from "../../api/permission-subscriber.api";
import { getSubscriptions } from "../../api/subscription.api";
import { getFeatures as getSubscriptionFeatures } from "../../api/feature-subscription.api";
import { getFeatures as getFeatureSystems } from "../../api/feature-system.api";
import { getMe } from "../../api/authentication.api";
import { getRestrictionNotices } from "../restriction-notice/restriction-notice.selector";
import { getBanners } from "../../api/banner.api";
import { getTargets } from "../../api/banner-target.api";

export function* _getInitialize() {
  try {
    yield put(setFetchInitializeLoading(true));

    let authUser = null;

    try {
      yield put(setAuthCheckLoading(true));

      const token = yield call(getAuthToken);

      if (token !== null) {
        try {
          yield put(setFetchMeLoading(true));

          const response = yield call(getMe);

          yield put(setIsFetchMeHitted(true));
          yield put(setUser(response?.data));

          authUser = response?.data;

          yield put(setFetchMeSuccess(response?.meta?.message));
          yield put(setFetchMeLoading(false));
        } catch (error) {
          yield put(setFetchMeFailed(error));
          yield put(setFetchMeLoading(false));
        }
      } else {
        yield call(clearAuthToken);
      }

      yield put(setIsAuthCheckHitted(true));

      yield put(setAuthCheckSuccess(true));
      yield put(setAuthCheckLoading(false));
    } catch (error) {
      yield put(setAuthCheckFailed(error));
      yield put(setAuthCheckLoading(false));
    }

    if (!isEmpty(authUser)) {
      const { role, userable } = authUser ?? {};
      const {
        permission_id,
        permission_admin_id,
        permission_merchant_id,
        permission_subscriber_id,
        merchant_id,
        market_id,
        branch_id,
        permission,
        permission_admin,
        permission_merchant,
        permission_subscriber,
      } = userable ?? {};

      const isMarketAdministrator = [
        USER_ROLES.USER_ADMIN,
        USER_ROLES.USER_MERCHANT,
      ].includes(role);
      const isCenterAdministrator =
        [USER_ROLES.USER_ADMIN, USER_ROLES.USER_MERCHANT].includes(role) ||
        (role === USER_ROLES.USER_SUBSCRIBER && isEmpty(branch_id));

      const masterPermissionId =
        role === USER_ROLES.USER_ADMIN
          ? permission_admin_id
          : role === USER_ROLES.USER_MERCHANT
          ? permission_merchant_id
          : role === USER_ROLES.USER_SUBSCRIBER
          ? permission_id
          : null;
      const secondPermissionId = isMarketAdministrator
        ? permission_subscriber_id
        : null;
      const currentMerchantId = !isEmpty(merchant_id) ? merchant_id : null;
      const currentMarketId = !isEmpty(market_id) ? market_id : null;
      const currentBranchId = !isEmpty(branch_id) ? branch_id : null;

      yield put(setMasterPermissionId(masterPermissionId));
      yield put(setSecondPermissionId(secondPermissionId));
      yield put(setCurrentMerchantId(currentMerchantId));
      yield put(setCurrentMarketId(currentMarketId));
      yield put(setCurrentBranchId(currentBranchId));

      yield put(setIsMarketAdministrator(isMarketAdministrator));
      yield put(setIsCenterAdministrator(isCenterAdministrator));

      let masterPermission =
        role === USER_ROLES.USER_ADMIN
          ? permission_admin
          : role === USER_ROLES.USER_MERCHANT
          ? permission_merchant
          : role === USER_ROLES.USER_SUBSCRIBER
          ? permission
          : null;
      let secondPermission = isMarketAdministrator
        ? permission_subscriber
        : null;
      let currentMarket = null;
      let currentBranch = null;

      let isMarketHasPlan = false;
      let isMarketHasAdvancedPlan = false;
      let isMarketHasBasicPlan = false;
      let isMarketHasTrialPlan = false;

      let isBranchHasPlan = false;
      let isBranchHasAdvancedPlan = false;
      let isBranchHasBasicPlan = false;
      let isBranchHasTrialPlan = false;

      if (!isEmpty(masterPermissionId) && isEmpty(masterPermission)) {
        try {
          yield put(setFetchMasterPermissionLoading(true));

          const response = yield call(
            role === USER_ROLES.USER_ADMIN
              ? getPermissionAdmin
              : role === USER_ROLES.USER_MERCHANT
              ? getPermissionMerchant
              : role === USER_ROLES.USER_SUBSCRIBER
              ? getPermissionSubscriber
              : getPermission,
            masterPermissionId
          );

          yield put(setIsFetchMasterPermissionHitted(true));

          masterPermission = response?.data;

          yield put(setFetchMasterPermissionSuccess(response?.meta?.message));
          yield put(setFetchMasterPermissionLoading(false));
        } catch (error) {
          yield put(setFetchMasterPermissionFailed(error));
          yield put(setFetchMasterPermissionLoading(false));
        }
      }
      if (!isEmpty(secondPermissionId) && isEmpty(secondPermission)) {
        try {
          yield put(setFetchSecondPermissionLoading(true));

          const response = yield call(
            role === USER_ROLES.USER_ADMIN
              ? getPermissionAdmin
              : role === USER_ROLES.USER_MERCHANT
              ? getPermissionMerchant
              : role === USER_ROLES.USER_SUBSCRIBER
              ? getPermissionSubscriber
              : getPermission,
            secondPermissionId
          );

          yield put(setIsFetchSecondPermissionHitted(true));

          secondPermission = response?.data;

          yield put(setFetchSecondPermissionSuccess(response?.meta?.message));
          yield put(setFetchSecondPermissionLoading(false));
        } catch (error) {
          yield put(setFetchSecondPermissionFailed(error));
          yield put(setFetchSecondPermissionLoading(false));
        }
      }
      if (!isEmpty(currentMerchantId) && role === USER_ROLES.USER_MERCHANT) {
        try {
          yield put(setFetchCurrentMerchantLoading(true));

          const response = yield call(getMerchant, currentMerchantId);

          yield put(setIsFetchCurrentMerchantHitted(true));
          yield put(setCurrentMerchant(response?.data));

          yield put(setFetchCurrentMerchantSuccess(response?.meta?.message));
          yield put(setFetchCurrentMerchantLoading(false));
        } catch (error) {
          yield put(setFetchCurrentMerchantFailed(error));
          yield put(setFetchCurrentMerchantLoading(false));
        }
      }
      if (!isEmpty(currentMarketId) && role === USER_ROLES.USER_SUBSCRIBER) {
        try {
          yield put(setFetchCurrentMarketLoading(true));

          const response = yield call(getMarket, currentMarketId);

          yield put(setIsFetchCurrentMarketHitted(true));
          yield put(setCurrentMarket(response?.data));

          currentMarket = response?.data;

          yield put(setFetchCurrentMarketSuccess(response?.meta?.message));
          yield put(setFetchCurrentMarketLoading(false));
        } catch (error) {
          yield put(setFetchCurrentMarketFailed(error));
          yield put(setFetchCurrentMarketLoading(false));
        }
      }
      if (!isEmpty(currentBranchId) && role === USER_ROLES.USER_SUBSCRIBER) {
        try {
          yield put(setFetchCurrentBranchLoading(true));

          const response = yield call(getBranch, currentBranchId);

          yield put(setIsFetchCurrentBranchHitted(true));
          yield put(setCurrentBranch(response?.data));

          currentBranch = response?.data;

          yield put(setFetchCurrentBranchSuccess(response?.meta?.message));
          yield put(setFetchCurrentBranchLoading(false));
        } catch (error) {
          yield put(setFetchCurrentBranchFailed(error));
          yield put(setFetchCurrentBranchLoading(false));
        }
      }
      if (!isEmpty(currentMarket)) {
        const marketPlans = currentMarket?.plans ?? [];
        const activePlans = getActivePlans(marketPlans);

        const { isHasAdvancedPlan, isHasBasicPlan, isHasTrialPlan } =
          activePlans;

        isMarketHasPlan = !isEmpty(marketPlans) || isMarketAdministrator;
        isMarketHasAdvancedPlan = isHasAdvancedPlan || isMarketAdministrator;
        isMarketHasBasicPlan = isHasBasicPlan || isMarketAdministrator;
        isMarketHasTrialPlan = isHasTrialPlan || isMarketAdministrator;

        yield put(setMarketPlans(marketPlans));
        yield put(setIsMarketHasPlan(isMarketHasPlan));
        yield put(setIsMarketHasAdvancedPlan(isMarketHasAdvancedPlan));
        yield put(setIsMarketHasBasicPlan(isMarketHasBasicPlan));
        yield put(setIsMarketHasTrialPlan(isMarketHasTrialPlan));
      }
      if (!isEmpty(currentBranch)) {
        const branchPlans = currentBranch?.plans ?? [];
        const activePlans = getActivePlans(branchPlans);

        const { isHasAdvancedPlan, isHasBasicPlan, isHasTrialPlan } =
          activePlans;

        isBranchHasPlan = !isEmpty(branchPlans) || isMarketAdministrator;
        isBranchHasAdvancedPlan = isHasAdvancedPlan || isMarketAdministrator;
        isBranchHasBasicPlan = isHasBasicPlan || isMarketAdministrator;
        isBranchHasTrialPlan = isHasTrialPlan || isMarketAdministrator;

        yield put(setBranchPlans(branchPlans));
        yield put(setIsBranchHasPlan(isBranchHasPlan));
        yield put(setIsBranchHasAdvancedPlan(isBranchHasAdvancedPlan));
        yield put(setIsBranchHasBasicPlan(isBranchHasBasicPlan));
        yield put(setIsBranchHasTrialPlan(isBranchHasTrialPlan));
      }

      const isHasActivePlan = isCenterAdministrator
        ? isMarketHasPlan
        : isBranchHasPlan;
      const isHasActiveAdvancedPlan = isCenterAdministrator
        ? isMarketHasAdvancedPlan
        : isBranchHasAdvancedPlan;
      const isHasActiveBasicPlan = isCenterAdministrator
        ? isMarketHasBasicPlan
        : isBranchHasBasicPlan;
      const isHasActiveTrialPlan = isCenterAdministrator
        ? isMarketHasTrialPlan
        : isBranchHasTrialPlan;

      if (!isEmpty(masterPermission)) {
        const sectorPages = currentMarket?.sector?.pages ?? [];
        const permissionPages = masterPermission?.pages ?? [];

        const accessPages =
          role !== USER_ROLES.USER_SUBSCRIBER
            ? permissionPages
            : getValidSectorPages(sectorPages, permissionPages);
        const forbidPages =
          role !== USER_ROLES.USER_SUBSCRIBER
            ? []
            : isHasActiveAdvancedPlan
            ? []
            : isHasActiveBasicPlan
            ? [
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_PURCHASE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_RESUPPLY,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_SUPPLIER,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY_INGREDIENT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY_RECIPE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_PURCHASE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_WAITING,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE,
              ]
            : isHasActiveTrialPlan
            ? [
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_PURCHASE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_RESUPPLY,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ACCOUNTING_SUPPLIER,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_ADVERTISEMENT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_APPLICATION,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_CUSTOMER,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_DEVICE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_DISCOUNT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_GENERAL_SETTINGS,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY_INGREDIENT,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_INVENTORY_RECIPE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_KITCHEN,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_PAYMENT_METHOD,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_PERMISSION,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_RATING,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_ATTENDANCE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_PURCHASE,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_REPORT_SUMMARY,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_SCREEN_WAITING,
                PERMISSION_SUBSCRIBER_PAGES.PAGE_TABLE,
              ]
            : [];
        const permitPages = ArrayExtension.getUniqueValues(
          accessPages,
          forbidPages
        );

        yield put(setMasterPermission(masterPermission));
        yield put(setMasterAccessPages(accessPages));
        yield put(setMasterForbidPages(forbidPages));
        yield put(setMasterPermitPages(permitPages));
      }
      if (!isEmpty(secondPermission)) {
        const permissionPages = secondPermission?.pages ?? [];

        const accessPages = permissionPages;
        const forbidPages = [];
        const permitPages = ArrayExtension.getUniqueValues(
          accessPages,
          forbidPages
        );

        yield put(setSecondPermission(secondPermission));
        yield put(setSecondAccessPages(accessPages));
        yield put(setSecondForbidPages(forbidPages));
        yield put(setSecondPermitPages(permitPages));
      }

      yield put(setIsHasActivePlan(isHasActivePlan));
      yield put(setIsHasActiveAdvancedPlan(isHasActiveAdvancedPlan));
      yield put(setIsHasActiveBasicPlan(isHasActiveBasicPlan));
      yield put(setIsHasActiveTrialPlan(isHasActiveTrialPlan));
    }

    yield put(setIsFetchInitializeHitted(true));

    yield put(setFetchInitializeSuccess("Success"));
    yield put(setFetchInitializeLoading(false));
  } catch (error) {
    yield put(setFetchInitializeFailed(error));
    yield put(setFetchInitializeLoading(false));
  }
}
export function* _getMasterPermission({ payload: { role, permissionId } }) {
  try {
    yield put(setFetchMasterPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(
      role === USER_ROLES.USER_ADMIN
        ? getPermissionAdmin
        : role === USER_ROLES.USER_MERCHANT
        ? getPermissionMerchant
        : role === USER_ROLES.USER_SUBSCRIBER
        ? getPermissionSubscriber
        : getPermission,
      permissionId
    );

    yield put(setIsFetchMasterPermissionHitted(true));
    yield put(setMasterPermission(permission));

    yield put(setFetchMasterPermissionSuccess(message));
    yield put(setFetchMasterPermissionLoading(false));
  } catch (error) {
    yield put(setFetchMasterPermissionFailed(error));
    yield put(setFetchMasterPermissionLoading(false));
  }
}
export function* _getSecondPermission({ payload: { role, permissionId } }) {
  try {
    yield put(setFetchSecondPermissionLoading(true));

    const {
      meta: { message },
      data: permission,
    } = yield call(
      role === USER_ROLES.USER_ADMIN
        ? getPermissionAdmin
        : role === USER_ROLES.USER_MERCHANT
        ? getPermissionMerchant
        : role === USER_ROLES.USER_SUBSCRIBER
        ? getPermissionSubscriber
        : getPermission,
      permissionId
    );

    yield put(setIsFetchSecondPermissionHitted(true));
    yield put(setSecondPermission(permission));

    yield put(setFetchSecondPermissionSuccess(message));
    yield put(setFetchSecondPermissionLoading(false));
  } catch (error) {
    yield put(setFetchSecondPermissionFailed(error));
    yield put(setFetchSecondPermissionLoading(false));
  }
}
export function* _getCurrentMarket({ payload: marketId }) {
  try {
    yield put(setFetchCurrentMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId);

    yield put(setIsFetchCurrentMarketHitted(true));
    yield put(setCurrentMarket(market));

    yield put(setFetchCurrentMarketSuccess(message));
    yield put(setFetchCurrentMarketLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMarketFailed(error));
    yield put(setFetchCurrentMarketLoading(false));
  }
}
export function* _getCurrentBranch({ payload: branchId }) {
  try {
    yield put(setFetchCurrentBranchLoading(true));

    const {
      meta: { message },
      data: branch,
    } = yield call(getBranch, branchId);

    yield put(setIsFetchCurrentBranchHitted(true));
    yield put(setCurrentBranch(branch));

    yield put(setFetchCurrentBranchSuccess(message));
    yield put(setFetchCurrentBranchLoading(false));
  } catch (error) {
    yield put(setFetchCurrentBranchFailed(error));
    yield put(setFetchCurrentBranchLoading(false));
  }
}
export function* _getCurrentMerchant({ payload: merchantId }) {
  try {
    yield put(setFetchCurrentMerchantLoading(true));

    const {
      meta: { message },
      data: merchant,
    } = yield call(getMerchant, merchantId);

    yield put(setIsFetchCurrentMerchantHitted(true));
    yield put(setCurrentMerchant(merchant));

    yield put(setFetchCurrentMerchantSuccess(message));
    yield put(setFetchCurrentMerchantLoading(false));
  } catch (error) {
    yield put(setFetchCurrentMerchantFailed(error));
    yield put(setFetchCurrentMerchantLoading(false));
  }
}
export function* _getCurrentSchedules() {
  try {
    yield put(setFetchCurrentSchedulesLoading(true));

    const includes = yield select(getFetchCurrentSchedulesIncludes);
    const market_id = yield select(getFetchCurrentSchedulesFilterMarketId);
    const branch_id = yield select(getFetchCurrentSchedulesFilterBranchId);

    const parameters = {
      includes,
      filter: { market_id, branch_id },
    };

    const {
      meta: { message },
      data: schedules,
    } = yield call(getSchedules, parameters);

    yield put(setIsFetchCurrentSchedulesHitted(true));
    yield put(setCurrentSchedules(schedules));

    yield put(setFetchCurrentSchedulesSuccess(message));
    yield put(setFetchCurrentSchedulesLoading(false));
  } catch (error) {
    yield put(setFetchCurrentSchedulesFailed(error));
    yield put(setFetchCurrentSchedulesLoading(false));
  }
}
export function* _getTodayAttendance({ payload: userId }) {
  try {
    yield put(setFetchTodayAttendanceLoading(true));

    const market_id = yield select(getFetchTodayAttendanceMarketId);
    const branch_id = yield select(getFetchTodayAttendanceBranchId);

    const parameters = { market_id, branch_id };

    const {
      meta: { message },
      data: attendance,
    } = yield call(getCurrentAttendance, userId, parameters);

    yield put(setIsFetchTodayAttendanceHitted(true));
    yield put(setTodayAttendance(attendance));

    yield put(setFetchTodayAttendanceSuccess(message));
    yield put(setFetchTodayAttendanceLoading(false));
  } catch (error) {
    yield put(setFetchTodayAttendanceFailed(error));
    yield put(setFetchTodayAttendanceLoading(false));
  }
}
export function* _getGlobalSubscriptions() {
  try {
    yield put(setFetchGlobalSubscriptionsLoading(true));

    const search = yield select(getFetchGlobalSubscriptionsSearch);
    const sort = yield select(getFetchGlobalSubscriptionsSort);
    const key_by = yield select(getFetchGlobalSubscriptionsKeyBy);
    const page = yield select(getFetchGlobalSubscriptionsPage);
    const per_page = yield select(getFetchGlobalSubscriptionsPerPage);
    const includes = yield select(getFetchGlobalSubscriptionsIncludes);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
    };

    const {
      meta: { message },
      data: subscriptions,
    } = yield call(getSubscriptions, parameters);

    yield put(setIsFetchGlobalSubscriptionsHitted(true));
    yield put(
      setIsGlobalSubscriptionsHasMore(Object.keys(subscriptions).length > 0)
    );

    if (page > 1) {
      yield put(appendGlobalSubscriptions(subscriptions));
    } else {
      yield put(setGlobalSubscriptions(subscriptions));
    }

    yield put(setFetchGlobalSubscriptionsSuccess(message));
    yield put(setFetchGlobalSubscriptionsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalSubscriptionsFailed(error));
    yield put(setFetchGlobalSubscriptionsLoading(false));
  }
}
export function* _getGlobalFeatureSubscriptions() {
  try {
    yield put(setFetchGlobalFeatureSubscriptionsLoading(true));

    const search = yield select(getFetchGlobalFeatureSubscriptionsSearch);
    const sort = yield select(getFetchGlobalFeatureSubscriptionsSort);
    const key_by = yield select(getFetchGlobalFeatureSubscriptionsKeyBy);
    const page = yield select(getFetchGlobalFeatureSubscriptionsPage);
    const per_page = yield select(getFetchGlobalFeatureSubscriptionsPerPage);
    const includes = yield select(getFetchGlobalFeatureSubscriptionsIncludes);
    const is_highlight = yield select(
      getFetchGlobalFeatureSubscriptionsFilterIsHighlight
    );

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { is_highlight },
    };

    const {
      meta: { message },
      data: { data: features },
    } = yield call(getSubscriptionFeatures, parameters);

    yield put(setIsFetchGlobalFeatureSubscriptionsHitted(true));
    yield put(setIsGlobalFeatureSubscriptionsHasMore(features.length > 0));

    if (page > 1) {
      yield put(appendGlobalFeatureSubscriptions(features));
    } else {
      yield put(setGlobalFeatureSubscriptions(features));
    }

    yield put(setFetchGlobalFeatureSubscriptionsSuccess(message));
    yield put(setFetchGlobalFeatureSubscriptionsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalFeatureSubscriptionsFailed(error));
    yield put(setFetchGlobalFeatureSubscriptionsLoading(false));
  }
}
export function* _getGlobalFeatureSystems() {
  try {
    yield put(setFetchGlobalFeatureSystemsLoading(true));

    const search = yield select(getFetchGlobalFeatureSystemsSearch);
    const sort = yield select(getFetchGlobalFeatureSystemsSort);
    const key_by = yield select(getFetchGlobalFeatureSystemsKeyBy);
    const page = yield select(getFetchGlobalFeatureSystemsPage);
    const per_page = yield select(getFetchGlobalFeatureSystemsPerPage);
    const includes = yield select(getFetchGlobalFeatureSystemsIncludes);
    const roles = yield select(getFetchGlobalFeatureSystemsFilterRoles);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { roles },
    };

    const {
      meta: { message },
      data: features,
    } = yield call(getFeatureSystems, parameters);

    yield put(setIsFetchGlobalFeatureSystemsHitted(true));
    yield put(
      setIsGlobalFeatureSystemsHasMore(Object.keys(features).length > 0)
    );

    if (page > 1) {
      yield put(appendGlobalFeatureSystems(features));
    } else {
      yield put(setGlobalFeatureSystems(features));
    }

    yield put(setFetchGlobalFeatureSystemsSuccess(message));
    yield put(setFetchGlobalFeatureSystemsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalFeatureSystemsFailed(error));
    yield put(setFetchGlobalFeatureSystemsLoading(false));
  }
}
export function* _getGlobalRestrictionNotices() {
  try {
    yield put(setFetchGlobalRestrictionNoticesLoading(true));

    const search = yield select(getFetchGlobalRestrictionNoticesSearch);
    const sort = yield select(getFetchGlobalRestrictionNoticesSort);
    const key_by = yield select(getFetchGlobalRestrictionNoticesKeyBy);
    const page = yield select(getFetchGlobalRestrictionNoticesPage);
    const per_page = yield select(getFetchGlobalRestrictionNoticesPerPage);
    const includes = yield select(getFetchGlobalRestrictionNoticesIncludes);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
    };

    const {
      meta: { message },
      data: restrictionNotices,
    } = yield call(getRestrictionNotices, parameters);

    yield put(setIsFetchGlobalRestrictionNoticesHitted(true));
    yield put(
      setIsGlobalRestrictionNoticesHasMore(
        Object.keys(restrictionNotices).length > 0
      )
    );

    if (page > 1) {
      yield put(appendGlobalRestrictionNotices(restrictionNotices));
    } else {
      yield put(setGlobalRestrictionNotices(restrictionNotices));
    }

    yield put(setFetchGlobalRestrictionNoticesSuccess(message));
    yield put(setFetchGlobalRestrictionNoticesLoading(false));
  } catch (error) {
    yield put(setFetchGlobalRestrictionNoticesFailed(error));
    yield put(setFetchGlobalRestrictionNoticesLoading(false));
  }
}
export function* _getGlobalBanners() {
  try {
    yield put(setFetchGlobalBannersLoading(true));

    const search = yield select(getFetchGlobalBannersSearch);
    const sort = yield select(getFetchGlobalBannersSort);
    const key_by = yield select(getFetchGlobalBannersKeyBy);
    const page = yield select(getFetchGlobalBannersPage);
    const per_page = yield select(getFetchGlobalBannersPerPage);
    const includes = yield select(getFetchGlobalBannersIncludes);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
    };

    const {
      meta: { message },
      data: banners,
    } = yield call(getBanners, parameters);

    yield put(setIsFetchGlobalBannersHitted(true));
    yield put(setIsGlobalBannersHasMore(Object.keys(banners).length > 0));

    if (page > 1) {
      yield put(appendGlobalBanners(banners));
    } else {
      yield put(setGlobalBanners(banners));
    }

    yield put(setFetchGlobalBannersSuccess(message));
    yield put(setFetchGlobalBannersLoading(false));
  } catch (error) {
    yield put(setFetchGlobalBannersFailed(error));
    yield put(setFetchGlobalBannersLoading(false));
  }
}
export function* _getGlobalBannerTargets() {
  try {
    yield put(setFetchGlobalBannerTargetsLoading(true));

    const search = yield select(getFetchGlobalBannerTargetsSearch);
    const sort = yield select(getFetchGlobalBannerTargetsSort);
    const key_by = yield select(getFetchGlobalBannerTargetsKeyBy);
    const page = yield select(getFetchGlobalBannerTargetsPage);
    const per_page = yield select(getFetchGlobalBannerTargetsPerPage);
    const includes = yield select(getFetchGlobalBannerTargetsIncludes);
    const role = yield select(getFetchGlobalBannerTargetsFilterRole);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: { role },
    };

    const {
      meta: { message },
      data: targets,
    } = yield call(getTargets, parameters);

    yield put(setIsFetchGlobalBannerTargetsHitted(true));
    yield put(setIsGlobalBannerTargetsHasMore(Object.keys(targets).length > 0));

    if (page > 1) {
      yield put(appendGlobalBannerTargets(targets));
    } else {
      yield put(setGlobalBannerTargets(targets));
    }

    yield put(setFetchGlobalBannerTargetsSuccess(message));
    yield put(setFetchGlobalBannerTargetsLoading(false));
  } catch (error) {
    yield put(setFetchGlobalBannerTargetsFailed(error));
    yield put(setFetchGlobalBannerTargetsLoading(false));
  }
}

export function* onFetchInitializeStart() {
  yield takeLatest(GLOBAL_ACTION_TYPES.FETCH_INITIALIZE_START, _getInitialize);
}
export function* onFetchMasterPermissionStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_MASTER_PERMISSION_START,
    _getMasterPermission
  );
}
export function* onFetchSecondPermissionStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_SECOND_PERMISSION_START,
    _getSecondPermission
  );
}
export function* onFetchCurrentMarketStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MARKET_START,
    _getCurrentMarket
  );
}
export function* onFetchCurrentBranchStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_BRANCH_START,
    _getCurrentBranch
  );
}
export function* onFetchCurrentMerchantStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_MERCHANT_START,
    _getCurrentMerchant
  );
}
export function* onFetchCurrentSchedulesStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_CURRENT_SCHEDULES_START,
    _getCurrentSchedules
  );
}
export function* onFetchTodayAttendanceStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_TODAY_ATTENDANCE_START,
    _getTodayAttendance
  );
}
export function* onFetchGlobalSubscriptionsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_SUBSCRIPTIONS_START,
    _getGlobalSubscriptions
  );
}
export function* onFetchGlobalFeatureSubscriptionsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_FEATURE_SUBSCRIPTIONS_START,
    _getGlobalFeatureSubscriptions
  );
}
export function* onFetchGlobalFeatureSystemsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_FEATURE_SYSTEMS_START,
    _getGlobalFeatureSystems
  );
}
export function* onFetchGlobalRestrictionNoticesStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_RESTRICTION_NOTICES_START,
    _getGlobalRestrictionNotices
  );
}
export function* onFetchGlobalBannersStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_BANNERS_START,
    _getGlobalBanners
  );
}
export function* onFetchGlobalBannerTargetsStart() {
  yield takeLatest(
    GLOBAL_ACTION_TYPES.FETCH_GLOBAL_BANNER_TARGETS_START,
    _getGlobalBannerTargets
  );
}

export function* globalSaga() {
  yield all([
    call(onFetchInitializeStart),
    call(onFetchMasterPermissionStart),
    call(onFetchSecondPermissionStart),
    call(onFetchCurrentMarketStart),
    call(onFetchCurrentBranchStart),
    call(onFetchCurrentMerchantStart),
    call(onFetchCurrentSchedulesStart),
    call(onFetchTodayAttendanceStart),
    call(onFetchGlobalSubscriptionsStart),
    call(onFetchGlobalFeatureSubscriptionsStart),
    call(onFetchGlobalFeatureSystemsStart),
    call(onFetchGlobalRestrictionNoticesStart),
    call(onFetchGlobalBannersStart),
    call(onFetchGlobalBannerTargetsStart),
  ]);
}
