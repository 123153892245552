import { takeLatest, put, all, call, select } from "redux-saga/effects";

import REPORT_ATTENDANCE_ACTION_TYPES from "./report-attendance.type";

import {
  appendReportAttendances,
  setFetchReportAttendancesFailed,
  setFetchReportAttendancesLoading,
  setFetchReportAttendancesSuccess,
  setFetchReportBranchTotalsFailed,
  setFetchReportBranchTotalsLoading,
  setFetchReportBranchTotalsSuccess,
  setFetchReportCashierTotalsFailed,
  setFetchReportCashierTotalsLoading,
  setFetchReportCashierTotalsSuccess,
  setFetchReportTotalsFailed,
  setFetchReportTotalsLoading,
  setFetchReportTotalsSuccess,
  setIsFetchReportAttendancesHitted,
  setIsFetchReportBranchTotalsHitted,
  setIsFetchReportCashierTotalsHitted,
  setIsFetchReportTotalsHitted,
  setIsReportAttendancesHasMore,
  setReportAttendances,
  setReportBranchTotals,
  setReportCashierTotals,
  setReportTotals,
} from "./report-attendance.action";
import {
  getFetchReportAttendancesIncludes,
  getFetchReportAttendancesKeyBy,
  getFetchReportAttendancesPage,
  getFetchReportAttendancesPerPage,
  getFetchReportAttendancesSearch,
  getFetchReportAttendancesSort,
  getFetchReportAttendanceTotalsFilterOrderStatuses,
  getFetchReportAttendanceTotalsFilterTransactionStatuses,
  getFetchReportCashierTotalsFilterCashierId,
  getFetchReportsFilterBranchId,
  getFetchReportsFilterEndAtBefore,
  getFetchReportsFilterMarketId,
  getFetchReportsFilterStartAtAfter,
  getFetchReportsFilterUserId,
} from "./report-attendance.selector";

import {
  getReportAttendances,
  getReportAttendanceTotals,
  getReportTotals,
} from "../../api/report-attendance.api";

export function* _getReportTotals() {
  try {
    yield put(setFetchReportTotalsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const user_id = yield select(getFetchReportsFilterUserId);
    const start_at_after = yield select(getFetchReportsFilterStartAtAfter);
    const end_at_before = yield select(getFetchReportsFilterEndAtBefore);

    const parameters = {
      filter: {
        market_id,
        branch_id,
        user_id,
        start_at_after,
        end_at_before,
      },
    };

    const {
      meta: { message },
      data: reportTotals,
    } = yield call(getReportTotals, parameters);

    yield put(setIsFetchReportTotalsHitted(true));
    yield put(setReportTotals(reportTotals));

    yield put(setFetchReportTotalsSuccess(message));
    yield put(setFetchReportTotalsLoading(false));
  } catch (error) {
    yield put(setFetchReportTotalsFailed(error));
    yield put(setFetchReportTotalsLoading(false));
  }
}
export function* _getReportAttendances() {
  try {
    yield put(setFetchReportAttendancesLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const user_id = yield select(getFetchReportsFilterUserId);
    const start_at_after = yield select(getFetchReportsFilterStartAtAfter);
    const end_at_before = yield select(getFetchReportsFilterEndAtBefore);

    const search = yield select(getFetchReportAttendancesSearch);
    const sort = yield select(getFetchReportAttendancesSort);
    const key_by = yield select(getFetchReportAttendancesKeyBy);
    const page = yield select(getFetchReportAttendancesPage);
    const per_page = yield select(getFetchReportAttendancesPerPage);
    const includes = yield select(getFetchReportAttendancesIncludes);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        user_id,
        start_at_after,
        end_at_before,
      },
    };

    const {
      meta: { message },
      data: { data: reportAttendances },
    } = yield call(getReportAttendances, parameters);

    yield put(setIsFetchReportAttendancesHitted(true));
    yield put(setIsReportAttendancesHasMore(reportAttendances.length > 0));

    if (page > 1) {
      yield put(appendReportAttendances(reportAttendances));
    } else {
      yield put(setReportAttendances(reportAttendances));
    }

    yield put(setFetchReportAttendancesSuccess(message));
    yield put(setFetchReportAttendancesLoading(false));
  } catch (error) {
    yield put(setFetchReportAttendancesFailed(error));
    yield put(setFetchReportAttendancesLoading(false));
  }
}
export function* _getReportCashierTotals({ payload: attendanceId }) {
  try {
    yield put(setFetchReportCashierTotalsLoading(true));

    const order_statuses = yield select(
      getFetchReportAttendanceTotalsFilterOrderStatuses
    );
    const transaction_statuses = yield select(
      getFetchReportAttendanceTotalsFilterTransactionStatuses
    );
    const cashier_id = yield select(getFetchReportCashierTotalsFilterCashierId);

    const parameters = {
      filter: {
        order_statuses,
        transaction_statuses,
        cashier_id,
      },
    };

    const {
      meta: { message },
      data: reportAttendanceTotals,
    } = yield call(getReportAttendanceTotals, attendanceId, parameters);

    yield put(setIsFetchReportCashierTotalsHitted(true));
    yield put(setReportCashierTotals(reportAttendanceTotals));

    yield put(setFetchReportCashierTotalsSuccess(message));
    yield put(setFetchReportCashierTotalsLoading(false));
  } catch (error) {
    yield put(setFetchReportCashierTotalsFailed(error));
    yield put(setFetchReportCashierTotalsLoading(false));
  }
}
export function* _getReportBranchTotals({ payload: attendanceId }) {
  try {
    yield put(setFetchReportBranchTotalsLoading(true));

    const order_statuses = yield select(
      getFetchReportAttendanceTotalsFilterOrderStatuses
    );
    const transaction_statuses = yield select(
      getFetchReportAttendanceTotalsFilterTransactionStatuses
    );

    const parameters = {
      filter: {
        order_statuses,
        transaction_statuses,
      },
    };

    const {
      meta: { message },
      data: reportAttendanceTotals,
    } = yield call(getReportAttendanceTotals, attendanceId, parameters);

    yield put(setIsFetchReportBranchTotalsHitted(true));
    yield put(setReportBranchTotals(reportAttendanceTotals));

    yield put(setFetchReportBranchTotalsSuccess(message));
    yield put(setFetchReportBranchTotalsLoading(false));
  } catch (error) {
    yield put(setFetchReportBranchTotalsFailed(error));
    yield put(setFetchReportBranchTotalsLoading(false));
  }
}

export function* onFetchReportTotalsStart() {
  yield takeLatest(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_TOTALS_START,
    _getReportTotals
  );
}
export function* onFetchReportAttendancesStart() {
  yield takeLatest(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_ATTENDANCES_START,
    _getReportAttendances
  );
}
export function* onFetchReportCashierTotalsStart() {
  yield takeLatest(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_CASHIER_TOTALS_START,
    _getReportCashierTotals
  );
}
export function* onFetchReportBranchTotalsStart() {
  yield takeLatest(
    REPORT_ATTENDANCE_ACTION_TYPES.FETCH_REPORT_BRANCH_TOTALS_START,
    _getReportBranchTotals
  );
}

export function* reportAttendanceSaga() {
  yield all([
    call(onFetchReportTotalsStart),
    call(onFetchReportAttendancesStart),
    call(onFetchReportCashierTotalsStart),
    call(onFetchReportBranchTotalsStart),
  ]);
}
