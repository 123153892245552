import { createAction } from "../../utils/store.utils";

import RENEWAL_SUBSCRIBER_ACTION_TYPES from "./renewal-subscriber.type";

export const setRenewals = (renewals) =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_RENEWALS, renewals);
export const setRenewal = (renewal) =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_RENEWAL, renewal);
export const setRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_RENEWAL_BRANCHES,
    renewalBranches
  );
export const setRenewalCreate = (renewalCreate) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_RENEWAL_CREATE,
    renewalCreate
  );
export const setRenewalCreateCalculate = (renewalCreateCalculate) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_RENEWAL_CREATE_CALCULATE,
    renewalCreateCalculate
  );

export const appendRenewals = (renewals) =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.APPEND_RENEWALS, renewals);
export const appendRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.APPEND_RENEWAL_BRANCHES,
    renewalBranches
  );

export const setIsRenewalsHasMore = (isRenewalsHasMore) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_RENEWALS_HAS_MORE,
    isRenewalsHasMore
  );
export const setIsRenewalBranchesHasMore = (isRenewalBranchesHasMore) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_RENEWAL_BRANCHES_HAS_MORE,
    isRenewalBranchesHasMore
  );

export const setFetchRenewalsSearch = (fetchRenewalsSearch) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_SEARCH,
    fetchRenewalsSearch
  );
export const setFetchRenewalsSort = (fetchRenewalsSort) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_SORT,
    fetchRenewalsSort
  );
export const setFetchRenewalsKeyBy = (fetchRenewalsKeyBy) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_KEY_BY,
    fetchRenewalsKeyBy
  );
export const setFetchRenewalsPage = (fetchRenewalsPage) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_PAGE,
    fetchRenewalsPage
  );
export const setFetchRenewalsPerPage = (fetchRenewalsPerPage) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_PER_PAGE,
    fetchRenewalsPerPage
  );
export const setFetchRenewalsIncludes = (fetchRenewalsIncludes) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_INCLUDES,
    fetchRenewalsIncludes
  );
export const setFetchRenewalsFilterMarketId = (fetchRenewalsFilterMarketId) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_MARKET_ID,
    fetchRenewalsFilterMarketId
  );
export const setFetchRenewalsFilterSubscriptionKeys = (
  fetchRenewalsFilterSubscriptionKeys
) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS,
    fetchRenewalsFilterSubscriptionKeys
  );
export const setFetchRenewalsFilterSubscriptionOptionKeys = (
  fetchRenewalsFilterSubscriptionOptionKeys
) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS,
    fetchRenewalsFilterSubscriptionOptionKeys
  );
export const setFetchRenewalsFilterStatuses = (fetchRenewalsFilterStatuses) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_STATUSES,
    fetchRenewalsFilterStatuses
  );
export const setFetchRenewalsFilterCreatedBy = (fetchRenewalsFilterCreatedBy) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_CREATED_BY,
    fetchRenewalsFilterCreatedBy
  );
export const setFetchRenewalsLoading = (fetchRenewalsLoading) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_LOADING,
    fetchRenewalsLoading
  );
export const setFetchRenewalsSuccess = (fetchRenewalsSuccess) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_SUCCESS,
    fetchRenewalsSuccess
  );
export const setFetchRenewalsFailed = (fetchRenewalsFailed) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWALS_FAILED,
    fetchRenewalsFailed
  );

export const setFetchRenewalLoading = (fetchRenewalLoading) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_LOADING,
    fetchRenewalLoading
  );
export const setFetchRenewalSuccess = (fetchRenewalSuccess) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_SUCCESS,
    fetchRenewalSuccess
  );
export const setFetchRenewalFailed = (fetchRenewalFailed) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_FAILED,
    fetchRenewalFailed
  );

export const setFetchRenewalBranchesParams = (fetchRenewalBranchesParams) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_PARAMS,
    fetchRenewalBranchesParams
  );
export const setFetchRenewalBranchesLoading = (fetchRenewalBranchesLoading) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_LOADING,
    fetchRenewalBranchesLoading
  );
export const setFetchRenewalBranchesSuccess = (fetchRenewalBranchesSuccess) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_SUCCESS,
    fetchRenewalBranchesSuccess
  );
export const setFetchRenewalBranchesFailed = (fetchRenewalBranchesFailed) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_FAILED,
    fetchRenewalBranchesFailed
  );

export const setCreateRenewalLoading = (createRenewalLoading) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_RENEWAL_LOADING,
    createRenewalLoading
  );
export const setCreateRenewalSuccess = (createRenewalSuccess) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_RENEWAL_SUCCESS,
    createRenewalSuccess
  );
export const setCreateRenewalFailed = (createRenewalFailed) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_RENEWAL_FAILED,
    createRenewalFailed
  );

export const setCreateCalculateRenewalLoading = (
  createCalculateRenewalLoading
) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_LOADING,
    createCalculateRenewalLoading
  );
export const setCreateCalculateRenewalSuccess = (
  createCalculateRenewalSuccess
) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_SUCCESS,
    createCalculateRenewalSuccess
  );
export const setCreateCalculateRenewalFailed = (createCalculateRenewalFailed) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_FAILED,
    createCalculateRenewalFailed
  );

export const setIsFetchRenewalsHitted = (isFetchRenewalsHitted) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_RENEWALS_HITTED,
    isFetchRenewalsHitted
  );
export const setIsFetchRenewalHitted = (isFetchRenewalHitted) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_HITTED,
    isFetchRenewalHitted
  );
export const setIsFetchRenewalBranchesHitted = (isFetchRenewalBranchesHitted) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_BRANCHES_HITTED,
    isFetchRenewalBranchesHitted
  );
export const setIsCreateRenewalHitted = (isCreateRenewalHitted) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_RENEWAL_HITTED,
    isCreateRenewalHitted
  );
export const setIsCreateCalculateRenewalHitted = (
  isCreateCalculateRenewalHitted
) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.SET_IS_CREATE_CALCULATE_RENEWAL_HITTED,
    isCreateCalculateRenewalHitted
  );

export const fetchRenewalsStart = () =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.FETCH_RENEWALS_START);
export const fetchRenewalStart = (renewalId) =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.FETCH_RENEWAL_START, renewalId);
export const fetchRenewalBranchesStart = () =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START);
export const createRenewalStart = (request) =>
  createAction(RENEWAL_SUBSCRIBER_ACTION_TYPES.CREATE_RENEWAL_START, request);
export const createCalculateRenewalStart = (request) =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.CREATE_CALCULATE_RENEWAL_START,
    request
  );

export const resetRenewalSubscriberReducer = () =>
  createAction(
    RENEWAL_SUBSCRIBER_ACTION_TYPES.RESET_RENEWAL_SUBSCRIBER_REDUCER
  );
