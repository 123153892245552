import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getCategories } from "../../api/category-purchase.api";

import SELECT_CATEGORY_PURCHASE_ACTION_TYPES from "./select-category-purchase.type";
import {
  appendCategories,
  appendSearchCategories,
  setAppendCategoriesFailed,
  setAppendCategoriesLoading,
  setAppendCategoriesSuccess,
  setCategories,
  setFetchCategoriesFailed,
  setFetchCategoriesLoading,
  setFetchCategoriesSuccess,
  setIsAppendCategoriesHitted,
  setIsCategoriesHasMore,
  setIsFetchCategoriesHitted,
  setIsSearchCategoriesHasMore,
  setIsSearchCategoriesHitted,
  setSearchCategories,
  setSearchCategoriesFailed,
  setSearchCategoriesLoading,
  setSearchCategoriesSuccess,
} from "./select-category-purchase.action";
import {
  getAppendCategoriesParams,
  getFetchCategoriesParams,
  getSearchCategoriesParams,
} from "./select-category-purchase.selector";

export function* _getFetchCategories() {
  try {
    const fetchCategoriesParams = yield select(getFetchCategoriesParams);
    const parameters = getParamsWithDefault(fetchCategoriesParams);

    yield put(setFetchCategoriesLoading(true));

    const {
      meta: { message },
      data: categories,
    } = yield call(getCategories, parameters);

    yield put(setIsFetchCategoriesHitted(true));
    yield put(setIsCategoriesHasMore(Object.keys(categories).length > 0));

    if (parameters.page > 1) {
      yield put(appendCategories(categories));
    } else {
      yield put(setCategories(categories));
    }

    yield put(setFetchCategoriesSuccess(message));
    yield put(setFetchCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchCategoriesFailed(error));
    yield put(setFetchCategoriesLoading(false));
  }
}
export function* _getSearchCategories() {
  try {
    const searchCategoriesParams = yield select(getSearchCategoriesParams);
    const parameters = getParamsWithDefault(searchCategoriesParams);

    yield put(setSearchCategoriesLoading(true));

    const {
      meta: { message },
      data: categories,
    } = yield call(getCategories, parameters);

    yield put(setIsSearchCategoriesHitted(true));
    yield put(setIsSearchCategoriesHasMore(Object.keys(categories).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchCategories(categories));
    } else {
      yield put(setSearchCategories(categories));
    }

    yield put(setSearchCategoriesSuccess(message));
    yield put(setSearchCategoriesLoading(false));
  } catch (error) {
    yield put(setSearchCategoriesFailed(error));
    yield put(setSearchCategoriesLoading(false));
  }
}
export function* _getAppendCategories() {
  try {
    const appendCategoriesParams = yield select(getAppendCategoriesParams);
    const parameters = getParamsWithDefault(appendCategoriesParams);

    yield put(setAppendCategoriesLoading(true));

    const {
      meta: { message },
      data: categories,
    } = yield call(getCategories, parameters);

    yield put(setIsAppendCategoriesHitted(true));
    yield put(appendCategories(categories));

    yield put(setAppendCategoriesSuccess(message));
    yield put(setAppendCategoriesLoading(false));
  } catch (error) {
    yield put(setAppendCategoriesFailed(error));
    yield put(setAppendCategoriesLoading(false));
  }
}

export function* onFetchCategoriesStart() {
  yield takeLatest(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.FETCH_CATEGORIES_START,
    _getFetchCategories
  );
}
export function* onSearchCategoriesStart() {
  yield takeLatest(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SEARCH_CATEGORIES_START,
    _getSearchCategories
  );
}
export function* onAppendCategoriesStart() {
  yield takeLatest(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_CATEGORIES_START,
    _getAppendCategories
  );
}

export function* selectCategoryPurchaseSaga() {
  yield all([
    call(onFetchCategoriesStart),
    call(onSearchCategoriesStart),
    call(onAppendCategoriesStart),
  ]);
}
