import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import {
  getRenewalTransfers,
  getRenewalTransfer,
  createRenewalTransfer,
  updateRenewalTransfer,
  deleteRenewalTransfer,
} from "../../api/renewal-transfer.api";

import RENEWAL_TRANSFER_ACTION_TYPES from "./renewal-transfer.type";
import {
  appendRenewalTransfers,
  setRenewalTransfers,
  setRenewalTransfer,
  setCreateRenewalTransferFailed,
  setCreateRenewalTransferLoading,
  setCreateRenewalTransferSuccess,
  setDeleteRenewalTransferFailed,
  setDeleteRenewalTransferLoading,
  setDeleteRenewalTransferSuccess,
  setFetchRenewalTransfersFailed,
  setFetchRenewalTransfersLoading,
  setFetchRenewalTransfersSuccess,
  setFetchRenewalTransferFailed,
  setFetchRenewalTransferLoading,
  setFetchRenewalTransferSuccess,
  setIsRenewalTransfersHasMore,
  setIsCreateRenewalTransferHitted,
  setIsDeleteRenewalTransferHitted,
  setIsFetchRenewalTransfersHitted,
  setIsFetchRenewalTransferHitted,
  setIsUpdateRenewalTransferHitted,
  setUpdateRenewalTransferFailed,
  setUpdateRenewalTransferLoading,
  setUpdateRenewalTransferSuccess,
  setFetchRenewalTransfersParams,
} from "./renewal-transfer.action";
import {
  getFetchRenewalTransferParams,
  getFetchRenewalTransfersParams,
  getIsFetchRenewalTransfersHitted,
} from "./renewal-transfer.selector";

export function* _getRenewalTransfers() {
  try {
    yield put(setFetchRenewalTransfersLoading(true));

    const fetchRenewalTransfersParams = yield select(
      getFetchRenewalTransfersParams
    );
    const parameters = getParamsWithDefault(fetchRenewalTransfersParams);

    const {
      meta: { message },
      data: { data: renewalTransfers },
    } = yield call(getRenewalTransfers, parameters);

    yield put(setIsFetchRenewalTransfersHitted(true));
    yield put(setIsRenewalTransfersHasMore(renewalTransfers.length > 0));

    if (parameters.page > 1) {
      yield put(appendRenewalTransfers(renewalTransfers));
    } else {
      yield put(setRenewalTransfers(renewalTransfers));
    }

    yield put(setFetchRenewalTransfersSuccess(message));
    yield put(setFetchRenewalTransfersLoading(false));
  } catch (error) {
    yield put(setFetchRenewalTransfersFailed(error));
    yield put(setFetchRenewalTransfersLoading(false));
  }
}
export function* _getRenewalTransfer({ payload: transferId }) {
  try {
    yield put(setFetchRenewalTransferLoading(true));

    const fetchRenewalTransferParams = yield select(
      getFetchRenewalTransferParams
    );
    const parameters = getParamsWithDefault(fetchRenewalTransferParams);

    const {
      meta: { message },
      data: renewalTransfer,
    } = yield call(getRenewalTransfer, transferId, parameters);

    yield put(setIsFetchRenewalTransferHitted(true));
    yield put(setRenewalTransfer(renewalTransfer));

    yield put(setFetchRenewalTransferSuccess(message));
    yield put(setFetchRenewalTransferLoading(false));
  } catch (error) {
    yield put(setFetchRenewalTransferFailed(error));
    yield put(setFetchRenewalTransferLoading(false));
  }
}
export function* _createRenewalTransfer({ payload: request }) {
  try {
    yield put(setCreateRenewalTransferLoading(true));

    const {
      meta: { message },
    } = yield call(createRenewalTransfer, request);

    yield put(setIsCreateRenewalTransferHitted(true));

    const isFetchRenewalTransfersHitted = yield select(
      getIsFetchRenewalTransfersHitted
    );
    const fetchRenewalTransfersParams = yield select(
      getFetchRenewalTransfersParams
    );

    if (isFetchRenewalTransfersHitted) {
      const params = { ...fetchRenewalTransfersParams };
      params.page = 1;
      params.per_page = 20;

      yield put(setFetchRenewalTransfersParams(params));
      yield call(_getRenewalTransfers);
    }

    yield put(setCreateRenewalTransferSuccess(message));
    yield put(setCreateRenewalTransferLoading(false));
  } catch (error) {
    yield put(setCreateRenewalTransferFailed(error));
    yield put(setCreateRenewalTransferLoading(false));
  }
}
export function* _updateRenewalTransfer({ payload: { transferId, request } }) {
  try {
    yield put(setUpdateRenewalTransferLoading(true));

    const {
      meta: { message },
    } = yield call(updateRenewalTransfer, transferId, request);

    yield put(setIsUpdateRenewalTransferHitted(true));

    const isFetchRenewalTransfersHitted = yield select(
      getIsFetchRenewalTransfersHitted
    );
    const fetchRenewalTransfersParams = yield select(
      getFetchRenewalTransfersParams
    );

    if (isFetchRenewalTransfersHitted) {
      const params = { ...fetchRenewalTransfersParams };
      params.page = 1;
      params.per_page = 20;

      yield put(setFetchRenewalTransfersParams(params));
      yield call(_getRenewalTransfers);
    }

    yield put(setUpdateRenewalTransferSuccess(message));
    yield put(setUpdateRenewalTransferLoading(false));
  } catch (error) {
    yield put(setUpdateRenewalTransferFailed(error));
    yield put(setUpdateRenewalTransferLoading(false));
  }
}
export function* _deleteRenewalTransfer({ payload: transferId }) {
  try {
    yield put(setDeleteRenewalTransferLoading(true));

    const {
      meta: { message },
    } = yield call(deleteRenewalTransfer, transferId);

    yield put(setIsDeleteRenewalTransferHitted(true));

    const isFetchRenewalTransfersHitted = yield select(
      getIsFetchRenewalTransfersHitted
    );
    const fetchRenewalTransfersParams = yield select(
      getFetchRenewalTransfersParams
    );

    if (isFetchRenewalTransfersHitted) {
      const params = { ...fetchRenewalTransfersParams };
      params.page = 1;
      params.per_page = 20;

      yield put(setFetchRenewalTransfersParams(params));
      yield call(_getRenewalTransfers);
    }

    yield put(setDeleteRenewalTransferSuccess(message));
    yield put(setDeleteRenewalTransferLoading(false));
  } catch (error) {
    yield put(setDeleteRenewalTransferFailed(error));
    yield put(setDeleteRenewalTransferLoading(false));
  }
}

export function* onFetchRenewalTransfersStart() {
  yield takeLatest(
    RENEWAL_TRANSFER_ACTION_TYPES.FETCH_RENEWAL_TRANSFERS_START,
    _getRenewalTransfers
  );
}
export function* onFetchRenewalTransferStart() {
  yield takeLatest(
    RENEWAL_TRANSFER_ACTION_TYPES.FETCH_RENEWAL_TRANSFER_START,
    _getRenewalTransfer
  );
}
export function* onCreateRenewalTransferStart() {
  yield takeLatest(
    RENEWAL_TRANSFER_ACTION_TYPES.CREATE_RENEWAL_TRANSFER_START,
    _createRenewalTransfer
  );
}
export function* onUpdateRenewalTransferStart() {
  yield takeLatest(
    RENEWAL_TRANSFER_ACTION_TYPES.UPDATE_RENEWAL_TRANSFER_START,
    _updateRenewalTransfer
  );
}
export function* onDeleteRenewalTransferStart() {
  yield takeLatest(
    RENEWAL_TRANSFER_ACTION_TYPES.DELETE_RENEWAL_TRANSFER_START,
    _deleteRenewalTransfer
  );
}

export function* renewalTransferSaga() {
  yield all([
    call(onFetchRenewalTransfersStart),
    call(onFetchRenewalTransferStart),
    call(onCreateRenewalTransferStart),
    call(onUpdateRenewalTransferStart),
    call(onDeleteRenewalTransferStart),
  ]);
}
