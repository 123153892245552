import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";
import { getPhoneNumberFormat } from "../../utils/formatter.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";
import {
  appendSearchSuppliers,
  appendSuppliersStart,
  fetchSuppliersStart,
  searchSuppliersStart,
  setAppendSuppliersParams,
  setFetchSuppliersParams,
  setSearchSuppliersParams,
} from "../../store/select-supplier/select-supplier.action";
import {
  getAppendSuppliersLoading,
  getAppendSuppliersParams,
  getFetchSuppliersLoading,
  getFetchSuppliersParams,
  getIsFetchSuppliersHitted,
  getIsSearchSuppliersHasMore,
  getIsSearchSuppliersHitted,
  getIsSuppliersHasMore,
  getSearchSuppliers,
  getSearchSuppliersLoading,
  getSearchSuppliersParams,
  getSuppliers,
} from "../../store/select-supplier/select-supplier.selector";

import FormSelectAsync from "../../components/form-select-async/form-select-async.component";

import {
  getAdditionalStyles,
  SelectSupplierOptionAvatar,
  SelectSupplierOptionContainer,
  SelectSupplierOptionDescription,
  SelectSupplierOptionInfo,
  SelectSupplierOptionName,
} from "./select-supplier.style";

export const SELECT_SUPPLIER_TYPES = {
  USER_ID: "USER_ID",
  USERABLE_ID: "USERABLE_ID",
};

const getValueKeyByType = (selectType) => {
  switch (selectType) {
    case SELECT_SUPPLIER_TYPES.USER_ID:
      return "id";
    case SELECT_SUPPLIER_TYPES.USERABLE_ID:
    default:
      return "userable_id";
  }
};

const getFilterKeyByType = (selectType) => {
  switch (selectType) {
    case SELECT_SUPPLIER_TYPES.USER_ID:
      return "users_ids";
    case SELECT_SUPPLIER_TYPES.USERABLE_ID:
    default:
      return "userables_ids";
  }
};

const getFormatOptionLabel =
  ({ translation, isRightToLeft = false }) =>
  (supplier) => {
    const { t } = translation ?? {};
    const { name, username, phone_prefix, phone, email } = supplier ?? {};

    const supplierName = !isEmpty(name)
      ? name
      : !isEmpty(username)
      ? username
      : email;
    const supplierInfo =
      !isEmpty(phone_prefix) && !isEmpty(phone)
        ? getPhoneNumberFormat(phone_prefix, phone)
        : email;

    return (
      <SelectSupplierOptionContainer isRightToLeft={isRightToLeft}>
        {!isEmpty(supplierName) && (
          <SelectSupplierOptionAvatar>
            {supplierName?.substring(0, 2)?.toUpperCase?.() ?? "-"}
          </SelectSupplierOptionAvatar>
        )}
        <SelectSupplierOptionInfo>
          <SelectSupplierOptionName>
            {!isEmpty(supplierName) ? supplierName : t("No name")}
          </SelectSupplierOptionName>
          <SelectSupplierOptionDescription>
            {!isEmpty(supplierInfo) ? supplierInfo : t("No information")}
          </SelectSupplierOptionDescription>
        </SelectSupplierOptionInfo>
      </SelectSupplierOptionContainer>
    );
  };

const SelectSupplier = ({
  paramsMarketId,
  selectSize,
  selectType,
  label,
  name,
  value,
  placeholder,
  onChange,
  isDisabled = false,
  isMultiple = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const translation = useTranslation();

  const [options, setOptions] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const isRightToLeft = useSelector(getIsRightToLeft);

  const appendSuppliersParams = useSelector(getAppendSuppliersParams);
  const appendSuppliersLoading = useSelector(getAppendSuppliersLoading);

  const isFetchSuppliersHitted = useSelector(getIsFetchSuppliersHitted);
  const isSuppliersHasMore = useSelector(getIsSuppliersHasMore);
  const fetchSuppliersParams = useSelector(getFetchSuppliersParams);
  const fetchSuppliersLoading = useSelector(getFetchSuppliersLoading);
  const suppliers = useSelector(getSuppliers);

  const isSearchSuppliersHitted = useSelector(getIsSearchSuppliersHitted);
  const isSearchSuppliersHasMore = useSelector(getIsSearchSuppliersHasMore);
  const searchSuppliersParams = useSelector(getSearchSuppliersParams);
  const searchSuppliersLoading = useSelector(getSearchSuppliersLoading);
  const searchSuppliers = useSelector(getSearchSuppliers);

  const valueKey = getValueKeyByType(selectType);
  const filterKey = getFilterKeyByType(selectType);
  const formatOptionLabel = getFormatOptionLabel({
    translation,
    isRightToLeft,
  });
  const additionalStyles = getAdditionalStyles({
    selectSize,
    isRightToLeft,
  });

  const getParams = (parameters) => {
    const params = { ...parameters };
    params.page = 1;
    params.per_page = 20;
    params.key_by = valueKey;
    params.filter = params?.filter ?? {};
    params.filter.market_id = paramsMarketId;

    return params;
  };

  const handleSearchChange = (searchKey) => setSearchKey(searchKey);

  const handleMainFetch = () => {
    const params = getParams(fetchSuppliersParams);
    dispatch(setFetchSuppliersParams(params));
    dispatch(fetchSuppliersStart());
  };
  const handleSearchFetch = () => {
    if (isEmpty(searchKey)) {
      setIsLoading(false);
      return;
    }

    const params = getParams(searchSuppliersParams);
    params.search = searchKey;

    dispatch(setSearchSuppliersParams(params));
    dispatch(searchSuppliersStart());
  };
  const handleAppendValue = () => {
    const isValueExist = isMultiple
      ? isEmpty(value?.filter((val) => isEmpty(suppliers?.[val])))
      : !isEmpty(suppliers?.[value]);

    if (!isEmpty(value) && isValueExist) {
      setIsLoading(false);
      return;
    }

    const params = getParams(appendSuppliersParams);
    params.filter[filterKey] = isMultiple ? value : [value];

    dispatch(setAppendSuppliersParams(params));
    dispatch(appendSuppliersStart());
  };

  const handleMainBottomScroll = () => {
    if (
      !isFetchSuppliersHitted ||
      !isSuppliersHasMore ||
      fetchSuppliersLoading
    ) {
      return;
    }

    const params = { ...fetchSuppliersParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setFetchSuppliersParams(params));
    dispatch(fetchSuppliersStart());
  };
  const handleSearchBottomScroll = () => {
    if (
      !isSearchSuppliersHitted ||
      !isSearchSuppliersHasMore ||
      searchSuppliersLoading
    ) {
      return;
    }

    const params = { ...searchSuppliersParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setSearchSuppliersParams(params));
    dispatch(searchSuppliersStart());
  };

  useEffect(() => {
    setIsLoading(
      fetchSuppliersLoading || searchSuppliersLoading || appendSuppliersLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSuppliersLoading, searchSuppliersLoading, appendSuppliersLoading]);

  useEffect(() => {
    const optionsTimeout = setTimeout(() => {
      const options = isSearching ? searchSuppliers : suppliers;
      setOptions(Object.values(options));
    }, 300);

    return () => clearTimeout(optionsTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suppliers, searchSuppliers, isSearching]);

  useEffect(() => {
    setIsLoading(true);

    const initializeTimeout = setTimeout(() => handleMainFetch(), 300);
    return () => clearTimeout(initializeTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId]);

  useEffect(() => {
    dispatch(appendSearchSuppliers(suppliers));

    setIsLoading(true);
    setIsSearching(!isEmpty(searchKey));

    const searchTimeout = setTimeout(() => handleSearchFetch(), 1500);
    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, searchKey]);

  useEffect(() => {
    if (!isEmpty(value)) {
      setIsLoading(true);

      const appendTimeout = setTimeout(() => handleAppendValue(), 300);
      return () => clearTimeout(appendTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, value]);

  return (
    <FormSelectAsync
      selectSize={selectSize}
      formatOptionLabel={formatOptionLabel}
      additionalStyles={additionalStyles}
      label={label}
      labelKey={["name", "username", "phone_prefix", "phone", "email"]}
      valueKey={valueKey}
      name={name}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      onSearchChange={handleSearchChange}
      onBottomScroll={
        isSearching ? handleSearchBottomScroll : handleMainBottomScroll
      }
      value={value}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isMultiple={isMultiple}
      {...otherProps}
    />
  );
};

export default SelectSupplier;
