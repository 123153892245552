import REPORT_MORE_ACTION_TYPES from "./report-more.type";
import { createAction } from "../../utils/store.utils";

export const setReportMoreCategories = (reportMoreCategories) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_CATEGORIES,
    reportMoreCategories
  );
export const setReportMoreProducts = (reportMoreProducts) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_PRODUCTS,
    reportMoreProducts
  );
export const setReportMoreOptions = (reportMoreOptions) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_OPTIONS,
    reportMoreOptions
  );
export const setReportMoreAdditionals = (reportMoreAdditionals) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_ADDITIONALS,
    reportMoreAdditionals
  );
export const setReportMoreCashiers = (reportMoreCashiers) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_CASHIERS,
    reportMoreCashiers
  );
export const setReportMorePayments = (reportMorePayments) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_PAYMENTS,
    reportMorePayments
  );
export const setReportMoreTables = (reportMoreTables) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_REPORT_MORE_TABLES,
    reportMoreTables
  );

export const setIsReportMoreCategoriesHasMore = (
  isReportMoreCategoriesHasMore
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_CATEGORIES_HAS_MORE,
    isReportMoreCategoriesHasMore
  );
export const setIsReportMoreProductsHasMore = (isReportMoreProductsHasMore) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_PRODUCTS_HAS_MORE,
    isReportMoreProductsHasMore
  );
export const setIsReportMoreOptionsHasMore = (isReportMoreOptionsHasMore) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_OPTIONS_HAS_MORE,
    isReportMoreOptionsHasMore
  );
export const setIsReportMoreAdditionalsHasMore = (
  isReportMoreAdditionalsHasMore
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_ADDITIONALS_HAS_MORE,
    isReportMoreAdditionalsHasMore
  );
export const setIsReportMoreCashiersHasMore = (isReportMoreCashiersHasMore) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_CASHIERS_HAS_MORE,
    isReportMoreCashiersHasMore
  );
export const setIsReportMorePaymentsHasMore = (isReportMorePaymentsHasMore) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_PAYMENTS_HAS_MORE,
    isReportMorePaymentsHasMore
  );
export const setIsReportMoreTablesHasMore = (isReportMoreTablesHasMore) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_REPORT_MORE_TABLES_HAS_MORE,
    isReportMoreTablesHasMore
  );

export const setFetchReportMoreCategoriesParams = (
  fetchReportMoreCategoriesParams
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_PARAMS,
    fetchReportMoreCategoriesParams
  );
export const setFetchReportMoreCategoriesLoading = (
  fetchReportMoreCategoriesLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_LOADING,
    fetchReportMoreCategoriesLoading
  );
export const setFetchReportMoreCategoriesSuccess = (
  fetchReportMoreCategoriesSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_SUCCESS,
    fetchReportMoreCategoriesSuccess
  );
export const setFetchReportMoreCategoriesFailed = (
  fetchReportMoreCategoriesFailed
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_FAILED,
    fetchReportMoreCategoriesFailed
  );

export const setFetchReportMoreProductsParams = (
  fetchReportMoreProductsParams
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_PARAMS,
    fetchReportMoreProductsParams
  );
export const setFetchReportMoreProductsLoading = (
  fetchReportMoreProductsLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_LOADING,
    fetchReportMoreProductsLoading
  );
export const setFetchReportMoreProductsSuccess = (
  fetchReportMoreProductsSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_SUCCESS,
    fetchReportMoreProductsSuccess
  );
export const setFetchReportMoreProductsFailed = (
  fetchReportMoreProductsFailed
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_FAILED,
    fetchReportMoreProductsFailed
  );

export const setFetchReportMoreOptionsParams = (fetchReportMoreOptionsParams) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_PARAMS,
    fetchReportMoreOptionsParams
  );
export const setFetchReportMoreOptionsLoading = (
  fetchReportMoreOptionsLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_LOADING,
    fetchReportMoreOptionsLoading
  );
export const setFetchReportMoreOptionsSuccess = (
  fetchReportMoreOptionsSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_SUCCESS,
    fetchReportMoreOptionsSuccess
  );
export const setFetchReportMoreOptionsFailed = (fetchReportMoreOptionsFailed) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_FAILED,
    fetchReportMoreOptionsFailed
  );

export const setFetchReportMoreAdditionalsParams = (
  fetchReportMoreAdditionalsParams
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_PARAMS,
    fetchReportMoreAdditionalsParams
  );
export const setFetchReportMoreAdditionalsLoading = (
  fetchReportMoreAdditionalsLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_LOADING,
    fetchReportMoreAdditionalsLoading
  );
export const setFetchReportMoreAdditionalsSuccess = (
  fetchReportMoreAdditionalsSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_SUCCESS,
    fetchReportMoreAdditionalsSuccess
  );
export const setFetchReportMoreAdditionalsFailed = (
  fetchReportMoreAdditionalsFailed
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_FAILED,
    fetchReportMoreAdditionalsFailed
  );

export const setFetchReportMoreCashiersParams = (
  fetchReportMoreCashiersParams
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_PARAMS,
    fetchReportMoreCashiersParams
  );
export const setFetchReportMoreCashiersLoading = (
  fetchReportMoreCashiersLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_LOADING,
    fetchReportMoreCashiersLoading
  );
export const setFetchReportMoreCashiersSuccess = (
  fetchReportMoreCashiersSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_SUCCESS,
    fetchReportMoreCashiersSuccess
  );
export const setFetchReportMoreCashiersFailed = (
  fetchReportMoreCashiersFailed
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_FAILED,
    fetchReportMoreCashiersFailed
  );

export const setFetchReportMorePaymentsParams = (
  fetchReportMorePaymentsParams
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_PARAMS,
    fetchReportMorePaymentsParams
  );
export const setFetchReportMorePaymentsLoading = (
  fetchReportMorePaymentsLoading
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_LOADING,
    fetchReportMorePaymentsLoading
  );
export const setFetchReportMorePaymentsSuccess = (
  fetchReportMorePaymentsSuccess
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_SUCCESS,
    fetchReportMorePaymentsSuccess
  );
export const setFetchReportMorePaymentsFailed = (
  fetchReportMorePaymentsFailed
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_FAILED,
    fetchReportMorePaymentsFailed
  );

export const setFetchReportMoreTablesParams = (fetchReportMoreTablesParams) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_PARAMS,
    fetchReportMoreTablesParams
  );
export const setFetchReportMoreTablesLoading = (fetchReportMoreTablesLoading) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_LOADING,
    fetchReportMoreTablesLoading
  );
export const setFetchReportMoreTablesSuccess = (fetchReportMoreTablesSuccess) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_SUCCESS,
    fetchReportMoreTablesSuccess
  );
export const setFetchReportMoreTablesFailed = (fetchReportMoreTablesFailed) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_FAILED,
    fetchReportMoreTablesFailed
  );

export const appendReportMoreCategories = (reportMoreCategories) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_CATEGORIES,
    reportMoreCategories
  );
export const appendReportMoreProducts = (reportMoreProducts) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_PRODUCTS,
    reportMoreProducts
  );
export const appendReportMoreOptions = (reportMoreOptions) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_OPTIONS,
    reportMoreOptions
  );
export const appendReportMoreAdditionals = (reportMoreAdditionals) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_ADDITIONALS,
    reportMoreAdditionals
  );
export const appendReportMoreCashiers = (reportMoreCashiers) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_CASHIERS,
    reportMoreCashiers
  );
export const appendReportMorePayments = (reportMorePayments) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_PAYMENTS,
    reportMorePayments
  );
export const appendReportMoreTables = (reportMoreTables) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.APPEND_REPORT_MORE_TABLES,
    reportMoreTables
  );

export const fetchReportMoreCategoriesStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_START);
export const fetchReportMoreProductsStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_START);
export const fetchReportMoreOptionsStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_START);
export const fetchReportMoreAdditionalsStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_START);
export const fetchReportMoreCashiersStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_START);
export const fetchReportMorePaymentsStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_START);
export const fetchReportMoreTablesStart = () =>
  createAction(REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_START);

export const setIsFetchReportMoreCategoriesHitted = (
  isFetchReportMoreCategoriesHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_CATEGORIES_HITTED,
    isFetchReportMoreCategoriesHitted
  );
export const setIsFetchReportMoreProductsHitted = (
  isFetchReportMoreProductsHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_PRODUCTS_HITTED,
    isFetchReportMoreProductsHitted
  );
export const setIsFetchReportMoreOptionsHitted = (
  isFetchReportMoreOptionsHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_OPTIONS_HITTED,
    isFetchReportMoreOptionsHitted
  );
export const setIsFetchReportMoreAdditionalsHitted = (
  isFetchReportMoreAdditionalsHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_ADDITIONALS_HITTED,
    isFetchReportMoreAdditionalsHitted
  );

export const setIsFetchReportMoreCashiersHitted = (
  isFetchReportMoreCashiersHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_CASHIERS_HITTED,
    isFetchReportMoreCashiersHitted
  );
export const setIsFetchReportMorePaymentsHitted = (
  isFetchReportMorePaymentsHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_PAYMENTS_HITTED,
    isFetchReportMorePaymentsHitted
  );
export const setIsFetchReportMoreTablesHitted = (
  isFetchReportMoreTablesHitted
) =>
  createAction(
    REPORT_MORE_ACTION_TYPES.SET_IS_FETCH_REPORT_MORE_TABLES_HITTED,
    isFetchReportMoreTablesHitted
  );

export const resetReportMoreReducer = () =>
  createAction(REPORT_MORE_ACTION_TYPES.RESET_REPORT_MORE_REDUCER);
