const EXPORT_EXCEL_ACTION_TYPES = {
  RESET_EXPORT_EXCEL_REDUCER: "export-excel/RESET_EXPORT_EXCEL_REDUCER",

  SET_EXPORT_EXCEL_REPORTS: "export-excel/SET_EXPORT_EXCEL_REPORTS",
  SET_EXPORT_EXCEL_PURCHASE: "export-excel/SET_EXPORT_EXCEL_PURCHASE",

  SET_EXPORT_EXCEL_REPORTS_LOADING:
    "export-excel/SET_EXPORT_EXCEL_REPORTS_LOADING",
  SET_EXPORT_EXCEL_REPORTS_SUCCESS:
    "export-excel/SET_EXPORT_EXCEL_REPORTS_SUCCESS",
  SET_EXPORT_EXCEL_REPORTS_FAILED:
    "export-excel/SET_EXPORT_EXCEL_REPORTS_FAILED",

  SET_EXPORT_EXCEL_PURCHASE_LOADING:
    "export-excel/SET_EXPORT_EXCEL_PURCHASE_LOADING",
  SET_EXPORT_EXCEL_PURCHASE_SUCCESS:
    "export-excel/SET_EXPORT_EXCEL_PURCHASE_SUCCESS",
  SET_EXPORT_EXCEL_PURCHASE_FAILED:
    "export-excel/SET_EXPORT_EXCEL_PURCHASE_FAILED",

  SET_IS_EXPORT_EXCEL_REPORTS_HITTED:
    "export-excel/SET_IS_EXPORT_EXCEL_REPORTS_HITTED",
  SET_IS_EXPORT_EXCEL_PURCHASE_HITTED:
    "export-excel/SET_IS_EXPORT_EXCEL_PURCHASE_HITTED",

  EXPORT_EXCEL_REPORTS_START: "export-excel/EXPORT_EXCEL_REPORTS_START",
  EXPORT_EXCEL_PURCHASE_START: "export-excel/EXPORT_EXCEL_PURCHASE_START",
};

export default EXPORT_EXCEL_ACTION_TYPES;
