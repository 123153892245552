import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getBranches } from "../../api/branch.api";
import {
  getRenewals,
  getRenewal,
  createRenewal,
  createCalculateRenewal,
} from "../../api/renewal.api";

import RENEWAL_ACTION_TYPES from "./renewal-subscriber.type";
import {
  appendRenewals,
  setCreateRenewalFailed,
  setCreateRenewalLoading,
  setCreateRenewalSuccess,
  setFetchRenewalFailed,
  setFetchRenewalLoading,
  setFetchRenewalsFailed,
  setFetchRenewalsLoading,
  setFetchRenewalsSuccess,
  setFetchRenewalSuccess,
  setIsRenewalsHasMore,
  setRenewal,
  setRenewals,
  setIsFetchRenewalsHitted,
  setIsFetchRenewalHitted,
  setIsCreateRenewalHitted,
  setFetchRenewalsPage,
  setCreateCalculateRenewalLoading,
  setIsCreateCalculateRenewalHitted,
  setCreateCalculateRenewalSuccess,
  setCreateCalculateRenewalFailed,
  setRenewalCreate,
  setRenewalCreateCalculate,
  setFetchRenewalBranchesLoading,
  setIsFetchRenewalBranchesHitted,
  setIsRenewalBranchesHasMore,
  appendRenewalBranches,
  setRenewalBranches,
  setFetchRenewalBranchesSuccess,
  setFetchRenewalBranchesFailed,
} from "./renewal-subscriber.action";
import {
  getFetchRenewalBranchesParams,
  getFetchRenewalsFilterCreatedBy,
  getFetchRenewalsFilterMarketId,
  getFetchRenewalsFilterStatuses,
  getFetchRenewalsFilterSubscriptionKeys,
  getFetchRenewalsFilterSubscriptionOptionKeys,
  getFetchRenewalsIncludes,
  getFetchRenewalsKeyBy,
  getFetchRenewalsPage,
  getFetchRenewalsPerPage,
  getFetchRenewalsSearch,
  getFetchRenewalsSort,
  getIsFetchRenewalsHitted,
} from "./renewal-subscriber.selector";

export function* _getRenewals() {
  try {
    yield put(setFetchRenewalsLoading(true));

    const search = yield select(getFetchRenewalsSearch);
    const sort = yield select(getFetchRenewalsSort);
    const key_by = yield select(getFetchRenewalsKeyBy);
    const page = yield select(getFetchRenewalsPage);
    const per_page = yield select(getFetchRenewalsPerPage);
    const includes = yield select(getFetchRenewalsIncludes);
    const market_id = yield select(getFetchRenewalsFilterMarketId);
    const subscription_keys = yield select(
      getFetchRenewalsFilterSubscriptionKeys
    );
    const subscription_option_keys = yield select(
      getFetchRenewalsFilterSubscriptionOptionKeys
    );
    const statuses = yield select(getFetchRenewalsFilterStatuses);
    const created_by = yield select(getFetchRenewalsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        subscription_keys,
        subscription_option_keys,
        statuses,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: renewals },
    } = yield call(getRenewals, parameters);

    yield put(setIsFetchRenewalsHitted(true));
    yield put(setIsRenewalsHasMore(renewals.length > 0));

    if (page > 1) {
      yield put(appendRenewals(renewals));
    } else {
      yield put(setRenewals(renewals));
    }

    yield put(setFetchRenewalsSuccess(message));
    yield put(setFetchRenewalsLoading(false));
  } catch (error) {
    yield put(setFetchRenewalsFailed(error));
    yield put(setFetchRenewalsLoading(false));
  }
}
export function* _getRenewal({ payload: renewalId }) {
  try {
    yield put(setFetchRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(getRenewal, renewalId);

    yield put(setIsFetchRenewalHitted(true));
    yield put(setRenewal(renewal));

    yield put(setFetchRenewalSuccess(message));
    yield put(setFetchRenewalLoading(false));
  } catch (error) {
    yield put(setFetchRenewalFailed(error));
    yield put(setFetchRenewalLoading(false));
  }
}
export function* _getRenewalBranches() {
  try {
    const fetchRenewalBranchesParams = yield select(
      getFetchRenewalBranchesParams
    );
    const parameters = getParamsWithDefault(fetchRenewalBranchesParams);

    yield put(setFetchRenewalBranchesLoading(true));

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchRenewalBranchesHitted(true));
    yield put(setIsRenewalBranchesHasMore(branches.length > 0));

    if (parameters.page > 1) {
      yield put(appendRenewalBranches(branches));
    } else {
      yield put(setRenewalBranches(branches));
    }

    yield put(setFetchRenewalBranchesSuccess(message));
    yield put(setFetchRenewalBranchesLoading(false));
  } catch (error) {
    yield put(setFetchRenewalBranchesFailed(error));
    yield put(setFetchRenewalBranchesLoading(false));
  }
}
export function* _createRenewal({ payload: request }) {
  try {
    yield put(setCreateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(createRenewal, request);

    yield put(setIsCreateRenewalHitted(true));
    yield put(setRenewalCreate(renewal));

    const isFetchRenewalsHitted = yield select(getIsFetchRenewalsHitted);

    if (isFetchRenewalsHitted) {
      yield put(setFetchRenewalsPage(1));
      yield call(_getRenewals);
    }

    yield put(setCreateRenewalSuccess(message));
    yield put(setCreateRenewalLoading(false));
  } catch (error) {
    yield put(setCreateRenewalFailed(error));
    yield put(setCreateRenewalLoading(false));
  }
}
export function* _createCalculateRenewal({ payload: request }) {
  try {
    yield put(setCreateCalculateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(createCalculateRenewal, request);

    yield put(setIsCreateCalculateRenewalHitted(true));
    yield put(setRenewalCreateCalculate(renewal));

    const isFetchRenewalsHitted = yield select(getIsFetchRenewalsHitted);

    if (isFetchRenewalsHitted) {
      yield put(setFetchRenewalsPage(1));
      yield call(_getRenewals);
    }

    yield put(setCreateCalculateRenewalSuccess(message));
    yield put(setCreateCalculateRenewalLoading(false));
  } catch (error) {
    yield put(setCreateCalculateRenewalFailed(error));
    yield put(setCreateCalculateRenewalLoading(false));
  }
}

export function* onFetchRenewalsStart() {
  yield takeLatest(RENEWAL_ACTION_TYPES.FETCH_RENEWALS_START, _getRenewals);
}
export function* onFetchRenewalStart() {
  yield takeLatest(RENEWAL_ACTION_TYPES.FETCH_RENEWAL_START, _getRenewal);
}
export function* onFetchRenewalBranchesStart() {
  yield takeLatest(
    RENEWAL_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START,
    _getRenewalBranches
  );
}
export function* onCreateRenewalStart() {
  yield takeLatest(RENEWAL_ACTION_TYPES.CREATE_RENEWAL_START, _createRenewal);
}
export function* onCreateCalculateRenewalStart() {
  yield takeLatest(
    RENEWAL_ACTION_TYPES.CREATE_CALCULATE_RENEWAL_START,
    _createCalculateRenewal
  );
}

export function* renewalSubscriberSaga() {
  yield all([
    call(onFetchRenewalsStart),
    call(onFetchRenewalStart),
    call(onFetchRenewalBranchesStart),
    call(onCreateRenewalStart),
    call(onCreateCalculateRenewalStart),
  ]);
}
