import { useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";

import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  FormSelectAsyncContainer,
  FormSelectAsyncMenu,
  formSelectAsyncStyles,
} from "./form-select-async.style";

export const FORM_SELECT_ASYNC_SIZES = {
  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const FormSelectAsync = ({
  selectSize = FORM_SELECT_ASYNC_SIZES.NORMAL,
  formatOptionLabel,
  additionalStyles = {},
  options = [],
  label,
  name,
  value,
  labelKey,
  valueKey,
  placeholder,
  isLoading = false,
  isDisabled = false,
  isMultiple = false,
  isOptionDisabled,
  isValidNewOption,
  menuPrefix,
  menuSuffix,
  onChange,
  onBottomScroll,
  onSearchChange,
  onMenuClose,
  onMenuOpen,
  ...otherProps
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  const selectStyles = formSelectAsyncStyles({
    additionalStyles,
    selectSize,
    isRightToLeft,
  });

  const getAttribute = (object, keys = []) => {
    if (keys.length === 0 || isEmpty(object)) return object;
    return getAttribute(object?.[keys.shift()], keys);
  };
  const getOptionLabel = (option) => {
    return Array.isArray(labelKey)
      ? labelKey.reduce((result, key) => {
          return `${result} ${getAttribute(option, key?.split("."))}`;
        }, "")
      : getAttribute(option, labelKey?.split("."));
  };
  const getOptionValue = (option) => {
    return Array.isArray(valueKey)
      ? valueKey.reduce((result, key) => {
          return { ...result, [key]: getAttribute(option, key?.split(".")) };
        }, {})
      : getAttribute(option, valueKey?.split("."));
  };

  const getSingValue = () => {
    if (isEmpty(value)) return "";
    return options.find(
      (option) =>
        JSON.stringify(value) === JSON.stringify(getOptionValue(option))
    );
  };
  const getMulValue = () => {
    if (isEmpty(value) || !Array.isArray(value)) {
      return [];
    }

    const currValues =
      value?.map((currValue) => JSON.stringify(currValue)) ?? [];

    return options.filter((option) =>
      currValues.includes(JSON.stringify(getOptionValue(option)))
    );
  };

  const handleDisabledOption = ({ disabled }) => disabled ?? false;
  const handleValidNewOption = () => false;

  const handleSelectOptionChange = (option) =>
    onChange?.({
      name,
      value: isMultiple
        ? option?.map((opt) => getOptionValue(opt)) ?? []
        : getOptionValue(option),
    });
  const handleSelectSearchChange = (search, { action }) => {
    // if (["set-value", "menu-close"].includes(action)) return;
    onSearchChange?.(search);
  };

  return (
    <FormSelectAsyncContainer>
      {!isEmpty(label) && label}
      <CreatableSelect
        styles={selectStyles}
        options={options}
        value={isMultiple ? getMulValue() : getSingValue()}
        getOptionLabel={isEmpty(labelKey) ? undefined : getOptionLabel}
        getOptionValue={isEmpty(valueKey) ? undefined : getOptionValue}
        formatOptionLabel={formatOptionLabel}
        onChange={handleSelectOptionChange}
        onInputChange={handleSelectSearchChange}
        onMenuScrollToBottom={onBottomScroll}
        onMenuClose={onMenuClose}
        onMenuOpen={onMenuOpen}
        placeholder={placeholder}
        isSearchable={!isEmpty(onSearchChange)}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isOptionDisabled={
          !isEmpty(isOptionDisabled) ? isOptionDisabled : handleDisabledOption
        }
        isValidNewOption={
          !isEmpty(isValidNewOption) ? isValidNewOption : handleValidNewOption
        }
        isMulti={isMultiple}
        components={{ Menu: handleRenderMenu({ menuPrefix, menuSuffix }) }}
        {...otherProps}
      />
    </FormSelectAsyncContainer>
  );
};

const handleRenderMenu =
  ({ menuPrefix, menuSuffix }) =>
  ({ innerRef, innerProps, isDisabled, children }) =>
    !isDisabled ? (
      <FormSelectAsyncMenu ref={innerRef} {...innerProps}>
        {menuPrefix}
        {children}
        {menuSuffix}
      </FormSelectAsyncMenu>
    ) : null;

export default FormSelectAsync;
