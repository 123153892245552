import styled, { css } from "styled-components";

import { DIALOG_COLORS, DIALOG_STYLES } from "./dialog.component";

const getStyle1IconBackground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#dfdafd";
    case DIALOG_COLORS.SUCCESS:
      return "#d9eedc";
    case DIALOG_COLORS.DANGER:
      return "#fddfde";
    case DIALOG_COLORS.WARNING:
      return "#ffeedc";
    case DIALOG_COLORS.LIGHT:
      return "#f2f2f4";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#e9e9ee";
  }
};
const getStyle1IconForeground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#2900ee";
    case DIALOG_COLORS.SUCCESS:
      return "#008d0e";
    case DIALOG_COLORS.DANGER:
      return "#f02c2c";
    case DIALOG_COLORS.WARNING:
      return "#fc9114";
    case DIALOG_COLORS.LIGHT:
      return "#9691ac";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#0e072f";
  }
};

const getStyle2ItemBackground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#dfdafd";
    case DIALOG_COLORS.SUCCESS:
      return "#d9eedc";
    case DIALOG_COLORS.DANGER:
      return "#fddfde";
    case DIALOG_COLORS.WARNING:
      return "#ffeedc";
    case DIALOG_COLORS.LIGHT:
      return "#f2f2f4";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#e9e9ee";
  }
};
const getStyle2ItemBorder = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#bfb3f6";
    case DIALOG_COLORS.SUCCESS:
      return "#b0dbb0";
    case DIALOG_COLORS.DANGER:
      return "#f6b5b5";
    case DIALOG_COLORS.WARNING:
      return "#fdd19f";
    case DIALOG_COLORS.LIGHT:
      return "#d8d6e1";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#d3d2db";
  }
};
const getStyle2IconBackground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#bfb3f6";
    case DIALOG_COLORS.SUCCESS:
      return "#c2e7c6";
    case DIALOG_COLORS.DANGER:
      return "#f9c2c2";
    case DIALOG_COLORS.WARNING:
      return "#fddab9";
    case DIALOG_COLORS.LIGHT:
      return "#e2e1e8";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#d3d2db";
  }
};
const getStyle2IconForeground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#2900ee";
    case DIALOG_COLORS.SUCCESS:
      return "#008d0e";
    case DIALOG_COLORS.DANGER:
      return "#f02c2c";
    case DIALOG_COLORS.WARNING:
      return "#fc9114";
    case DIALOG_COLORS.LIGHT:
      return "#9691ac";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#0e072f";
  }
};
const getStyle2AcceptBackground = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#bfb3f6";
    case DIALOG_COLORS.SUCCESS:
      return "#b0dbb0";
    case DIALOG_COLORS.DANGER:
      return "#f6b5b5";
    case DIALOG_COLORS.WARNING:
      return "#fdd19f";
    case DIALOG_COLORS.LIGHT:
      return "#d8d6e1";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#bfbec9";
  }
};
const getStyle2AcceptHover = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#aa99f3";
    case DIALOG_COLORS.SUCCESS:
      return "#a3d2a6";
    case DIALOG_COLORS.DANGER:
      return "#f39090";
    case DIALOG_COLORS.WARNING:
      return "#fcb875";
    case DIALOG_COLORS.LIGHT:
      return "#c5c3d2";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#a6a4b6";
  }
};
const getStyle2CancelBorder = ({ dialogColor }) => {
  switch (dialogColor) {
    case DIALOG_COLORS.PRIMARY:
      return "#bfb3f6";
    case DIALOG_COLORS.SUCCESS:
      return "#b0dbb0";
    case DIALOG_COLORS.DANGER:
      return "#f6b5b5";
    case DIALOG_COLORS.WARNING:
      return "#fdd19f";
    case DIALOG_COLORS.LIGHT:
      return "#d8d6e1";
    case DIALOG_COLORS.SECONDARY:
    default:
      return "#bfbec9";
  }
};

export const DialogGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.5rem;
`;

export const DialogItemIcon = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.75rem;
  min-width: 2.75rem;
  max-width: 2.75rem;
  height: 2.75rem;
  min-height: 2.75rem;
  max-height: 2.75rem;

  border-radius: 50%;

  svg {
    width: 1.5rem;
    min-width: 1.5rem;
    max-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    stroke-width: 2.5;
  }
`;

export const DialogItemInfo = styled.div`
  flex-grow: 1;
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.75rem;
`;

export const DialogItemUpper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.25rem;
`;

export const DialogItemLower = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

export const DialogItemTitle = styled.p`
  font-size: 1rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const DialogItemDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const DialogItemClose = styled.button.attrs({ type: "button" })`
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 1.15rem;
  min-width: 1.15rem;
  max-width: 1.15rem;
  height: 1.15rem;
  min-height: 1.15rem;
  max-height: 1.15rem;

  outline: none;
  border: none;
  border-radius: 50%;
  background-color: transparent;

  svg {
    width: 1.15rem;
    min-width: 1.15rem;
    max-width: 1.15rem;
    height: 1.15rem;
    min-height: 1.15rem;
    max-height: 1.15rem;
    stroke-width: 3;
  }
`;

export const DialogItemAccept = styled.button.attrs({ type: "button" })`
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 2.25rem;
  padding: 0 0.75rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #e9e9ee;
  transition: all 0.3s ease;

  font-size: 0.875rem;
  font-weight: 500;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const DialogItemCancel = styled.button.attrs({ type: "button" })`
  cursor: pointer;
  user-select: none;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 2.25rem;
  padding: 0 0.75rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #e9e9ee;
  transition: all 0.3s ease;

  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const dialog1Styles = css`
  border-color: #dfdcef;
  background-color: #ffffff;

  ${DialogItemIcon} {
    background-color: ${getStyle1IconBackground};

    svg {
      path,
      polyline,
      line,
      circle {
        &[fill] {
          fill: ${getStyle1IconForeground};
        }
        &[stroke] {
          stroke: ${getStyle1IconForeground};
        }
      }
    }
  }
`;
const dialog2Styles = css`
  border-width: 2px;
  border-color: ${getStyle2ItemBorder};
  background-color: ${getStyle2ItemBackground};

  ${DialogItemIcon} {
    background-color: ${getStyle2IconBackground};

    svg {
      path,
      polyline,
      line,
      circle {
        &[fill] {
          fill: ${getStyle2IconForeground};
        }
        &[stroke] {
          stroke: ${getStyle2IconForeground};
        }
      }
    }
  }
  ${DialogItemDescription} {
    color: #6b7280;
  }
  ${DialogItemAccept} {
    background-color: ${getStyle2AcceptBackground};

    &:hover {
      background-color: ${getStyle2AcceptHover};
    }
  }
  ${DialogItemCancel} {
    border: 2px solid ${getStyle2CancelBorder};
    background-color: transparent;
    color: #0e072f;

    &:hover {
      background-color: ${getStyle2CancelBorder};
    }
  }
`;

export const DialogItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 1rem;

  width: 100%;
  max-width: 27.5rem;
  padding: 1rem 1.25rem;

  border-radius: 1rem;
  border-width: 1.5px;
  border-style: solid;

  ${({ dialogStyle }) => {
    switch (dialogStyle) {
      case DIALOG_STYLES.STYLE_2:
        return dialog2Styles;
      case DIALOG_STYLES.STYLE_1:
      default:
        return dialog1Styles;
    }
  }}
`;

export const DialogContainer = styled.div`
  pointer-events: none;
  position: fixed;
  inset: 1rem;
  z-index: 2000;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 1.5rem;
  row-gap: 0.5rem;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  * {
    pointer-events: auto;
  }
`;
