import styled from "styled-components";

import { getSelectFontStyles } from "../../components/form-select-async/form-select-async.style";

export const SelectSupplierOptionAvatar = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  min-width: 2.5rem;
  max-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  max-height: 2.5rem;

  border-radius: 50%;
  background-color: #2900ee;

  font-size: 0.75rem;
  font-weight: 700;
  color: #ffffff;
`;

export const SelectSupplierOptionInfo = styled.div`
  flex-grow: 1;
  overflow: hidden;
`;

export const SelectSupplierOptionName = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  color: #0e072f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;

export const SelectSupplierOptionDescription = styled.p`
  font-size: 0.875rem;
  font-weight: normal;
  color: #9691ac;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;

export const SelectSupplierOptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;

  ${SelectSupplierOptionAvatar} {
    margin-left: ${({ isRightToLeft }) => (isRightToLeft ? "1rem" : 0)};
    margin-right: ${({ isRightToLeft }) => (!isRightToLeft ? "1rem" : 0)};
  }
`;

export const getAdditionalStyles = ({ selectSize, isRightToLeft = false }) => ({
  singleValue: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    [`${SelectSupplierOptionContainer}`]: {
      padding: 0,
    },
    [`${SelectSupplierOptionInfo}`]: {
      padding: 0,
    },
    [`${SelectSupplierOptionAvatar}`]: {
      display: "none",
    },
    [`${SelectSupplierOptionDescription}`]: {
      display: "none",
    },
  }),
  multiValue: (styles) => ({
    ...styles,

    alignItems: "center",
    backgroundColor: "#f2f2f4",
    padding: "0.5rem 0.75rem",
    borderRadius: "8px",

    [`${SelectSupplierOptionContainer}`]: {
      padding: 0,
    },
    [`${SelectSupplierOptionInfo}`]: {
      padding: 0,
    },
    [`${SelectSupplierOptionAvatar}`]: {
      display: "none",
    },
    [`${SelectSupplierOptionDescription}`]: {
      display: "none",
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    padding: "0 !important",
    marginLeft: isRightToLeft ? "0.5rem" : 0,
    marginRight: !isRightToLeft ? "0.5rem" : 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,

    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    color: "#0e072f",
    backgroundColor: "#d9d9db",
    borderRadius: "50%",
    transition: "0.3s ease all",

    width: "1.25rem",
    minWidth: "1.25rem",
    maxWidth: "1.25rem",
    height: "1.25rem",
    minHeight: "1.25rem",
    maxHeight: "1.25rem",

    svg: {
      minWidth: "0.75rem",
      maxWidth: "0.75rem",
      height: "0.75rem",
      minHeight: "0.75rem",
      maxHeight: "0.75rem",
    },

    ":hover": {
      color: "#0e072f",
      backgroundColor: "#c1c1c3",
    },
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: isSelected ? "#2900ee !important" : "#ffffff",

    [`${SelectSupplierOptionAvatar}`]: {
      backgroundColor: isSelected ? "#ffffff !important" : "#2900ee",
      color: isSelected ? "#0e072f !important" : "#ffffff",
    },
    [`${SelectSupplierOptionName}`]: {
      color: isSelected ? "#ffffff !important" : "#0e072f",
    },
    [`${SelectSupplierOptionDescription}`]: {
      color: isSelected ? "#bbbbbb !important" : "#9691ac",
    },

    "&:active": {
      backgroundColor: "#2900ee",

      [`${SelectSupplierOptionAvatar}`]: {
        backgroundColor: "#ffffff",
        color: "#0e072f",
      },
      [`${SelectSupplierOptionName}`]: {
        color: "#ffffff",
      },
      [`${SelectSupplierOptionDescription}`]: {
        color: "#bbbbbb",
      },
    },
    "&:hover": {
      backgroundColor: "#f2f2f4",

      [`${SelectSupplierOptionAvatar}`]: {
        backgroundColor: "#2900ee",
        color: "#ffffff",
      },
      [`${SelectSupplierOptionName}`]: {
        color: "#0e072f",
      },
      [`${SelectSupplierOptionDescription}`]: {
        color: "#9691ac",
      },
    },
  }),
});
