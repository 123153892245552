import { REPORT_PRODUCT_TABS } from "../../constants/report-summary.constant";

import STATE_REPORT_SUMMARY_ACTION_TYPES from "./state-report-summary.type";

export const STATE_REPORT_SUMMARY_INITIAL_STATE = {
  reportProductTab: REPORT_PRODUCT_TABS.PRODUCTS,
};

export const stateReportSummaryReducer = (
  state = STATE_REPORT_SUMMARY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_REPORT_SUMMARY_ACTION_TYPES.SET_REPORT_PRODUCT_TAB:
      return { ...state, reportProductTab: payload };

    case STATE_REPORT_SUMMARY_ACTION_TYPES.RESET_STATE_REPORT_SUMMARY_REDUCER:
      return STATE_REPORT_SUMMARY_INITIAL_STATE;
    default:
      return state;
  }
};
