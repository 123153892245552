import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { showErrorMessage } from "../../utils/popup.utils";
import { getValidationErrors, rule } from "../../utils/validation.utils";

import {
  getCurrentBranchId,
  getCurrentMarketId,
} from "../../store/global/global.selector";
import { appendFormIngredient } from "../../store/state-purchase/state-purchase.action";
import { getIsCalculateLoading } from "../../store/state-purchase/state-purchase.selector";
import {
  getCalculateCreatePurchaseLoading,
  getCalculateUpdatePurchaseLoading,
  getCreatePurchaseLoading,
  getUpdatePurchaseLoading,
} from "../../store/purchase/purchase.selector";

import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown from "../../components/dropdown-v2/dropdown.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";
import FormInput, {
  FORM_INPUT_SIZES,
  FORM_INPUT_TYPES,
} from "../../components/form-input/form-input.component";
import { FORM_SELECT_ASYNC_SIZES } from "../../components/form-select-async/form-select-async.component";
import ButtonCheckToggle from "../button-check-toggle/button-check-toggle.widget";
import SelectIngredient, {
  SELECT_INGREDIENT_TYPES,
} from "../select-ingredient/select-ingredient.widget";

import { ReactComponent as PlusSvg } from "../../assets/icons/Plus1.svg";

const defaultValues = {
  ingredient_id: "",
  qty: "",
  price: "",
  is_tax_included: 1,
};

const PurchaseFormIngredientCreate = ({ handleDropdownClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentMarketId = useSelector(getCurrentMarketId);
  const currentBranchId = useSelector(getCurrentBranchId);

  const calculateCreatePurchaseLoading = useSelector(
    getCalculateCreatePurchaseLoading
  );
  const calculateUpdatePurchaseLoading = useSelector(
    getCalculateUpdatePurchaseLoading
  );
  const createPurchaseLoading = useSelector(getCreatePurchaseLoading);
  const updatePurchaseLoading = useSelector(getUpdatePurchaseLoading);
  const isCalculateLoading = useSelector(getIsCalculateLoading);

  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState(defaultValues);

  const { ingredient_id, qty, price, is_tax_included } = inputValues;

  const handleCancelEdit = () => handleDropdownClose?.();
  const handleTaxIncludeToggle = () =>
    setInputValues({
      ...inputValues,
      is_tax_included: parseToBoolean(is_tax_included) ? 0 : 1,
    });
  const handleInputChange = ({ name, value }) =>
    setInputValues({ ...inputValues, [name]: value });

  const handleFormSubmit = () => {
    const errors = getValidationErrors(inputValues, {
      ingredient_id: [rule.required()],
      price: [rule.required()],
      qty: [rule.required()],
      is_tax_included: [rule.required()],
    });

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    dispatch(appendFormIngredient(inputValues));
    handleDropdownClose?.();
  };

  useEffect(() => {
    setIsLoading(
      calculateCreatePurchaseLoading ||
        calculateUpdatePurchaseLoading ||
        createPurchaseLoading ||
        updatePurchaseLoading ||
        isCalculateLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculateCreatePurchaseLoading,
    calculateUpdatePurchaseLoading,
    createPurchaseLoading,
    updatePurchaseLoading,
    isCalculateLoading,
  ]);

  return (
    <Responsive>
      <Responsive.Row rowGap="1rem">
        <Responsive.Col>
          <Dropdown.Form.Header
            title={t("Add New Ingredient")}
            description={t("Provide the information below")}
          />
        </Responsive.Col>
        <Responsive.Col>
          <SelectIngredient
            paramsMarketId={currentMarketId}
            paramsBranchId={currentBranchId}
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Ingredient")}
              </FormLabel>
            }
            selectSize={FORM_SELECT_ASYNC_SIZES.NORMAL}
            selectType={SELECT_INGREDIENT_TYPES.ID}
            name="ingredient_id"
            placeholder={t("Select ingredient")}
            onChange={handleInputChange}
            value={ingredient_id}
          />
        </Responsive.Col>
        <Responsive.Col sm={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Price")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.NUMBER}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="price"
            placeholder={t("Enter price")}
            onChange={handleInputChange}
            value={price}
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col sm={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Quantity")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.NUMBER}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="qty"
            placeholder={t("Enter quantity")}
            onChange={handleInputChange}
            value={qty}
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col>
          <ButtonCheckToggle
            isChecked={parseToBoolean(is_tax_included)}
            isDisabled={isLoading}
            handleCheckToggle={handleTaxIncludeToggle}
          >
            {t("Tax Included")}
          </ButtonCheckToggle>
        </Responsive.Col>
        <Responsive.Col></Responsive.Col>
        <Responsive.Col>
          <Dropdown.Form.Action>
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.DARK}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT_ICON}
              prefixIcon={<PlusSvg />}
              onClick={handleFormSubmit}
              isLoading={isLoading}
            >
              {t("Add Ingredient")}
            </Button>
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.SECONDARY}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handleCancelEdit}
            >
              {t("Cancel")}
            </Button>
          </Dropdown.Form.Action>
        </Responsive.Col>
      </Responsive.Row>
    </Responsive>
  );
};

export default PurchaseFormIngredientCreate;
