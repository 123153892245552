import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";

import { ButtonCheckToggleIcon } from "./button-check-toggle.style";

import { ReactComponent as Tick2Svg } from "../../assets/icons/Tick2.svg";

const ButtonCheckToggle = ({
  buttonSize = BUTTON_SIZES.NORMAL,
  isChecked = false,
  isDisabled = false,
  handleCheckToggle,
  children,
  ...props
}) => {
  const handleToggleClick = () => handleCheckToggle?.(!isChecked);

  return (
    <Button
      type="button"
      buttonColor={BUTTON_COLORS.SECONDARY}
      buttonSize={buttonSize}
      buttonType={BUTTON_TYPES.TEXT_ICON}
      prefixIcon={
        <ButtonCheckToggleIcon buttonSize={buttonSize} isChecked={isChecked}>
          <Tick2Svg />
        </ButtonCheckToggleIcon>
      }
      onClick={handleToggleClick}
      isDisabled={isDisabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export default ButtonCheckToggle;
