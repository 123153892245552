import SELECT_CATEGORY_PURCHASE_ACTION_TYPES from "./select-category-purchase.type";

const SELECT_CATEGORY_PURCHASE_INITIAL_STATE = {
  categories: {},
  searchCategories: {},

  isCategoriesHasMore: true,
  isSearchCategoriesHasMore: true,

  fetchCategoriesParams: {},
  fetchCategoriesLoading: false,
  fetchCategoriesSuccess: null,
  fetchCategoriesFailed: null,

  searchCategoriesParams: {},
  searchCategoriesLoading: false,
  searchCategoriesSuccess: null,
  searchCategoriesFailed: null,

  appendCategoriesParams: {},
  appendCategoriesLoading: false,
  appendCategoriesSuccess: null,
  appendCategoriesFailed: null,

  isFetchCategoriesHitted: false,
  isSearchCategoriesHitted: false,
  isAppendCategoriesHitted: false,
};

export const selectCategoryPurchaseReducer = (
  state = SELECT_CATEGORY_PURCHASE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_CATEGORIES:
      return { ...state, categories: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES:
      return { ...state, searchCategories: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_CATEGORIES_HAS_MORE:
      return { ...state, isCategoriesHasMore: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_SEARCH_CATEGORIES_HAS_MORE:
      return { ...state, isSearchCategoriesHasMore: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_PARAMS:
      return { ...state, fetchCategoriesParams: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_LOADING:
      return { ...state, fetchCategoriesLoading: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_SUCCESS:
      return { ...state, fetchCategoriesSuccess: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_FAILED:
      return { ...state, fetchCategoriesFailed: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_PARAMS:
      return { ...state, searchCategoriesParams: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_LOADING:
      return { ...state, searchCategoriesLoading: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_SUCCESS:
      return { ...state, searchCategoriesSuccess: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_FAILED:
      return { ...state, searchCategoriesFailed: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_PARAMS:
      return { ...state, appendCategoriesParams: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_LOADING:
      return { ...state, appendCategoriesLoading: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_SUCCESS:
      return { ...state, appendCategoriesSuccess: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_FAILED:
      return { ...state, appendCategoriesFailed: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_FETCH_CATEGORIES_HITTED:
      return { ...state, isFetchCategoriesHitted: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_SEARCH_CATEGORIES_HITTED:
      return { ...state, isSearchCategoriesHitted: payload };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_APPEND_CATEGORIES_HITTED:
      return { ...state, isAppendCategoriesHitted: payload };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_CATEGORIES:
      return {
        ...state,
        categories: { ...state.categories, ...payload },
      };
    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_SEARCH_CATEGORIES:
      return {
        ...state,
        searchCategories: { ...state.searchCategories, ...payload },
      };

    case SELECT_CATEGORY_PURCHASE_ACTION_TYPES.RESET_SELECT_CATEGORY_PURCHASE_REDUCER:
      return SELECT_CATEGORY_PURCHASE_INITIAL_STATE;
    default:
      return state;
  }
};
