import { createAction } from "../../utils/store.utils";

import ORDER_FORM_PRODUCT_ACTION_TYPES from "./order-form-product.type";

export const setBaseProducts = (baseProducts) =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.SET_BASE_PRODUCTS, baseProducts);
export const setSortProducts = (sortProducts) =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS, sortProducts);
export const appendBaseProducts = (baseProducts) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.APPEND_BASE_PRODUCTS,
    baseProducts
  );

export const setIsProductsHasMore = (isProductsHasMore) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE,
    isProductsHasMore
  );

export const setFetchProductsParams = (fetchProductsParams) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PARAMS,
    fetchProductsParams
  );
export const setFetchProductsLoading = (fetchProductsLoading) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING,
    fetchProductsLoading
  );
export const setFetchProductsSuccess = (fetchProductsSuccess) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS,
    fetchProductsSuccess
  );
export const setFetchProductsFailed = (fetchProductsFailed) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED,
    fetchProductsFailed
  );

export const setSortProductsLoading = (sortProductsLoading) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_LOADING,
    sortProductsLoading
  );
export const setSortProductsSuccess = (sortProductsSuccess) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_SUCCESS,
    sortProductsSuccess
  );
export const setSortProductsFailed = (sortProductsFailed) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_FAILED,
    sortProductsFailed
  );

export const setIsFetchProductsHitted = (isFetchProductsHitted) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED,
    isFetchProductsHitted
  );
export const setIsSortProductsHitted = (isSortProductsHitted) =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_SORT_PRODUCTS_HITTED,
    isSortProductsHitted
  );

export const switchSortValue = (key, value) =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.SWITCH_SORT_VALUE, {
    key,
    value,
  });
export const removeSortValue = (key) =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.REMOVE_SORT_VALUE, key);

export const fetchProductsStart = () =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START);
export const sortProductsStart = () =>
  createAction(ORDER_FORM_PRODUCT_ACTION_TYPES.SORT_PRODUCTS_START);

export const resetProductReducer = () =>
  createAction(
    ORDER_FORM_PRODUCT_ACTION_TYPES.RESET_ORDER_FORM_PRODUCT_REDUCER
  );
