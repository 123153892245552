import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getPageDetails as getPageAdminDetails } from "../../utils/permission-admin.utils";
import { getPageDetails as getPageMerchantDetails } from "../../utils/permission-merchant.utils";
import { getPageDetails as getPageSubscriberDetails } from "../../utils/permission-subscriber.utils";

import { USER_ROLES } from "../../constants/user.constant";
import { PERIODICITIES } from "../../constants/periodicity.constant";
import { ORDER_STATUSES } from "../../constants/order.constant";
import {
  SUBSCRIPTION_ADMIN_TABS,
  SUBSCRIPTION_SUBSCRIBER_TABS,
  SUBSCRIPTIONS_KEYS,
  SUBSCRIPTIONS_OPTION_KEYS,
} from "../../constants/subscription.constant";
import { RENEWAL_STATUSES } from "../../constants/renewal.constant";
import { EXCEL_REPORTS } from "../../constants/excel.constant";
import { DISCOUNT_TYPES } from "../../constants/discount.constant";
import { PRODUCT_MODIFIER_TYPES } from "../../constants/product-modifier.constant";
import { PERMISSION_ADMIN_PAGES } from "../../constants/permission-admin.constant";
import { PERMISSION_MERCHANT_PAGES } from "../../constants/permission-merchant.constant";
import { PERMISSION_SUBSCRIBER_PAGES } from "../../constants/permission-subscriber.constant";
import { PURCHASE_TABS } from "../../constants/purchase.constant";
import { REPORT_PRODUCT_TABS } from "../../constants/report-summary.constant";

import { getIsRightToLeft } from "../../store/global/global.selector";
import {
  setDashboardSubscriberTabs,
  setDiscountTypes,
  setOrderStatuses,
  setPermissionAdminPages,
  setPermissionMerchantPages,
  setPermissionSubscriberPages,
  setProductAdditionalPrices,
  setProductOptionPrices,
  setProductTypes,
  setPurchaseTabs,
  setReportExportTypes,
  setReportProductTabs,
  setSubscriptionAdminTabs,
  setSubscriptionCycles,
  setSubscriptionPeriods,
  setSubscriptionPlans,
  setSubscriptionStatuses,
  setSubscriptionSubscriberTabs,
  setUserRoles,
} from "../../store/translation/translation.action";

import { ReactComponent as CopySvg } from "../../assets/icons/Copy.svg";
import { ReactComponent as MenuSvg } from "../../assets/icons/Menu.svg";
import { ReactComponent as NoteSvg } from "../../assets/icons/Note.svg";
import { ReactComponent as UserSvg } from "../../assets/icons/User.svg";
import { ReactComponent as WalletSvg } from "../../assets/icons/Wallet.svg";
import { ReactComponent as ReserveSvg } from "../../assets/icons/Reserve.svg";
import { ReactComponent as CouponSvg } from "../../assets/icons/Coupon.svg";
import { ReactComponent as OfferSvg } from "../../assets/icons/Offer.svg";
import { ReactComponent as PromotionSvg } from "../../assets/icons/Promotion.svg";
import { ReactComponent as CalculateSvg } from "../../assets/icons/stroke/Calculate.svg";
import { ReactComponent as CoinSvg } from "../../assets/icons/stroke/Coin.svg";

const GlobalTranslation = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();

  const isRightToLeft = useSelector(getIsRightToLeft);

  const handleInitialize = () => {
    const userRoles = [
      { label: t("Admin"), value: USER_ROLES.USER_ADMIN },
      { label: t("Customer"), value: USER_ROLES.USER_CUSTOMER },
      { label: t("Merchant"), value: USER_ROLES.USER_MERCHANT },
      { label: t("Subscriber"), value: USER_ROLES.USER_SUBSCRIBER },
    ];

    const orderStatuses = [
      { label: t("Waiting"), value: ORDER_STATUSES.WAITING },
      { label: t("Process"), value: ORDER_STATUSES.PROCESS },
      { label: t("Completed"), value: ORDER_STATUSES.COMPLETED },
      { label: t("Canceled"), value: ORDER_STATUSES.CANCELED },
      { label: t("On Delivery"), value: ORDER_STATUSES.ON_DELIVERY },
      { label: t("Delivered"), value: ORDER_STATUSES.DELIVERED },
    ];

    const subscriptionPlans = [
      { label: t("Free"), value: SUBSCRIPTIONS_KEYS.TRIAL },
      { label: t("Basic"), value: SUBSCRIPTIONS_KEYS.BASIC },
      { label: t("Ultimate"), value: SUBSCRIPTIONS_KEYS.ADVANCED },
    ];
    const subscriptionCycles = [
      {
        label: t("Weekly"),
        value: SUBSCRIPTIONS_OPTION_KEYS.WEEKLY,
      },
      {
        label: t("Monthly"),
        value: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
      },
      {
        label: t("Yearly"),
        value: SUBSCRIPTIONS_OPTION_KEYS.YEARLY,
      },
    ];
    const subscriptionStatuses = [
      { label: t("Process"), value: RENEWAL_STATUSES.PROCESS },
      { label: t("Completed"), value: RENEWAL_STATUSES.COMPLETED },
      { label: t("Canceled"), value: RENEWAL_STATUSES.CANCELED },
    ];
    const subscriptionPeriods = {
      [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: {
        label: t("Monthly"),
        label_price: t("Monthly Price"),
        label_interval: t("Per Month"),
        value: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
      },
      [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: {
        label: t("Yearly"),
        label_price: t("Yearly Price"),
        label_interval: t("Per Year"),
        value: SUBSCRIPTIONS_OPTION_KEYS.YEARLY,
      },
    };

    const reportExportTypes = [
      {
        icon: <CopySvg />,
        title: t("Report Totals"),
        description: t(
          "Exports totals for orders, sales, taxes, cost, and profit"
        ),
        value: EXCEL_REPORTS.REPORT_TOTAL,
      },
      {
        icon: <MenuSvg />,
        title: t("Top Categories"),
        description: t(
          "Exports data on the highest-selling product categories"
        ),
        value: EXCEL_REPORTS.REPORT_CATEGORY,
      },
      {
        icon: <NoteSvg />,
        title: t("Top Products"),
        description: t(
          "Exports data on the highest-selling individual products"
        ),
        value: EXCEL_REPORTS.REPORT_PRODUCT,
      },
      {
        icon: <UserSvg />,
        title: t("Top Cashiers"),
        description: t(
          "Exports data on the cashiers with the best sales performance"
        ),
        value: EXCEL_REPORTS.REPORT_CASHIER,
      },
      {
        icon: <WalletSvg />,
        title: t("Top Payment Methods"),
        description: t(
          "Exports data on the most frequently used payment methods"
        ),
        value: EXCEL_REPORTS.REPORT_PAYMENT,
      },
      {
        icon: <ReserveSvg />,
        title: t("Top Tables"),
        description: t(
          "Exports data on the most frequently reserved tables by customers"
        ),
        value: EXCEL_REPORTS.REPORT_TABLE,
      },
    ];

    const discountTypes = [
      {
        icon: <CouponSvg />,
        title: t("Discount Coupon"),
        description: t(
          "This discount requires customers to enter a unique coupon code at checkout to redeem the discount."
        ),
        route: "coupon",
        value: DISCOUNT_TYPES.COUPON,
      },
      {
        icon: <OfferSvg />,
        title: t("Discount Offer"),
        description: t(
          "A limited-time discount available during a specific promotional period, such as seasonal sales or special events."
        ),
        route: "offer",
        value: DISCOUNT_TYPES.OFFER,
      },
      {
        icon: <PromotionSvg />,
        title: t("Discount Promotion"),
        description: t(
          "Conditional discounts based on specific purchasing rules, such as 'Buy 1 Get 1 Free' or 'Buy 2 Get 50% Off.'"
        ),
        route: "promotion",
        value: DISCOUNT_TYPES.PROMOTION,
      },
    ];
    const productTypes = [
      {
        type: "full",
        title: t("Full Details"),
        description: t(
          "Provide full product details to ensure a thorough and complete product setup."
        ),
      },
      {
        type: "quick",
        title: t("Quick Setup"),
        description: t(
          "Provide only the necessary information to create a product efficiently and quickly."
        ),
      },
    ];
    const productOptionPrices = [
      {
        value: PRODUCT_MODIFIER_TYPES.OPTION,
        icon: <CoinSvg />,
        title: t("Fixed Price"),
        description: t("Consistent price, remains the same for every order."),
      },
      {
        value: PRODUCT_MODIFIER_TYPES.OPTION_VARIABLE,
        icon: <CalculateSvg />,
        title: t("Variable Price"),
        description: t(
          "Adjustable price, determined during create order process."
        ),
      },
    ];
    const productAdditionalPrices = [
      {
        value: PRODUCT_MODIFIER_TYPES.ADDITIONAL,
        icon: <CoinSvg />,
        title: t("Fixed Price"),
        description: t("Consistent price, remains the same for every order."),
      },
      {
        value: PRODUCT_MODIFIER_TYPES.ADDITIONAL_VARIABLE,
        icon: <CalculateSvg />,
        title: t("Variable Price"),
        description: t(
          "Adjustable price, determined during create order process."
        ),
      },
    ];

    const dashboardSubscriberTabs = [
      {
        label: t("Daily"),
        value: PERIODICITIES.DAILY,
      },
      {
        label: t("Weekly"),
        value: PERIODICITIES.WEEKLY,
      },
      {
        label: t("Monthly"),
        value: PERIODICITIES.MONTHLY,
      },
    ];
    const subscriptionAdminTabs = [
      {
        label: t("Plans"),
        value: SUBSCRIPTION_ADMIN_TABS.PLANS,
      },
      {
        label: t("Features"),
        value: SUBSCRIPTION_ADMIN_TABS.FEATURES,
      },
    ];
    const subscriptionSubscriberTabs = [
      {
        label: t("Plans"),
        value: SUBSCRIPTION_SUBSCRIBER_TABS.PLANS,
      },
      {
        label: t("Billing"),
        value: SUBSCRIPTION_SUBSCRIBER_TABS.BILLING,
      },
      {
        label: t("Invoices"),
        value: SUBSCRIPTION_SUBSCRIBER_TABS.INVOICES,
      },
    ];
    const purchaseTabs = [
      {
        label: t("Ingredients"),
        value: PURCHASE_TABS.INGREDIENTS,
      },
      {
        label: t("Expenses"),
        value: PURCHASE_TABS.EXPENSES,
      },
    ];
    const reportProductTabs = [
      {
        label: t("Products"),
        value: REPORT_PRODUCT_TABS.PRODUCTS,
      },
      {
        label: t("Options"),
        value: REPORT_PRODUCT_TABS.OPTIONS,
      },
      {
        label: t("Add-ons"),
        value: REPORT_PRODUCT_TABS.ADDITIONALS,
      },
    ];

    const permissionAdminPages = Object.values(PERMISSION_ADMIN_PAGES).map(
      (pageKey) => {
        const pageName = getPageAdminDetails(pageKey)?.name;
        const isExists = i18n.exists(pageName);

        return { label: isExists ? t(pageName) : pageName, value: pageKey };
      }
    );
    const permissionMerchantPages = Object.values(
      PERMISSION_MERCHANT_PAGES
    ).map((pageKey) => {
      const pageName = getPageMerchantDetails(pageKey)?.name;
      const isExists = i18n.exists(pageName);

      return { label: isExists ? t(pageName) : pageName, value: pageKey };
    });
    const permissionSubscriberPages = Object.values(
      PERMISSION_SUBSCRIBER_PAGES
    ).map((pageKey) => {
      const pageName = getPageSubscriberDetails(pageKey)?.name;
      const isExists = i18n.exists(pageName);

      return { label: isExists ? t(pageName) : pageName, value: pageKey };
    });

    dispatch(setUserRoles(userRoles));
    dispatch(setOrderStatuses(orderStatuses));
    dispatch(setOrderStatuses(orderStatuses));
    dispatch(setSubscriptionPlans(subscriptionPlans));
    dispatch(setSubscriptionCycles(subscriptionCycles));
    dispatch(setSubscriptionStatuses(subscriptionStatuses));
    dispatch(setSubscriptionPeriods(subscriptionPeriods));
    dispatch(setReportExportTypes(reportExportTypes));
    dispatch(setDiscountTypes(discountTypes));
    dispatch(setProductTypes(productTypes));
    dispatch(setProductOptionPrices(productOptionPrices));
    dispatch(setProductAdditionalPrices(productAdditionalPrices));

    dispatch(setDashboardSubscriberTabs(dashboardSubscriberTabs));
    dispatch(setSubscriptionAdminTabs(subscriptionAdminTabs));
    dispatch(setSubscriptionSubscriberTabs(subscriptionSubscriberTabs));

    dispatch(setPurchaseTabs(purchaseTabs));

    dispatch(setReportProductTabs(reportProductTabs));

    dispatch(setPermissionAdminPages(permissionAdminPages));
    dispatch(setPermissionMerchantPages(permissionMerchantPages));
    dispatch(setPermissionSubscriberPages(permissionSubscriberPages));
  };

  useEffect(() => {
    const initializeTimeout = setTimeout(() => handleInitialize(), 300);
    return () => clearTimeout(initializeTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRightToLeft]);

  return <></>;
};

export default GlobalTranslation;
