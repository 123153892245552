const SELECT_SECTOR_ACTION_TYPES = {
  RESET_SELECT_SECTOR_REDUCER: "select-sector/RESET_SELECT_SECTOR_REDUCER",

  SET_SECTORS: "select-sector/SET_SECTORS",
  SET_SEARCH_SECTORS: "select-sector/SET_SEARCH_SECTORS",

  APPEND_SECTORS: "select-sector/APPEND_SECTORS",
  APPEND_SEARCH_SECTORS: "select-sector/APPEND_SEARCH_SECTORS",

  SET_IS_SECTORS_HAS_MORE: "select-sector/SET_IS_SECTORS_HAS_MORE",
  SET_IS_SEARCH_SECTORS_HAS_MORE:
    "select-sector/SET_IS_SEARCH_SECTORS_HAS_MORE",

  SET_FETCH_SECTORS_PARAMS: "select-sector/SET_FETCH_SECTORS_PARAMS",
  SET_FETCH_SECTORS_LOADING: "select-sector/SET_FETCH_SECTORS_LOADING",
  SET_FETCH_SECTORS_SUCCESS: "select-sector/SET_FETCH_SECTORS_SUCCESS",
  SET_FETCH_SECTORS_FAILED: "select-sector/SET_FETCH_SECTORS_FAILED",

  SET_SEARCH_SECTORS_PARAMS: "select-sector/SET_SEARCH_SECTORS_PARAMS",
  SET_SEARCH_SECTORS_LOADING: "select-sector/SET_SEARCH_SECTORS_LOADING",
  SET_SEARCH_SECTORS_SUCCESS: "select-sector/SET_SEARCH_SECTORS_SUCCESS",
  SET_SEARCH_SECTORS_FAILED: "select-sector/SET_SEARCH_SECTORS_FAILED",

  SET_APPEND_SECTORS_PARAMS: "select-sector/SET_APPEND_SECTORS_PARAMS",
  SET_APPEND_SECTORS_LOADING: "select-sector/SET_APPEND_SECTORS_LOADING",
  SET_APPEND_SECTORS_SUCCESS: "select-sector/SET_APPEND_SECTORS_SUCCESS",
  SET_APPEND_SECTORS_FAILED: "select-sector/SET_APPEND_SECTORS_FAILED",

  SET_IS_FETCH_SECTORS_HITTED: "select-sector/SET_IS_FETCH_SECTORS_HITTED",
  SET_IS_SEARCH_SECTORS_HITTED: "select-sector/SET_IS_SEARCH_SECTORS_HITTED",
  SET_IS_APPEND_SECTORS_HITTED: "select-sector/SET_IS_APPEND_SECTORS_HITTED",

  FETCH_SECTORS_START: "select-sector/FETCH_SECTORS_START",
  SEARCH_SECTORS_START: "select-sector/SEARCH_SECTORS_START",
  APPEND_SECTORS_START: "select-sector/APPEND_SECTORS_START",
};

export default SELECT_SECTOR_ACTION_TYPES;
