import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import REPORT_MORE_ACTION_TYPES from "./report-more.type";

import {
  appendReportMoreAdditionals,
  appendReportMoreCashiers,
  appendReportMoreCategories,
  appendReportMoreOptions,
  appendReportMorePayments,
  appendReportMoreProducts,
  appendReportMoreTables,
  setFetchReportMoreAdditionalsFailed,
  setFetchReportMoreAdditionalsLoading,
  setFetchReportMoreAdditionalsSuccess,
  setFetchReportMoreCashiersFailed,
  setFetchReportMoreCashiersLoading,
  setFetchReportMoreCashiersSuccess,
  setFetchReportMoreCategoriesFailed,
  setFetchReportMoreCategoriesLoading,
  setFetchReportMoreCategoriesSuccess,
  setFetchReportMoreOptionsFailed,
  setFetchReportMoreOptionsLoading,
  setFetchReportMoreOptionsSuccess,
  setFetchReportMorePaymentsFailed,
  setFetchReportMorePaymentsLoading,
  setFetchReportMorePaymentsSuccess,
  setFetchReportMoreProductsFailed,
  setFetchReportMoreProductsLoading,
  setFetchReportMoreProductsSuccess,
  setFetchReportMoreTablesFailed,
  setFetchReportMoreTablesLoading,
  setFetchReportMoreTablesSuccess,
  setIsFetchReportMoreAdditionalsHitted,
  setIsFetchReportMoreCashiersHitted,
  setIsFetchReportMoreCategoriesHitted,
  setIsFetchReportMoreOptionsHitted,
  setIsFetchReportMorePaymentsHitted,
  setIsFetchReportMoreProductsHitted,
  setIsFetchReportMoreTablesHitted,
  setIsReportMoreAdditionalsHasMore,
  setIsReportMoreCashiersHasMore,
  setIsReportMoreCategoriesHasMore,
  setIsReportMoreOptionsHasMore,
  setIsReportMorePaymentsHasMore,
  setIsReportMoreProductsHasMore,
  setIsReportMoreTablesHasMore,
  setReportMoreAdditionals,
  setReportMoreCashiers,
  setReportMoreCategories,
  setReportMoreOptions,
  setReportMorePayments,
  setReportMoreProducts,
  setReportMoreTables,
} from "./report-more.action";
import {
  getFetchReportMoreAdditionalsParams,
  getFetchReportMoreCashiersParams,
  getFetchReportMoreCategoriesParams,
  getFetchReportMoreOptionsParams,
  getFetchReportMorePaymentsParams,
  getFetchReportMoreProductsParams,
  getFetchReportMoreTablesParams,
} from "./report-more.selector";

import {
  getReportAdditionals,
  getReportCashiers,
  getReportCategories,
  getReportOptions,
  getReportPayments,
  getReportProducts,
  getReportTables,
} from "../../api/report.api";

export function* _getReportMoreCategories() {
  try {
    const storedParams = yield select(getFetchReportMoreCategoriesParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreCategoriesLoading(true));

    const {
      meta: { message },
      data: reportCategories,
    } = yield call(getReportCategories, parameters);

    yield put(setIsFetchReportMoreCategoriesHitted(true));
    yield put(setIsReportMoreCategoriesHasMore(reportCategories.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreCategories(reportCategories));
    } else {
      yield put(setReportMoreCategories(reportCategories));
    }

    yield put(setFetchReportMoreCategoriesSuccess(message));
    yield put(setFetchReportMoreCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreCategoriesFailed(error));
    yield put(setFetchReportMoreCategoriesLoading(false));
  }
}
export function* _getReportMoreProducts() {
  try {
    const storedParams = yield select(getFetchReportMoreProductsParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreProductsLoading(true));

    const {
      meta: { message },
      data: reportProducts,
    } = yield call(getReportProducts, parameters);

    yield put(setIsFetchReportMoreProductsHitted(true));
    yield put(setIsReportMoreProductsHasMore(reportProducts.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreProducts(reportProducts));
    } else {
      yield put(setReportMoreProducts(reportProducts));
    }

    yield put(setFetchReportMoreProductsSuccess(message));
    yield put(setFetchReportMoreProductsLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreProductsFailed(error));
    yield put(setFetchReportMoreProductsLoading(false));
  }
}
export function* _getReportMoreOptions() {
  try {
    const storedParams = yield select(getFetchReportMoreOptionsParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreOptionsLoading(true));

    const {
      meta: { message },
      data: reportOptions,
    } = yield call(getReportOptions, parameters);

    yield put(setIsFetchReportMoreOptionsHitted(true));
    yield put(setIsReportMoreOptionsHasMore(reportOptions.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreOptions(reportOptions));
    } else {
      yield put(setReportMoreOptions(reportOptions));
    }

    yield put(setFetchReportMoreOptionsSuccess(message));
    yield put(setFetchReportMoreOptionsLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreOptionsFailed(error));
    yield put(setFetchReportMoreOptionsLoading(false));
  }
}
export function* _getReportMoreAdditionals() {
  try {
    const storedParams = yield select(getFetchReportMoreAdditionalsParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreAdditionalsLoading(true));

    const {
      meta: { message },
      data: reportAdditionals,
    } = yield call(getReportAdditionals, parameters);

    yield put(setIsFetchReportMoreAdditionalsHitted(true));
    yield put(setIsReportMoreAdditionalsHasMore(reportAdditionals.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreAdditionals(reportAdditionals));
    } else {
      yield put(setReportMoreAdditionals(reportAdditionals));
    }

    yield put(setFetchReportMoreAdditionalsSuccess(message));
    yield put(setFetchReportMoreAdditionalsLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreAdditionalsFailed(error));
    yield put(setFetchReportMoreAdditionalsLoading(false));
  }
}
export function* _getReportMoreCashiers() {
  try {
    const storedParams = yield select(getFetchReportMoreCashiersParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreCashiersLoading(true));

    const {
      meta: { message },
      data: reportCashiers,
    } = yield call(getReportCashiers, parameters);

    yield put(setIsFetchReportMoreCashiersHitted(true));
    yield put(setIsReportMoreCashiersHasMore(reportCashiers.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreCashiers(reportCashiers));
    } else {
      yield put(setReportMoreCashiers(reportCashiers));
    }

    yield put(setFetchReportMoreCashiersSuccess(message));
    yield put(setFetchReportMoreCashiersLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreCashiersFailed(error));
    yield put(setFetchReportMoreCashiersLoading(false));
  }
}
export function* _getReportMorePayments() {
  try {
    const storedParams = yield select(getFetchReportMorePaymentsParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMorePaymentsLoading(true));

    const {
      meta: { message },
      data: reportPayments,
    } = yield call(getReportPayments, parameters);

    yield put(setIsFetchReportMorePaymentsHitted(true));
    yield put(setIsReportMorePaymentsHasMore(reportPayments.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMorePayments(reportPayments));
    } else {
      yield put(setReportMorePayments(reportPayments));
    }

    yield put(setFetchReportMorePaymentsSuccess(message));
    yield put(setFetchReportMorePaymentsLoading(false));
  } catch (error) {
    yield put(setFetchReportMorePaymentsFailed(error));
    yield put(setFetchReportMorePaymentsLoading(false));
  }
}
export function* _getReportMoreTables() {
  try {
    const storedParams = yield select(getFetchReportMoreTablesParams);
    const parameters = getParamsWithDefault(storedParams);

    yield put(setFetchReportMoreTablesLoading(true));

    const {
      meta: { message },
      data: reportTables,
    } = yield call(getReportTables, parameters);

    yield put(setIsFetchReportMoreTablesHitted(true));
    yield put(setIsReportMoreTablesHasMore(reportTables.length > 0));

    if (parameters.page > 1) {
      yield put(appendReportMoreTables(reportTables));
    } else {
      yield put(setReportMoreTables(reportTables));
    }

    yield put(setFetchReportMoreTablesSuccess(message));
    yield put(setFetchReportMoreTablesLoading(false));
  } catch (error) {
    yield put(setFetchReportMoreTablesFailed(error));
    yield put(setFetchReportMoreTablesLoading(false));
  }
}

export function* onFetchReportMoreCategoriesStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CATEGORIES_START,
    _getReportMoreCategories
  );
}
export function* onFetchReportMoreProductsStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PRODUCTS_START,
    _getReportMoreProducts
  );
}
export function* onFetchReportMoreOptionsStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_OPTIONS_START,
    _getReportMoreOptions
  );
}
export function* onFetchReportMoreAdditionalsStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_ADDITIONALS_START,
    _getReportMoreAdditionals
  );
}
export function* onFetchReportMoreCashiersStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_CASHIERS_START,
    _getReportMoreCashiers
  );
}
export function* onFetchReportMorePaymentsStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_PAYMENTS_START,
    _getReportMorePayments
  );
}
export function* onFetchReportMoreTablesStart() {
  yield takeLatest(
    REPORT_MORE_ACTION_TYPES.FETCH_REPORT_MORE_TABLES_START,
    _getReportMoreTables
  );
}

export function* reportMoreSaga() {
  yield all([
    call(onFetchReportMoreCategoriesStart),
    call(onFetchReportMoreProductsStart),
    call(onFetchReportMoreOptionsStart),
    call(onFetchReportMoreAdditionalsStart),
    call(onFetchReportMoreCashiersStart),
    call(onFetchReportMorePaymentsStart),
    call(onFetchReportMoreTablesStart),
  ]);
}
