import styled from "styled-components";

import { getSelectFontStyles } from "../../components/form-select-async/form-select-async.style";

export const SelectIngredientOptionName = styled.p`
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 600;
  color: #0e072f;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
`;

export const SelectIngredientOptionStock = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.5rem 0.75rem;
  border-radius: 12px;
  background-color: #e9e9ee;

  font-size: 0.75rem;
  font-weight: 600;
  white-space: nowrap;
`;

export const SelectIngredientOptionContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.15rem 0.5rem;
  column-gap: 0.75rem;
`;

export const getAdditionalStyles = ({ selectSize, isRightToLeft = false }) => ({
  singleValue: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    [`${SelectIngredientOptionContainer}`]: {
      padding: 0,
    },
    [`${SelectIngredientOptionStock}`]: {
      display: "none",
    },
  }),
  multiValue: (styles) => ({
    ...styles,

    alignItems: "center",
    backgroundColor: "#f2f2f4",
    padding: "0.5rem 0.75rem",
    borderRadius: "8px",

    [`${SelectIngredientOptionContainer}`]: {
      padding: 0,
    },
    [`${SelectIngredientOptionStock}`]: {
      display: "none",
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    padding: "0 !important",
    marginLeft: isRightToLeft ? "0.5rem" : 0,
    marginRight: !isRightToLeft ? "0.5rem" : 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,

    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    color: "#0e072f",
    backgroundColor: "#d9d9db",
    borderRadius: "50%",
    transition: "0.3s ease all",

    width: "1.25rem",
    minWidth: "1.25rem",
    maxWidth: "1.25rem",
    height: "1.25rem",
    minHeight: "1.25rem",
    maxHeight: "1.25rem",

    svg: {
      minWidth: "0.75rem",
      maxWidth: "0.75rem",
      height: "0.75rem",
      minHeight: "0.75rem",
      maxHeight: "0.75rem",
    },

    ":hover": {
      color: "#0e072f",
      backgroundColor: "#c1c1c3",
    },
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    cursor: "pointer",
    backgroundColor: isSelected ? "#2900ee !important" : "#ffffff",

    [`${SelectIngredientOptionName}`]: {
      color: isSelected ? "#ffffff !important" : "#0e072f",
    },
    [`${SelectIngredientOptionStock}`]: {
      color: isSelected ? "#ffffff !important" : "#0e072f",
      backgroundColor: isSelected ? "#7f66f5 !important" : "#f1f5f9",
    },

    "&:active": {
      backgroundColor: "#2900ee",

      [`${SelectIngredientOptionName}`]: {
        color: "#ffffff",
      },
      [`${SelectIngredientOptionStock}`]: {
        color: "#ffffff",
        backgroundColor: "#7f66f5",
      },
    },
    "&:hover": {
      backgroundColor: "#f8fafc",

      [`${SelectIngredientOptionName}`]: {
        color: "#0e072f",
      },
      [`${SelectIngredientOptionStock}`]: {
        color: "#0e072f",
        backgroundColor: "#e2e8f0",
      },
    },
  }),
});
