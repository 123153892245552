import {
  SUBSCRIPTIONS_KEYS,
  SUBSCRIPTIONS_OPTION_KEYS,
} from "../constants/subscription.constant";

import { parseToBoolean } from "./parser.utils";

export const getActivePlans = (activePlans = []) => {
  let isHasAdvancedPlan = false;
  let isHasBasicPlan = false;
  let isHasTrialPlan = false;

  for (const activePlan of activePlans) {
    const { subscription_key } = activePlan ?? {};

    isHasAdvancedPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.ADVANCED;
    isHasBasicPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.BASIC;
    isHasTrialPlan ||= subscription_key === SUBSCRIPTIONS_KEYS.TRIAL;
  }

  return {
    isHasAdvancedPlan: parseToBoolean(isHasAdvancedPlan),
    isHasBasicPlan: parseToBoolean(isHasBasicPlan),
    isHasTrialPlan: parseToBoolean(isHasTrialPlan),
  };
};

export const getPlanKeyLabel = (planKey) =>
  ({
    [SUBSCRIPTIONS_KEYS.ADVANCED]: "Advanced",
    [SUBSCRIPTIONS_KEYS.BASIC]: "Basic",
    [SUBSCRIPTIONS_KEYS.TRIAL]: "Free",
  }?.[planKey]);
export const getPlanCycleLabel = (planCycle) =>
  ({
    [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: "Yearly",
    [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: "Monthly",
    [SUBSCRIPTIONS_OPTION_KEYS.WEEKLY]: "Weekly",
  }?.[planCycle]);
export const getPlanCyclePrice = (planCycle) =>
  ({
    [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: "Yearly Price",
    [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: "Monthly Price",
    [SUBSCRIPTIONS_OPTION_KEYS.WEEKLY]: "Weekly Price",
  }?.[planCycle]);
export const getPlanCycleInterval = (planCycle) =>
  ({
    [SUBSCRIPTIONS_OPTION_KEYS.YEARLY]: "Per Yearly",
    [SUBSCRIPTIONS_OPTION_KEYS.MONTHLY]: "Per Monthly",
    [SUBSCRIPTIONS_OPTION_KEYS.WEEKLY]: "Per Weekly",
  }?.[planCycle]);
