import { createSelector } from "reselect";

const stateSubscriptionSelector = ({ stateSubscription }) => stateSubscription;

export const getSubscriptionAdminTab = createSelector(
  [stateSubscriptionSelector],
  ({ subscriptionAdminTab }) => subscriptionAdminTab
);
export const getSubscriptionSubscriberTab = createSelector(
  [stateSubscriptionSelector],
  ({ subscriptionSubscriberTab }) => subscriptionSubscriberTab
);

export const getBillingPlan = createSelector(
  [stateSubscriptionSelector],
  ({ billingPlan }) => billingPlan
);
export const getBillingCycle = createSelector(
  [stateSubscriptionSelector],
  ({ billingCycle }) => billingCycle
);

export const getIsBranchesChanging = createSelector(
  [stateSubscriptionSelector],
  ({ isBranchesChanging }) => isBranchesChanging
);
export const getIsCalculateLoading = createSelector(
  [stateSubscriptionSelector],
  ({ isCalculateLoading }) => isCalculateLoading
);
export const getIsTransformLoading = createSelector(
  [stateSubscriptionSelector],
  ({ isTransformLoading }) => isTransformLoading
);

export const getUpdatingBranchKey = createSelector(
  [stateSubscriptionSelector],
  ({ updatingBranchKey }) => updatingBranchKey
);
export const getUpdatingItemIndex = createSelector(
  [stateSubscriptionSelector],
  ({ updatingItemIndex }) => updatingItemIndex
);

export const getRenewalBranchesIds = createSelector(
  [stateSubscriptionSelector],
  ({ renewalBranchesIds }) => renewalBranchesIds
);
export const getRenewalPlan = createSelector(
  [stateSubscriptionSelector],
  ({ renewalPlan }) => renewalPlan
);
export const getRenewalCycle = createSelector(
  [stateSubscriptionSelector],
  ({ renewalCycle }) => renewalCycle
);
export const getRenewalBranches = createSelector(
  [stateSubscriptionSelector],
  ({ renewalBranches }) => renewalBranches
);
export const getRenewalItems = createSelector(
  [stateSubscriptionSelector],
  ({ renewalItems }) => renewalItems
);
export const getRenewalDiscount = createSelector(
  [stateSubscriptionSelector],
  ({ renewalDiscount }) => renewalDiscount
);
