import { createAction } from "../../utils/store.utils";

import STATE_PURCHASE_ACTION_TYPES from "./state-purchase.type";

export const setPurchaseTab = (purchaseTab) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.SET_PURCHASE_TAB, purchaseTab);

export const setCreateStep = (createStep) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.SET_CREATE_STEP, createStep);
export const setCreateValues = (createValues) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.SET_CREATE_VALUES, createValues);
export const resetCreateValues = () =>
  createAction(STATE_PURCHASE_ACTION_TYPES.RESET_CREATE_VALUES);

export const setFormIngredients = (formIngredients) =>
  createAction(
    STATE_PURCHASE_ACTION_TYPES.SET_FORM_INGREDIENTS,
    formIngredients
  );
export const appendFormIngredient = (value) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.APPEND_FORM_INGREDIENT, value);
export const updateFormIngredient = (index, value) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.UPDATE_FORM_INGREDIENT, {
    index,
    value,
  });
export const deleteFormIngredient = (index) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.DELETE_FORM_INGREDIENT, index);

export const setFormItems = (formItems) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.SET_FORM_ITEMS, formItems);
export const appendFormItem = (value) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.APPEND_FORM_ITEM, value);
export const updateFormItem = (index, value) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.UPDATE_FORM_ITEM, {
    index,
    value,
  });
export const deleteFormItem = (index) =>
  createAction(STATE_PURCHASE_ACTION_TYPES.DELETE_FORM_ITEM, index);

export const setIsCalculateLoading = (isCalculateLoading) =>
  createAction(
    STATE_PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_LOADING,
    isCalculateLoading
  );

export const setUpdatingIngredientIndex = (updatingIngredientIndex) =>
  createAction(
    STATE_PURCHASE_ACTION_TYPES.SET_UPDATING_INGREDIENT_INDEX,
    updatingIngredientIndex
  );
export const setUpdatingItemIndex = (updatingItemIndex) =>
  createAction(
    STATE_PURCHASE_ACTION_TYPES.SET_UPDATING_ITEM_INDEX,
    updatingItemIndex
  );

export const resetStatePurchaseReducer = () =>
  createAction(STATE_PURCHASE_ACTION_TYPES.RESET_STATE_PURCHASE_REDUCER);
