import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import {
  setAppendCategoriesFailed,
  setFetchCategoriesFailed,
  setSearchCategoriesFailed,
} from "../../store/select-category-purchase/select-category-purchase.action";
import {
  getAppendCategoriesFailed,
  getFetchCategoriesFailed,
  getSearchCategoriesFailed,
} from "../../store/select-category-purchase/select-category-purchase.selector";

const SelectCategoryPurcasePopup = () => {
  const dispatch = useDispatch();

  const fetchCategoriesFailed = useSelector(getFetchCategoriesFailed);
  const searchCategoriesFailed = useSelector(getSearchCategoriesFailed);
  const appendCategoriesFailed = useSelector(getAppendCategoriesFailed);

  useEffect(() => {
    if (
      fetchCategoriesFailed !== null ||
      searchCategoriesFailed !== null ||
      appendCategoriesFailed !== null
    ) {
      showErrorMessage(
        fetchCategoriesFailed ??
          searchCategoriesFailed ??
          appendCategoriesFailed
      );

      if (fetchCategoriesFailed !== null)
        dispatch(setFetchCategoriesFailed(null));
      if (searchCategoriesFailed !== null)
        dispatch(setSearchCategoriesFailed(null));
      if (appendCategoriesFailed !== null)
        dispatch(setAppendCategoriesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategoriesFailed, searchCategoriesFailed, appendCategoriesFailed]);

  return <></>;
};

export default SelectCategoryPurcasePopup;
