const RESUPPLY_ACTION_TYPES = {
  RESET_RESUPPLY_REDUCER: "resupply/RESET_RESUPPLY_REDUCER",

  SET_RESUPPLIES: "resupply/SET_RESUPPLIES",
  SET_RESUPPLY: "resupply/SET_RESUPPLY",
  SET_CREATE_RESUPPLY: "resupply/SET_CREATE_RESUPPLY",
  SET_UPDATE_RESUPPLY: "resupply/SET_UPDATE_RESUPPLY",
  SET_RECEIVE_RESUPPLY: "resupply/SET_RECEIVE_RESUPPLY",
  SET_APPROVE_RESUPPLY: "resupply/SET_APPROVE_RESUPPLY",
  SET_DECLINE_RESUPPLY: "resupply/SET_DECLINE_RESUPPLY",

  SET_IS_RESUPPLIES_HAS_MORE: "resupply/SET_IS_RESUPPLIES_HAS_MORE",

  SET_FETCH_RESUPPLIES_SEARCH: "resupply/SET_FETCH_RESUPPLIES_SEARCH",
  SET_FETCH_RESUPPLIES_SORT: "resupply/SET_FETCH_RESUPPLIES_SORT",
  SET_FETCH_RESUPPLIES_KEY_BY: "resupply/SET_FETCH_RESUPPLIES_KEY_BY",
  SET_FETCH_RESUPPLIES_PAGE: "resupply/SET_FETCH_RESUPPLIES_PAGE",
  SET_FETCH_RESUPPLIES_PER_PAGE: "resupply/SET_FETCH_RESUPPLIES_PER_PAGE",
  SET_FETCH_RESUPPLIES_INCLUDES: "resupply/SET_FETCH_RESUPPLIES_INCLUDES",
  SET_FETCH_RESUPPLIES_FILTER_MARKET_ID:
    "resupply/SET_FETCH_RESUPPLIES_FILTER_MARKET_ID",
  SET_FETCH_RESUPPLIES_FILTER_BRANCH_ID:
    "resupply/SET_FETCH_RESUPPLIES_FILTER_BRANCH_ID",
  SET_FETCH_RESUPPLIES_FILTER_CREATED_BY:
    "resupply/SET_FETCH_RESUPPLIES_FILTER_CREATED_BY",
  SET_FETCH_RESUPPLIES_FILTER_STATUS:
    "resupply/SET_FETCH_RESUPPLIES_FILTER_STATUS",
  SET_FETCH_RESUPPLIES_FILTER_IS_RECEIVED:
    "resupply/SET_FETCH_RESUPPLIES_FILTER_IS_RECEIVED",
  SET_FETCH_RESUPPLIES_LOADING: "resupply/SET_FETCH_RESUPPLIES_LOADING",
  SET_FETCH_RESUPPLIES_SUCCESS: "resupply/SET_FETCH_RESUPPLIES_SUCCESS",
  SET_FETCH_RESUPPLIES_FAILED: "resupply/SET_FETCH_RESUPPLIES_FAILED",

  SET_FETCH_RESUPPLY_LOADING: "resupply/SET_FETCH_RESUPPLY_LOADING",
  SET_FETCH_RESUPPLY_SUCCESS: "resupply/SET_FETCH_RESUPPLY_SUCCESS",
  SET_FETCH_RESUPPLY_FAILED: "resupply/SET_FETCH_RESUPPLY_FAILED",

  SET_CREATE_RESUPPLY_LOADING: "resupply/SET_CREATE_RESUPPLY_LOADING",
  SET_CREATE_RESUPPLY_SUCCESS: "resupply/SET_CREATE_RESUPPLY_SUCCESS",
  SET_CREATE_RESUPPLY_FAILED: "resupply/SET_CREATE_RESUPPLY_FAILED",

  SET_UPDATE_RESUPPLY_LOADING: "resupply/SET_UPDATE_RESUPPLY_LOADING",
  SET_UPDATE_RESUPPLY_SUCCESS: "resupply/SET_UPDATE_RESUPPLY_SUCCESS",
  SET_UPDATE_RESUPPLY_FAILED: "resupply/SET_UPDATE_RESUPPLY_FAILED",

  SET_DELETE_RESUPPLY_LOADING: "resupply/SET_DELETE_RESUPPLY_LOADING",
  SET_DELETE_RESUPPLY_SUCCESS: "resupply/SET_DELETE_RESUPPLY_SUCCESS",
  SET_DELETE_RESUPPLY_FAILED: "resupply/SET_DELETE_RESUPPLY_FAILED",

  SET_RECEIVE_RESUPPLY_LOADING: "resupply/SET_RECEIVE_RESUPPLY_LOADING",
  SET_RECEIVE_RESUPPLY_SUCCESS: "resupply/SET_RECEIVE_RESUPPLY_SUCCESS",
  SET_RECEIVE_RESUPPLY_FAILED: "resupply/SET_RECEIVE_RESUPPLY_FAILED",

  SET_APPROVE_RESUPPLY_LOADING: "resupply/SET_APPROVE_RESUPPLY_LOADING",
  SET_APPROVE_RESUPPLY_SUCCESS: "resupply/SET_APPROVE_RESUPPLY_SUCCESS",
  SET_APPROVE_RESUPPLY_FAILED: "resupply/SET_APPROVE_RESUPPLY_FAILED",

  SET_DECLINE_RESUPPLY_LOADING: "resupply/SET_DECLINE_RESUPPLY_LOADING",
  SET_DECLINE_RESUPPLY_SUCCESS: "resupply/SET_DECLINE_RESUPPLY_SUCCESS",
  SET_DECLINE_RESUPPLY_FAILED: "resupply/SET_DECLINE_RESUPPLY_FAILED",

  APPEND_RESUPPLIES: "resupply/APPEND_RESUPPLIES",

  IS_FETCH_RESUPPLIES_HITTED: "resupply/IS_FETCH_RESUPPLIES_HITTED",
  IS_FETCH_RESUPPLY_HITTED: "resupply/IS_FETCH_RESUPPLY_HITTED",
  IS_CREATE_RESUPPLY_HITTED: "resupply/IS_CREATE_RESUPPLY_HITTED",
  IS_UPDATE_RESUPPLY_HITTED: "resupply/IS_UPDATE_RESUPPLY_HITTED",
  IS_DELETE_RESUPPLY_HITTED: "resupply/IS_DELETE_RESUPPLY_HITTED",
  IS_RECEIVE_RESUPPLY_HITTED: "resupply/IS_RECEIVE_RESUPPLY_HITTED",
  IS_APPROVE_RESUPPLY_HITTED: "resupply/IS_APPROVE_RESUPPLY_HITTED",
  IS_DECLINE_RESUPPLY_HITTED: "resupply/IS_DECLINE_RESUPPLY_HITTED",

  FETCH_RESUPPLIES_START: "resupply/FETCH_RESUPPLIES_START",
  FETCH_RESUPPLY_START: "resupply/FETCH_RESUPPLY_START",
  CREATE_RESUPPLY_START: "resupply/CREATE_RESUPPLY_START",
  UPDATE_RESUPPLY_START: "resupply/UPDATE_RESUPPLY_START",
  DELETE_RESUPPLY_START: "resupply/DELETE_RESUPPLY_START",
  RECEIVE_RESUPPLY_START: "resupply/RECEIVE_RESUPPLY_START",
  APPROVE_RESUPPLY_START: "resupply/APPROVE_RESUPPLY_START",
  DECLINE_RESUPPLY_START: "resupply/DECLINE_RESUPPLY_START",
};

export default RESUPPLY_ACTION_TYPES;
