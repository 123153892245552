import SELECT_SECTOR_ACTION_TYPES from "./select-sector.type";

const SELECT_SECTOR_INITIAL_STATE = {
  sectors: {},
  searchSectors: {},

  isSectorsHasMore: true,
  isSearchSectorsHasMore: true,

  fetchSectorsParams: {},
  fetchSectorsLoading: false,
  fetchSectorsSuccess: null,
  fetchSectorsFailed: null,

  searchSectorsParams: {},
  searchSectorsLoading: false,
  searchSectorsSuccess: null,
  searchSectorsFailed: null,

  appendSectorsParams: {},
  appendSectorsLoading: false,
  appendSectorsSuccess: null,
  appendSectorsFailed: null,

  isFetchSectorsHitted: false,
  isSearchSectorsHitted: false,
  isAppendSectorsHitted: false,
};

export const selectSectorReducer = (
  state = SELECT_SECTOR_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_SECTOR_ACTION_TYPES.SET_SECTORS:
      return { ...state, sectors: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS:
      return { ...state, searchSectors: payload };

    case SELECT_SECTOR_ACTION_TYPES.SET_IS_SECTORS_HAS_MORE:
      return { ...state, isSectorsHasMore: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_IS_SEARCH_SECTORS_HAS_MORE:
      return { ...state, isSearchSectorsHasMore: payload };

    case SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PARAMS:
      return { ...state, fetchSectorsParams: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_LOADING:
      return { ...state, fetchSectorsLoading: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SUCCESS:
      return { ...state, fetchSectorsSuccess: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_FAILED:
      return { ...state, fetchSectorsFailed: payload };

    case SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_PARAMS:
      return { ...state, searchSectorsParams: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_LOADING:
      return { ...state, searchSectorsLoading: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_SUCCESS:
      return { ...state, searchSectorsSuccess: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_FAILED:
      return { ...state, searchSectorsFailed: payload };

    case SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_PARAMS:
      return { ...state, appendSectorsParams: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_LOADING:
      return { ...state, appendSectorsLoading: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_SUCCESS:
      return { ...state, appendSectorsSuccess: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_FAILED:
      return { ...state, appendSectorsFailed: payload };

    case SELECT_SECTOR_ACTION_TYPES.SET_IS_FETCH_SECTORS_HITTED:
      return { ...state, isFetchSectorsHitted: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_IS_SEARCH_SECTORS_HITTED:
      return { ...state, isSearchSectorsHitted: payload };
    case SELECT_SECTOR_ACTION_TYPES.SET_IS_APPEND_SECTORS_HITTED:
      return { ...state, isAppendSectorsHitted: payload };

    case SELECT_SECTOR_ACTION_TYPES.APPEND_SECTORS:
      return {
        ...state,
        sectors: { ...state.sectors, ...payload },
      };
    case SELECT_SECTOR_ACTION_TYPES.APPEND_SEARCH_SECTORS:
      return {
        ...state,
        searchSectors: { ...state.searchSectors, ...payload },
      };

    case SELECT_SECTOR_ACTION_TYPES.RESET_SELECT_SECTOR_REDUCER:
      return SELECT_SECTOR_INITIAL_STATE;
    default:
      return state;
  }
};
