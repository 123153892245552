import apiService from "./api";

export const getCategories = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/categories/purchase", params }))
    .data;
export const getCategory = async (categoryId) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/categories/purchase/${categoryId}`,
    })
  ).data;
export const createCategory = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/categories/purchase",
      request,
    })
  ).data;
export const updateCategory = async (categoryId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/categories/purchase/${categoryId}`,
      request,
    })
  ).data;
export const deleteCategory = async (categoryId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/categories/purchase/${categoryId}`,
    })
  ).data;
