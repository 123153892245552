import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateRenewalTransferFailed,
  setCreateRenewalTransferSuccess,
  setDeleteRenewalTransferFailed,
  setDeleteRenewalTransferSuccess,
  setFetchRenewalTransfersFailed,
  setFetchRenewalTransferFailed,
  setUpdateRenewalTransferFailed,
  setUpdateRenewalTransferSuccess,
} from "../../store/renewal-transfer/renewal-transfer.action";
import {
  getCreateRenewalTransferFailed,
  getCreateRenewalTransferSuccess,
  getDeleteRenewalTransferFailed,
  getDeleteRenewalTransferSuccess,
  getFetchRenewalTransfersFailed,
  getFetchRenewalTransferFailed,
  getUpdateRenewalTransferFailed,
  getUpdateRenewalTransferSuccess,
} from "../../store/renewal-transfer/renewal-transfer.selector";

const RenewalTransferPopup = () => {
  const dispatch = useDispatch();

  const createRenewalTransferSuccess = useSelector(
    getCreateRenewalTransferSuccess
  );
  const updateRenewalTransferSuccess = useSelector(
    getUpdateRenewalTransferSuccess
  );
  const deleteRenewalTransferSuccess = useSelector(
    getDeleteRenewalTransferSuccess
  );

  const fetchRenewalTransfersFailed = useSelector(
    getFetchRenewalTransfersFailed
  );
  const fetchRenewalTransferFailed = useSelector(getFetchRenewalTransferFailed);
  const createRenewalTransferFailed = useSelector(
    getCreateRenewalTransferFailed
  );
  const updateRenewalTransferFailed = useSelector(
    getUpdateRenewalTransferFailed
  );
  const deleteRenewalTransferFailed = useSelector(
    getDeleteRenewalTransferFailed
  );

  useEffect(() => {
    if (
      createRenewalTransferSuccess !== null ||
      updateRenewalTransferSuccess !== null ||
      deleteRenewalTransferSuccess !== null
    ) {
      showSuccessMessage(
        createRenewalTransferSuccess ??
          updateRenewalTransferSuccess ??
          deleteRenewalTransferSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createRenewalTransferSuccess !== null)
        dispatch(setCreateRenewalTransferSuccess(null));
      if (updateRenewalTransferSuccess !== null)
        dispatch(setUpdateRenewalTransferSuccess(null));
      if (deleteRenewalTransferSuccess !== null)
        dispatch(setDeleteRenewalTransferSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createRenewalTransferSuccess,
    updateRenewalTransferSuccess,
    deleteRenewalTransferSuccess,
  ]);

  useEffect(() => {
    if (
      fetchRenewalTransfersFailed !== null ||
      fetchRenewalTransferFailed !== null ||
      createRenewalTransferFailed !== null ||
      updateRenewalTransferFailed !== null ||
      deleteRenewalTransferFailed !== null
    ) {
      showErrorMessage(
        fetchRenewalTransfersFailed ??
          fetchRenewalTransferFailed ??
          createRenewalTransferFailed ??
          updateRenewalTransferFailed ??
          deleteRenewalTransferFailed
      );

      if (fetchRenewalTransfersFailed !== null)
        dispatch(setFetchRenewalTransfersFailed(null));
      if (fetchRenewalTransferFailed !== null)
        dispatch(setFetchRenewalTransferFailed(null));
      if (createRenewalTransferFailed !== null)
        dispatch(setCreateRenewalTransferFailed(null));
      if (updateRenewalTransferFailed !== null)
        dispatch(setUpdateRenewalTransferFailed(null));
      if (deleteRenewalTransferFailed !== null)
        dispatch(setDeleteRenewalTransferFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalTransfersFailed,
    fetchRenewalTransferFailed,
    createRenewalTransferFailed,
    updateRenewalTransferFailed,
    deleteRenewalTransferFailed,
  ]);

  return <></>;
};

export default RenewalTransferPopup;
