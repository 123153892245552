import { createSelector } from "reselect";

const renewalTransferSelector = ({ renewalTransfer }) => renewalTransfer;

export const getRenewalTransfers = createSelector(
  [renewalTransferSelector],
  ({ renewalTransfers }) => renewalTransfers
);
export const getRenewalTransfer = createSelector(
  [renewalTransferSelector],
  ({ renewalTransfer }) => renewalTransfer
);

export const getIsRenewalTransfersHasMore = createSelector(
  [renewalTransferSelector],
  ({ isRenewalTransfersHasMore }) => isRenewalTransfersHasMore
);

export const getFetchRenewalTransfersParams = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransfersParams }) => fetchRenewalTransfersParams
);
export const getFetchRenewalTransfersLoading = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransfersLoading }) => fetchRenewalTransfersLoading
);
export const getFetchRenewalTransfersSuccess = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransfersSuccess }) => fetchRenewalTransfersSuccess
);
export const getFetchRenewalTransfersFailed = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransfersFailed }) => fetchRenewalTransfersFailed
);

export const getFetchRenewalTransferParams = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransferParams }) => fetchRenewalTransferParams
);
export const getFetchRenewalTransferLoading = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransferLoading }) => fetchRenewalTransferLoading
);
export const getFetchRenewalTransferSuccess = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransferSuccess }) => fetchRenewalTransferSuccess
);
export const getFetchRenewalTransferFailed = createSelector(
  [renewalTransferSelector],
  ({ fetchRenewalTransferFailed }) => fetchRenewalTransferFailed
);

export const getCreateRenewalTransferLoading = createSelector(
  [renewalTransferSelector],
  ({ createRenewalTransferLoading }) => createRenewalTransferLoading
);
export const getCreateRenewalTransferSuccess = createSelector(
  [renewalTransferSelector],
  ({ createRenewalTransferSuccess }) => createRenewalTransferSuccess
);
export const getCreateRenewalTransferFailed = createSelector(
  [renewalTransferSelector],
  ({ createRenewalTransferFailed }) => createRenewalTransferFailed
);

export const getUpdateRenewalTransferLoading = createSelector(
  [renewalTransferSelector],
  ({ updateRenewalTransferLoading }) => updateRenewalTransferLoading
);
export const getUpdateRenewalTransferSuccess = createSelector(
  [renewalTransferSelector],
  ({ updateRenewalTransferSuccess }) => updateRenewalTransferSuccess
);
export const getUpdateRenewalTransferFailed = createSelector(
  [renewalTransferSelector],
  ({ updateRenewalTransferFailed }) => updateRenewalTransferFailed
);

export const getDeleteRenewalTransferLoading = createSelector(
  [renewalTransferSelector],
  ({ deleteRenewalTransferLoading }) => deleteRenewalTransferLoading
);
export const getDeleteRenewalTransferSuccess = createSelector(
  [renewalTransferSelector],
  ({ deleteRenewalTransferSuccess }) => deleteRenewalTransferSuccess
);
export const getDeleteRenewalTransferFailed = createSelector(
  [renewalTransferSelector],
  ({ deleteRenewalTransferFailed }) => deleteRenewalTransferFailed
);

export const getIsFetchRenewalTransfersHitted = createSelector(
  [renewalTransferSelector],
  ({ isFetchRenewalTransfersHitted }) => isFetchRenewalTransfersHitted
);
export const getIsFetchRenewalTransferHitted = createSelector(
  [renewalTransferSelector],
  ({ isFetchRenewalTransferHitted }) => isFetchRenewalTransferHitted
);
export const getIsCreateRenewalTransferHitted = createSelector(
  [renewalTransferSelector],
  ({ isCreateRenewalTransferHitted }) => isCreateRenewalTransferHitted
);
export const getIsUpdateRenewalTransferHitted = createSelector(
  [renewalTransferSelector],
  ({ isUpdateRenewalTransferHitted }) => isUpdateRenewalTransferHitted
);
export const getIsDeleteRenewalTransferHitted = createSelector(
  [renewalTransferSelector],
  ({ isDeleteRenewalTransferHitted }) => isDeleteRenewalTransferHitted
);
