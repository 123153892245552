import { createSelector } from "reselect";

const exportExcelSelector = ({ exportExcel }) => exportExcel;

export const getExportExcelReports = createSelector(
  [exportExcelSelector],
  ({ exportExcelReports }) => exportExcelReports
);
export const getExportExcelPurchase = createSelector(
  [exportExcelSelector],
  ({ exportExcelPurchase }) => exportExcelPurchase
);

export const getExportExcelReportsLoading = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsLoading }) => exportExcelReportsLoading
);
export const getExportExcelReportsSuccess = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsSuccess }) => exportExcelReportsSuccess
);
export const getExportExcelReportsFailed = createSelector(
  [exportExcelSelector],
  ({ exportExcelReportsFailed }) => exportExcelReportsFailed
);

export const getExportExcelPurchaseLoading = createSelector(
  [exportExcelSelector],
  ({ exportExcelPurchaseLoading }) => exportExcelPurchaseLoading
);
export const getExportExcelPurchaseSuccess = createSelector(
  [exportExcelSelector],
  ({ exportExcelPurchaseSuccess }) => exportExcelPurchaseSuccess
);
export const getExportExcelPurchaseFailed = createSelector(
  [exportExcelSelector],
  ({ exportExcelPurchaseFailed }) => exportExcelPurchaseFailed
);

export const getIsExportExcelReportsHitted = createSelector(
  [exportExcelSelector],
  ({ isExportExcelReportsHitted }) => isExportExcelReportsHitted
);
export const getIsExportExcelPurchaseHitted = createSelector(
  [exportExcelSelector],
  ({ isExportExcelPurchaseHitted }) => isExportExcelPurchaseHitted
);
