import RENEWAL_TRANSFER_ACTION_TYPES from "./renewal-transfer.type";
import { createAction } from "../../utils/store.utils";

export const setRenewalTransfers = (renewalTransfers) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_RENEWAL_TRANSFERS,
    renewalTransfers
  );
export const setRenewalTransfer = (renewalTransfer) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_RENEWAL_TRANSFER,
    renewalTransfer
  );

export const setIsRenewalTransfersHasMore = (isRenewalTransfersHasMore) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_RENEWAL_TRANSFERS_HAS_MORE,
    isRenewalTransfersHasMore
  );

export const setFetchRenewalTransfersParams = (fetchRenewalTransfersParams) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_PARAMS,
    fetchRenewalTransfersParams
  );
export const setFetchRenewalTransfersLoading = (fetchRenewalTransfersLoading) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_LOADING,
    fetchRenewalTransfersLoading
  );
export const setFetchRenewalTransfersSuccess = (fetchRenewalTransfersSuccess) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_SUCCESS,
    fetchRenewalTransfersSuccess
  );
export const setFetchRenewalTransfersFailed = (fetchRenewalTransfersFailed) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFERS_FAILED,
    fetchRenewalTransfersFailed
  );

export const setFetchRenewalTransferParams = (fetchRenewalTransferParams) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_PARAMS,
    fetchRenewalTransferParams
  );
export const setFetchRenewalTransferLoading = (fetchRenewalTransferLoading) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_LOADING,
    fetchRenewalTransferLoading
  );
export const setFetchRenewalTransferSuccess = (fetchRenewalTransferSuccess) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_SUCCESS,
    fetchRenewalTransferSuccess
  );
export const setFetchRenewalTransferFailed = (fetchRenewalTransferFailed) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_FETCH_RENEWAL_TRANSFER_FAILED,
    fetchRenewalTransferFailed
  );

export const setCreateRenewalTransferLoading = (createRenewalTransferLoading) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_LOADING,
    createRenewalTransferLoading
  );
export const setCreateRenewalTransferSuccess = (createRenewalTransferSuccess) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_SUCCESS,
    createRenewalTransferSuccess
  );
export const setCreateRenewalTransferFailed = (createRenewalTransferFailed) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_CREATE_RENEWAL_TRANSFER_FAILED,
    createRenewalTransferFailed
  );

export const setUpdateRenewalTransferLoading = (updateRenewalTransferLoading) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_LOADING,
    updateRenewalTransferLoading
  );
export const setUpdateRenewalTransferSuccess = (updateRenewalTransferSuccess) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_SUCCESS,
    updateRenewalTransferSuccess
  );
export const setUpdateRenewalTransferFailed = (updateRenewalTransferFailed) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_UPDATE_RENEWAL_TRANSFER_FAILED,
    updateRenewalTransferFailed
  );

export const setDeleteRenewalTransferLoading = (deleteRenewalTransferLoading) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_LOADING,
    deleteRenewalTransferLoading
  );
export const setDeleteRenewalTransferSuccess = (deleteRenewalTransferSuccess) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_SUCCESS,
    deleteRenewalTransferSuccess
  );
export const setDeleteRenewalTransferFailed = (deleteRenewalTransferFailed) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_DELETE_RENEWAL_TRANSFER_FAILED,
    deleteRenewalTransferFailed
  );

export const appendRenewalTransfers = (renewalTransfers) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.APPEND_RENEWAL_TRANSFERS,
    renewalTransfers
  );

export const setIsFetchRenewalTransfersHitted = (
  isFetchRenewalTransfersHitted
) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_TRANSFERS_HITTED,
    isFetchRenewalTransfersHitted
  );
export const setIsFetchRenewalTransferHitted = (isFetchRenewalTransferHitted) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_FETCH_RENEWAL_TRANSFER_HITTED,
    isFetchRenewalTransferHitted
  );
export const setIsCreateRenewalTransferHitted = (
  isCreateRenewalTransferHitted
) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_CREATE_RENEWAL_TRANSFER_HITTED,
    isCreateRenewalTransferHitted
  );
export const setIsUpdateRenewalTransferHitted = (
  isUpdateRenewalTransferHitted
) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_UPDATE_RENEWAL_TRANSFER_HITTED,
    isUpdateRenewalTransferHitted
  );
export const setIsDeleteRenewalTransferHitted = (
  isDeleteRenewalTransferHitted
) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.SET_IS_DELETE_RENEWAL_TRANSFER_HITTED,
    isDeleteRenewalTransferHitted
  );

export const fetchRenewalTransfersStart = () =>
  createAction(RENEWAL_TRANSFER_ACTION_TYPES.FETCH_RENEWAL_TRANSFERS_START);
export const fetchRenewalTransferStart = (transferId) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.FETCH_RENEWAL_TRANSFER_START,
    transferId
  );
export const createRenewalTransferStart = (request) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.CREATE_RENEWAL_TRANSFER_START,
    request
  );
export const updateRenewalTransferStart = (transferId, request) =>
  createAction(RENEWAL_TRANSFER_ACTION_TYPES.UPDATE_RENEWAL_TRANSFER_START, {
    transferId,
    request,
  });
export const deleteRenewalTransferStart = (transferId) =>
  createAction(
    RENEWAL_TRANSFER_ACTION_TYPES.DELETE_RENEWAL_TRANSFER_START,
    transferId
  );

export const resetRenewalTransferReducer = () =>
  createAction(RENEWAL_TRANSFER_ACTION_TYPES.RESET_RENEWAL_TRANSFER_REDUCER);
