import styled from "styled-components";

import { COLORS, FORM_INPUT_STYLES } from "../../utils/styles.utils";

export const FormDateTimeContainer = styled.div`
  position: relative;

  .rdt {
    .form-control {
      cursor: pointer;

      height: 3rem;
      width: 100%;
      padding-left: 1.5rem;
      padding-right: 1.5rem;

      background-color: ${FORM_INPUT_STYLES.INPUT_BACKGROUND_COLOR};
      border-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
      border-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
      border-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
      border-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
      font-size: ${FORM_INPUT_STYLES.INPUT_FONT_SIZE};
      font-weight: ${FORM_INPUT_STYLES.INPUT_FONT_WEIGHT};

      &:disabled {
        background-color: ${FORM_INPUT_STYLES.INPUT_SOLID_BG_COLOR};
      }

      &:focus {
        border-color: ${FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR};
      }

      &::placeholder {
        color: #a3a3a3;
        font-weight: ${FORM_INPUT_STYLES.INPUT_PLACEHOLDER_FONT_WEIGHT};
      }
    }

    .rdtPicker {
      padding: 0.5rem;
      border-radius: 4px;
      box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
        0 4px 11px hsla(0, 0%, 0%, 0.1);
      border: none;
      margin: 8px 0;
    }

    .rdtPicker {
      td,
      th {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    .rdtPicker {
      td {
        &.rdtToday::before {
          border-bottom: 7px solid ${COLORS.PRIMARY};
        }
        &.rdtActive {
          background-color: ${COLORS.PRIMARY};

          &:hover {
            background-color: ${COLORS.PRIMARY};
          }
        }
      }
      th.rdtSwitch {
        white-space: nowrap;
      }
    }

    .rdtCounters {
      display: flex;
      justify-content: center;
    }

    .rdtCounter {
      .rdtCount {
        line-height: 1;
      }
    }
  }
`;
