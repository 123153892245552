import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage } from "../../utils/popup.utils";

import { setFetchCategoriesFailed } from "../../store/order-form-category/order-form-category.action";
import { getFetchCategoriesFailed } from "../../store/order-form-category/order-form-category.selector";

const OrderFormCategoryPopup = () => {
  const dispatch = useDispatch();

  const fetchCategoriesFailed = useSelector(getFetchCategoriesFailed);

  useEffect(() => {
    if (fetchCategoriesFailed !== null) {
      showErrorMessage(fetchCategoriesFailed);
      dispatch(setFetchCategoriesFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCategoriesFailed]);

  return <></>;
};

export default OrderFormCategoryPopup;
