const RENEWAL_SUBSCRIBER_ACTION_TYPES = {
  RESET_RENEWAL_SUBSCRIBER_REDUCER:
    "renewal-subscriber/RESET_RENEWAL_SUBSCRIBER_REDUCER",

  SET_RENEWALS: "renewal-subscriber/SET_RENEWALS",
  SET_RENEWAL: "renewal-subscriber/SET_RENEWAL",
  SET_RENEWAL_BRANCHES: "renewal-subscriber/SET_RENEWAL_BRANCHES",
  SET_RENEWAL_CREATE: "renewal-subscriber/SET_CREATE_RENEWAL",
  SET_RENEWAL_CREATE_CALCULATE:
    "renewal-subscriber/SET_CREATE_CALCULATE_RENEWAL",

  APPEND_RENEWALS: "renewal-subscriber/APPEND_RENEWALS",
  APPEND_RENEWAL_BRANCHES: "renewal-subscriber/APPEND_RENEWAL_BRANCHES",

  SET_IS_RENEWALS_HAS_MORE: "renewal-subscriber/SET_IS_RENEWALS_HAS_MORE",
  SET_IS_RENEWAL_BRANCHES_HAS_MORE:
    "renewal-subscriber/SET_IS_RENEWAL_BRANCHES_HAS_MORE",

  SET_FETCH_RENEWALS_SEARCH: "renewal-subscriber/SET_FETCH_RENEWALS_SEARCH",
  SET_FETCH_RENEWALS_SORT: "renewal-subscriber/SET_FETCH_RENEWALS_SORT",
  SET_FETCH_RENEWALS_KEY_BY: "renewal-subscriber/SET_FETCH_RENEWALS_KEY_BY",
  SET_FETCH_RENEWALS_PAGE: "renewal-subscriber/SET_FETCH_RENEWALS_PAGE",
  SET_FETCH_RENEWALS_PER_PAGE: "renewal-subscriber/SET_FETCH_RENEWALS_PER_PAGE",
  SET_FETCH_RENEWALS_INCLUDES: "renewal-subscriber/SET_FETCH_RENEWALS_INCLUDES",
  SET_FETCH_RENEWALS_FILTER_MARKET_ID:
    "renewal-subscriber/SET_FETCH_RENEWALS_FILTER_MARKET_ID",
  SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS:
    "renewal-subscriber/SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS",
  SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS:
    "renewal-subscriber/SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS",
  SET_FETCH_RENEWALS_FILTER_STATUSES:
    "renewal-subscriber/SET_FETCH_RENEWALS_FILTER_STATUSES",
  SET_FETCH_RENEWALS_FILTER_CREATED_BY:
    "renewal-subscriber/SET_FETCH_RENEWALS_FILTER_CREATED_BY",
  SET_FETCH_RENEWALS_LOADING: "renewal-subscriber/SET_FETCH_RENEWALS_LOADING",
  SET_FETCH_RENEWALS_SUCCESS: "renewal-subscriber/SET_FETCH_RENEWALS_SUCCESS",
  SET_FETCH_RENEWALS_FAILED: "renewal-subscriber/SET_FETCH_RENEWALS_FAILED",

  SET_FETCH_RENEWAL_LOADING: "renewal-subscriber/SET_FETCH_RENEWAL_LOADING",
  SET_FETCH_RENEWAL_SUCCESS: "renewal-subscriber/SET_FETCH_RENEWAL_SUCCESS",
  SET_FETCH_RENEWAL_FAILED: "renewal-subscriber/SET_FETCH_RENEWAL_FAILED",

  SET_FETCH_RENEWAL_BRANCHES_PARAMS:
    "renewal-subscriber/SET_FETCH_RENEWAL_BRANCHES_PARAMS",
  SET_FETCH_RENEWAL_BRANCHES_LOADING:
    "renewal-subscriber/SET_FETCH_RENEWAL_BRANCHES_LOADING",
  SET_FETCH_RENEWAL_BRANCHES_SUCCESS:
    "renewal-subscriber/SET_FETCH_RENEWAL_BRANCHES_SUCCESS",
  SET_FETCH_RENEWAL_BRANCHES_FAILED:
    "renewal-subscriber/SET_FETCH_RENEWAL_BRANCHES_FAILED",

  SET_CREATE_RENEWAL_LOADING: "renewal-subscriber/SET_CREATE_RENEWAL_LOADING",
  SET_CREATE_RENEWAL_SUCCESS: "renewal-subscriber/SET_CREATE_RENEWAL_SUCCESS",
  SET_CREATE_RENEWAL_FAILED: "renewal-subscriber/SET_CREATE_RENEWAL_FAILED",

  SET_CREATE_CALCULATE_RENEWAL_LOADING:
    "renewal-subscriber/SET_CREATE_CALCULATE_RENEWAL_LOADING",
  SET_CREATE_CALCULATE_RENEWAL_SUCCESS:
    "renewal-subscriber/SET_CREATE_CALCULATE_RENEWAL_SUCCESS",
  SET_CREATE_CALCULATE_RENEWAL_FAILED:
    "renewal-subscriber/SET_CREATE_CALCULATE_RENEWAL_FAILED",

  SET_IS_FETCH_RENEWALS_HITTED:
    "renewal-subscriber/SET_IS_FETCH_RENEWALS_HITTED",
  SET_IS_FETCH_RENEWAL_HITTED: "renewal-subscriber/SET_IS_FETCH_RENEWAL_HITTED",
  SET_IS_FETCH_RENEWAL_BRANCHES_HITTED:
    "renewal-subscriber/SET_IS_FETCH_RENEWAL_BRANCHES_HITTED",
  SET_IS_CREATE_RENEWAL_HITTED:
    "renewal-subscriber/SET_IS_CREATE_RENEWAL_HITTED",
  SET_IS_CREATE_CALCULATE_RENEWAL_HITTED:
    "renewal-subscriber/SET_IS_CREATE_CALCULATE_RENEWAL_HITTED",

  FETCH_RENEWALS_START: "renewal-subscriber/FETCH_RENEWALS_START",
  FETCH_RENEWAL_START: "renewal-subscriber/FETCH_RENEWAL_START",
  FETCH_RENEWAL_BRANCHES_START:
    "renewal-subscriber/FETCH_RENEWAL_BRANCHES_START",
  CREATE_RENEWAL_START: "renewal-subscriber/CREATE_RENEWAL_START",
  CREATE_CALCULATE_RENEWAL_START:
    "renewal-subscriber/CREATE_CALCULATE_RENEWAL_START",
};

export default RENEWAL_SUBSCRIBER_ACTION_TYPES;
