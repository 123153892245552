import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateCalculateRenewalFailed,
  setCreateCalculateRenewalSuccess,
  setCreateRenewalFailed,
  setCreateRenewalSuccess,
  setCustomCalculateRenewalFailed,
  setCustomCalculateRenewalSuccess,
  setCustomRenewalFailed,
  setCustomRenewalSuccess,
  setFetchRenewalBranchesFailed,
  setFetchRenewalBranchesSuccess,
  setFetchRenewalFailed,
  setFetchRenewalMarketFailed,
  setFetchRenewalMarketSuccess,
  setFetchRenewalsFailed,
  setFetchRenewalsSuccess,
  setFetchRenewalSuccess,
  setUpdateCalculateRenewalFailed,
  setUpdateCalculateRenewalSuccess,
  setUpdateRenewalFailed,
  setUpdateRenewalSuccess,
} from "../../store/renewal-admin/renewal-admin.action";
import {
  getCreateCalculateRenewalFailed,
  getCreateCalculateRenewalSuccess,
  getCreateRenewalFailed,
  getCreateRenewalSuccess,
  getCustomCalculateRenewalFailed,
  getCustomCalculateRenewalSuccess,
  getCustomRenewalFailed,
  getCustomRenewalSuccess,
  getFetchRenewalBranchesFailed,
  getFetchRenewalBranchesSuccess,
  getFetchRenewalFailed,
  getFetchRenewalMarketFailed,
  getFetchRenewalMarketSuccess,
  getFetchRenewalsFailed,
  getFetchRenewalsSuccess,
  getFetchRenewalSuccess,
  getUpdateCalculateRenewalFailed,
  getUpdateCalculateRenewalSuccess,
  getUpdateRenewalFailed,
  getUpdateRenewalSuccess,
} from "../../store/renewal-admin/renewal-admin.selector";

const RenewalAdminPopup = () => {
  const dispatch = useDispatch();

  const fetchRenewalsSuccess = useSelector(getFetchRenewalsSuccess);
  const fetchRenewalSuccess = useSelector(getFetchRenewalSuccess);
  const fetchRenewalMarketSuccess = useSelector(getFetchRenewalMarketSuccess);
  const fetchRenewalBranchesSuccess = useSelector(
    getFetchRenewalBranchesSuccess
  );
  const createRenewalSuccess = useSelector(getCreateRenewalSuccess);
  const createCalculateRenewalSuccess = useSelector(
    getCreateCalculateRenewalSuccess
  );
  const customRenewalSuccess = useSelector(getCustomRenewalSuccess);
  const customCalculateRenewalSuccess = useSelector(
    getCustomCalculateRenewalSuccess
  );
  const updateRenewalSuccess = useSelector(getUpdateRenewalSuccess);
  const updateCalculateRenewalSuccess = useSelector(
    getUpdateCalculateRenewalSuccess
  );

  const fetchRenewalsFailed = useSelector(getFetchRenewalsFailed);
  const fetchRenewalFailed = useSelector(getFetchRenewalFailed);
  const fetchRenewalMarketFailed = useSelector(getFetchRenewalMarketFailed);
  const fetchRenewalBranchesFailed = useSelector(getFetchRenewalBranchesFailed);
  const createRenewalFailed = useSelector(getCreateRenewalFailed);
  const createCalculateRenewalFailed = useSelector(
    getCreateCalculateRenewalFailed
  );
  const customRenewalFailed = useSelector(getCustomRenewalFailed);
  const customCalculateRenewalFailed = useSelector(
    getCustomCalculateRenewalFailed
  );
  const updateRenewalFailed = useSelector(getUpdateRenewalFailed);
  const updateCalculateRenewalFailed = useSelector(
    getUpdateCalculateRenewalFailed
  );

  useEffect(() => {
    if (
      fetchRenewalsSuccess !== null ||
      fetchRenewalSuccess !== null ||
      fetchRenewalMarketSuccess !== null ||
      fetchRenewalBranchesSuccess !== null ||
      createRenewalSuccess !== null ||
      createCalculateRenewalSuccess !== null ||
      customRenewalSuccess !== null ||
      customCalculateRenewalSuccess !== null ||
      updateRenewalSuccess !== null ||
      updateCalculateRenewalSuccess !== null
    ) {
      if (
        createRenewalSuccess !== null ||
        customRenewalSuccess !== null ||
        updateRenewalSuccess !== null
      ) {
        showSuccessMessage(
          createRenewalSuccess ?? customRenewalSuccess ?? updateRenewalSuccess
        );
        dispatch(setIsModalOpen(false));
      }

      if (fetchRenewalsSuccess !== null)
        dispatch(setFetchRenewalsSuccess(null));
      if (fetchRenewalSuccess !== null) dispatch(setFetchRenewalSuccess(null));
      if (fetchRenewalMarketSuccess !== null)
        dispatch(setFetchRenewalMarketSuccess(null));
      if (fetchRenewalBranchesSuccess !== null)
        dispatch(setFetchRenewalBranchesSuccess(null));
      if (createRenewalSuccess !== null)
        dispatch(setCreateRenewalSuccess(null));
      if (createCalculateRenewalSuccess !== null)
        dispatch(setCreateCalculateRenewalSuccess(null));
      if (customRenewalSuccess !== null)
        dispatch(setCustomRenewalSuccess(null));
      if (customCalculateRenewalSuccess !== null)
        dispatch(setCustomCalculateRenewalSuccess(null));
      if (updateRenewalSuccess !== null)
        dispatch(setUpdateRenewalSuccess(null));
      if (updateCalculateRenewalSuccess !== null)
        dispatch(setUpdateCalculateRenewalSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalsSuccess,
    fetchRenewalSuccess,
    fetchRenewalMarketSuccess,
    fetchRenewalBranchesSuccess,
    createRenewalSuccess,
    createCalculateRenewalSuccess,
    customRenewalSuccess,
    customCalculateRenewalSuccess,
    updateRenewalSuccess,
    updateCalculateRenewalSuccess,
  ]);

  useEffect(() => {
    if (
      fetchRenewalsFailed !== null ||
      fetchRenewalFailed !== null ||
      fetchRenewalMarketFailed !== null ||
      fetchRenewalBranchesFailed !== null ||
      createRenewalFailed !== null ||
      createCalculateRenewalFailed !== null ||
      customRenewalFailed !== null ||
      customCalculateRenewalFailed !== null ||
      updateRenewalFailed !== null ||
      updateCalculateRenewalFailed !== null
    ) {
      showErrorMessage(
        fetchRenewalsFailed ??
          fetchRenewalFailed ??
          fetchRenewalMarketFailed ??
          fetchRenewalBranchesFailed ??
          createRenewalFailed ??
          createCalculateRenewalFailed ??
          customRenewalFailed ??
          customCalculateRenewalFailed ??
          updateRenewalFailed ??
          updateCalculateRenewalFailed
      );

      if (fetchRenewalsFailed !== null) dispatch(setFetchRenewalsFailed(null));
      if (fetchRenewalFailed !== null) dispatch(setFetchRenewalFailed(null));
      if (fetchRenewalMarketFailed !== null)
        dispatch(setFetchRenewalMarketFailed(null));
      if (fetchRenewalBranchesFailed !== null)
        dispatch(setFetchRenewalBranchesFailed(null));
      if (createRenewalFailed !== null) dispatch(setCreateRenewalFailed(null));
      if (createCalculateRenewalFailed !== null)
        dispatch(setCreateCalculateRenewalFailed(null));
      if (customRenewalFailed !== null) dispatch(setCustomRenewalFailed(null));
      if (customCalculateRenewalFailed !== null)
        dispatch(setCustomCalculateRenewalFailed(null));
      if (updateRenewalFailed !== null) dispatch(setUpdateRenewalFailed(null));
      if (updateCalculateRenewalFailed !== null)
        dispatch(setUpdateCalculateRenewalFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalsFailed,
    fetchRenewalFailed,
    fetchRenewalMarketFailed,
    fetchRenewalBranchesFailed,
    createRenewalFailed,
    createCalculateRenewalFailed,
    customRenewalFailed,
    customCalculateRenewalFailed,
    updateRenewalFailed,
    updateCalculateRenewalFailed,
  ]);

  return <></>;
};

export default RenewalAdminPopup;
