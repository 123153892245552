import axios from "axios";
import i18next from "i18next";

import { BASE_API_URL } from "../config";

import { getAuthToken, getIpAddress } from "../utils/storage.utils";
import { isEmpty } from "../utils/validation.utils";

export const REQUEST_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const defaultOptions = {
  baseUrl: BASE_API_URL,
  headers: () => ({
    "Accept-Language": i18next.language,
    Authorization: getAuthToken() ? `Bearer ${getAuthToken()}` : undefined,
    Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    IpAddress: getIpAddress(),
    webhook: true,
    debug: true,
    app:
      process.env.REACT_APP_NODE_ENV === "production"
        ? "flavours.pos"
        : "flavours.dev",
  }),
  error: {
    meta: {
      code: 503,
      status: "error",
      message:
        "Something went wrong. Please check your internet connection or contact our support.",
    },
    data: null,
  },
};

const isFileExists = (inputValues = {}) => {
  const isContainsFile = (value) => {
    const isArray = Array.isArray(value);
    const isFile = value instanceof File;
    const isObject =
      typeof value === "object" && !isArray && value !== null && !isFile;

    if (isFile) return true;
    if (isArray || isObject) {
      if (isObject) value = Object.values(value);
      for (let index in value) {
        if (isContainsFile(value?.[index])) return true;
      }
    }

    return false;
  };

  const values = Object.values(inputValues);
  for (let index in values) {
    if (isContainsFile(values?.[index])) return true;
  }

  return false;
};

const getContentType = (request) => ({
  "Content-Type": isFileExists(request)
    ? "multipart/form-data"
    : true
    ? "application/json"
    : "application/x-www-form-urlencoded",
});

const httpRequest = ({
  responseType = "json",
  headers,
  url,
  method,
  request,
  params,
}) => {
  const startTime = performance.now();

  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      headers: !isEmpty(headers)
        ? headers
        : {
            ...defaultOptions.headers(),
            ...getContentType(request),
          },
      params:
        method === REQUEST_METHODS.GET
          ? !isEmpty(request)
            ? request
            : params
          : params,
      data: method !== REQUEST_METHODS.GET ? request : undefined,
      responseType: !isEmpty(responseType) ? responseType : "json",
    })
      .then((response) => {
        if (
          process.env.REACT_APP_NODE_ENV === "local" ||
          process.env.REACT_APP_NODE_ENV === "development"
        ) {
          console.log(
            url,
            `Execution time: ${performance.now() - startTime} milliseconds`
          );
        }
        resolve(response);
      })
      .catch((error) => {
        if (
          process.env.REACT_APP_NODE_ENV === "local" ||
          process.env.REACT_APP_NODE_ENV === "development"
        ) {
          console.log(
            url,
            `Execution time: ${performance.now() - startTime} milliseconds`
          );
          // console.log(error?.response ?? defaultOptions.error);
        }
        reject(
          error?.response?.data instanceof Blob
            ? { meta: { message: error?.response?.statusText } }
            : error?.response?.data ?? defaultOptions.error
        );
      });
  });
};

const innerHttpRequest = ({
  responseType = "json",
  headers,
  method,
  urlPath,
  request,
  params,
}) =>
  httpRequest({
    responseType,
    headers,
    method,
    url: `${defaultOptions.baseUrl}${urlPath}`,
    request,
    params,
  });
const outerHttpRequest = ({
  responseType = "json",
  headers,
  method,
  url,
  request,
  params,
}) =>
  httpRequest({
    responseType,
    headers,
    url,
    method,
    request,
    params,
  });

const apiService = {
  innerGet: (args) =>
    innerHttpRequest({ method: REQUEST_METHODS.GET, ...args }),
  innerPost: (args) =>
    innerHttpRequest({ method: REQUEST_METHODS.POST, ...args }),
  innerPut: (args) =>
    innerHttpRequest({ method: REQUEST_METHODS.PUT, ...args }),
  innerPatch: (args) =>
    innerHttpRequest({ method: REQUEST_METHODS.PATCH, ...args }),
  innerDelete: (args) =>
    innerHttpRequest({ method: REQUEST_METHODS.DELETE, ...args }),

  outerGet: (args) =>
    outerHttpRequest({ method: REQUEST_METHODS.GET, ...args }),
  outerPost: (args) =>
    outerHttpRequest({ method: REQUEST_METHODS.POST, ...args }),
  outerPut: (args) =>
    outerHttpRequest({ method: REQUEST_METHODS.PUT, ...args }),
  outerPatch: (args) =>
    outerHttpRequest({ method: REQUEST_METHODS.PATCH, ...args }),
  outerDelete: (args) =>
    outerHttpRequest({ method: REQUEST_METHODS.DELETE, ...args }),

  // externalBlobGet: (...args) => externalBlobHttpRequest(REQUEST_METHODS.GET, ...args),
  // blobGet: (...args) => internalBlobHttpRequest(REQUEST_METHODS.GET, ...args),
};

export default apiService;
