import styled from "styled-components";

import { FORM_INPUT_STYLES } from "../../utils/styles.utils";

import { FORM_SELECT_ASYNC_SIZES } from "./form-select-async.component";

export const getSelectFontStyles = (selectSize) => {
  return {
    color: FORM_INPUT_STYLES.INPUT_COLOR,
    fontWeight: FORM_INPUT_STYLES.INPUT_FONT_WEIGHT,
    fontSize: (() => {
      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "0.925rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "0.75rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "0.875rem";
      }
    })(),
  };
};

export const formSelectAsyncStyles = ({
  additionalStyles,
  selectSize,
  isRightToLeft,
}) => ({
  container: (styles) => ({ ...styles, userSelect: "none" }),
  menu: (styles) => ({ ...styles, zIndex: 10 }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: 0,
    paddingRight: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    fontWeight: FORM_INPUT_STYLES.INPUT_PLACEHOLDER_FONT_WEIGHT,
    color: "#a3a3a3",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginLeft: 0,
    marginRight: 0,
  }),
  multiValue: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    alignItems: "center",
    backgroundColor: "#f2f2f4",
    padding: "0.5rem 0.75rem",
    borderRadius: "8px",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    padding: "0 !important",
    marginLeft: isRightToLeft ? "0.5rem" : 0,
    marginRight: !isRightToLeft ? "0.5rem" : 0,
  }),
  multiValueRemove: (styles) => ({
    ...styles,

    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",

    color: "#0e072f",
    backgroundColor: "#d9d9db",
    borderRadius: "50%",
    transition: "0.3s ease all",

    width: "1.25rem",
    minWidth: "1.25rem",
    maxWidth: "1.25rem",
    height: "1.25rem",
    minHeight: "1.25rem",
    maxHeight: "1.25rem",

    svg: {
      minWidth: "0.75rem",
      maxWidth: "0.75rem",
      height: "0.75rem",
      minHeight: "0.75rem",
      maxHeight: "0.75rem",
    },

    ":hover": {
      color: "#0e072f",
      backgroundColor: "#c1c1c3",
    },
  }),
  indicatorsContainer: (styles) => ({
    ...styles,

    marginLeft: isRightToLeft ? "0.35rem" : 0,
    marginRight: isRightToLeft ? 0 : "0.35rem",
    svg: {
      width: (() => {
        switch (selectSize) {
          case FORM_SELECT_ASYNC_SIZES.LARGE:
            return "1.5rem";
          case FORM_SELECT_ASYNC_SIZES.SMALL:
            return "1rem";
          case FORM_SELECT_ASYNC_SIZES.NORMAL:
          default:
            return "1.25rem";
        }
      })(),
      height: (() => {
        switch (selectSize) {
          case FORM_SELECT_ASYNC_SIZES.LARGE:
            return "1.5rem";
          case FORM_SELECT_ASYNC_SIZES.SMALL:
            return "1rem";
          case FORM_SELECT_ASYNC_SIZES.NORMAL:
          default:
            return "1.25rem";
        }
      })(),
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,

    marginLeft: isRightToLeft ? "0.35rem" : 0,
    marginRight: isRightToLeft ? 0 : "0.35rem",
  }),
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    cursor: "pointer",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    backgroundColor: isDisabled ? "#f9f9f9" : "#ffffff",
    minHeight: (() => {
      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "3.5rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "2.5rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "3rem";
      }
    })(),
    paddingLeft: (() => {
      if (isRightToLeft) return 0;

      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "1.5rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "1rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "1.5rem";
      }
    })(),
    paddingRight: (() => {
      if (!isRightToLeft) return 0;

      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "1.5rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "1rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "1.5rem";
      }
    })(),
    borderRadius: (() => {
      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "1rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "0.75rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "1rem";
      }
    })(),
    borderWidth: FORM_INPUT_STYLES.INPUT_BORDER_WIDTH,
    borderStyle: FORM_INPUT_STYLES.INPUT_BORDER_STYLE,
    borderColor: isFocused
      ? FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR
      : FORM_INPUT_STYLES.INPUT_BORDER_COLOR,
    boxShadow: "0 !important",
    "&:hover": {
      borderColor: isFocused
        ? FORM_INPUT_STYLES.INPUT_FOCUS_BORDER_COLOR
        : FORM_INPUT_STYLES.INPUT_BORDER_COLOR,
    },
  }),
  option: (styles, { isSelected, isDisabled }) => ({
    ...styles,
    ...getSelectFontStyles(selectSize),

    cursor: "pointer",
    padding: (() => {
      switch (selectSize) {
        case FORM_SELECT_ASYNC_SIZES.LARGE:
          return "0.75rem 1rem";
        case FORM_SELECT_ASYNC_SIZES.SMALL:
          return "0.5rem 0.75rem";
        case FORM_SELECT_ASYNC_SIZES.NORMAL:
        default:
          return "0.65rem 0.875rem";
      }
    })(),
    color: isSelected
      ? "#ffffff !important"
      : isDisabled
      ? "#9691ac !important"
      : "#0e072f",
    backgroundColor: isSelected
      ? "#2900ee !important"
      : isDisabled
      ? "#f2f2f4 !important"
      : "#ffffff",

    "&:active": {
      color: "#ffffff",
      backgroundColor: "#2900ee",
    },
    "&:hover": {
      color: "#0e072f",
      backgroundColor: "#f9f9f9",
    },
  }),
  ...additionalStyles,
});

export const FormSelectAsyncContainer = styled.div`
  position: relative;
`;

export const FormSelectAsyncMenu = styled.div`
  top: 100%;
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
`;
