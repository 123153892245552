const STATE_RESUPPLY_ACTION_TYPES = {
  RESET_STATE_RESUPPLY_REDUCER: "state-resupply/RESET_STATE_RESUPPLY_REDUCER",

  SET_RESUPPLY_DIALOG_KEY: "state-resupply/SET_RESUPPLY_DIALOG_KEY",

  SET_RESUPPLY_INGREDIENTS: "state-resupply/SET_RESUPPLY_INGREDIENTS",
  SET_RECEIVED_INGREDIENTS: "state-resupply/SET_RECEIVED_INGREDIENTS",

  APPEND_RESUPPLY_INGREDIENT: "state-resupply/APPEND_RESUPPLY_INGREDIENT",
  UPDATE_RESUPPLY_INGREDIENT: "state-resupply/UPDATE_RESUPPLY_INGREDIENT",
  DELETE_RESUPPLY_INGREDIENT: "state-resupply/DELETE_RESUPPLY_INGREDIENT",

  UPSERT_RECEIVED_INGREDIENT: "state-resupply/UPSERT_RECEIVED_INGREDIENT",
  DELETE_RECEIVED_INGREDIENT: "state-resupply/DELETE_RECEIVED_INGREDIENT",
};

export default STATE_RESUPPLY_ACTION_TYPES;
