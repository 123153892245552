import { createAction } from "../../utils/store.utils";

import STATE_REPORT_SUMMARY_ACTION_TYPES from "./state-report-summary.type";

export const setReportProductTab = (reportProductTab) =>
  createAction(
    STATE_REPORT_SUMMARY_ACTION_TYPES.SET_REPORT_PRODUCT_TAB,
    reportProductTab
  );

export const resetStateReportSummaryReducer = () =>
  createAction(
    STATE_REPORT_SUMMARY_ACTION_TYPES.RESET_STATE_REPORT_SUMMARY_REDUCER
  );
