import {
  Children,
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { Popover } from "react-tiny-popover";

import { getIsRightToLeft } from "../../store/global/global.selector";

import DropdownForm from "../dropdown-form/dropdown-form.component";

import {
  DropdownButtonStyled,
  DropdownCustomStyled,
  DropdownItemStyled,
  DropdownMenuStyled,
} from "./dropdown.style";

export const DROPDOWN_POSITIONS = {
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
  BOTTOM: "bottom",
};

export const DROPDOWN_ALIGNS = {
  START: "start",
  CENTER: "center",
  END: "end",
};

export const DROPDOWN_TOGGLE_TYPES = {
  BUTTON: "BUTTON",
  CUSTOM: "CUSTOM",
};

export const DropdownContext = createContext();

const Dropdown = ({
  position = DROPDOWN_POSITIONS.BOTTOM,
  align = DROPDOWN_ALIGNS.END,
  padding = 7.5,
  zIndex = 102,
  overlayMenu,
  children,
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = () => setIsOpen((isOpen) => !isOpen);
  const handleDropdownOpen = () => setIsOpen(true);
  const handleDropdownClose = () => setIsOpen(false);

  return (
    <DropdownContext.Provider
      value={{
        isOpen,
        handleDropdownToggle,
        handleDropdownOpen,
        handleDropdownClose,
      }}
    >
      <Popover
        isOpen={isOpen}
        positions={position}
        align={
          (align === DROPDOWN_ALIGNS.END && !isRightToLeft) ||
          (align === DROPDOWN_ALIGNS.START && isRightToLeft)
            ? DROPDOWN_ALIGNS.END
            : DROPDOWN_ALIGNS.START
        }
        padding={padding}
        content={overlayMenu}
        containerStyle={{ zIndex }}
        onClickOutside={handleDropdownClose}
        clickOutsideCapture={true}
      >
        <DropdownButtonStyled>{children}</DropdownButtonStyled>
      </Popover>
    </DropdownContext.Provider>
  );
};

const DropdownToggle = (
  { type = DROPDOWN_TOGGLE_TYPES.BUTTON, children },
  ref
) => {
  const { handleDropdownToggle } = useContext(DropdownContext);
  const props = { ref, onClick: handleDropdownToggle };

  if (type === DROPDOWN_TOGGLE_TYPES.BUTTON) props.type = "button";

  return cloneElement(children, props);
};

const DropdownCustom = ({ children, ...props }, ref) => {
  return (
    <DropdownCustomStyled ref={ref} {...props}>
      {children}
    </DropdownCustomStyled>
  );
};

const DropdownMenu = ({ children, ...props }, ref) => {
  return (
    <DropdownMenuStyled ref={ref} {...props}>
      {children}
    </DropdownMenuStyled>
  );
};

const DropdownItem = ({ children, handleItemClick, ...props }, ref) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  const { handleDropdownClose } = useContext(DropdownContext);

  const handleDropdownItemClick = (event) => {
    handleItemClick?.(event);
    handleDropdownClose();
  };

  return (
    <DropdownItemStyled
      ref={ref}
      isRightToLeft={isRightToLeft}
      onClick={handleDropdownItemClick}
      {...props}
    >
      {children}
    </DropdownItemStyled>
  );
};

Dropdown.Toggle = forwardRef(DropdownToggle);
Dropdown.Custom = forwardRef(DropdownCustom);
Dropdown.Menu = forwardRef(DropdownMenu);
Dropdown.Item = forwardRef(DropdownItem);
Dropdown.Form = DropdownForm;

export default Dropdown;
