import styled from "styled-components";

export const DropdownFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  padding: 1.5rem;
  width: 24rem;
  max-width: 100vw;

  border: 1.5px solid #dfdcef;
  border-radius: 1rem;
  background-color: #ffffff;
`;

export const DropdownFormHeaderContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  row-gap: 0.15rem;
`;

export const DropdownFormHeaderTitle = styled.h5`
  font-size: 1.15rem;
  font-weight: 700;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const DropdownFormHeaderDescription = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;

export const DropdownFormActionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 0.75rem;
`;
