import { useTranslation } from "react-i18next";

import { getCurrencyFormat } from "../../utils/formatter.utils";

import {
  PurchaseFormSummaryContainer,
  PurchaseFormSummaryInfo,
  PurchaseFormSummaryLabel,
  PurchaseFormSummaryValue,
} from "./purchase-form-summary.style";

const PurchaseFormSummary = ({ purchase }) => {
  const { t } = useTranslation();

  const { subtotal, tax, total } = purchase ?? {};

  return (
    <PurchaseFormSummaryContainer>
      <PurchaseFormSummaryInfo>
        <PurchaseFormSummaryLabel>{t("Subtotal")}</PurchaseFormSummaryLabel>
        <PurchaseFormSummaryValue>
          {getCurrencyFormat(subtotal)}
        </PurchaseFormSummaryValue>
      </PurchaseFormSummaryInfo>
      <PurchaseFormSummaryInfo>
        <PurchaseFormSummaryLabel>{t("Tax")}</PurchaseFormSummaryLabel>
        <PurchaseFormSummaryValue>
          {getCurrencyFormat(tax)}
        </PurchaseFormSummaryValue>
      </PurchaseFormSummaryInfo>
      <PurchaseFormSummaryInfo>
        <PurchaseFormSummaryLabel>{t("Total")}</PurchaseFormSummaryLabel>
        <PurchaseFormSummaryValue>
          {getCurrencyFormat(total)}
        </PurchaseFormSummaryValue>
      </PurchaseFormSummaryInfo>
    </PurchaseFormSummaryContainer>
  );
};

export default PurchaseFormSummary;
