const SELECT_CATEGORY_PURCHASE_ACTION_TYPES = {
  RESET_SELECT_CATEGORY_PURCHASE_REDUCER:
    "select-category-purchase/RESET_SELECT_CATEGORY_PURCHASE_REDUCER",

  SET_CATEGORIES: "select-category-purchase/SET_CATEGORIES",
  SET_SEARCH_CATEGORIES: "select-category-purchase/SET_SEARCH_CATEGORIES",

  APPEND_CATEGORIES: "select-category-purchase/APPEND_CATEGORIES",
  APPEND_SEARCH_CATEGORIES: "select-category-purchase/APPEND_SEARCH_CATEGORIES",

  SET_IS_CATEGORIES_HAS_MORE:
    "select-category-purchase/SET_IS_CATEGORIES_HAS_MORE",
  SET_IS_SEARCH_CATEGORIES_HAS_MORE:
    "select-category-purchase/SET_IS_SEARCH_CATEGORIES_HAS_MORE",

  SET_FETCH_CATEGORIES_PARAMS:
    "select-category-purchase/SET_FETCH_CATEGORIES_PARAMS",
  SET_FETCH_CATEGORIES_LOADING:
    "select-category-purchase/SET_FETCH_CATEGORIES_LOADING",
  SET_FETCH_CATEGORIES_SUCCESS:
    "select-category-purchase/SET_FETCH_CATEGORIES_SUCCESS",
  SET_FETCH_CATEGORIES_FAILED:
    "select-category-purchase/SET_FETCH_CATEGORIES_FAILED",

  SET_SEARCH_CATEGORIES_PARAMS:
    "select-category-purchase/SET_SEARCH_CATEGORIES_PARAMS",
  SET_SEARCH_CATEGORIES_LOADING:
    "select-category-purchase/SET_SEARCH_CATEGORIES_LOADING",
  SET_SEARCH_CATEGORIES_SUCCESS:
    "select-category-purchase/SET_SEARCH_CATEGORIES_SUCCESS",
  SET_SEARCH_CATEGORIES_FAILED:
    "select-category-purchase/SET_SEARCH_CATEGORIES_FAILED",

  SET_APPEND_CATEGORIES_PARAMS:
    "select-category-purchase/SET_APPEND_CATEGORIES_PARAMS",
  SET_APPEND_CATEGORIES_LOADING:
    "select-category-purchase/SET_APPEND_CATEGORIES_LOADING",
  SET_APPEND_CATEGORIES_SUCCESS:
    "select-category-purchase/SET_APPEND_CATEGORIES_SUCCESS",
  SET_APPEND_CATEGORIES_FAILED:
    "select-category-purchase/SET_APPEND_CATEGORIES_FAILED",

  SET_IS_FETCH_CATEGORIES_HITTED:
    "select-category-purchase/SET_IS_FETCH_CATEGORIES_HITTED",
  SET_IS_SEARCH_CATEGORIES_HITTED:
    "select-category-purchase/SET_IS_SEARCH_CATEGORIES_HITTED",
  SET_IS_APPEND_CATEGORIES_HITTED:
    "select-category-purchase/SET_IS_APPEND_CATEGORIES_HITTED",

  FETCH_CATEGORIES_START: "select-category-purchase/FETCH_CATEGORIES_START",
  SEARCH_CATEGORIES_START: "select-category-purchase/SEARCH_CATEGORIES_START",
  APPEND_CATEGORIES_START: "select-category-purchase/APPEND_CATEGORIES_START",
};

export default SELECT_CATEGORY_PURCHASE_ACTION_TYPES;
