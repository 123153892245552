import apiService from "./api";

export const getRenewals = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/renewals", params })).data;
export const getRenewal = async (renewalId) =>
  (await apiService.innerGet({ urlPath: `/v2/renewals/${renewalId}` })).data;
export const createRenewal = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/renewals", request })).data;
export const createCalculateRenewal = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/renewals/calculate", request }))
    .data;
export const updateRenewal = async (renewalId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/renewals/${renewalId}`,
      request,
    })
  ).data;
export const updateCalculateRenewal = async (renewalId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/renewals/${renewalId}/calculate`,
      request,
    })
  ).data;
export const customRenewal = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/renewals/custom",
      request,
    })
  ).data;
export const customCalculateRenewal = async (request) =>
  (
    await apiService.innerPost({
      urlPath: "/v2/renewals/custom/calculate",
      request,
    })
  ).data;
export const stopRenewal = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/renewals/stop", request })).data;
