import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getMarket } from "../../api/market.api";
import { getBranches } from "../../api/branch.api";
import {
  createCalculateRenewal,
  createRenewal,
  customCalculateRenewal,
  customRenewal,
  getRenewal,
  getRenewals,
  updateCalculateRenewal,
  updateRenewal,
} from "../../api/renewal.api";

import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";
import {
  appendRenewalBranches,
  appendRenewals,
  setCreateCalculateRenewalFailed,
  setCreateCalculateRenewalLoading,
  setCreateCalculateRenewalSuccess,
  setCreateRenewalFailed,
  setCreateRenewalLoading,
  setCreateRenewalSuccess,
  setCustomCalculateRenewalFailed,
  setCustomCalculateRenewalLoading,
  setCustomCalculateRenewalSuccess,
  setCustomRenewalFailed,
  setCustomRenewalLoading,
  setCustomRenewalSuccess,
  setFetchRenewalBranchesFailed,
  setFetchRenewalBranchesLoading,
  setFetchRenewalBranchesSuccess,
  setFetchRenewalFailed,
  setFetchRenewalLoading,
  setFetchRenewalMarketFailed,
  setFetchRenewalMarketLoading,
  setFetchRenewalMarketSuccess,
  setFetchRenewalsFailed,
  setFetchRenewalsLoading,
  setFetchRenewalsSuccess,
  setFetchRenewalSuccess,
  setIsCreateCalculateRenewalHitted,
  setIsCreateRenewalHitted,
  setIsCustomCalculateRenewalHitted,
  setIsCustomRenewalHitted,
  setIsFetchRenewalBranchesHitted,
  setIsFetchRenewalHitted,
  setIsFetchRenewalMarketHitted,
  setIsFetchRenewalsHitted,
  setIsRenewalBranchesHasMore,
  setIsRenewalsHasMore,
  setIsUpdateCalculateRenewalHitted,
  setIsUpdateRenewalHitted,
  setRenewal,
  setRenewalBranches,
  setRenewalCreate,
  setRenewalCreateCalculate,
  setRenewalCustom,
  setRenewalCustomCalculate,
  setRenewalMarket,
  setRenewals,
  setRenewalUpdate,
  setRenewalUpdateCalculate,
  setUpdateCalculateRenewalFailed,
  setUpdateCalculateRenewalLoading,
  setUpdateCalculateRenewalSuccess,
  setUpdateRenewalFailed,
  setUpdateRenewalLoading,
  setUpdateRenewalSuccess,
} from "./renewal-admin.action";
import {
  getFetchRenewalBranchesParams,
  getFetchRenewalMarketParams,
  getFetchRenewalsFilterCreatedBy,
  getFetchRenewalsFilterMarketId,
  getFetchRenewalsFilterStatuses,
  getFetchRenewalsFilterSubscriptionKeys,
  getFetchRenewalsFilterSubscriptionOptionKeys,
  getFetchRenewalsIncludes,
  getFetchRenewalsKeyBy,
  getFetchRenewalsPage,
  getFetchRenewalsPerPage,
  getFetchRenewalsSearch,
  getFetchRenewalsSort,
} from "./renewal-admin.selector";

export function* _getRenewals() {
  try {
    yield put(setFetchRenewalsLoading(true));

    const search = yield select(getFetchRenewalsSearch);
    const sort = yield select(getFetchRenewalsSort);
    const key_by = yield select(getFetchRenewalsKeyBy);
    const page = yield select(getFetchRenewalsPage);
    const per_page = yield select(getFetchRenewalsPerPage);
    const includes = yield select(getFetchRenewalsIncludes);
    const market_id = yield select(getFetchRenewalsFilterMarketId);
    const subscription_keys = yield select(
      getFetchRenewalsFilterSubscriptionKeys
    );
    const subscription_option_keys = yield select(
      getFetchRenewalsFilterSubscriptionOptionKeys
    );
    const statuses = yield select(getFetchRenewalsFilterStatuses);
    const created_by = yield select(getFetchRenewalsFilterCreatedBy);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        subscription_keys,
        subscription_option_keys,
        statuses,
        created_by,
      },
    };

    const {
      meta: { message },
      data: { data: renewals },
    } = yield call(getRenewals, parameters);

    yield put(setIsFetchRenewalsHitted(true));
    yield put(setIsRenewalsHasMore(renewals.length > 0));

    if (page > 1) {
      yield put(appendRenewals(renewals));
    } else {
      yield put(setRenewals(renewals));
    }

    yield put(setFetchRenewalsSuccess(message));
    yield put(setFetchRenewalsLoading(false));
  } catch (error) {
    yield put(setFetchRenewalsFailed(error));
    yield put(setFetchRenewalsLoading(false));
  }
}
export function* _getRenewal({ payload: renewalId }) {
  try {
    yield put(setFetchRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(getRenewal, renewalId);

    yield put(setIsFetchRenewalHitted(true));
    yield put(setRenewal(renewal));

    yield put(setFetchRenewalSuccess(message));
    yield put(setFetchRenewalLoading(false));
  } catch (error) {
    yield put(setFetchRenewalFailed(error));
    yield put(setFetchRenewalLoading(false));
  }
}
export function* _getRenewalMarket({ payload: marketId }) {
  try {
    const fetchRenewalMarketParams = yield select(getFetchRenewalMarketParams);
    const parameters = getParamsWithDefault(fetchRenewalMarketParams);

    yield put(setFetchRenewalMarketLoading(true));

    const {
      meta: { message },
      data: market,
    } = yield call(getMarket, marketId, parameters);

    yield put(setIsFetchRenewalMarketHitted(true));
    yield put(setRenewalMarket(market));

    yield put(setFetchRenewalMarketSuccess(message));
    yield put(setFetchRenewalMarketLoading(false));
  } catch (error) {
    yield put(setFetchRenewalMarketFailed(error));
    yield put(setFetchRenewalMarketLoading(false));
  }
}
export function* _getRenewalBranches() {
  try {
    const fetchRenewalBranchesParams = yield select(
      getFetchRenewalBranchesParams
    );
    const parameters = getParamsWithDefault(fetchRenewalBranchesParams);

    yield put(setFetchRenewalBranchesLoading(true));

    const {
      meta: { message },
      data: { data: branches },
    } = yield call(getBranches, parameters);

    yield put(setIsFetchRenewalBranchesHitted(true));
    yield put(setIsRenewalBranchesHasMore(branches.length > 0));

    if (parameters.page > 1) {
      yield put(appendRenewalBranches(branches));
    } else {
      yield put(setRenewalBranches(branches));
    }

    yield put(setFetchRenewalBranchesSuccess(message));
    yield put(setFetchRenewalBranchesLoading(false));
  } catch (error) {
    yield put(setFetchRenewalBranchesFailed(error));
    yield put(setFetchRenewalBranchesLoading(false));
  }
}
export function* _getCreateRenewal({ payload: request }) {
  try {
    yield put(setCreateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(createRenewal, request);

    yield put(setIsCreateRenewalHitted(true));
    yield put(setRenewalCreate(renewal));

    yield put(setCreateRenewalSuccess(message));
    yield put(setCreateRenewalLoading(false));
  } catch (error) {
    yield put(setCreateRenewalFailed(error));
    yield put(setCreateRenewalLoading(false));
  }
}
export function* _getCreateCalculateRenewal({ payload: request }) {
  try {
    yield put(setCreateCalculateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(createCalculateRenewal, request);

    yield put(setIsCreateCalculateRenewalHitted(true));
    yield put(setRenewalCreateCalculate(renewal));

    yield put(setCreateCalculateRenewalSuccess(message));
    yield put(setCreateCalculateRenewalLoading(false));
  } catch (error) {
    yield put(setCreateCalculateRenewalFailed(error));
    yield put(setCreateCalculateRenewalLoading(false));
  }
}
export function* _getCustomRenewal({ payload: request }) {
  try {
    yield put(setCustomRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(customRenewal, request);

    yield put(setIsCustomRenewalHitted(true));
    yield put(setRenewalCustom(renewal));

    yield put(setCustomRenewalSuccess(message));
    yield put(setCustomRenewalLoading(false));
  } catch (error) {
    yield put(setCustomRenewalFailed(error));
    yield put(setCustomRenewalLoading(false));
  }
}
export function* _getCustomCalculateRenewal({ payload: request }) {
  try {
    yield put(setCustomCalculateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(customCalculateRenewal, request);

    yield put(setIsCustomCalculateRenewalHitted(true));
    yield put(setRenewalCustomCalculate(renewal));

    yield put(setCustomCalculateRenewalSuccess(message));
    yield put(setCustomCalculateRenewalLoading(false));
  } catch (error) {
    yield put(setCustomCalculateRenewalFailed(error));
    yield put(setCustomCalculateRenewalLoading(false));
  }
}
export function* _getUpdateRenewal({ payload: { renewalId, request } }) {
  try {
    yield put(setUpdateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(updateRenewal, renewalId, request);

    yield put(setIsUpdateRenewalHitted(true));
    yield put(setRenewalUpdate(renewal));

    yield put(setUpdateRenewalSuccess(message));
    yield put(setUpdateRenewalLoading(false));
  } catch (error) {
    yield put(setUpdateRenewalFailed(error));
    yield put(setUpdateRenewalLoading(false));
  }
}
export function* _getUpdateCalculateRenewal({
  payload: { renewalId, request },
}) {
  try {
    yield put(setUpdateCalculateRenewalLoading(true));

    const {
      meta: { message },
      data: renewal,
    } = yield call(updateCalculateRenewal, renewalId, request);

    yield put(setIsUpdateCalculateRenewalHitted(true));
    yield put(setRenewalUpdateCalculate(renewal));

    yield put(setUpdateCalculateRenewalSuccess(message));
    yield put(setUpdateCalculateRenewalLoading(false));
  } catch (error) {
    yield put(setUpdateCalculateRenewalFailed(error));
    yield put(setUpdateCalculateRenewalLoading(false));
  }
}

export function* onFetchRenewalsStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWALS_START,
    _getRenewals
  );
}
export function* onFetchRenewalStart() {
  yield takeLatest(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_START, _getRenewal);
}
export function* onFetchRenewalMarketStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_MARKET_START,
    _getRenewalMarket
  );
}
export function* onFetchRenewalBranchesStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START,
    _getRenewalBranches
  );
}
export function* onCreateRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.CREATE_RENEWAL_START,
    _getCreateRenewal
  );
}
export function* onCreateCalculateRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.CREATE_CALCULATE_RENEWAL_START,
    _getCreateCalculateRenewal
  );
}
export function* onCustomRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.CUSTOM_RENEWAL_START,
    _getCustomRenewal
  );
}
export function* onCustomCalculateRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.CUSTOM_CALCULATE_RENEWAL_START,
    _getCustomCalculateRenewal
  );
}
export function* onUpdateRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.UPDATE_RENEWAL_START,
    _getUpdateRenewal
  );
}
export function* onUpdateCalculateRenewalStart() {
  yield takeLatest(
    RENEWAL_ADMIN_ACTION_TYPES.UPDATE_CALCULATE_RENEWAL_START,
    _getUpdateCalculateRenewal
  );
}

export function* renewalAdminSaga() {
  yield all([
    call(onFetchRenewalsStart),
    call(onFetchRenewalStart),
    call(onFetchRenewalMarketStart),
    call(onFetchRenewalBranchesStart),
    call(onCreateRenewalStart),
    call(onCreateCalculateRenewalStart),
    call(onCustomRenewalStart),
    call(onCustomCalculateRenewalStart),
    call(onUpdateRenewalStart),
    call(onUpdateCalculateRenewalStart),
  ]);
}
