import { useTranslation } from "react-i18next";

import { getCurrencyFormat } from "../../utils/formatter.utils";
import { isEmpty } from "../../utils/validation.utils";

import { PurchaseFormTableTotalLabel } from "./purchase-form-table-total.style";

const PurchaseFormTableTotal = ({ total }) => {
  const { t } = useTranslation();

  return (
    <PurchaseFormTableTotalLabel isNumeric={!isEmpty(total)}>
      {!isEmpty(total) ? getCurrencyFormat(total) : t("No information")}
    </PurchaseFormTableTotalLabel>
  );
};

export default PurchaseFormTableTotal;
