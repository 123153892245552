import { cloneElement, forwardRef, useContext } from "react";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import { DropdownContext } from "../dropdown-v2/dropdown.component";

import {
  DropdownFormActionContainer,
  DropdownFormContainer,
  DropdownFormHeaderContainer,
  DropdownFormHeaderDescription,
  DropdownFormHeaderTitle,
} from "./dropdown-form.style";

const DropdownForm = forwardRef(({ children, ...props }, ref) => {
  const { handleDropdownClose } = useContext(DropdownContext);

  return (
    <DropdownFormContainer ref={ref} {...props}>
      {cloneElement(children, { handleDropdownClose })}
    </DropdownFormContainer>
  );
});

const DropdownFormHeader = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <DropdownFormHeaderContainer>
      <DropdownFormHeaderTitle>
        {!isEmpty(title) ? title : t("No name")}
      </DropdownFormHeaderTitle>
      <DropdownFormHeaderDescription>
        {!isEmpty(description) ? description : t("No information")}
      </DropdownFormHeaderDescription>
    </DropdownFormHeaderContainer>
  );
};

const DropdownFormAction = ({ children }) => {
  return <DropdownFormActionContainer>{children}</DropdownFormActionContainer>;
};

export default Object.assign(DropdownForm, {
  Header: DropdownFormHeader,
  Action: DropdownFormAction,
});
