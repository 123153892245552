import { createAction } from "../../utils/store.utils";

import STATE_RECIPE_ACTION_TYPES from "./state-recipe.type";

export const setRecipeIngredients = (recipeIngredients) =>
  createAction(
    STATE_RECIPE_ACTION_TYPES.SET_RECIPE_INGREDIENTS,
    recipeIngredients
  );

export const appendRecipeIngredient = (value) =>
  createAction(STATE_RECIPE_ACTION_TYPES.APPEND_RECIPE_INGREDIENT, value);
export const updateRecipeIngredient = (index, value) =>
  createAction(STATE_RECIPE_ACTION_TYPES.UPDATE_RECIPE_INGREDIENT, {
    index,
    value,
  });
export const deleteRecipeIngredient = (index) =>
  createAction(STATE_RECIPE_ACTION_TYPES.DELETE_RECIPE_INGREDIENT, index);

export const resetStateRecipeReducer = () =>
  createAction(STATE_RECIPE_ACTION_TYPES.RESET_STATE_RECIPE_REDUCER);
