import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getDateTimeFormat } from "../../utils/formatter.utils";
import { parseToBoolean } from "../../utils/parser.utils";
import { showErrorMessage } from "../../utils/popup.utils";
import {
  getValidationErrors,
  isEmpty,
  rule,
} from "../../utils/validation.utils";

import {
  PURCHASE_STATUSES,
  PURCHASE_TYPES,
} from "../../constants/purchase.constant";

import {
  getCurrentBranchId,
  getCurrentMarketId,
} from "../../store/global/global.selector";
import {
  getCurrentModal,
  getIsModalOpen,
} from "../../store/component/component.selector";
import {
  resetCreateValues,
  setCreateStep,
  setCreateValues,
  setFormIngredients,
  setFormItems,
  setIsCalculateLoading,
} from "../../store/state-purchase/state-purchase.action";
import {
  getCreateStep,
  getCreateValues,
  getFormIngredients,
  getFormItems,
} from "../../store/state-purchase/state-purchase.selector";
import {
  calculateCreatePurchaseStart,
  createPurchaseStart,
} from "../../store/purchase/purchase.action";
import {
  getCalculateCreatePurchase,
  getCalculateCreatePurchaseLoading,
  getCreatePurchaseLoading,
  getCreatePurchaseSuccess,
} from "../../store/purchase/purchase.selector";

import Responsive, {
  RESPONSIVE_ROW_ALIGNS,
} from "../../components/responsive/responsive.component";
import Modal from "../../components/modal/modal.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown, {
  DROPDOWN_ALIGNS,
  DROPDOWN_POSITIONS,
} from "../../components/dropdown-v2/dropdown.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";
import { FORM_SELECT_ASYNC_SIZES } from "../../components/form-select-async/form-select-async.component";
import FormRadioCard from "../../components/form-radio-card/form-radio-card.widget";
import FormFileArea from "../../components/form-file-area/form-file-area.widget";
import FormDateTime from "../../components/form-datetime/form-datetime.component";
import FormInputRadiobox from "../../components/form-input-radiobox/form-input-radiobox.component";
import FormStepHeader from "../form-step-header/form-step-header.widget";
import SelectCategoryPurchase from "../select-category-purchase/select-category-purchase.widget";
import SelectSupplier, {
  SELECT_SUPPLIER_TYPES,
} from "../select-supplier/select-supplier.widget";
import SelectSupplierCreate from "../select-supplier-create/select-supplier-create.widget";
import PurchaseFormTable from "../purchase-form-table/purchase-form-table.widget";
import PurchaseFormIngredientCreate from "../purchase-form-ingredient-create/purchase-form-ingredient-create.widget";
import PurchaseFormItemCreate from "../purchase-form-item-create/purchase-form-item-create.widget";
import PurchaseFormSummary from "../purchase-form-summary/purchase-form-summary.widget";

import { ReactComponent as PurchaseSvg } from "../../assets/icons/PagePurchase.svg";
import { ReactComponent as InventoryExpenseSvg } from "../../assets/icons/InventoryExpense.svg";
import { ReactComponent as InventoryIngredientSvg } from "../../assets/icons/InventoryIngredient.svg";
import { ReactComponent as PlusSvg } from "../../assets/icons/Plus1.svg";

export const modalName = "PURCHASE_CREATE";

export const PURCHASE_STEPS = {
  PURCHASE_DETAILS: "PURCHASE_DETAILS",
  PURCHASE_ITEMS: "PURCHASE_ITEMS",
};

const PurchaseCreateDetails = ({ handleNextStep, isLoading = false }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentMarketId = useSelector(getCurrentMarketId);
  const createValues = useSelector(getCreateValues);

  const {
    type,
    status,
    invoice_img,
    is_taxable,
    purchase_at,
    category_id,
    supplier_id,
  } = createValues;

  const handleInputChange = ({ name, value }) =>
    dispatch(setCreateValues({ ...createValues, [name]: value }));

  const handleNextClick = () => handleNextStep?.();

  return (
    <Responsive.Row rowVerAlign={RESPONSIVE_ROW_ALIGNS.END} rowGap="1rem">
      <Responsive.Col>
        <FormStepHeader
          title={t("Purchase Details")}
          description={t("Provide essential purchase information.")}
        />
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormRadioCard
          cardIcon={<InventoryIngredientSvg />}
          cardName={t("Ingredient")}
          cardInfo={t("Purchases of essential food or material supplies.")}
          name="type"
          value={PURCHASE_TYPES.TYPE_INGREDIENT}
          onChange={handleInputChange}
          isChecked={type === PURCHASE_TYPES.TYPE_INGREDIENT}
          isDisabled={isLoading}
          isSolid={isLoading}
        />
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormRadioCard
          cardIcon={<InventoryExpenseSvg />}
          cardName={t("Expense")}
          cardInfo={t("Business expenses outside of ingredient purchases.")}
          name="type"
          value={PURCHASE_TYPES.TYPE_EXPENSE}
          onChange={handleInputChange}
          isChecked={type === PURCHASE_TYPES.TYPE_EXPENSE}
          isDisabled={isLoading}
          isSolid={isLoading}
        />
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <SelectCategoryPurchase
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Category")}
            </FormLabel>
          }
          selectSize={FORM_SELECT_ASYNC_SIZES.NORMAL}
          name="category_id"
          placeholder={t("Select category")}
          onChange={handleInputChange}
          value={category_id}
          isDisabled={isLoading}
        />
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormDateTime
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Date & Time")}
            </FormLabel>
          }
          name="purchase_at"
          placeholder={t("Enter date & time")}
          onChange={handleInputChange}
          value={isEmpty(purchase_at) ? "" : getDateTimeFormat(purchase_at)}
          disabled={isLoading}
        />
      </Responsive.Col>
      <Responsive.Col>
        <SelectSupplier
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Supplier")}
            </FormLabel>
          }
          paramsMarketId={currentMarketId}
          selectSize={FORM_SELECT_ASYNC_SIZES.NORMAL}
          selectType={SELECT_SUPPLIER_TYPES.USERABLE_ID}
          menuPrefix={<SelectSupplierCreate />}
          name="supplier_id"
          placeholder={t("Select supplier")}
          onChange={handleInputChange}
          value={supplier_id}
          isDisabled={isLoading}
        />
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormInputRadiobox
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Payment Status")}
            </FormLabel>
          }
          name="status"
          value={PURCHASE_STATUSES.STATUS_PAID}
          onChange={handleInputChange}
          isChecked={status === PURCHASE_STATUSES.STATUS_PAID}
          isDisabled={isLoading}
          isSolid={isLoading}
        >
          {t("Paid")}
        </FormInputRadiobox>
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormInputRadiobox
          name="status"
          value={PURCHASE_STATUSES.STATUS_UNPAID}
          onChange={handleInputChange}
          isChecked={status === PURCHASE_STATUSES.STATUS_UNPAID}
          isDisabled={isLoading}
          isSolid={isLoading}
        >
          {t("Unpaid")}
        </FormInputRadiobox>
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormInputRadiobox
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Invoice Taxable")}
            </FormLabel>
          }
          name="is_taxable"
          value="1"
          onChange={handleInputChange}
          isChecked={!isEmpty(is_taxable) && parseToBoolean(is_taxable)}
          isDisabled={isLoading}
          isSolid={isLoading}
        >
          {t("Yes")}
        </FormInputRadiobox>
      </Responsive.Col>
      <Responsive.Col sm={6}>
        <FormInputRadiobox
          name="is_taxable"
          value="0"
          onChange={handleInputChange}
          isChecked={!isEmpty(is_taxable) && !parseToBoolean(is_taxable)}
          isDisabled={isLoading}
          isSolid={isLoading}
        >
          {t("No")}
        </FormInputRadiobox>
      </Responsive.Col>
      <Responsive.Col>
        <FormFileArea
          label={
            <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
              {t("Invoice Image")}
            </FormLabel>
          }
          name="invoice_img"
          onChange={handleInputChange}
          value={invoice_img}
          isDisabled={isLoading}
          isSolid
        />
      </Responsive.Col>
      <Responsive.Col></Responsive.Col>
      <Responsive.Col style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="button"
          buttonColor={BUTTON_COLORS.DARK}
          buttonSize={BUTTON_SIZES.NORMAL}
          buttonType={BUTTON_TYPES.TEXT}
          onClick={handleNextClick}
          isLoading={isLoading}
        >
          {t("Continue")}
        </Button>
      </Responsive.Col>
    </Responsive.Row>
  );
};
const PurchaseCreateItems = ({ handlePrevStep, isLoading = false }) => {
  const { t } = useTranslation();

  const calculateCreatePurchase = useSelector(getCalculateCreatePurchase);
  const createValues = useSelector(getCreateValues);

  const { ingredients, items } = calculateCreatePurchase ?? {};

  const handlePrevClick = () => handlePrevStep?.();

  return (
    <Responsive.Row rowGap="1rem">
      <Responsive.Col>
        <FormStepHeader
          title={t("Purchase Items")}
          description={t("Add the items related to the purchase.")}
        />
      </Responsive.Col>
      <Responsive.Col>
        <PurchaseFormTable
          type={createValues?.type}
          ingredients={!isEmpty(ingredients) ? ingredients : []}
          items={!isEmpty(items) ? items : []}
        />
      </Responsive.Col>
      <Responsive.Col>
        <Dropdown
          align={DROPDOWN_ALIGNS.START}
          position={[DROPDOWN_POSITIONS.RIGHT, DROPDOWN_POSITIONS.BOTTOM]}
          overlayMenu={
            <Dropdown.Form style={{ width: "32rem" }}>
              {createValues?.type === PURCHASE_TYPES.TYPE_INGREDIENT ? (
                <PurchaseFormIngredientCreate />
              ) : (
                <PurchaseFormItemCreate />
              )}
            </Dropdown.Form>
          }
          zIndex={1001}
        >
          <Dropdown.Toggle>
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.SECONDARY}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT_ICON}
              prefixIcon={<PlusSvg />}
              isDisabled={isLoading}
              isLoading={isLoading}
            >
              {t("Add Item")}
            </Button>
          </Dropdown.Toggle>
        </Dropdown>
      </Responsive.Col>
      <Responsive.Col>
        <PurchaseFormSummary purchase={calculateCreatePurchase} />
      </Responsive.Col>
      <Responsive.Col></Responsive.Col>
      <Responsive.Col
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button
          type="button"
          buttonColor={BUTTON_COLORS.SECONDARY}
          buttonSize={BUTTON_SIZES.NORMAL}
          buttonType={BUTTON_TYPES.TEXT}
          onClick={handlePrevClick}
        >
          {t("Back")}
        </Button>
        <Button
          type="submit"
          buttonColor={BUTTON_COLORS.PRIMARY}
          buttonSize={BUTTON_SIZES.NORMAL}
          buttonType={BUTTON_TYPES.TEXT}
          isLoading={isLoading}
        >
          {t("Create Purchase")}
        </Button>
      </Responsive.Col>
    </Responsive.Row>
  );
};

const PurchaseCreate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentMarketId = useSelector(getCurrentMarketId);
  const currentBranchId = useSelector(getCurrentBranchId);

  const currentModal = useSelector(getCurrentModal);
  const isModalOpen = useSelector(getIsModalOpen);

  const calculateCreatePurchaseLoading = useSelector(
    getCalculateCreatePurchaseLoading
  );
  const createPurchaseLoading = useSelector(getCreatePurchaseLoading);
  const createPurchaseSuccess = useSelector(getCreatePurchaseSuccess);

  const createStep = useSelector(getCreateStep);
  const createValues = useSelector(getCreateValues);
  const formIngredients = useSelector(getFormIngredients);
  const formItems = useSelector(getFormItems);

  const resetInputValues = () => {
    dispatch(setCreateStep(PURCHASE_STEPS.PURCHASE_DETAILS));
    dispatch(resetCreateValues());
    dispatch(setFormIngredients([]));
    dispatch(setFormItems([]));
  };

  const handleDetailsNext = () => {
    const errors = getValidationErrors(createValues, {
      type: [rule.required()],
      status: [rule.required()],
      purchase_at: [rule.required()],
    });

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    dispatch(setCreateStep(PURCHASE_STEPS.PURCHASE_ITEMS));
  };
  const handleItemsPrev = () =>
    dispatch(setCreateStep(PURCHASE_STEPS.PURCHASE_DETAILS));

  const handlePurchaseCalculate = () => {
    if (
      !isEmpty(currentMarketId) &&
      !isEmpty(currentBranchId) &&
      currentModal === modalName &&
      isModalOpen
    ) {
      const payload = {
        market_id: currentMarketId,
        branch_id: currentBranchId,
        ...createValues,
      };
      if (createValues.type === PURCHASE_TYPES.TYPE_INGREDIENT) {
        payload.ingredients = formIngredients;
      }
      if (createValues.type === PURCHASE_TYPES.TYPE_EXPENSE) {
        payload.items = formItems;
      }

      dispatch(calculateCreatePurchaseStart(payload));
    } else {
      dispatch(setIsCalculateLoading(false));
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const payload = {
      market_id: currentMarketId,
      branch_id: currentBranchId,
      ...createValues,
    };
    if (createValues.type === PURCHASE_TYPES.TYPE_INGREDIENT) {
      payload.ingredients = formIngredients;
    }
    if (createValues.type === PURCHASE_TYPES.TYPE_EXPENSE) {
      payload.items = formItems;
    }

    const errors = getValidationErrors(payload, {
      ingredients:
        createValues?.type === PURCHASE_TYPES.TYPE_INGREDIENT
          ? [rule.notEmptyArray()]
          : [],
      items:
        createValues?.type === PURCHASE_TYPES.TYPE_EXPENSE
          ? [rule.notEmptyArray()]
          : [],
    });

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    dispatch(createPurchaseStart(payload));
  };

  useEffect(() => {
    dispatch(setIsCalculateLoading(true));
    const calculateTimeout = setTimeout(handlePurchaseCalculate, 500);
    return () => clearTimeout(calculateTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentModal,
    isModalOpen,
    formIngredients,
    formItems,
    createValues.type,
  ]);

  useEffect(() => {
    dispatch(
      setIsCalculateLoading(
        calculateCreatePurchaseLoading || createPurchaseLoading
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateCreatePurchaseLoading, createPurchaseLoading]);

  useEffect(() => {
    if (createPurchaseSuccess !== null) resetInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPurchaseSuccess]);

  useEffect(() => {
    resetInputValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      name={modalName}
      icon={<PurchaseSvg />}
      title={t("Add New Purchase")}
      description={t("Provide the information below")}
    >
      <form onSubmit={handleFormSubmit}>
        <Responsive>
          {createStep === PURCHASE_STEPS.PURCHASE_DETAILS && (
            <PurchaseCreateDetails
              handleNextStep={handleDetailsNext}
              isLoading={createPurchaseLoading}
            />
          )}
          {createStep === PURCHASE_STEPS.PURCHASE_ITEMS && (
            <PurchaseCreateItems
              handlePrevStep={handleItemsPrev}
              isLoading={createPurchaseLoading}
            />
          )}
        </Responsive>
      </form>
    </Modal>
  );
};

export default PurchaseCreate;
