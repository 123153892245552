import { ReactComponent as ConfirmSvg } from "../assets/icons/stroke/Confirm.svg";
import { ReactComponent as CancelSvg } from "../assets/icons/stroke/Cancel.svg";
import { ReactComponent as FrameSvg } from "../assets/icons/stroke/Frame.svg";

export const RENEWAL_TABLE_COLUMNS = {
  SUBSCRIBER: "SUBSCRIBER",
  BRANCH: "BRANCH",
  PLAN: "PLAN",
  PERIOD: "PERIOD",
  STATUS: "STATUS",
  DURATION: "DURATION",
  PRICE: "PRICE",
  QUANTITY: "QUANTITY",
  TOTAL: "TOTAL",
  EXTEND_DATE: "EXTEND_DATE",
};

export const RENEWAL_INCLUDES = {
  PAYMENT: "payment",
  MARKET: "market",
  BRANCH: "branch",
  CREATOR: "creator",
};

export const RENEWAL_STATUSES = {
  PROCESS: "PROCESS",
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
};

export const getTableColumnName = (column) =>
  ({
    [RENEWAL_TABLE_COLUMNS.SUBSCRIBER]: "Subscriber",
    [RENEWAL_TABLE_COLUMNS.BRANCH]: "Branch",
    [RENEWAL_TABLE_COLUMNS.PLAN]: "Plan",
    [RENEWAL_TABLE_COLUMNS.PERIOD]: "Period",
    [RENEWAL_TABLE_COLUMNS.STATUS]: "Status",
    [RENEWAL_TABLE_COLUMNS.DURATION]: "Duration",
    [RENEWAL_TABLE_COLUMNS.PRICE]: "Price",
    [RENEWAL_TABLE_COLUMNS.QUANTITY]: "Quantity",
    [RENEWAL_TABLE_COLUMNS.TOTAL]: "Total",
    [RENEWAL_TABLE_COLUMNS.EXTEND_DATE]: "Extend Date",
  }?.[column] ?? "-");

export const getRenewalStatusIcon = (status) =>
  ({
    [RENEWAL_STATUSES.PROCESS]: FrameSvg,
    [RENEWAL_STATUSES.COMPLETED]: ConfirmSvg,
    [RENEWAL_STATUSES.CANCELED]: CancelSvg,
  }?.[status] ?? FrameSvg);

export const getRenewalStatusLabel = (status) =>
  ({
    [RENEWAL_STATUSES.PROCESS]: "Process",
    [RENEWAL_STATUSES.COMPLETED]: "Completed",
    [RENEWAL_STATUSES.CANCELED]: "Canceled",
  }?.[status]);
