import RESUPPLY_ACTION_TYPES from "./resupply.type";

export const RESUPPLY_INITIAL_STATE = {
  resupplies: [],
  resupply: null,
  createResupply: null,
  updateResupply: null,
  receiveResupply: null,
  approveResupply: null,
  declineResupply: null,

  isResuppliesHasMore: true,

  fetchResuppliesSearch: null,
  fetchResuppliesSort: null,
  fetchResuppliesKeyBy: null,
  fetchResuppliesPage: 1,
  fetchResuppliesPerPage: null,
  fetchResuppliesIncludes: null,
  fetchResuppliesFilterMarketId: null,
  fetchResuppliesFilterBranchId: null,
  fetchResuppliesFilterCreatedBy: null,
  fetchResuppliesFilterStatus: null,
  fetchResuppliesFilterIsReceived: null,
  fetchResuppliesLoading: false,
  fetchResuppliesSuccess: null,
  fetchResuppliesFailed: null,

  fetchResupplyLoading: false,
  fetchResupplySuccess: null,
  fetchResupplyFailed: null,

  createResupplyLoading: false,
  createResupplySuccess: null,
  createResupplyFailed: null,

  updateResupplyLoading: false,
  updateResupplySuccess: null,
  updateResupplyFailed: null,

  deleteResupplyLoading: false,
  deleteResupplySuccess: null,
  deleteResupplyFailed: null,

  receiveResupplyLoading: false,
  receiveResupplySuccess: null,
  receiveResupplyFailed: null,

  approveResupplyLoading: false,
  approveResupplySuccess: null,
  approveResupplyFailed: null,

  declineResupplyLoading: false,
  declineResupplySuccess: null,
  declineResupplyFailed: null,

  isFetchResuppliesHitted: false,
  isFetchResupplyHitted: false,
  isCreateResupplyHitted: false,
  isUpdateResupplyHitted: false,
  isDeleteResupplyHitted: false,
  isReceiveResupplyHitted: false,
  isApproveResupplyHitted: false,
  isDeclineResupplyHitted: false,
};

export const resupplyReducer = (state = RESUPPLY_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RESUPPLY_ACTION_TYPES.SET_RESUPPLIES:
      return { ...state, resupplies: payload };
    case RESUPPLY_ACTION_TYPES.SET_RESUPPLY:
      return { ...state, resupply: payload };
    case RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY:
      return { ...state, createResupply: payload };
    case RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY:
      return { ...state, updateResupply: payload };
    case RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY:
      return { ...state, receiveResupply: payload };
    case RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY:
      return { ...state, approveResupply: payload };
    case RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY:
      return { ...state, declineResupply: payload };

    case RESUPPLY_ACTION_TYPES.SET_IS_RESUPPLIES_HAS_MORE:
      return { ...state, isResuppliesHasMore: payload };

    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_SEARCH:
      return { ...state, fetchResuppliesSearch: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_SORT:
      return { ...state, fetchResuppliesSort: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_KEY_BY:
      return { ...state, fetchResuppliesKeyBy: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_PAGE:
      return { ...state, fetchResuppliesPage: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_PER_PAGE:
      return { ...state, fetchResuppliesPerPage: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_INCLUDES:
      return { ...state, fetchResuppliesIncludes: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_MARKET_ID:
      return { ...state, fetchResuppliesFilterMarketId: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_BRANCH_ID:
      return { ...state, fetchResuppliesFilterBranchId: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_CREATED_BY:
      return { ...state, fetchResuppliesFilterCreatedBy: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_STATUS:
      return { ...state, fetchResuppliesFilterStatus: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_IS_RECEIVED:
      return { ...state, fetchResuppliesFilterIsReceived: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_LOADING:
      return { ...state, fetchResuppliesLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_SUCCESS:
      return { ...state, fetchResuppliesSuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FAILED:
      return { ...state, fetchResuppliesFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_LOADING:
      return { ...state, fetchResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_SUCCESS:
      return { ...state, fetchResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_FAILED:
      return { ...state, fetchResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_LOADING:
      return { ...state, createResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_SUCCESS:
      return { ...state, createResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_FAILED:
      return { ...state, createResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_LOADING:
      return { ...state, updateResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_SUCCESS:
      return { ...state, updateResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_FAILED:
      return { ...state, updateResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_LOADING:
      return { ...state, deleteResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_SUCCESS:
      return { ...state, deleteResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_FAILED:
      return { ...state, deleteResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_LOADING:
      return { ...state, receiveResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_SUCCESS:
      return { ...state, receiveResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_FAILED:
      return { ...state, receiveResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_LOADING:
      return { ...state, approveResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_SUCCESS:
      return { ...state, approveResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_FAILED:
      return { ...state, approveResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_LOADING:
      return { ...state, declineResupplyLoading: payload };
    case RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_SUCCESS:
      return { ...state, declineResupplySuccess: payload };
    case RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_FAILED:
      return { ...state, declineResupplyFailed: payload };

    case RESUPPLY_ACTION_TYPES.IS_FETCH_RESUPPLIES_HITTED:
      return { ...state, isFetchResuppliesHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_FETCH_RESUPPLY_HITTED:
      return { ...state, isFetchResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_CREATE_RESUPPLY_HITTED:
      return { ...state, isCreateResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_UPDATE_RESUPPLY_HITTED:
      return { ...state, isUpdateResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_DELETE_RESUPPLY_HITTED:
      return { ...state, isDeleteResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_RECEIVE_RESUPPLY_HITTED:
      return { ...state, isReceiveResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_APPROVE_RESUPPLY_HITTED:
      return { ...state, isApproveResupplyHitted: payload };
    case RESUPPLY_ACTION_TYPES.IS_DECLINE_RESUPPLY_HITTED:
      return { ...state, isDeclineResupplyHitted: payload };

    case RESUPPLY_ACTION_TYPES.APPEND_RESUPPLIES:
      return {
        ...state,
        resupplies: [...state.resupplies, ...payload],
      };

    case RESUPPLY_ACTION_TYPES.RESET_RESUPPLY_REDUCER:
      return RESUPPLY_INITIAL_STATE;
    default:
      return state;
  }
};
