import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  getReportCategories,
  getReportCategory,
  getReportTotals,
} from "../../api/report-purchase.api";

import REPORT_PURCHASE_ACTION_TYPES from "./report-purchase.type";
import {
  appendReportPurchases,
  setFetchReportPurchaseFailed,
  setFetchReportPurchaseLoading,
  setFetchReportPurchasesFailed,
  setFetchReportPurchasesLoading,
  setFetchReportPurchasesSuccess,
  setFetchReportPurchaseSuccess,
  setFetchReportTotalsFailed,
  setFetchReportTotalsLoading,
  setFetchReportTotalsSuccess,
  setIsFetchReportPurchaseHitted,
  setIsFetchReportPurchasesHitted,
  setIsFetchReportTotalsHitted,
  setIsReportPurchasesHasMore,
  setReportPurchase,
  setReportPurchases,
  setReportTotals,
} from "./report-purchase.action";
import {
  getFetchReportPurchasesKeyBy,
  getFetchReportPurchasesPage,
  getFetchReportPurchasesPerPage,
  getFetchReportPurchasesSearch,
  getFetchReportPurchasesSort,
  getFetchReportsFilterBranchId,
  getFetchReportsFilterCategoriesIds,
  getFetchReportsFilterMarketId,
  getFetchReportsFilterPurchaseAtAfter,
  getFetchReportsFilterPurchaseAtBefore,
  getFetchReportsFilterStatuses,
  getFetchReportsFilterSupplierId,
  getFetchReportsFilterTypes,
} from "./report-purchase.selector";

export function* _getReportTotals() {
  try {
    yield put(setFetchReportTotalsLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const supplier_id = yield select(getFetchReportsFilterSupplierId);
    const categories_ids = yield select(getFetchReportsFilterCategoriesIds);
    const types = yield select(getFetchReportsFilterTypes);
    const statuses = yield select(getFetchReportsFilterStatuses);
    const purchase_at_before = yield select(
      getFetchReportsFilterPurchaseAtBefore
    );
    const purchase_at_after = yield select(
      getFetchReportsFilterPurchaseAtAfter
    );

    const parameters = {
      filter: {
        market_id,
        branch_id,
        supplier_id,
        categories_ids,
        types,
        statuses,
        purchase_at_before,
        purchase_at_after,
      },
    };

    const {
      meta: { message },
      data: reportTotals,
    } = yield call(getReportTotals, parameters);

    yield put(setIsFetchReportTotalsHitted(true));
    yield put(setReportTotals(reportTotals));

    yield put(setFetchReportTotalsSuccess(message));
    yield put(setFetchReportTotalsLoading(false));
  } catch (error) {
    yield put(setFetchReportTotalsFailed(error));
    yield put(setFetchReportTotalsLoading(false));
  }
}
export function* _getReportPurchases() {
  try {
    yield put(setFetchReportPurchasesLoading(true));

    const market_id = yield select(getFetchReportsFilterMarketId);
    const branch_id = yield select(getFetchReportsFilterBranchId);
    const supplier_id = yield select(getFetchReportsFilterSupplierId);
    const categories_ids = yield select(getFetchReportsFilterCategoriesIds);
    const types = yield select(getFetchReportsFilterTypes);
    const statuses = yield select(getFetchReportsFilterStatuses);
    const purchase_at_before = yield select(
      getFetchReportsFilterPurchaseAtBefore
    );
    const purchase_at_after = yield select(
      getFetchReportsFilterPurchaseAtAfter
    );

    const search = yield select(getFetchReportPurchasesSearch);
    const sort = yield select(getFetchReportPurchasesSort);
    const key_by = yield select(getFetchReportPurchasesKeyBy);
    const page = yield select(getFetchReportPurchasesPage);
    const per_page = yield select(getFetchReportPurchasesPerPage);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      filter: {
        market_id,
        branch_id,
        supplier_id,
        categories_ids,
        types,
        statuses,
        purchase_at_before,
        purchase_at_after,
      },
    };

    const {
      meta: { message },
      data: { data: reportPurchases },
    } = yield call(getReportCategories, parameters);

    yield put(setIsFetchReportPurchasesHitted(true));
    yield put(setIsReportPurchasesHasMore(reportPurchases.length > 0));

    if (page > 1) {
      yield put(appendReportPurchases(reportPurchases));
    } else {
      yield put(setReportPurchases(reportPurchases));
    }

    yield put(setFetchReportPurchasesSuccess(message));
    yield put(setFetchReportPurchasesLoading(false));
  } catch (error) {
    yield put(setFetchReportPurchasesFailed(error));
    yield put(setFetchReportPurchasesLoading(false));
  }
}
export function* _getReportPurchase({ payload: categoryId }) {
  try {
    yield put(setFetchReportPurchaseLoading(true));

    const {
      meta: { message },
      data: reportPurchase,
    } = yield call(getReportCategory, categoryId);

    yield put(setIsFetchReportPurchaseHitted(true));
    yield put(setReportPurchase(reportPurchase));

    yield put(setFetchReportPurchaseSuccess(message));
    yield put(setFetchReportPurchaseLoading(false));
  } catch (error) {
    yield put(setFetchReportPurchaseFailed(error));
    yield put(setFetchReportPurchaseLoading(false));
  }
}

export function* onFetchReportTotalsStart() {
  yield takeLatest(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_START,
    _getReportTotals
  );
}
export function* onFetchReportPurchasesStart() {
  yield takeLatest(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_START,
    _getReportPurchases
  );
}
export function* onFetchReportPurchaseStart() {
  yield takeLatest(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_START,
    _getReportPurchase
  );
}

export function* reportPurchaseSaga() {
  yield all([
    call(onFetchReportTotalsStart),
    call(onFetchReportPurchasesStart),
    call(onFetchReportPurchaseStart),
  ]);
}
