import moment from "moment";
import DateTime from "react-datetime";

import { isEmpty } from "../../utils/validation.utils";

import { FormDateTimeContainer } from "./form-datetime.style";

import "react-datetime/css/react-datetime.css";

const FormDateTime = ({ label, name, value = "", onChange, ...otherProps }) => {
  const handleDateTimeChange = (dateTime) =>
    onChange?.({
      name,
      value: moment.isMoment(dateTime)
        ? dateTime.valueOf()
        : moment(dateTime).valueOf(),
    });

  return (
    <FormDateTimeContainer>
      {!isEmpty(label) && label}
      <DateTime
        dateFormat="DD MMM YYYY,"
        timeFormat="h:mm A"
        onChange={handleDateTimeChange}
        inputProps={{ readOnly: true, name, value, ...otherProps }}
      />
    </FormDateTimeContainer>
  );
};

export default FormDateTime;
