import ORDER_FORM_CATEGORY_ACTION_TYPES from "./order-form-category.type";

const ORDER_FORM_CATEGORY_INITIAL_STATE = {
  categories: [],

  isCategoriesHasMore: true,

  fetchCategoriesParams: {},
  fetchCategoriesLoading: false,
  fetchCategoriesSuccess: null,
  fetchCategoriesFailed: null,

  isFetchCategoriesHitted: false,
};

export const orderFormCategoryReducer = (
  state = ORDER_FORM_CATEGORY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_CATEGORIES:
      return { ...state, categories: payload };
    case ORDER_FORM_CATEGORY_ACTION_TYPES.APPEND_CATEGORIES:
      return {
        ...state,
        categories: [...state.categories, ...payload],
      };

    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_IS_CATEGORIES_HAS_MORE:
      return { ...state, isCategoriesHasMore: payload };

    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_PARAMS:
      return { ...state, fetchCategoriesParams: payload };
    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_LOADING:
      return { ...state, fetchCategoriesLoading: payload };
    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_SUCCESS:
      return { ...state, fetchCategoriesSuccess: payload };
    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_FAILED:
      return { ...state, fetchCategoriesFailed: payload };

    case ORDER_FORM_CATEGORY_ACTION_TYPES.SET_IS_FETCH_CATEGORIES_HITTED:
      return { ...state, isFetchCategoriesHitted: payload };

    case ORDER_FORM_CATEGORY_ACTION_TYPES.RESET_ORDER_FORM_CATEGORY_REDUCER:
      return ORDER_FORM_CATEGORY_INITIAL_STATE;
    default:
      return state;
  }
};
