import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { PURCHASE_TYPES } from "../../constants/purchase.constant";

import {
  getPurchases,
  getPurchase,
  createPurchase,
  updatePurchase,
  deletePurchase,
  calcCreatePurchase,
  calcUpdatePurchase,
} from "../../api/purchase.api";

import PURCHASE_ACTION_TYPES from "./purchase.type";
import {
  appendIngredientPurchases,
  appendItemPurchases,
  setCalculateCreatePurchase,
  setCalculateCreatePurchaseFailed,
  setCalculateCreatePurchaseLoading,
  setCalculateCreatePurchaseSuccess,
  setCalculateUpdatePurchase,
  setCalculateUpdatePurchaseFailed,
  setCalculateUpdatePurchaseLoading,
  setCalculateUpdatePurchaseSuccess,
  setCreatePurchase,
  setCreatePurchaseFailed,
  setCreatePurchaseLoading,
  setCreatePurchaseSuccess,
  setDeletePurchaseFailed,
  setDeletePurchaseLoading,
  setDeletePurchaseSuccess,
  setFetchIngredientPurchasesFailed,
  setFetchIngredientPurchasesLoading,
  setFetchIngredientPurchasesPage,
  setFetchIngredientPurchasesSuccess,
  setFetchItemPurchasesFailed,
  setFetchItemPurchasesLoading,
  setFetchItemPurchasesPage,
  setFetchItemPurchasesSuccess,
  setFetchPurchaseFailed,
  setFetchPurchaseLoading,
  setFetchPurchaseSuccess,
  setIngredientPurchases,
  setIsCalculateCreatePurchaseHitted,
  setIsCalculateUpdatePurchaseHitted,
  setIsCreatePurchaseHitted,
  setIsDeletePurchaseHitted,
  setIsFetchIngredientPurchasesHitted,
  setIsFetchItemPurchasesHitted,
  setIsFetchPurchaseHitted,
  setIsIngredientPurchasesHasMore,
  setIsItemPurchasesHasMore,
  setIsUpdatePurchaseHitted,
  setItemPurchases,
  setPurchase,
  setUpdatePurchase,
  setUpdatePurchaseFailed,
  setUpdatePurchaseLoading,
  setUpdatePurchaseSuccess,
} from "./purchase.action";
import {
  getFetchIngredientPurchasesFilterBranchId,
  getFetchIngredientPurchasesFilterCategoryId,
  getFetchIngredientPurchasesFilterMarketId,
  getFetchIngredientPurchasesFilterStatus,
  getFetchIngredientPurchasesFilterSupplierId,
  getFetchIngredientPurchasesIncludes,
  getFetchIngredientPurchasesKeyBy,
  getFetchIngredientPurchasesPage,
  getFetchIngredientPurchasesPerPage,
  getFetchIngredientPurchasesSearch,
  getFetchIngredientPurchasesSort,
  getFetchItemPurchasesFilterBranchId,
  getFetchItemPurchasesFilterCategoryId,
  getFetchItemPurchasesFilterMarketId,
  getFetchItemPurchasesFilterStatus,
  getFetchItemPurchasesFilterSupplierId,
  getFetchItemPurchasesIncludes,
  getFetchItemPurchasesKeyBy,
  getFetchItemPurchasesPage,
  getFetchItemPurchasesPerPage,
  getFetchItemPurchasesSearch,
  getFetchItemPurchasesSort,
  getIsFetchIngredientPurchasesHitted,
  getIsFetchItemPurchasesHitted,
  getPurchase as getDeletePurchase,
  getPurchase as getEditPurchase,
} from "./purchase.selector";

export function* _getIngredientPurchases() {
  try {
    yield put(setFetchIngredientPurchasesLoading(true));

    const search = yield select(getFetchIngredientPurchasesSearch);
    const sort = yield select(getFetchIngredientPurchasesSort);
    const key_by = yield select(getFetchIngredientPurchasesKeyBy);
    const page = yield select(getFetchIngredientPurchasesPage);
    const per_page = yield select(getFetchIngredientPurchasesPerPage);
    const includes = yield select(getFetchIngredientPurchasesIncludes);
    const market_id = yield select(getFetchIngredientPurchasesFilterMarketId);
    const branch_id = yield select(getFetchIngredientPurchasesFilterBranchId);
    const category_id = yield select(
      getFetchIngredientPurchasesFilterCategoryId
    );
    const supplier_id = yield select(
      getFetchIngredientPurchasesFilterSupplierId
    );
    const status = yield select(getFetchIngredientPurchasesFilterStatus);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PURCHASE_TYPES.TYPE_INGREDIENT,
        market_id,
        branch_id,
        category_id,
        supplier_id,
        status,
      },
    };

    const {
      meta: { message },
      data: { data: purchases },
    } = yield call(getPurchases, parameters);

    yield put(setIsFetchIngredientPurchasesHitted(true));
    yield put(setIsIngredientPurchasesHasMore(purchases.length > 0));

    if (page > 1) {
      yield put(appendIngredientPurchases(purchases));
    } else {
      yield put(setIngredientPurchases(purchases));
    }

    yield put(setFetchIngredientPurchasesSuccess(message));
    yield put(setFetchIngredientPurchasesLoading(false));
  } catch (error) {
    yield put(setFetchIngredientPurchasesFailed(error));
    yield put(setFetchIngredientPurchasesLoading(false));
  }
}
export function* _getItemPurchases() {
  try {
    yield put(setFetchItemPurchasesLoading(true));

    const search = yield select(getFetchItemPurchasesSearch);
    const sort = yield select(getFetchItemPurchasesSort);
    const key_by = yield select(getFetchItemPurchasesKeyBy);
    const page = yield select(getFetchItemPurchasesPage);
    const per_page = yield select(getFetchItemPurchasesPerPage);
    const includes = yield select(getFetchItemPurchasesIncludes);
    const market_id = yield select(getFetchItemPurchasesFilterMarketId);
    const branch_id = yield select(getFetchItemPurchasesFilterBranchId);
    const category_id = yield select(getFetchItemPurchasesFilterCategoryId);
    const supplier_id = yield select(getFetchItemPurchasesFilterSupplierId);
    const status = yield select(getFetchItemPurchasesFilterStatus);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        type: PURCHASE_TYPES.TYPE_EXPENSE,
        market_id,
        branch_id,
        category_id,
        supplier_id,
        status,
      },
    };

    const {
      meta: { message },
      data: { data: purchases },
    } = yield call(getPurchases, parameters);

    yield put(setIsFetchItemPurchasesHitted(true));
    yield put(setIsItemPurchasesHasMore(purchases.length > 0));

    if (page > 1) {
      yield put(appendItemPurchases(purchases));
    } else {
      yield put(setItemPurchases(purchases));
    }

    yield put(setFetchItemPurchasesSuccess(message));
    yield put(setFetchItemPurchasesLoading(false));
  } catch (error) {
    yield put(setFetchItemPurchasesFailed(error));
    yield put(setFetchItemPurchasesLoading(false));
  }
}
export function* _getPurchase({ payload: purchaseId }) {
  try {
    yield put(setFetchPurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(getPurchase, purchaseId);

    yield put(setIsFetchPurchaseHitted(true));
    yield put(setPurchase(purchase));

    yield put(setFetchPurchaseSuccess(message));
    yield put(setFetchPurchaseLoading(false));
  } catch (error) {
    yield put(setFetchPurchaseFailed(error));
    yield put(setFetchPurchaseLoading(false));
  }
}
export function* _calculateCreatePurchase({ payload: request }) {
  try {
    yield put(setCalculateCreatePurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(calcCreatePurchase, request);

    yield put(setIsCalculateCreatePurchaseHitted(true));
    yield put(setCalculateCreatePurchase(purchase));

    yield put(setCalculateCreatePurchaseSuccess(message));
    yield put(setCalculateCreatePurchaseLoading(false));
  } catch (error) {
    yield put(setCalculateCreatePurchaseFailed(error));
    yield put(setCalculateCreatePurchaseLoading(false));
  }
}
export function* _calculateUpdatePurchase({
  payload: { purchaseId, request },
}) {
  try {
    yield put(setCalculateUpdatePurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(calcUpdatePurchase, purchaseId, request);

    yield put(setIsCalculateUpdatePurchaseHitted(true));
    yield put(setCalculateUpdatePurchase(purchase));

    yield put(setCalculateUpdatePurchaseSuccess(message));
    yield put(setCalculateUpdatePurchaseLoading(false));
  } catch (error) {
    yield put(setCalculateUpdatePurchaseFailed(error));
    yield put(setCalculateUpdatePurchaseLoading(false));
  }
}
export function* _createPurchase({ payload: request }) {
  try {
    yield put(setCreatePurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(createPurchase, request);

    yield put(setIsCreatePurchaseHitted(true));
    yield put(setCreatePurchase(purchase));

    const isFetchIngredientPurchasesHitted = yield select(
      getIsFetchIngredientPurchasesHitted
    );
    const isFetchItemPurchasesHitted = yield select(
      getIsFetchItemPurchasesHitted
    );

    if (
      isFetchIngredientPurchasesHitted &&
      request?.type === PURCHASE_TYPES.TYPE_INGREDIENT
    ) {
      yield put(setFetchIngredientPurchasesPage(1));
      yield call(_getIngredientPurchases);
    }
    if (
      isFetchItemPurchasesHitted &&
      request?.type === PURCHASE_TYPES.TYPE_EXPENSE
    ) {
      yield put(setFetchItemPurchasesPage(1));
      yield call(_getItemPurchases);
    }

    yield put(setCreatePurchaseSuccess(message));
    yield put(setCreatePurchaseLoading(false));
  } catch (error) {
    yield put(setCreatePurchaseFailed(error));
    yield put(setCreatePurchaseLoading(false));
  }
}
export function* _updatePurchase({ payload: { purchaseId, request } }) {
  try {
    yield put(setUpdatePurchaseLoading(true));

    const {
      meta: { message },
      data: purchase,
    } = yield call(updatePurchase, purchaseId, request);

    yield put(setIsUpdatePurchaseHitted(true));
    yield put(setUpdatePurchase(purchase));

    const editPurchase = yield select(getEditPurchase);
    const isFetchIngredientPurchasesHitted = yield select(
      getIsFetchIngredientPurchasesHitted
    );
    const isFetchItemPurchasesHitted = yield select(
      getIsFetchItemPurchasesHitted
    );

    if (
      isFetchIngredientPurchasesHitted &&
      [request?.type, editPurchase?.type].includes(
        PURCHASE_TYPES.TYPE_INGREDIENT
      )
    ) {
      yield put(setFetchIngredientPurchasesPage(1));
      yield call(_getIngredientPurchases);
    }
    if (
      isFetchItemPurchasesHitted &&
      [request?.type, editPurchase?.type].includes(PURCHASE_TYPES.TYPE_EXPENSE)
    ) {
      yield put(setFetchItemPurchasesPage(1));
      yield call(_getItemPurchases);
    }

    yield put(setUpdatePurchaseSuccess(message));
    yield put(setUpdatePurchaseLoading(false));
  } catch (error) {
    yield put(setUpdatePurchaseFailed(error));
    yield put(setUpdatePurchaseLoading(false));
  }
}
export function* _deletePurchase({ payload: purchaseId }) {
  try {
    yield put(setDeletePurchaseLoading(true));

    const {
      meta: { message },
    } = yield call(deletePurchase, purchaseId);

    yield put(setIsDeletePurchaseHitted(true));

    const purchase = yield select(getDeletePurchase);
    const isFetchIngredientPurchasesHitted = yield select(
      getIsFetchIngredientPurchasesHitted
    );
    const isFetchItemPurchasesHitted = yield select(
      getIsFetchItemPurchasesHitted
    );

    if (
      isFetchIngredientPurchasesHitted &&
      purchase?.type === PURCHASE_TYPES.TYPE_INGREDIENT
    ) {
      yield put(setFetchIngredientPurchasesPage(1));
      yield call(_getIngredientPurchases);
    }
    if (
      isFetchItemPurchasesHitted &&
      purchase?.type === PURCHASE_TYPES.TYPE_EXPENSE
    ) {
      yield put(setFetchItemPurchasesPage(1));
      yield call(_getItemPurchases);
    }

    yield put(setDeletePurchaseSuccess(message));
    yield put(setDeletePurchaseLoading(false));
  } catch (error) {
    yield put(setDeletePurchaseFailed(error));
    yield put(setDeletePurchaseLoading(false));
  }
}

export function* onFetchIngredientPurchasesStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.FETCH_INGREDIENT_PURCHASES_START,
    _getIngredientPurchases
  );
}
export function* onFetchItemPurchasesStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.FETCH_ITEM_PURCHASES_START,
    _getItemPurchases
  );
}
export function* onFetchPurchaseStart() {
  yield takeLatest(PURCHASE_ACTION_TYPES.FETCH_PURCHASE_START, _getPurchase);
}
export function* onCalculateCreatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.CALCULATE_CREATE_PURCHASE_START,
    _calculateCreatePurchase
  );
}
export function* onCalculateUpdatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.CALCULATE_UPDATE_PURCHASE_START,
    _calculateUpdatePurchase
  );
}
export function* onCreatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.CREATE_PURCHASE_START,
    _createPurchase
  );
}
export function* onUpdatePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.UPDATE_PURCHASE_START,
    _updatePurchase
  );
}
export function* onDeletePurchaseStart() {
  yield takeLatest(
    PURCHASE_ACTION_TYPES.DELETE_PURCHASE_START,
    _deletePurchase
  );
}

export function* purchaseSaga() {
  yield all([
    call(onFetchIngredientPurchasesStart),
    call(onFetchItemPurchasesStart),
    call(onFetchPurchaseStart),
    call(onCalculateCreatePurchaseStart),
    call(onCalculateUpdatePurchaseStart),
    call(onCreatePurchaseStart),
    call(onUpdatePurchaseStart),
    call(onDeletePurchaseStart),
  ]);
}
