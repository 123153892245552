import EXPORT_EXCEL_ACTION_TYPES from "./export-excel.type";
import { createAction } from "../../utils/store.utils";

export const setExportExcelReports = (exportExcelReports) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS,
    exportExcelReports
  );
export const setExportExcelPurchase = (exportExcelPurchase) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE,
    exportExcelPurchase
  );

export const setExportExcelReportsLoading = (exportExcelReportsLoading) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_LOADING,
    exportExcelReportsLoading
  );
export const setExportExcelReportsSuccess = (exportExcelReportsSuccess) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_SUCCESS,
    exportExcelReportsSuccess
  );
export const setExportExcelReportsFailed = (exportExcelReportsFailed) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_REPORTS_FAILED,
    exportExcelReportsFailed
  );

export const setExportExcelPurchaseLoading = (exportExcelPurchaseLoading) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_LOADING,
    exportExcelPurchaseLoading
  );
export const setExportExcelPurchaseSuccess = (exportExcelPurchaseSuccess) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_SUCCESS,
    exportExcelPurchaseSuccess
  );
export const setExportExcelPurchaseFailed = (exportExcelPurchaseFailed) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_EXPORT_EXCEL_PURCHASE_FAILED,
    exportExcelPurchaseFailed
  );

export const exportExcelReportsStart = (request) =>
  createAction(EXPORT_EXCEL_ACTION_TYPES.EXPORT_EXCEL_REPORTS_START, request);
export const exportExcelPurchaseStart = (request) =>
  createAction(EXPORT_EXCEL_ACTION_TYPES.EXPORT_EXCEL_PURCHASE_START, request);

export const setIsExportExcelReportsHitted = (isExportExcelReportsHitted) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_IS_EXPORT_EXCEL_REPORTS_HITTED,
    isExportExcelReportsHitted
  );
export const setIsExportExcelPurchaseHitted = (isExportExcelPurchaseHitted) =>
  createAction(
    EXPORT_EXCEL_ACTION_TYPES.SET_IS_EXPORT_EXCEL_PURCHASE_HITTED,
    isExportExcelPurchaseHitted
  );

export const resetExportExcelReducer = () =>
  createAction(EXPORT_EXCEL_ACTION_TYPES.RESET_EXPORT_EXCEL_REDUCER);
