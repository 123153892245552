import { createSelector } from "reselect";

const statePurchaseSelector = ({ statePurchase }) => statePurchase;

export const getPurchaseTab = createSelector(
  [statePurchaseSelector],
  ({ purchaseTab }) => purchaseTab
);

export const getCreateStep = createSelector(
  [statePurchaseSelector],
  ({ createStep }) => createStep
);
export const getCreateValues = createSelector(
  [statePurchaseSelector],
  ({ createValues }) => createValues
);

export const getFormIngredients = createSelector(
  [statePurchaseSelector],
  ({ formIngredients }) => formIngredients
);
export const getFormItems = createSelector(
  [statePurchaseSelector],
  ({ formItems }) => formItems
);

export const getIsCalculateLoading = createSelector(
  [statePurchaseSelector],
  ({ isCalculateLoading }) => isCalculateLoading
);

export const getUpdatingIngredientIndex = createSelector(
  [statePurchaseSelector],
  ({ updatingIngredientIndex }) => updatingIngredientIndex
);
export const getUpdatingItemIndex = createSelector(
  [statePurchaseSelector],
  ({ updatingItemIndex }) => updatingItemIndex
);
