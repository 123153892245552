import { takeLatest, put, all, call, select } from "redux-saga/effects";

import {
  approveResupply,
  createResupply,
  declineResupply,
  deleteResupply,
  getResupplies,
  getResupply,
  receiveResupply,
  updateResupply,
} from "../../api/resupply.api";

import RESUPPLY_ACTION_TYPES from "./resupply.type";
import {
  appendResupplies,
  setApproveResupply,
  setApproveResupplyFailed,
  setApproveResupplyLoading,
  setApproveResupplySuccess,
  setCreateResupply,
  setCreateResupplyFailed,
  setCreateResupplyLoading,
  setCreateResupplySuccess,
  setDeclineResupply,
  setDeclineResupplyFailed,
  setDeclineResupplyLoading,
  setDeclineResupplySuccess,
  setDeleteResupplyFailed,
  setDeleteResupplyLoading,
  setDeleteResupplySuccess,
  setFetchResuppliesFailed,
  setFetchResuppliesLoading,
  setFetchResuppliesPage,
  setFetchResuppliesSuccess,
  setFetchResupplyFailed,
  setFetchResupplyLoading,
  setFetchResupplySuccess,
  setIsApproveResupplyHitted,
  setIsCreateResupplyHitted,
  setIsDeclineResupplyHitted,
  setIsDeleteResupplyHitted,
  setIsFetchResuppliesHitted,
  setIsFetchResupplyHitted,
  setIsReceiveResupplyHitted,
  setIsResuppliesHasMore,
  setIsUpdateResupplyHitted,
  setReceiveResupply,
  setReceiveResupplyFailed,
  setReceiveResupplyLoading,
  setReceiveResupplySuccess,
  setResupplies,
  setResupply,
  setUpdateResupply,
  setUpdateResupplyFailed,
  setUpdateResupplyLoading,
  setUpdateResupplySuccess,
} from "./resupply.action";
import {
  getFetchResuppliesFilterBranchId,
  getFetchResuppliesFilterCreatedBy,
  getFetchResuppliesFilterIsReceived,
  getFetchResuppliesFilterMarketId,
  getFetchResuppliesFilterStatus,
  getFetchResuppliesIncludes,
  getFetchResuppliesKeyBy,
  getFetchResuppliesPage,
  getFetchResuppliesPerPage,
  getFetchResuppliesSearch,
  getFetchResuppliesSort,
  getIsFetchResuppliesHitted,
} from "./resupply.selector";

export function* _getResupplies() {
  try {
    yield put(setFetchResuppliesLoading(true));

    const search = yield select(getFetchResuppliesSearch);
    const sort = yield select(getFetchResuppliesSort);
    const key_by = yield select(getFetchResuppliesKeyBy);
    const page = yield select(getFetchResuppliesPage);
    const per_page = yield select(getFetchResuppliesPerPage);
    const includes = yield select(getFetchResuppliesIncludes);
    const market_id = yield select(getFetchResuppliesFilterMarketId);
    const branch_id = yield select(getFetchResuppliesFilterBranchId);
    const created_by = yield select(getFetchResuppliesFilterCreatedBy);
    const status = yield select(getFetchResuppliesFilterStatus);
    const is_received = yield select(getFetchResuppliesFilterIsReceived);

    const parameters = {
      search,
      sort,
      key_by,
      page,
      per_page,
      includes,
      filter: {
        market_id,
        branch_id,
        created_by,
        status,
        is_received,
      },
    };

    const {
      meta: { message },
      data: { data: resupplies },
    } = yield call(getResupplies, parameters);

    yield put(setIsFetchResuppliesHitted(true));
    yield put(setIsResuppliesHasMore(resupplies.length > 0));

    if (page > 1) {
      yield put(appendResupplies(resupplies));
    } else {
      yield put(setResupplies(resupplies));
    }

    yield put(setFetchResuppliesSuccess(message));
    yield put(setFetchResuppliesLoading(false));
  } catch (error) {
    yield put(setFetchResuppliesFailed(error));
    yield put(setFetchResuppliesLoading(false));
  }
}
export function* _getResupply({ payload: resupplyId }) {
  try {
    yield put(setFetchResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(getResupply, resupplyId);

    yield put(setIsFetchResupplyHitted(true));
    yield put(setResupply(resupply));

    yield put(setFetchResupplySuccess(message));
    yield put(setFetchResupplyLoading(false));
  } catch (error) {
    yield put(setFetchResupplyFailed(error));
    yield put(setFetchResupplyLoading(false));
  }
}
export function* _createResupply({ payload: request }) {
  try {
    yield put(setCreateResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(createResupply, request);

    yield put(setIsCreateResupplyHitted(true));
    yield put(setCreateResupply(resupply));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setCreateResupplySuccess(message));
    yield put(setCreateResupplyLoading(false));
  } catch (error) {
    yield put(setCreateResupplyFailed(error));
    yield put(setCreateResupplyLoading(false));
  }
}
export function* _updateResupply({ payload: { resupplyId, request } }) {
  try {
    yield put(setUpdateResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(updateResupply, resupplyId, request);

    yield put(setIsUpdateResupplyHitted(true));
    yield put(setUpdateResupply(resupply));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setUpdateResupplySuccess(message));
    yield put(setUpdateResupplyLoading(false));
  } catch (error) {
    yield put(setUpdateResupplyFailed(error));
    yield put(setUpdateResupplyLoading(false));
  }
}
export function* _deleteResupply({ payload: resupplyId }) {
  try {
    yield put(setDeleteResupplyLoading(true));

    const {
      meta: { message },
    } = yield call(deleteResupply, resupplyId);

    yield put(setIsDeleteResupplyHitted(true));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setDeleteResupplySuccess(message));
    yield put(setDeleteResupplyLoading(false));
  } catch (error) {
    yield put(setDeleteResupplyFailed(error));
    yield put(setDeleteResupplyLoading(false));
  }
}
export function* _receiveResupply({ payload: { resupplyId, request } }) {
  try {
    yield put(setReceiveResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(receiveResupply, resupplyId, request);

    yield put(setIsReceiveResupplyHitted(true));
    yield put(setReceiveResupply(resupply));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setReceiveResupplySuccess(message));
    yield put(setReceiveResupplyLoading(false));
  } catch (error) {
    yield put(setReceiveResupplyFailed(error));
    yield put(setReceiveResupplyLoading(false));
  }
}
export function* _approveResupply({ payload: resupplyId }) {
  try {
    yield put(setApproveResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(approveResupply, resupplyId);

    yield put(setIsApproveResupplyHitted(true));
    yield put(setApproveResupply(resupply));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setApproveResupplySuccess(message));
    yield put(setApproveResupplyLoading(false));
  } catch (error) {
    yield put(setApproveResupplyFailed(error));
    yield put(setApproveResupplyLoading(false));
  }
}
export function* _declineResupply({ payload: resupplyId }) {
  try {
    yield put(setDeclineResupplyLoading(true));

    const {
      meta: { message },
      data: resupply,
    } = yield call(declineResupply, resupplyId);

    yield put(setIsDeclineResupplyHitted(true));
    yield put(setDeclineResupply(resupply));

    const isFetchResuppliesHitted = yield select(getIsFetchResuppliesHitted);

    if (isFetchResuppliesHitted) {
      yield put(setFetchResuppliesPage(1));
      yield call(_getResupplies);
    }

    yield put(setDeclineResupplySuccess(message));
    yield put(setDeclineResupplyLoading(false));
  } catch (error) {
    yield put(setDeclineResupplyFailed(error));
    yield put(setDeclineResupplyLoading(false));
  }
}

export function* onFetchResuppliesStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.FETCH_RESUPPLIES_START,
    _getResupplies
  );
}
export function* onFetchResupplyStart() {
  yield takeLatest(RESUPPLY_ACTION_TYPES.FETCH_RESUPPLY_START, _getResupply);
}
export function* onCreateResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.CREATE_RESUPPLY_START,
    _createResupply
  );
}
export function* onUpdateResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.UPDATE_RESUPPLY_START,
    _updateResupply
  );
}
export function* onDeleteResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.DELETE_RESUPPLY_START,
    _deleteResupply
  );
}
export function* onReceiveResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.RECEIVE_RESUPPLY_START,
    _receiveResupply
  );
}
export function* onApproveResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.APPROVE_RESUPPLY_START,
    _approveResupply
  );
}
export function* onDeclineResupplyStart() {
  yield takeLatest(
    RESUPPLY_ACTION_TYPES.DECLINE_RESUPPLY_START,
    _declineResupply
  );
}

export function* resupplySaga() {
  yield all([
    call(onFetchResuppliesStart),
    call(onFetchResupplyStart),
    call(onCreateResupplyStart),
    call(onUpdateResupplyStart),
    call(onDeleteResupplyStart),
    call(onReceiveResupplyStart),
    call(onApproveResupplyStart),
    call(onDeclineResupplyStart),
  ]);
}
