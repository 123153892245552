import PURCHASE_ACTION_TYPES from "./purchase.type";
import { createAction } from "../../utils/store.utils";

export const setIngredientPurchases = (ingredientPurchases) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_INGREDIENT_PURCHASES,
    ingredientPurchases
  );
export const setItemPurchases = (itemPurchases) =>
  createAction(PURCHASE_ACTION_TYPES.SET_ITEM_PURCHASES, itemPurchases);
export const setPurchase = (purchase) =>
  createAction(PURCHASE_ACTION_TYPES.SET_PURCHASE, purchase);
export const setCalculateCreatePurchase = (calculateCreatePurchase) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE,
    calculateCreatePurchase
  );
export const setCalculateUpdatePurchase = (calculateUpdatePurchase) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE,
    calculateUpdatePurchase
  );
export const setCreatePurchase = (createPurchase) =>
  createAction(PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE, createPurchase);
export const setUpdatePurchase = (updatePurchase) =>
  createAction(PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE, updatePurchase);

export const setIsIngredientPurchasesHasMore = (isIngredientPurchasesHasMore) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_INGREDIENT_PURCHASES_HAS_MORE,
    isIngredientPurchasesHasMore
  );
export const setIsItemPurchasesHasMore = (isItemPurchasesHasMore) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_ITEM_PURCHASES_HAS_MORE,
    isItemPurchasesHasMore
  );

export const setFetchIngredientPurchasesSearch = (
  fetchIngredientPurchasesSearch
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SEARCH,
    fetchIngredientPurchasesSearch
  );
export const setFetchIngredientPurchasesSort = (fetchIngredientPurchasesSort) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SORT,
    fetchIngredientPurchasesSort
  );
export const setFetchIngredientPurchasesKeyBy = (
  fetchIngredientPurchasesKeyBy
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_KEY_BY,
    fetchIngredientPurchasesKeyBy
  );
export const setFetchIngredientPurchasesPage = (fetchIngredientPurchasesPage) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_PAGE,
    fetchIngredientPurchasesPage
  );
export const setFetchIngredientPurchasesPerPage = (
  fetchIngredientPurchasesPerPage
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_PER_PAGE,
    fetchIngredientPurchasesPerPage
  );
export const setFetchIngredientPurchasesIncludes = (
  fetchIngredientPurchasesIncludes
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_INCLUDES,
    fetchIngredientPurchasesIncludes
  );
export const setFetchIngredientPurchasesFilterMarketId = (
  fetchIngredientPurchasesFilterMarketId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_MARKET_ID,
    fetchIngredientPurchasesFilterMarketId
  );
export const setFetchIngredientPurchasesFilterBranchId = (
  fetchIngredientPurchasesFilterBranchId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_BRANCH_ID,
    fetchIngredientPurchasesFilterBranchId
  );
export const setFetchIngredientPurchasesFilterCategoryId = (
  fetchIngredientPurchasesFilterCategoryId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_CATEGORY_ID,
    fetchIngredientPurchasesFilterCategoryId
  );
export const setFetchIngredientPurchasesFilterSupplierId = (
  fetchIngredientPurchasesFilterSupplierId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_SUPPLIER_ID,
    fetchIngredientPurchasesFilterSupplierId
  );
export const setFetchIngredientPurchasesFilterStatus = (
  fetchIngredientPurchasesFilterStatus
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FILTER_STATUS,
    fetchIngredientPurchasesFilterStatus
  );
export const setFetchIngredientPurchasesLoading = (
  fetchIngredientPurchasesLoading
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_LOADING,
    fetchIngredientPurchasesLoading
  );
export const setFetchIngredientPurchasesSuccess = (
  fetchIngredientPurchasesSuccess
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_SUCCESS,
    fetchIngredientPurchasesSuccess
  );
export const setFetchIngredientPurchasesFailed = (
  fetchIngredientPurchasesFailed
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_INGREDIENT_PURCHASES_FAILED,
    fetchIngredientPurchasesFailed
  );

export const setFetchItemPurchasesSearch = (fetchItemPurchasesSearch) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SEARCH,
    fetchItemPurchasesSearch
  );
export const setFetchItemPurchasesSort = (fetchItemPurchasesSort) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SORT,
    fetchItemPurchasesSort
  );
export const setFetchItemPurchasesKeyBy = (fetchItemPurchasesKeyBy) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_KEY_BY,
    fetchItemPurchasesKeyBy
  );
export const setFetchItemPurchasesPage = (fetchItemPurchasesPage) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_PAGE,
    fetchItemPurchasesPage
  );
export const setFetchItemPurchasesPerPage = (fetchItemPurchasesPerPage) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_PER_PAGE,
    fetchItemPurchasesPerPage
  );
export const setFetchItemPurchasesIncludes = (fetchItemPurchasesIncludes) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_INCLUDES,
    fetchItemPurchasesIncludes
  );
export const setFetchItemPurchasesFilterMarketId = (
  fetchItemPurchasesFilterMarketId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_MARKET_ID,
    fetchItemPurchasesFilterMarketId
  );
export const setFetchItemPurchasesFilterBranchId = (
  fetchItemPurchasesFilterBranchId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_BRANCH_ID,
    fetchItemPurchasesFilterBranchId
  );
export const setFetchItemPurchasesFilterCategoryId = (
  fetchItemPurchasesFilterCategoryId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_CATEGORY_ID,
    fetchItemPurchasesFilterCategoryId
  );
export const setFetchItemPurchasesFilterSupplierId = (
  fetchItemPurchasesFilterSupplierId
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_SUPPLIER_ID,
    fetchItemPurchasesFilterSupplierId
  );
export const setFetchItemPurchasesFilterStatus = (
  fetchItemPurchasesFilterStatus
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FILTER_STATUS,
    fetchItemPurchasesFilterStatus
  );
export const setFetchItemPurchasesLoading = (fetchItemPurchasesLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_LOADING,
    fetchItemPurchasesLoading
  );
export const setFetchItemPurchasesSuccess = (fetchItemPurchasesSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_SUCCESS,
    fetchItemPurchasesSuccess
  );
export const setFetchItemPurchasesFailed = (fetchItemPurchasesFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_ITEM_PURCHASES_FAILED,
    fetchItemPurchasesFailed
  );

export const setFetchPurchaseLoading = (fetchPurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_LOADING,
    fetchPurchaseLoading
  );
export const setFetchPurchaseSuccess = (fetchPurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_SUCCESS,
    fetchPurchaseSuccess
  );
export const setFetchPurchaseFailed = (fetchPurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_FETCH_PURCHASE_FAILED,
    fetchPurchaseFailed
  );

export const setCalculateCreatePurchaseLoading = (
  calculateCreatePurchaseLoading
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_LOADING,
    calculateCreatePurchaseLoading
  );
export const setCalculateCreatePurchaseSuccess = (
  calculateCreatePurchaseSuccess
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_SUCCESS,
    calculateCreatePurchaseSuccess
  );
export const setCalculateCreatePurchaseFailed = (
  calculateCreatePurchaseFailed
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_CREATE_PURCHASE_FAILED,
    calculateCreatePurchaseFailed
  );

export const setCalculateUpdatePurchaseLoading = (
  calculateUpdatePurchaseLoading
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_LOADING,
    calculateUpdatePurchaseLoading
  );
export const setCalculateUpdatePurchaseSuccess = (
  calculateUpdatePurchaseSuccess
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_SUCCESS,
    calculateUpdatePurchaseSuccess
  );
export const setCalculateUpdatePurchaseFailed = (
  calculateUpdatePurchaseFailed
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CALCULATE_UPDATE_PURCHASE_FAILED,
    calculateUpdatePurchaseFailed
  );

export const setCreatePurchaseLoading = (createPurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_LOADING,
    createPurchaseLoading
  );
export const setCreatePurchaseSuccess = (createPurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_SUCCESS,
    createPurchaseSuccess
  );
export const setCreatePurchaseFailed = (createPurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_CREATE_PURCHASE_FAILED,
    createPurchaseFailed
  );

export const setUpdatePurchaseLoading = (updatePurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_LOADING,
    updatePurchaseLoading
  );
export const setUpdatePurchaseSuccess = (updatePurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_SUCCESS,
    updatePurchaseSuccess
  );
export const setUpdatePurchaseFailed = (updatePurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_UPDATE_PURCHASE_FAILED,
    updatePurchaseFailed
  );

export const setDeletePurchaseLoading = (deletePurchaseLoading) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_LOADING,
    deletePurchaseLoading
  );
export const setDeletePurchaseSuccess = (deletePurchaseSuccess) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_SUCCESS,
    deletePurchaseSuccess
  );
export const setDeletePurchaseFailed = (deletePurchaseFailed) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_DELETE_PURCHASE_FAILED,
    deletePurchaseFailed
  );

export const appendIngredientPurchases = (ingredientPurchases) =>
  createAction(
    PURCHASE_ACTION_TYPES.APPEND_INGREDIENT_PURCHASES,
    ingredientPurchases
  );
export const appendItemPurchases = (itemPurchases) =>
  createAction(PURCHASE_ACTION_TYPES.APPEND_ITEM_PURCHASES, itemPurchases);

export const fetchIngredientPurchasesStart = () =>
  createAction(PURCHASE_ACTION_TYPES.FETCH_INGREDIENT_PURCHASES_START);
export const fetchItemPurchasesStart = () =>
  createAction(PURCHASE_ACTION_TYPES.FETCH_ITEM_PURCHASES_START);
export const fetchPurchaseStart = (purchaseId) =>
  createAction(PURCHASE_ACTION_TYPES.FETCH_PURCHASE_START, purchaseId);
export const calculateCreatePurchaseStart = (request) =>
  createAction(PURCHASE_ACTION_TYPES.CALCULATE_CREATE_PURCHASE_START, request);
export const calculateUpdatePurchaseStart = (purchaseId, request) =>
  createAction(PURCHASE_ACTION_TYPES.CALCULATE_UPDATE_PURCHASE_START, {
    purchaseId,
    request,
  });
export const createPurchaseStart = (request) =>
  createAction(PURCHASE_ACTION_TYPES.CREATE_PURCHASE_START, request);
export const updatePurchaseStart = (purchaseId, request) =>
  createAction(PURCHASE_ACTION_TYPES.UPDATE_PURCHASE_START, {
    purchaseId,
    request,
  });
export const deletePurchaseStart = (purchaseId) =>
  createAction(PURCHASE_ACTION_TYPES.DELETE_PURCHASE_START, purchaseId);

export const setIsFetchIngredientPurchasesHitted = (
  isFetchIngredientPurchasesHitted
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_FETCH_INGREDIENT_PURCHASES_HITTED,
    isFetchIngredientPurchasesHitted
  );
export const setIsFetchItemPurchasesHitted = (isFetchItemPurchasesHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_FETCH_ITEM_PURCHASES_HITTED,
    isFetchItemPurchasesHitted
  );
export const setIsFetchPurchaseHitted = (isFetchPurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_FETCH_PURCHASE_HITTED,
    isFetchPurchaseHitted
  );
export const setIsCalculateCreatePurchaseHitted = (
  isCalculateCreatePurchaseHitted
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_CREATE_PURCHASE_HITTED,
    isCalculateCreatePurchaseHitted
  );
export const setIsCalculateUpdatePurchaseHitted = (
  isCalculateUpdatePurchaseHitted
) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_UPDATE_PURCHASE_HITTED,
    isCalculateUpdatePurchaseHitted
  );
export const setIsCreatePurchaseHitted = (isCreatePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_CREATE_PURCHASE_HITTED,
    isCreatePurchaseHitted
  );
export const setIsUpdatePurchaseHitted = (isUpdatePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_UPDATE_PURCHASE_HITTED,
    isUpdatePurchaseHitted
  );
export const setIsDeletePurchaseHitted = (isDeletePurchaseHitted) =>
  createAction(
    PURCHASE_ACTION_TYPES.SET_IS_DELETE_PURCHASE_HITTED,
    isDeletePurchaseHitted
  );

export const resetPurchaseReducer = () =>
  createAction(PURCHASE_ACTION_TYPES.RESET_PURCHASE_REDUCER);
