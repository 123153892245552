import { createSelector } from "reselect";

const reportPurchaseSelector = ({ reportPurchase }) => reportPurchase;

export const getReportTotals = createSelector(
  [reportPurchaseSelector],
  ({ reportTotals }) => reportTotals
);
export const getReportPurchases = createSelector(
  [reportPurchaseSelector],
  ({ reportPurchases }) => reportPurchases
);
export const getReportPurchase = createSelector(
  [reportPurchaseSelector],
  ({ reportPurchase }) => reportPurchase
);

export const getIsReportPurchasesHasMore = createSelector(
  [reportPurchaseSelector],
  ({ isReportPurchasesHasMore }) => isReportPurchasesHasMore
);

export const getFetchReportsFilterMarketId = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterMarketId }) => fetchReportsFilterMarketId
);
export const getFetchReportsFilterBranchId = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterBranchId }) => fetchReportsFilterBranchId
);
export const getFetchReportsFilterSupplierId = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterSupplierId }) => fetchReportsFilterSupplierId
);
export const getFetchReportsFilterCategoriesIds = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterCategoriesIds }) => fetchReportsFilterCategoriesIds
);
export const getFetchReportsFilterTypes = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterTypes }) => fetchReportsFilterTypes
);
export const getFetchReportsFilterStatuses = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterStatuses }) => fetchReportsFilterStatuses
);
export const getFetchReportsFilterPurchaseAtBefore = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterPurchaseAtBefore }) => fetchReportsFilterPurchaseAtBefore
);
export const getFetchReportsFilterPurchaseAtAfter = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportsFilterPurchaseAtAfter }) => fetchReportsFilterPurchaseAtAfter
);

export const getFetchReportTotalsLoading = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportTotalsLoading }) => fetchReportTotalsLoading
);
export const getFetchReportTotalsSuccess = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportTotalsSuccess }) => fetchReportTotalsSuccess
);
export const getFetchReportTotalsFailed = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportTotalsFailed }) => fetchReportTotalsFailed
);

export const getFetchReportPurchasesSearch = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesSearch }) => fetchReportPurchasesSearch
);
export const getFetchReportPurchasesSort = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesSort }) => fetchReportPurchasesSort
);
export const getFetchReportPurchasesKeyBy = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesKeyBy }) => fetchReportPurchasesKeyBy
);
export const getFetchReportPurchasesPage = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesPage }) => fetchReportPurchasesPage
);
export const getFetchReportPurchasesPerPage = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesPerPage }) => fetchReportPurchasesPerPage
);
export const getFetchReportPurchasesLoading = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesLoading }) => fetchReportPurchasesLoading
);
export const getFetchReportPurchasesSuccess = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesSuccess }) => fetchReportPurchasesSuccess
);
export const getFetchReportPurchasesFailed = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchasesFailed }) => fetchReportPurchasesFailed
);

export const getFetchReportPurchaseLoading = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchaseLoading }) => fetchReportPurchaseLoading
);
export const getFetchReportPurchaseSuccess = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchaseSuccess }) => fetchReportPurchaseSuccess
);
export const getFetchReportPurchaseFailed = createSelector(
  [reportPurchaseSelector],
  ({ fetchReportPurchaseFailed }) => fetchReportPurchaseFailed
);

export const getIsFetchReportTotalsHitted = createSelector(
  [reportPurchaseSelector],
  ({ isFetchReportTotalsHitted }) => isFetchReportTotalsHitted
);
export const getIsFetchReportPurchasesHitted = createSelector(
  [reportPurchaseSelector],
  ({ isFetchReportPurchasesHitted }) => isFetchReportPurchasesHitted
);
export const getIsFetchReportPurchaseHitted = createSelector(
  [reportPurchaseSelector],
  ({ isFetchReportPurchaseHitted }) => isFetchReportPurchaseHitted
);
