import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { deleteFormItem } from "../../store/state-purchase/state-purchase.action";
import { getIsCalculateLoading } from "../../store/state-purchase/state-purchase.selector";
import {
  getCalculateCreatePurchaseLoading,
  getCalculateUpdatePurchaseLoading,
  getCreatePurchaseLoading,
  getUpdatePurchaseLoading,
} from "../../store/purchase/purchase.selector";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown, {
  DROPDOWN_ALIGNS,
  DROPDOWN_POSITIONS,
} from "../../components/dropdown-v2/dropdown.component";
import Shimmer, {
  SHIMMER_RADIUS,
} from "../../components/shimmer/shimmer.component";
import PurchaseFormItemEdit from "../purchase-form-item-edit/purchase-form-item-edit.widget";

import { PurchaseFormTableItemActionContainer } from "./purchase-form-table-item-action.style";

import { ReactComponent as EditSvg } from "../../assets/icons/Edit3.svg";
import { ReactComponent as TrashSvg } from "../../assets/icons/line/Trash2.svg";

const PurchaseFormTableItemAction = ({
  index,
  item,
  isLoading: isShimmerLoading = false,
}) => {
  const dispatch = useDispatch();

  const calculateCreatePurchaseLoading = useSelector(
    getCalculateCreatePurchaseLoading
  );
  const calculateUpdatePurchaseLoading = useSelector(
    getCalculateUpdatePurchaseLoading
  );
  const createPurchaseLoading = useSelector(getCreatePurchaseLoading);
  const updatePurchaseLoading = useSelector(getUpdatePurchaseLoading);
  const isCalculateLoading = useSelector(getIsCalculateLoading);

  const [isLoading, setIsLoading] = useState(false);

  const handleItemRemove = () => dispatch(deleteFormItem(index));

  useEffect(() => {
    setIsLoading(
      calculateCreatePurchaseLoading ||
        calculateUpdatePurchaseLoading ||
        createPurchaseLoading ||
        updatePurchaseLoading ||
        isCalculateLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculateCreatePurchaseLoading,
    calculateUpdatePurchaseLoading,
    createPurchaseLoading,
    updatePurchaseLoading,
    isCalculateLoading,
  ]);

  if (isShimmerLoading) {
    return (
      <PurchaseFormTableItemActionContainer>
        <Shimmer
          radius={SHIMMER_RADIUS.PX12}
          width="2.5rem"
          minWidth="2.5rem"
          maxWidth="2.5rem"
          height="2.5rem"
          minHeight="2.5rem"
          maxHeight="2.5rem"
        />
        <Shimmer
          radius={SHIMMER_RADIUS.PX12}
          width="2.5rem"
          minWidth="2.5rem"
          maxWidth="2.5rem"
          height="2.5rem"
          minHeight="2.5rem"
          maxHeight="2.5rem"
        />
      </PurchaseFormTableItemActionContainer>
    );
  }

  return (
    <PurchaseFormTableItemActionContainer>
      <Dropdown
        align={DROPDOWN_ALIGNS.END}
        position={[DROPDOWN_POSITIONS.LEFT, DROPDOWN_POSITIONS.TOP]}
        overlayMenu={
          <Dropdown.Form style={{ width: "32rem" }}>
            <PurchaseFormItemEdit index={index} item={item} />
          </Dropdown.Form>
        }
        zIndex={1001}
      >
        <Dropdown.Toggle>
          <Button
            type="button"
            buttonColor={BUTTON_COLORS.SECONDARY}
            buttonSize={BUTTON_SIZES.PX40}
            buttonType={BUTTON_TYPES.ICON}
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            <EditSvg />
          </Button>
        </Dropdown.Toggle>
      </Dropdown>
      <Button
        type="button"
        buttonColor={BUTTON_COLORS.LIGHT_DANGER}
        buttonSize={BUTTON_SIZES.PX40}
        buttonType={BUTTON_TYPES.ICON}
        onClick={handleItemRemove}
        isDisabled={isLoading}
        isLoading={isLoading}
      >
        <TrashSvg />
      </Button>
    </PurchaseFormTableItemActionContainer>
  );
};

export default PurchaseFormTableItemAction;
