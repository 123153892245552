import styled from "styled-components";

export const PurchaseFormTableQtyLabel = styled.p`
  font-size: 0.925rem;
  font-weight: 600;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;

  ${({ isNumeric }) =>
    isNumeric && 'font-family: "Space Mono", monospace !important;'}
`;
