import REPORT_PURCHASE_ACTION_TYPES from "./report-purchase.type";

export const REPORT_PURCHASE_INITIAL_STATE = {
  reportTotals: null,
  reportPurchases: [],
  reportPurchase: null,

  isReportPurchasesHasMore: true,

  fetchReportsFilterMarketId: null,
  fetchReportsFilterBranchId: null,
  fetchReportsFilterSupplierId: null,
  fetchReportsFilterCategoriesIds: null,
  fetchReportsFilterTypes: null,
  fetchReportsFilterStatuses: null,
  fetchReportsFilterPurchaseAtBefore: null,
  fetchReportsFilterPurchaseAtAfter: null,

  fetchReportTotalsLoading: false,
  fetchReportTotalsSuccess: null,
  fetchReportTotalsFailed: null,

  fetchReportPurchasesSearch: null,
  fetchReportPurchasesSort: null,
  fetchReportPurchasesKeyBy: null,
  fetchReportPurchasesPage: 1,
  fetchReportPurchasesPerPage: null,
  fetchReportPurchasesLoading: false,
  fetchReportPurchasesSuccess: null,
  fetchReportPurchasesFailed: null,

  fetchReportPurchaseLoading: false,
  fetchReportPurchaseSuccess: null,
  fetchReportPurchaseFailed: null,

  isFetchReportTotalsHitted: false,
  isFetchReportPurchasesHitted: false,
  isFetchReportPurchaseHitted: false,
};

export const reportPurchaseReducer = (
  state = REPORT_PURCHASE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_TOTALS:
      return { ...state, reportTotals: payload };
    case REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_PURCHASES:
      return { ...state, reportPurchases: payload };
    case REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_PURCHASE:
      return { ...state, reportPurchase: payload };

    case REPORT_PURCHASE_ACTION_TYPES.SET_IS_REPORT_PURCHASES_HAS_MORE:
      return { ...state, isReportPurchasesHasMore: payload };

    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID:
      return { ...state, fetchReportsFilterMarketId: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID:
      return { ...state, fetchReportsFilterBranchId: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_SUPPLIER_ID:
      return { ...state, fetchReportsFilterSupplierId: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_CATEGORIES_IDS:
      return { ...state, fetchReportsFilterCategoriesIds: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_TYPES:
      return { ...state, fetchReportsFilterTypes: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_STATUSES:
      return { ...state, fetchReportsFilterStatuses: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_PURCHASE_AT_BEFORE:
      return { ...state, fetchReportsFilterPurchaseAtBefore: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_PURCHASE_AT_AFTER:
      return { ...state, fetchReportsFilterPurchaseAtAfter: payload };

    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_LOADING:
      return { ...state, fetchReportTotalsLoading: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_SUCCESS:
      return { ...state, fetchReportTotalsSuccess: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_FAILED:
      return { ...state, fetchReportTotalsFailed: payload };

    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SEARCH:
      return { ...state, fetchReportPurchasesSearch: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SORT:
      return { ...state, fetchReportPurchasesSort: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_KEY_BY:
      return { ...state, fetchReportPurchasesKeyBy: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_PAGE:
      return { ...state, fetchReportPurchasesPage: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_PER_PAGE:
      return { ...state, fetchReportPurchasesPerPage: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_LOADING:
      return { ...state, fetchReportPurchasesLoading: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SUCCESS:
      return { ...state, fetchReportPurchasesSuccess: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_FAILED:
      return { ...state, fetchReportPurchasesFailed: payload };

    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_LOADING:
      return { ...state, fetchReportPurchaseLoading: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_SUCCESS:
      return { ...state, fetchReportPurchaseSuccess: payload };
    case REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_FAILED:
      return { ...state, fetchReportPurchaseFailed: payload };

    case REPORT_PURCHASE_ACTION_TYPES.APPEND_REPORT_PURCHASES:
      return {
        ...state,
        reportPurchases: [...state.reportPurchases, ...payload],
      };

    case REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_TOTALS_HITTED:
      return { ...state, isFetchReportTotalsHitted: payload };
    case REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_PURCHASES_HITTED:
      return { ...state, isFetchReportPurchasesHitted: payload };
    case REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_PURCHASE_HITTED:
      return { ...state, isFetchReportPurchaseHitted: payload };

    case REPORT_PURCHASE_ACTION_TYPES.RESET_REPORT_PURCHASE_REDUCER:
      return REPORT_PURCHASE_INITIAL_STATE;
    default:
      return state;
  }
};
