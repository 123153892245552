import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import { PurchaseFormTableQtyLabel } from "./purchase-form-table-qty.style";

const PurchaseFormTableQty = ({ quantity }) => {
  const { t } = useTranslation();

  return (
    <PurchaseFormTableQtyLabel isNumeric={!isEmpty(quantity)}>
      {!isEmpty(quantity) ? quantity : t("No information")}
    </PurchaseFormTableQtyLabel>
  );
};

export default PurchaseFormTableQty;
