import { useTranslation } from "react-i18next";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown, {
  DROPDOWN_ALIGNS,
  DROPDOWN_POSITIONS,
} from "../../components/dropdown-v2/dropdown.component";
import SelectSupplierCreateForm from "../select-supplier-create-form/select-supplier-create-form.widget";

import { SelectSupplierCreateContainer } from "./select-supplier-create.style";

import { ReactComponent as PlusSvg } from "../../assets/icons/Plus1.svg";

const SelectSupplierCreate = () => {
  const { t } = useTranslation();

  return (
    <SelectSupplierCreateContainer>
      <Dropdown
        align={DROPDOWN_ALIGNS.START}
        position={[DROPDOWN_POSITIONS.LEFT, DROPDOWN_POSITIONS.BOTTOM]}
        overlayMenu={
          <Dropdown.Form style={{ width: "32rem" }}>
            <SelectSupplierCreateForm />
          </Dropdown.Form>
        }
        zIndex={1001}
      >
        <Dropdown.Toggle>
          <Button
            type="button"
            buttonColor={BUTTON_COLORS.SECONDARY}
            buttonSize={BUTTON_SIZES.NORMAL}
            buttonType={BUTTON_TYPES.TEXT_ICON}
            isFullWidth
          >
            <PlusSvg /> {t("Add New Supplier")}
          </Button>
        </Dropdown.Toggle>
      </Dropdown>
    </SelectSupplierCreateContainer>
  );
};

export default SelectSupplierCreate;
