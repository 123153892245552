import apiService from "./api";

export const getRenewalTransfers = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/renewal-transfers", params }))
    .data;
export const getRenewalTransfer = async (transferId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/renewal-transfers/${transferId}`,
      params,
    })
  ).data;
export const createRenewalTransfer = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/renewal-transfers", request }))
    .data;
export const updateRenewalTransfer = async (transferId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/renewal-transfers/${transferId}`,
      request,
    })
  ).data;
export const deleteRenewalTransfer = async (transferId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/renewal-transfers/${transferId}`,
    })
  ).data;
