import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getProductCategories } from "../../api/product-category.api";

import ORDER_FORM_CATEGORY_ACTION_TYPES from "./order-form-category.type";
import {
  appendCategories,
  setFetchCategoriesFailed,
  setFetchCategoriesLoading,
  setFetchCategoriesSuccess,
  setIsFetchCategoriesHitted,
  setIsCategoriesHasMore,
  setCategories,
} from "./order-form-category.action";
import { getFetchCategoriesParams } from "./order-form-category.selector";

export function* _getFetchCategories() {
  try {
    const fetchCategoriesParams = yield select(getFetchCategoriesParams);
    const parameters = getParamsWithDefault(fetchCategoriesParams);

    yield put(setFetchCategoriesLoading(true));

    const {
      meta: { message },
      data: { data: categories },
    } = yield call(getProductCategories, parameters);

    yield put(setIsFetchCategoriesHitted(true));
    yield put(setIsCategoriesHasMore(Object.keys(categories).length > 0));

    if (parameters.page > 1) {
      yield put(appendCategories(categories));
    } else {
      yield put(setCategories(categories));
    }

    yield put(setFetchCategoriesSuccess(message));
    yield put(setFetchCategoriesLoading(false));
  } catch (error) {
    yield put(setFetchCategoriesFailed(error));
    yield put(setFetchCategoriesLoading(false));
  }
}

export function* onFetchCategoriesStart() {
  yield takeLatest(
    ORDER_FORM_CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_START,
    _getFetchCategories
  );
}

export function* orderFormCategorySaga() {
  yield all([call(onFetchCategoriesStart)]);
}
