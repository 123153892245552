import styled, { css } from "styled-components";

import { COLORS, FORM_INPUT_STYLES } from "../../utils/styles.utils";

export const FormInputRadioboxInput = styled.input.attrs({ type: "radio" })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

export const FormInputRadioboxMark = styled.span`
  position: absolute;
  top: 50%;
  min-height: 1rem;
  width: 1rem;
  background-color: transparent;
  transform: translateY(-50%);

  border-width: 1px;
  border-style: solid;
  border-color: #c1becf;
  border-radius: 50%;

  &::after {
    content: "";
    position: absolute;
    display: none;

    top: 50%;
    left: 50%;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: transparent;
    transform: translate(-50%, -50%);
  }
`;

export const FormInputRadioboxField = styled.label`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  height: 3rem;
  width: 100%;

  border-width: ${FORM_INPUT_STYLES.INPUT_BORDER_WIDTH};
  border-style: ${FORM_INPUT_STYLES.INPUT_BORDER_STYLE};
  border-color: ${FORM_INPUT_STYLES.INPUT_BORDER_COLOR};
  border-radius: ${FORM_INPUT_STYLES.INPUT_BORDER_RADIUS};
  transition: all 0.3s ease;

  font-size: ${FORM_INPUT_STYLES.INPUT_FONT_SIZE};
  font-weight: ${FORM_INPUT_STYLES.INPUT_FONT_WEIGHT};
  white-space: nowrap;
  user-select: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover {
    background-color: #f9f9f9;
  }

  ${FormInputRadioboxInput}:checked ~ ${FormInputRadioboxMark} {
    border-color: ${COLORS.PRIMARY};

    &::after {
      display: block;
      background-color: ${COLORS.PRIMARY};
    }
  }
`;

export const FormInputRadioboxContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${FormInputRadioboxMark} {
    ${({ isRightToLeft }) =>
      isRightToLeft
        ? css`
            right: 1rem;
            left: initial;
          `
        : css`
            right: initial;
            left: 1rem;
          `}
  }
  ${FormInputRadioboxField} {
    background-color: ${({ isSolid }) =>
      isSolid
        ? FORM_INPUT_STYLES.INPUT_SOLID_BG_COLOR
        : FORM_INPUT_STYLES.INPUT_BACKGROUND_COLOR};

    ${({ isRightToLeft }) =>
      isRightToLeft
        ? css`
            padding-left: 1.5rem;
            padding-right: calc(1rem + 1.25rem + 0.5rem);
          `
        : css`
            padding-left: calc(1rem + 1.25rem + 0.5rem);
            padding-right: 1.5rem;
          `}
  }
`;
