import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import {
  FormStepHeaderContainer,
  FormStepHeaderDescription,
  FormStepHeaderInfo,
  FormStepHeaderLower,
  FormStepHeaderSeparator,
  FormStepHeaderTitle,
  FormStepHeaderToolbar,
  FormStepHeaderUpper,
} from "./form-step-header.style";

const FormStepHeader = ({ title, description, toolbar }) => {
  const { t } = useTranslation();

  return (
    <FormStepHeaderContainer>
      <FormStepHeaderUpper>
        <FormStepHeaderInfo>
          <FormStepHeaderTitle>
            {!isEmpty(title) ? title : t("No name")}
          </FormStepHeaderTitle>
          <FormStepHeaderDescription>
            {!isEmpty(description) ? description : t("No information")}
          </FormStepHeaderDescription>
        </FormStepHeaderInfo>
        {!isEmpty(toolbar) && (
          <FormStepHeaderToolbar>{toolbar}</FormStepHeaderToolbar>
        )}
      </FormStepHeaderUpper>
      <FormStepHeaderLower>
        <FormStepHeaderSeparator />
      </FormStepHeaderLower>
    </FormStepHeaderContainer>
  );
};

export default FormStepHeader;
