import moment from "moment";

import {
  PURCHASE_STATUSES,
  PURCHASE_TABS,
  PURCHASE_TYPES,
} from "../../constants/purchase.constant";

import { PURCHASE_STEPS } from "../../widgets/purchase-create/purchase-create.widget";

import STATE_PURCHASE_ACTION_TYPES from "./state-purchase.type";

export const STATE_PURCHASE_INITIAL_STATE = {
  purchaseTab: PURCHASE_TABS.INGREDIENTS,

  formIngredients: [],
  formItems: [],

  createStep: PURCHASE_STEPS.PURCHASE_DETAILS,
  createValues: {
    category_id: "",
    supplier_id: "",
    resupply_id: "",
    type: PURCHASE_TYPES.TYPE_INGREDIENT,
    status: PURCHASE_STATUSES.STATUS_PAID,
    invoice_img: "",
    is_taxable: "",
    purchase_at: moment().valueOf(),
  },

  isCalculateLoading: false,

  updatingIngredientIndex: null,
  updatingItemIndex: null,
};

export const statePurchaseReducer = (
  state = STATE_PURCHASE_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_PURCHASE_ACTION_TYPES.SET_PURCHASE_TAB:
      return { ...state, purchaseTab: payload };

    case STATE_PURCHASE_ACTION_TYPES.SET_CREATE_STEP:
      return { ...state, createStep: payload };
    case STATE_PURCHASE_ACTION_TYPES.SET_CREATE_VALUES:
      return { ...state, createValues: payload };
    case STATE_PURCHASE_ACTION_TYPES.RESET_CREATE_VALUES: {
      const createValues = STATE_PURCHASE_INITIAL_STATE.createValues;
      createValues.category_id = state.formCategoryId;

      return { ...state, createValues };
    }

    case STATE_PURCHASE_ACTION_TYPES.SET_FORM_INGREDIENTS:
      return { ...state, formIngredients: payload };
    case STATE_PURCHASE_ACTION_TYPES.APPEND_FORM_INGREDIENT: {
      const formIngredients = [...state.formIngredients];
      formIngredients.push(payload);

      return { ...state, formIngredients };
    }
    case STATE_PURCHASE_ACTION_TYPES.UPDATE_FORM_INGREDIENT: {
      const formIngredients = [...state.formIngredients];
      if (payload.index in formIngredients) {
        formIngredients[payload.index] = payload.value;
      }

      return { ...state, formIngredients };
    }
    case STATE_PURCHASE_ACTION_TYPES.DELETE_FORM_INGREDIENT: {
      const formIngredients = [...state.formIngredients];
      if (payload in formIngredients) {
        formIngredients.splice(payload, 1);
      }

      return { ...state, formIngredients };
    }

    case STATE_PURCHASE_ACTION_TYPES.SET_FORM_ITEMS:
      return { ...state, formItems: payload };
    case STATE_PURCHASE_ACTION_TYPES.APPEND_PURCHASE_ITEM: {
      const formItems = [...state.formItems];
      formItems.push(payload);

      return { ...state, formItems };
    }
    case STATE_PURCHASE_ACTION_TYPES.UPDATE_PURCHASE_ITEM: {
      const formItems = [...state.formItems];
      if (payload.index in formItems) {
        formItems[payload.index] = payload.value;
      }

      return { ...state, formItems };
    }
    case STATE_PURCHASE_ACTION_TYPES.DELETE_PURCHASE_ITEM: {
      const formItems = [...state.formItems];
      if (payload in formItems) formItems.splice(payload, 1);

      return { ...state, formItems };
    }

    case STATE_PURCHASE_ACTION_TYPES.SET_IS_CALCULATE_LOADING:
      return { ...state, isCalculateLoading: payload };

    case STATE_PURCHASE_ACTION_TYPES.SET_UPDATING_INGREDIENT_INDEX:
      return { ...state, updatingIngredientIndex: payload };
    case STATE_PURCHASE_ACTION_TYPES.SET_UPDATING_ITEM_INDEX:
      return { ...state, updatingItemIndex: payload };

    case STATE_PURCHASE_ACTION_TYPES.RESET_STATE_PURCHASE_REDUCER:
      return STATE_PURCHASE_INITIAL_STATE;
    default:
      return state;
  }
};
