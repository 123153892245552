import ORDER_FORM_PRODUCT_ACTION_TYPES from "./order-form-product.type";

const ORDER_FORM_PRODUCT_INITIAL_STATE = {
  baseProducts: [],
  sortProducts: [],

  sortValues: {},

  isProductsHasMore: true,

  fetchProductsParams: {},
  fetchProductsLoading: false,
  fetchProductsSuccess: null,
  fetchProductsFailed: null,

  sortProductsLoading: false,
  sortProductsSuccess: null,
  sortProductsFailed: null,

  isFetchProductsHitted: false,
  isSortProductsHitted: false,
};

export const orderFormProductReducer = (
  state = ORDER_FORM_PRODUCT_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  const sortValues = { ...state.sortValues };

  switch (type) {
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_BASE_PRODUCTS:
      return { ...state, baseProducts: payload, sortProducts: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS:
      return { ...state, sortProducts: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.APPEND_BASE_PRODUCTS:
      return {
        ...state,
        baseProducts: [...state.baseProducts, ...payload],
        sortProducts: [...state.sortProducts, ...payload],
      };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.SWITCH_SORT_VALUE:
      sortValues[payload.key] = payload.value;
      return { ...state, sortValues };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.REMOVE_SORT_VALUE:
      if (payload in sortValues) delete sortValues[payload];
      return { ...state, sortValues };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_PRODUCTS_HAS_MORE:
      return { ...state, isProductsHasMore: payload };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_PARAMS:
      return { ...state, fetchProductsParams: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_LOADING:
      return { ...state, fetchProductsLoading: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_SUCCESS:
      return { ...state, fetchProductsSuccess: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_FETCH_PRODUCTS_FAILED:
      return { ...state, fetchProductsFailed: payload };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_LOADING:
      return { ...state, sortProductsLoading: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_SUCCESS:
      return { ...state, sortProductsSuccess: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_SORT_PRODUCTS_FAILED:
      return { ...state, sortProductsFailed: payload };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_FETCH_PRODUCTS_HITTED:
      return { ...state, isFetchProductsHitted: payload };
    case ORDER_FORM_PRODUCT_ACTION_TYPES.SET_IS_SORT_PRODUCTS_HITTED:
      return { ...state, isSortProductsHitted: payload };

    case ORDER_FORM_PRODUCT_ACTION_TYPES.RESET_ORDER_FORM_PRODUCT_REDUCER:
      return ORDER_FORM_PRODUCT_INITIAL_STATE;
    default:
      return state;
  }
};
