import apiService from "./api";

export const getResupplies = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/resupplies", params })).data;
export const getResupply = async (resupplyId) =>
  (await apiService.innerGet({ urlPath: `/v2/resupplies/${resupplyId}` })).data;
export const createResupply = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/resupplies", request })).data;
export const updateResupply = async (resupplyId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/resupplies/${resupplyId}`,
      request,
    })
  ).data;
export const deleteResupply = async (resupplyId) =>
  (
    await apiService.innerDelete({
      urlPath: `/v2/resupplies/${resupplyId}`,
    })
  ).data;

export const receiveResupply = async (resupplyId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/resupplies/${resupplyId}/receive`,
      request,
    })
  ).data;
export const approveResupply = async (resupplyId) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/resupplies/${resupplyId}/approve`,
    })
  ).data;
export const declineResupply = async (resupplyId) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/resupplies/${resupplyId}/decline`,
    })
  ).data;
