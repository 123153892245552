const STATE_RECIPE_ACTION_TYPES = {
  RESET_STATE_RECIPE_REDUCER: "state-recipe/RESET_STATE_RECIPE_REDUCER",

  SET_RECIPE_INGREDIENTS: "state-recipe/SET_RECIPE_INGREDIENTS",

  APPEND_RECIPE_INGREDIENT: "state-recipe/APPEND_RECIPE_INGREDIENT",
  UPDATE_RECIPE_INGREDIENT: "state-recipe/UPDATE_RECIPE_INGREDIENT",
  DELETE_RECIPE_INGREDIENT: "state-recipe/DELETE_RECIPE_INGREDIENT",
};

export default STATE_RECIPE_ACTION_TYPES;
