import { createAction } from "../../utils/store.utils";

import STATE_RESUPPLY_ACTION_TYPES from "./state-resupply.type";

export const setResupplyDialogKey = (resupplyDialogKey) =>
  createAction(
    STATE_RESUPPLY_ACTION_TYPES.SET_RESUPPLY_DIALOG_KEY,
    resupplyDialogKey
  );

export const setResupplyIngredients = (resupplyIngredients) =>
  createAction(
    STATE_RESUPPLY_ACTION_TYPES.SET_RESUPPLY_INGREDIENTS,
    resupplyIngredients
  );
export const setReceivedIngredients = (receivedIngredients) =>
  createAction(
    STATE_RESUPPLY_ACTION_TYPES.SET_RECEIVED_INGREDIENTS,
    receivedIngredients
  );

export const appendResupplyIngredient = (value) =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.APPEND_RESUPPLY_INGREDIENT, value);
export const updateResupplyIngredient = (index, value) =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.UPDATE_RESUPPLY_INGREDIENT, {
    index,
    value,
  });
export const deleteResupplyIngredient = (index) =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.DELETE_RESUPPLY_INGREDIENT, index);

export const upsertReceivedIngredient = (key, value) =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.UPSERT_RECEIVED_INGREDIENT, {
    key,
    value,
  });
export const deleteReceivedIngredient = (key) =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.DELETE_RECEIVED_INGREDIENT, key);

export const resetStateResupplyReducer = () =>
  createAction(STATE_RESUPPLY_ACTION_TYPES.RESET_STATE_RESUPPLY_REDUCER);
