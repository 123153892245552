import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getNumberFormat } from "../../utils/formatter.utils";
import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";
import {
  appendIngredientsStart,
  appendSearchIngredients,
  fetchIngredientsStart,
  searchIngredientsStart,
  setAppendIngredientsParams,
  setFetchIngredientsParams,
  setSearchIngredientsParams,
} from "../../store/select-ingredient/select-ingredient.action";
import {
  getAppendIngredientsLoading,
  getAppendIngredientsParams,
  getFetchIngredientsLoading,
  getFetchIngredientsParams,
  getIngredients,
  getIsFetchIngredientsHitted,
  getIsIngredientsHasMore,
  getIsSearchIngredientsHasMore,
  getIsSearchIngredientsHitted,
  getSearchIngredients,
  getSearchIngredientsLoading,
  getSearchIngredientsParams,
} from "../../store/select-ingredient/select-ingredient.selector";

import FormSelectAsync from "../../components/form-select-async/form-select-async.component";

import {
  getAdditionalStyles,
  SelectIngredientOptionContainer,
  SelectIngredientOptionName,
  SelectIngredientOptionStock,
} from "./select-ingredient.style";

export const SELECT_INGREDIENT_TYPES = {
  ID: "ID",
  GROUP_ID: "GROUP_ID",
};

const getValueKeyByType = (selectType) => {
  switch (selectType) {
    case SELECT_INGREDIENT_TYPES.GROUP_ID:
      return "group_id";
    case SELECT_INGREDIENT_TYPES.ID:
    default:
      return "id";
  }
};

const getFilterKeyByType = (selectType) => {
  switch (selectType) {
    case SELECT_INGREDIENT_TYPES.GROUP_ID:
      return "ingredients_group_ids";
    case SELECT_INGREDIENT_TYPES.ID:
    default:
      return "ingredients_ids";
  }
};

const getFormatOptionLabel =
  ({ translation }) =>
  (ingredient) => {
    const { i18n, t } = translation ?? {};
    const { name_en, name_ar, storage_stock, storage_unit } = ingredient ?? {};

    const ingredientName =
      ingredient?.[`name_${i18n.language}`] ?? name_en ?? name_ar;
    const ingredientInfo =
      !isEmpty(storage_stock) && !isEmpty(storage_unit)
        ? `${getNumberFormat(storage_stock)} ${storage_unit}`
        : !isEmpty(storage_stock)
        ? getNumberFormat(storage_stock)
        : storage_stock ?? "-";

    return (
      <SelectIngredientOptionContainer>
        <SelectIngredientOptionName>
          {!isEmpty(ingredientName) ? ingredientName : t("No name")}
        </SelectIngredientOptionName>
        <SelectIngredientOptionStock>
          {ingredientInfo}
        </SelectIngredientOptionStock>
      </SelectIngredientOptionContainer>
    );
  };

const SelectIngredient = ({
  paramsMarketId,
  paramsBranchId,
  selectSize,
  selectType,
  label,
  name,
  value,
  exValues,
  placeholder,
  onChange,
  isDisabled = false,
  isMultiple = false,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const translation = useTranslation();

  const [options, setOptions] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const isRightToLeft = useSelector(getIsRightToLeft);

  const appendIngredientsParams = useSelector(getAppendIngredientsParams);
  const appendIngredientsLoading = useSelector(getAppendIngredientsLoading);

  const isFetchIngredientsHitted = useSelector(getIsFetchIngredientsHitted);
  const isIngredientsHasMore = useSelector(getIsIngredientsHasMore);
  const fetchIngredientsParams = useSelector(getFetchIngredientsParams);
  const fetchIngredientsLoading = useSelector(getFetchIngredientsLoading);
  const ingredients = useSelector(getIngredients);

  const isSearchIngredientsHitted = useSelector(getIsSearchIngredientsHitted);
  const isSearchIngredientsHasMore = useSelector(getIsSearchIngredientsHasMore);
  const searchIngredientsParams = useSelector(getSearchIngredientsParams);
  const searchIngredientsLoading = useSelector(getSearchIngredientsLoading);
  const searchIngredients = useSelector(getSearchIngredients);

  const valueKey = getValueKeyByType(selectType);
  const filterKey = getFilterKeyByType(selectType);
  const formatOptionLabel = getFormatOptionLabel({ translation });
  const additionalStyles = getAdditionalStyles({
    selectSize,
    isRightToLeft,
  });

  const getParams = (parameters) => {
    const params = { ...parameters };
    params.page = 1;
    params.per_page = 20;
    params.key_by = valueKey;
    params.filter = params?.filter ?? {};
    params.filter.market_id = paramsMarketId;
    params.filter.branch_id = paramsBranchId;
    params.filter.is_group =
      selectType === SELECT_INGREDIENT_TYPES.GROUP_ID ? 1 : 0;

    return params;
  };

  const handleSearchChange = (searchKey) => setSearchKey(searchKey);

  const handleMainFetch = () => {
    const params = getParams(fetchIngredientsParams);
    dispatch(setFetchIngredientsParams(params));
    dispatch(fetchIngredientsStart());
  };
  const handleSearchFetch = () => {
    if (isEmpty(searchKey)) {
      setIsLoading(false);
      return;
    }

    const params = getParams(searchIngredientsParams);
    params.search = searchKey;

    dispatch(setSearchIngredientsParams(params));
    dispatch(searchIngredientsStart());
  };
  const handleAppendValue = () => {
    const isValueExist = isMultiple
      ? isEmpty(value?.filter((val) => isEmpty(ingredients?.[val])))
      : !isEmpty(ingredients?.[value]);

    if (!isEmpty(value) && isValueExist) {
      setIsLoading(false);
      return;
    }

    const params = getParams(appendIngredientsParams);
    params.filter[filterKey] = isMultiple ? value : [value];

    dispatch(setAppendIngredientsParams(params));
    dispatch(appendIngredientsStart());
  };

  const handleMainBottomScroll = () => {
    if (
      !isFetchIngredientsHitted ||
      !isIngredientsHasMore ||
      fetchIngredientsLoading
    ) {
      return;
    }

    const params = { ...fetchIngredientsParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setFetchIngredientsParams(params));
    dispatch(fetchIngredientsStart());
  };
  const handleSearchBottomScroll = () => {
    if (
      !isSearchIngredientsHitted ||
      !isSearchIngredientsHasMore ||
      searchIngredientsLoading
    ) {
      return;
    }

    const params = { ...searchIngredientsParams };
    params.page = params.page + 1;
    params.per_page = 20;

    dispatch(setSearchIngredientsParams(params));
    dispatch(searchIngredientsStart());
  };

  useEffect(() => {
    setIsLoading(
      fetchIngredientsLoading ||
        searchIngredientsLoading ||
        appendIngredientsLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchIngredientsLoading,
    searchIngredientsLoading,
    appendIngredientsLoading,
  ]);

  useEffect(() => {
    const optionsTimeout = setTimeout(() => {
      const resultOptions = isSearching ? searchIngredients : ingredients;
      const filterOptions = Object.values(resultOptions).filter(
        (option) => !(exValues?.includes(option?.[valueKey]) ?? false)
      );

      setOptions(filterOptions);
    }, 300);

    return () => clearTimeout(optionsTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, searchIngredients, isSearching, exValues]);

  useEffect(() => {
    setIsLoading(true);

    const initializeTimeout = setTimeout(() => handleMainFetch(), 300);
    return () => clearTimeout(initializeTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, paramsBranchId, selectType]);

  useEffect(() => {
    dispatch(appendSearchIngredients(ingredients));

    setIsLoading(true);
    setIsSearching(!isEmpty(searchKey));

    const searchTimeout = setTimeout(() => handleSearchFetch(), 1500);
    return () => clearTimeout(searchTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, paramsBranchId, selectType, searchKey]);

  useEffect(() => {
    if (!isEmpty(value)) {
      setIsLoading(true);

      const appendTimeout = setTimeout(() => handleAppendValue(), 300);
      return () => clearTimeout(appendTimeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsMarketId, paramsBranchId, selectType, value]);

  return (
    <FormSelectAsync
      selectSize={selectSize}
      formatOptionLabel={formatOptionLabel}
      additionalStyles={additionalStyles}
      label={label}
      labelKey={["icon", "name_en", "name_ar", "storage_unit", "storage_stock"]}
      valueKey={valueKey}
      name={name}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      onSearchChange={handleSearchChange}
      onBottomScroll={
        isSearching ? handleSearchBottomScroll : handleMainBottomScroll
      }
      value={value}
      isLoading={isLoading}
      isDisabled={isDisabled}
      isMultiple={isMultiple}
      {...otherProps}
    />
  );
};

export default SelectIngredient;
