import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";
import { showCustomErrorMessage } from "../../utils/popup.utils";

import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../button/button.component";

import {
  FormFileAreaContainer,
  FormFileAreaContent,
  FormFileAreaDescription,
  FormFileAreaIcon,
  FormFileAreaImage,
  FormFileAreaInput,
  FormFileAreaRemove,
  FormFileAreaTitle,
} from "./form-file-area.style";

import { ReactComponent as TrashSvg } from "../../assets/icons/line/Trash2.svg";
import { ReactComponent as ImageSvg } from "../../assets/icons/stroke/Image.svg";

const FormFileArea = ({
  label,
  name,
  value,
  onChange,
  isDisabled = false,
  isSolid = false,
}) => {
  const { t } = useTranslation();

  const inputFileRef = useRef();
  const removeImageRef = useRef();

  const [isDraging, setIsDraging] = useState(false);
  const [isImageValid, setIsImageValid] = useState(true);

  const handleUploadedImages = (files = []) => {
    if (isDisabled) return;

    let isMoreThan2MbExist = false;

    const [selectedFile] = [...files].filter(({ type, size }) => {
      const isImage = type.match(/image.*/) !== null;
      const isNotMoreThan2Mb = size / 1024 / 1024 <= 2;

      isMoreThan2MbExist = isMoreThan2MbExist || !isNotMoreThan2Mb;

      return isImage && isNotMoreThan2Mb;
    });

    if (isMoreThan2MbExist)
      showCustomErrorMessage(t("File size can't be more than 2 mb"));

    onChange?.({ name, value: selectedFile });
  };

  const handleImageUploadClick = ({ target }) => {
    if (isDisabled) return;
    if (removeImageRef?.current?.contains(target)) return;
    inputFileRef?.current?.click();
  };
  const handleImageDeleteClick = () => {
    if (isDisabled) return;
    onChange?.({ name, value: null });
  };

  const handleImageDragStart = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraging(true);
  };
  const handleImageDragEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDraging(false);
  };
  const handleImageDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleImageDragEnd(event);

    const {
      dataTransfer: { files },
    } = event;
    handleUploadedImages(files);
  };

  const handleImageUploaded = ({ target }) =>
    handleUploadedImages(target?.files ?? []);
  const handleImageError = () => setIsImageValid(false);

  useEffect(() => {
    setIsImageValid(!isEmpty(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FormFileAreaContainer isDisabled={isDisabled} isSolid={isSolid}>
      {label}
      <FormFileAreaInput ref={inputFileRef} onChange={handleImageUploaded} />
      <FormFileAreaContent
        isDraging={isDraging}
        onDragEnter={handleImageDragStart}
        onDragOver={handleImageDragStart}
        onDragLeave={handleImageDragEnd}
        onDrop={handleImageDrop}
        onClick={handleImageUploadClick}
      >
        {isImageValid ? (
          <>
            <FormFileAreaImage
              src={value instanceof File ? URL.createObjectURL(value) : value}
              onError={handleImageError}
            />
            <FormFileAreaRemove ref={removeImageRef}>
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.LIGHT_DANGER}
                buttonSize={BUTTON_SIZES.NORMAL}
                buttonType={BUTTON_TYPES.TEXT_ICON}
                prefixIcon={<TrashSvg />}
                onClick={handleImageDeleteClick}
              >
                {t("Remove")}
              </Button>
            </FormFileAreaRemove>
          </>
        ) : (
          <>
            <FormFileAreaIcon>
              <ImageSvg />
            </FormFileAreaIcon>
            <FormFileAreaTitle>{t("Upload Your Image")}</FormFileAreaTitle>
            <FormFileAreaDescription>
              {t(
                "Drag and drop image here, or click to browse from your device."
              )}
            </FormFileAreaDescription>
          </>
        )}
      </FormFileAreaContent>
    </FormFileAreaContainer>
  );
};

export default FormFileArea;
