import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { showErrorMessage } from "../../utils/popup.utils";
import {
  getValidationErrors,
  isEmpty,
  rule,
} from "../../utils/validation.utils";

import {
  setUpdatingItemIndex,
  updateFormItem,
} from "../../store/state-purchase/state-purchase.action";
import { getIsCalculateLoading } from "../../store/state-purchase/state-purchase.selector";
import {
  getCalculateCreatePurchaseLoading,
  getCalculateUpdatePurchaseLoading,
  getCreatePurchaseLoading,
  getUpdatePurchaseLoading,
} from "../../store/purchase/purchase.selector";

import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown from "../../components/dropdown-v2/dropdown.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";
import FormInput, {
  FORM_INPUT_SIZES,
  FORM_INPUT_TYPES,
} from "../../components/form-input/form-input.component";

import { ReactComponent as ExchangeSvg } from "../../assets/icons/Exchange.svg";
import { ReactComponent as ConfirmSvg } from "../../assets/icons/stroke/Confirm.svg";

const defaultValues = {
  name_en: "",
  name_ar: "",
  qty: "",
  price: "",
  total: "",
  is_tax_included: 1,
};

const PurchaseFormItemEdit = ({ index, item: data, handleDropdownClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const item = useMemo(() => data, [data]);

  const calculateCreatePurchaseLoading = useSelector(
    getCalculateCreatePurchaseLoading
  );
  const calculateUpdatePurchaseLoading = useSelector(
    getCalculateUpdatePurchaseLoading
  );
  const createPurchaseLoading = useSelector(getCreatePurchaseLoading);
  const updatePurchaseLoading = useSelector(getUpdatePurchaseLoading);
  const isCalculateLoading = useSelector(getIsCalculateLoading);

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [currentValues, setCurrentValues] = useState(defaultValues);

  const { name_en, name_ar, qty, price, total, is_tax_included } =
    currentValues;

  const handleCancelEdit = () => handleDropdownClose?.();
  const handleTaxIncludeToggle = () => {
    const { price, total } = initialValues;

    const inputValues = { ...currentValues };
    inputValues.is_tax_included = parseToBoolean(is_tax_included) ? 0 : 1;
    if (!isEmpty(price)) inputValues.price = price;
    if (!isEmpty(total)) inputValues.total = total;

    setCurrentValues(inputValues);
  };
  const handleInputChange = ({ name, value }) =>
    setCurrentValues({ ...currentValues, [name]: value });

  const handleFormSubmit = () => {
    if (isEmpty(item)) return;

    const errors = getValidationErrors(currentValues, {
      name_en: [rule.required()],
      price: parseToBoolean(is_tax_included) ? [] : [rule.required()],
      total: parseToBoolean(is_tax_included) ? [rule.required()] : [],
      is_tax_included: [rule.required()],
    });

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    const payload = {
      ...currentValues,
      price: parseToBoolean(is_tax_included) ? total : price,
    };

    dispatch(setUpdatingItemIndex(index));
    dispatch(updateFormItem(index, payload));
    handleDropdownClose?.();
  };

  useEffect(() => {
    const { name_en, name_ar, qty, item_price, item_total } = item ?? {};

    const initialValues = { ...defaultValues };
    if (!isEmpty(name_en)) initialValues.name_en = name_en;
    if (!isEmpty(name_ar)) initialValues.name_ar = name_ar;
    if (!isEmpty(qty)) initialValues.qty = qty;
    if (!isEmpty(item_price)) initialValues.price = item_price;
    if (!isEmpty(item_total)) initialValues.total = item_total;

    setInitialValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    setCurrentValues(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    setIsLoading(
      calculateCreatePurchaseLoading ||
        calculateUpdatePurchaseLoading ||
        createPurchaseLoading ||
        updatePurchaseLoading ||
        isCalculateLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calculateCreatePurchaseLoading,
    calculateUpdatePurchaseLoading,
    createPurchaseLoading,
    updatePurchaseLoading,
    isCalculateLoading,
  ]);

  return (
    <Responsive>
      <Responsive.Row rowGap="1rem">
        <Responsive.Col>
          <Dropdown.Form.Header
            title={t("Edit Item")}
            description={t("Provide the information below")}
          />
        </Responsive.Col>
        <Responsive.Col sm={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Item Name :language", { language: "English" })}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.TEXT}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="name_en"
            placeholder={t("Enter item name")}
            onChange={handleInputChange}
            value={name_en}
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col sm={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Item Name :language", { language: "Arabic" })}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.TEXT}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="name_ar"
            placeholder={t("Enter item name")}
            onChange={handleInputChange}
            value={name_ar}
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {parseToBoolean(is_tax_included) ? t("Total") : t("Price")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.NUMBER}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name={parseToBoolean(is_tax_included) ? "total" : "price"}
            placeholder={
              parseToBoolean(is_tax_included)
                ? t("Enter total")
                : t("Enter price")
            }
            onChange={handleInputChange}
            value={parseToBoolean(is_tax_included) ? total : price}
            suffixOut={
              <Button
                type="button"
                buttonColor={BUTTON_COLORS.SECONDARY}
                buttonSize={BUTTON_SIZES.NORMAL}
                buttonType={BUTTON_TYPES.TEXT_ICON}
                prefixIcon={<ExchangeSvg />}
                onClick={handleTaxIncludeToggle}
                isDisabled={isLoading}
              >
                {parseToBoolean(is_tax_included) ? t("Price") : t("Total")}
              </Button>
            }
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Quantity")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.NUMBER}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="qty"
            placeholder={t("Enter quantity")}
            onChange={handleInputChange}
            value={qty}
            isDisabled={isLoading}
            isSolid={isLoading}
          />
        </Responsive.Col>
        <Responsive.Col></Responsive.Col>
        <Responsive.Col>
          <Dropdown.Form.Action>
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.DARK}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT_ICON}
              prefixIcon={<ConfirmSvg />}
              onClick={handleFormSubmit}
              isLoading={isLoading}
            >
              {t("Save Changes")}
            </Button>
            <Button
              type="button"
              buttonColor={BUTTON_COLORS.SECONDARY}
              buttonSize={BUTTON_SIZES.NORMAL}
              buttonType={BUTTON_TYPES.TEXT}
              onClick={handleCancelEdit}
            >
              {t("Cancel")}
            </Button>
          </Dropdown.Form.Action>
        </Responsive.Col>
      </Responsive.Row>
    </Responsive>
  );
};

export default PurchaseFormItemEdit;
