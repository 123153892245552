import { createSelector } from "reselect";

const renewalAdminSelector = ({ renewalAdmin }) => renewalAdmin;

export const getRenewals = createSelector(
  [renewalAdminSelector],
  ({ renewals }) => renewals
);
export const getRenewal = createSelector(
  [renewalAdminSelector],
  ({ renewal }) => renewal
);
export const getRenewalMarket = createSelector(
  [renewalAdminSelector],
  ({ renewalMarket }) => renewalMarket
);
export const getRenewalBranches = createSelector(
  [renewalAdminSelector],
  ({ renewalBranches }) => renewalBranches
);
export const getRenewalCreate = createSelector(
  [renewalAdminSelector],
  ({ renewalCreate }) => renewalCreate
);
export const getRenewalCreateCalculate = createSelector(
  [renewalAdminSelector],
  ({ renewalCreateCalculate }) => renewalCreateCalculate
);
export const getRenewalCustom = createSelector(
  [renewalAdminSelector],
  ({ renewalCustom }) => renewalCustom
);
export const getRenewalCustomCalculate = createSelector(
  [renewalAdminSelector],
  ({ renewalCustomCalculate }) => renewalCustomCalculate
);
export const getRenewalUpdate = createSelector(
  [renewalAdminSelector],
  ({ renewalUpdate }) => renewalUpdate
);
export const getRenewalUpdateCalculate = createSelector(
  [renewalAdminSelector],
  ({ renewalUpdateCalculate }) => renewalUpdateCalculate
);

export const getIsRenewalsHasMore = createSelector(
  [renewalAdminSelector],
  ({ isRenewalsHasMore }) => isRenewalsHasMore
);
export const getIsRenewalBranchesHasMore = createSelector(
  [renewalAdminSelector],
  ({ isRenewalBranchesHasMore }) => isRenewalBranchesHasMore
);

export const getFetchRenewalsSearch = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsSearch }) => fetchRenewalsSearch
);
export const getFetchRenewalsSort = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsSort }) => fetchRenewalsSort
);
export const getFetchRenewalsKeyBy = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsKeyBy }) => fetchRenewalsKeyBy
);
export const getFetchRenewalsPage = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsPage }) => fetchRenewalsPage
);
export const getFetchRenewalsPerPage = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsPerPage }) => fetchRenewalsPerPage
);
export const getFetchRenewalsIncludes = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsIncludes }) => fetchRenewalsIncludes
);
export const getFetchRenewalsFilterMarketId = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFilterMarketId }) => fetchRenewalsFilterMarketId
);
export const getFetchRenewalsFilterSubscriptionKeys = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFilterSubscriptionKeys }) =>
    fetchRenewalsFilterSubscriptionKeys
);
export const getFetchRenewalsFilterSubscriptionOptionKeys = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFilterSubscriptionOptionKeys }) =>
    fetchRenewalsFilterSubscriptionOptionKeys
);
export const getFetchRenewalsFilterStatuses = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFilterStatuses }) => fetchRenewalsFilterStatuses
);
export const getFetchRenewalsFilterCreatedBy = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFilterCreatedBy }) => fetchRenewalsFilterCreatedBy
);
export const getFetchRenewalsLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsLoading }) => fetchRenewalsLoading
);
export const getFetchRenewalsSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsSuccess }) => fetchRenewalsSuccess
);
export const getFetchRenewalsFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalsFailed }) => fetchRenewalsFailed
);

export const getFetchRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalLoading }) => fetchRenewalLoading
);
export const getFetchRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalSuccess }) => fetchRenewalSuccess
);
export const getFetchRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalFailed }) => fetchRenewalFailed
);

export const getFetchRenewalMarketParams = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketParams }) => fetchRenewalMarketParams
);
export const getFetchRenewalMarketLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketLoading }) => fetchRenewalMarketLoading
);
export const getFetchRenewalMarketSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketSuccess }) => fetchRenewalMarketSuccess
);
export const getFetchRenewalMarketFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalMarketFailed }) => fetchRenewalMarketFailed
);

export const getFetchRenewalBranchesParams = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesParams }) => fetchRenewalBranchesParams
);
export const getFetchRenewalBranchesLoading = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesLoading }) => fetchRenewalBranchesLoading
);
export const getFetchRenewalBranchesSuccess = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesSuccess }) => fetchRenewalBranchesSuccess
);
export const getFetchRenewalBranchesFailed = createSelector(
  [renewalAdminSelector],
  ({ fetchRenewalBranchesFailed }) => fetchRenewalBranchesFailed
);

export const getCreateRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ createRenewalLoading }) => createRenewalLoading
);
export const getCreateRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ createRenewalSuccess }) => createRenewalSuccess
);
export const getCreateRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ createRenewalFailed }) => createRenewalFailed
);

export const getCreateCalculateRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ createCalculateRenewalLoading }) => createCalculateRenewalLoading
);
export const getCreateCalculateRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ createCalculateRenewalSuccess }) => createCalculateRenewalSuccess
);
export const getCreateCalculateRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ createCalculateRenewalFailed }) => createCalculateRenewalFailed
);

export const getCustomRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ customRenewalLoading }) => customRenewalLoading
);
export const getCustomRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ customRenewalSuccess }) => customRenewalSuccess
);
export const getCustomRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ customRenewalFailed }) => customRenewalFailed
);

export const getCustomCalculateRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ customCalculateRenewalLoading }) => customCalculateRenewalLoading
);
export const getCustomCalculateRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ customCalculateRenewalSuccess }) => customCalculateRenewalSuccess
);
export const getCustomCalculateRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ customCalculateRenewalFailed }) => customCalculateRenewalFailed
);

export const getUpdateRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ updateRenewalLoading }) => updateRenewalLoading
);
export const getUpdateRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ updateRenewalSuccess }) => updateRenewalSuccess
);
export const getUpdateRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ updateRenewalFailed }) => updateRenewalFailed
);

export const getUpdateCalculateRenewalLoading = createSelector(
  [renewalAdminSelector],
  ({ updateCalculateRenewalLoading }) => updateCalculateRenewalLoading
);
export const getUpdateCalculateRenewalSuccess = createSelector(
  [renewalAdminSelector],
  ({ updateCalculateRenewalSuccess }) => updateCalculateRenewalSuccess
);
export const getUpdateCalculateRenewalFailed = createSelector(
  [renewalAdminSelector],
  ({ updateCalculateRenewalFailed }) => updateCalculateRenewalFailed
);

export const getIsFetchRenewalsHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalsHitted }) => isFetchRenewalsHitted
);
export const getIsFetchRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalHitted }) => isFetchRenewalHitted
);
export const getIsFetchRenewalMarketHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalMarketHitted }) => isFetchRenewalMarketHitted
);
export const getIsFetchRenewalBranchesHitted = createSelector(
  [renewalAdminSelector],
  ({ isFetchRenewalBranchesHitted }) => isFetchRenewalBranchesHitted
);
export const getIsCreateRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isCreateRenewalHitted }) => isCreateRenewalHitted
);
export const getIsCreateCalculateRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isCreateCalculateRenewalHitted }) => isCreateCalculateRenewalHitted
);
export const getIsCustomRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isCustomRenewalHitted }) => isCustomRenewalHitted
);
export const getIsCustomCalculateRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isCustomCalculateRenewalHitted }) => isCustomCalculateRenewalHitted
);
export const getIsUpdateRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isUpdateRenewalHitted }) => isUpdateRenewalHitted
);
export const getIsUpdateCalculateRenewalHitted = createSelector(
  [renewalAdminSelector],
  ({ isUpdateCalculateRenewalHitted }) => isUpdateCalculateRenewalHitted
);
