import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getSectors } from "../../api/sector.api";

import SELECT_SECTOR_ACTION_TYPES from "./select-sector.type";
import {
  appendSectors,
  appendSearchSectors,
  setAppendSectorsFailed,
  setAppendSectorsLoading,
  setAppendSectorsSuccess,
  setFetchSectorsFailed,
  setFetchSectorsLoading,
  setFetchSectorsSuccess,
  setIsAppendSectorsHitted,
  setIsFetchSectorsHitted,
  setIsSectorsHasMore,
  setIsSearchSectorsHasMore,
  setIsSearchSectorsHitted,
  setSectors,
  setSearchSectors,
  setSearchSectorsFailed,
  setSearchSectorsLoading,
  setSearchSectorsSuccess,
} from "./select-sector.action";
import {
  getAppendSectorsParams,
  getFetchSectorsParams,
  getSearchSectorsParams,
} from "./select-sector.selector";

export function* _getFetchSectors() {
  try {
    const fetchSectorsParams = yield select(getFetchSectorsParams);
    const parameters = getParamsWithDefault(fetchSectorsParams);

    yield put(setFetchSectorsLoading(true));

    const {
      meta: { message },
      data: sectors,
    } = yield call(getSectors, parameters);

    yield put(setIsFetchSectorsHitted(true));
    yield put(setIsSectorsHasMore(Object.keys(sectors).length > 0));

    if (parameters.page > 1) {
      yield put(appendSectors(sectors));
    } else {
      yield put(setSectors(sectors));
    }

    yield put(setFetchSectorsSuccess(message));
    yield put(setFetchSectorsLoading(false));
  } catch (error) {
    yield put(setFetchSectorsFailed(error));
    yield put(setFetchSectorsLoading(false));
  }
}
export function* _getSearchSectors() {
  try {
    const searchSectorsParams = yield select(getSearchSectorsParams);
    const parameters = getParamsWithDefault(searchSectorsParams);

    yield put(setSearchSectorsLoading(true));

    const {
      meta: { message },
      data: sectors,
    } = yield call(getSectors, parameters);

    yield put(setIsSearchSectorsHitted(true));
    yield put(setIsSearchSectorsHasMore(Object.keys(sectors).length > 0));

    if (parameters.page > 1) {
      yield put(appendSearchSectors(sectors));
    } else {
      yield put(setSearchSectors(sectors));
    }

    yield put(setSearchSectorsSuccess(message));
    yield put(setSearchSectorsLoading(false));
  } catch (error) {
    yield put(setSearchSectorsFailed(error));
    yield put(setSearchSectorsLoading(false));
  }
}
export function* _getAppendSectors() {
  try {
    const appendSectorsParams = yield select(getAppendSectorsParams);
    const parameters = getParamsWithDefault(appendSectorsParams);

    yield put(setAppendSectorsLoading(true));

    const {
      meta: { message },
      data: sectors,
    } = yield call(getSectors, parameters);

    yield put(setIsAppendSectorsHitted(true));
    yield put(appendSectors(sectors));

    yield put(setAppendSectorsSuccess(message));
    yield put(setAppendSectorsLoading(false));
  } catch (error) {
    yield put(setAppendSectorsFailed(error));
    yield put(setAppendSectorsLoading(false));
  }
}

export function* onFetchSectorsStart() {
  yield takeLatest(
    SELECT_SECTOR_ACTION_TYPES.FETCH_SECTORS_START,
    _getFetchSectors
  );
}
export function* onSearchSectorsStart() {
  yield takeLatest(
    SELECT_SECTOR_ACTION_TYPES.SEARCH_SECTORS_START,
    _getSearchSectors
  );
}
export function* onAppendSectorsStart() {
  yield takeLatest(
    SELECT_SECTOR_ACTION_TYPES.APPEND_SECTORS_START,
    _getAppendSectors
  );
}

export function* selectSectorSaga() {
  yield all([
    call(onFetchSectorsStart),
    call(onSearchSectorsStart),
    call(onAppendSectorsStart),
  ]);
}
