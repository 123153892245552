import { createSelector } from "reselect";

const componentSelector = ({ component }) => component;

export const getOverlayPosition = createSelector(
  [componentSelector],
  ({ overlayPosition }) => overlayPosition
);
export const getScreenWidth = createSelector(
  [componentSelector],
  ({ screenWidth }) => screenWidth
);
export const getTopnavHeight = createSelector(
  [componentSelector],
  ({ topnavHeight }) => topnavHeight
);
export const getSidenavWidth = createSelector(
  [componentSelector],
  ({ sidenavWidth }) => sidenavWidth
);

export const getIsSidenavOpen = createSelector(
  [componentSelector],
  ({ isSidenavOpen }) => isSidenavOpen
);
export const getIsModalOpen = createSelector(
  [componentSelector],
  ({ isModalOpen }) => isModalOpen
);
export const getCurrentModal = createSelector(
  [componentSelector],
  ({ currentModal }) => currentModal
);

export const getCurrentModals = createSelector(
  [componentSelector],
  ({ currentModals }) => currentModals
);
export const getCurrentDialogs = createSelector(
  [componentSelector],
  ({ currentDialogs }) => currentDialogs
);

export const getLeftDialogs = createSelector(
  [componentSelector],
  ({ leftDialogs }) => leftDialogs
);
export const getRightDialogs = createSelector(
  [componentSelector],
  ({ rightDialogs }) => rightDialogs
);
export const getCenterDialogs = createSelector(
  [componentSelector],
  ({ centerDialogs }) => centerDialogs
);

export const getIsHasThanksPage = createSelector(
  [componentSelector],
  ({ isHasThanksPage }) => isHasThanksPage
);
export const getIsHasGettingStarted = createSelector(
  [componentSelector],
  ({ isHasGettingStarted }) => isHasGettingStarted
);

export const getOrderCustomerIpAddress = createSelector(
  [componentSelector],
  ({ orderCustomerIpAddress }) => orderCustomerIpAddress
);
export const getIsOrderCustomerHideSidebar = createSelector(
  [componentSelector],
  ({ isOrderCustomerHideSidebar }) => isOrderCustomerHideSidebar
);
export const getIsOrderCustomerHideBackButton = createSelector(
  [componentSelector],
  ({ isOrderCustomerHideBackButton }) => isOrderCustomerHideBackButton
);
export const getIsOrderCustomerHideTitle = createSelector(
  [componentSelector],
  ({ isOrderCustomerHideTitle }) => isOrderCustomerHideTitle
);
export const getIsOrderCustomerHideTopToolbar = createSelector(
  [componentSelector],
  ({ isOrderCustomerHideTopToolbar }) => isOrderCustomerHideTopToolbar
);

export const getOrderWaitingDisplay = createSelector(
  [componentSelector],
  ({ orderWaitingDisplay }) => orderWaitingDisplay
);
export const getIsOrderWaitingHideSidebar = createSelector(
  [componentSelector],
  ({ isOrderWaitingHideSidebar }) => isOrderWaitingHideSidebar
);
export const getIsOrderWaitingHideBackButton = createSelector(
  [componentSelector],
  ({ isOrderWaitingHideBackButton }) => isOrderWaitingHideBackButton
);
export const getIsOrderWaitingHideTitle = createSelector(
  [componentSelector],
  ({ isOrderWaitingHideTitle }) => isOrderWaitingHideTitle
);
export const getIsOrderWaitingHideTopToolbar = createSelector(
  [componentSelector],
  ({ isOrderWaitingHideTopToolbar }) => isOrderWaitingHideTopToolbar
);
