import { createSelector } from "reselect";

const reportMoreSelector = ({ reportMore }) => reportMore;

export const getReportMoreCategories = createSelector(
  [reportMoreSelector],
  ({ reportMoreCategories }) => reportMoreCategories
);
export const getReportMoreProducts = createSelector(
  [reportMoreSelector],
  ({ reportMoreProducts }) => reportMoreProducts
);
export const getReportMoreOptions = createSelector(
  [reportMoreSelector],
  ({ reportMoreOptions }) => reportMoreOptions
);
export const getReportMoreAdditionals = createSelector(
  [reportMoreSelector],
  ({ reportMoreAdditionals }) => reportMoreAdditionals
);
export const getReportMoreCashiers = createSelector(
  [reportMoreSelector],
  ({ reportMoreCashiers }) => reportMoreCashiers
);
export const getReportMorePayments = createSelector(
  [reportMoreSelector],
  ({ reportMorePayments }) => reportMorePayments
);
export const getReportMoreTables = createSelector(
  [reportMoreSelector],
  ({ reportMoreTables }) => reportMoreTables
);

export const getIsReportMoreCategoriesHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreCategoriesHasMore }) => isReportMoreCategoriesHasMore
);
export const getIsReportMoreProductsHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreProductsHasMore }) => isReportMoreProductsHasMore
);
export const getIsReportMoreOptionsHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreOptionsHasMore }) => isReportMoreOptionsHasMore
);
export const getIsReportMoreAdditionalsHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreAdditionalsHasMore }) => isReportMoreAdditionalsHasMore
);
export const getIsReportMoreCashiersHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreCashiersHasMore }) => isReportMoreCashiersHasMore
);
export const getIsReportMorePaymentsHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMorePaymentsHasMore }) => isReportMorePaymentsHasMore
);
export const getIsReportMoreTablesHasMore = createSelector(
  [reportMoreSelector],
  ({ isReportMoreTablesHasMore }) => isReportMoreTablesHasMore
);

export const getFetchReportMoreCategoriesParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCategoriesParams }) => fetchReportMoreCategoriesParams
);
export const getFetchReportMoreCategoriesLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCategoriesLoading }) => fetchReportMoreCategoriesLoading
);
export const getFetchReportMoreCategoriesSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCategoriesSuccess }) => fetchReportMoreCategoriesSuccess
);
export const getFetchReportMoreCategoriesFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCategoriesFailed }) => fetchReportMoreCategoriesFailed
);

export const getFetchReportMoreProductsParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreProductsParams }) => fetchReportMoreProductsParams
);
export const getFetchReportMoreProductsLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreProductsLoading }) => fetchReportMoreProductsLoading
);
export const getFetchReportMoreProductsSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreProductsSuccess }) => fetchReportMoreProductsSuccess
);
export const getFetchReportMoreProductsFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreProductsFailed }) => fetchReportMoreProductsFailed
);

export const getFetchReportMoreOptionsParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreOptionsParams }) => fetchReportMoreOptionsParams
);
export const getFetchReportMoreOptionsLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreOptionsLoading }) => fetchReportMoreOptionsLoading
);
export const getFetchReportMoreOptionsSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreOptionsSuccess }) => fetchReportMoreOptionsSuccess
);
export const getFetchReportMoreOptionsFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreOptionsFailed }) => fetchReportMoreOptionsFailed
);

export const getFetchReportMoreAdditionalsParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreAdditionalsParams }) => fetchReportMoreAdditionalsParams
);
export const getFetchReportMoreAdditionalsLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreAdditionalsLoading }) => fetchReportMoreAdditionalsLoading
);
export const getFetchReportMoreAdditionalsSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreAdditionalsSuccess }) => fetchReportMoreAdditionalsSuccess
);
export const getFetchReportMoreAdditionalsFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreAdditionalsFailed }) => fetchReportMoreAdditionalsFailed
);

export const getFetchReportMoreCashiersParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCashiersParams }) => fetchReportMoreCashiersParams
);
export const getFetchReportMoreCashiersLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCashiersLoading }) => fetchReportMoreCashiersLoading
);
export const getFetchReportMoreCashiersSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCashiersSuccess }) => fetchReportMoreCashiersSuccess
);
export const getFetchReportMoreCashiersFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreCashiersFailed }) => fetchReportMoreCashiersFailed
);

export const getFetchReportMorePaymentsParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMorePaymentsParams }) => fetchReportMorePaymentsParams
);
export const getFetchReportMorePaymentsLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMorePaymentsLoading }) => fetchReportMorePaymentsLoading
);
export const getFetchReportMorePaymentsSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMorePaymentsSuccess }) => fetchReportMorePaymentsSuccess
);
export const getFetchReportMorePaymentsFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMorePaymentsFailed }) => fetchReportMorePaymentsFailed
);

export const getFetchReportMoreTablesParams = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreTablesParams }) => fetchReportMoreTablesParams
);
export const getFetchReportMoreTablesLoading = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreTablesLoading }) => fetchReportMoreTablesLoading
);
export const getFetchReportMoreTablesSuccess = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreTablesSuccess }) => fetchReportMoreTablesSuccess
);
export const getFetchReportMoreTablesFailed = createSelector(
  [reportMoreSelector],
  ({ fetchReportMoreTablesFailed }) => fetchReportMoreTablesFailed
);

export const getIsFetchReportMoreCategoriesHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreCategoriesHitted }) => isFetchReportMoreCategoriesHitted
);
export const getIsFetchReportMoreProductsHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreProductsHitted }) => isFetchReportMoreProductsHitted
);
export const getIsFetchReportMoreOptionsHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreOptionsHitted }) => isFetchReportMoreOptionsHitted
);
export const getIsFetchReportMoreAdditionalsHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreAdditionalsHitted }) => isFetchReportMoreAdditionalsHitted
);
export const getIsFetchReportMoreCashiersHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreCashiersHitted }) => isFetchReportMoreCashiersHitted
);
export const getIsFetchReportMorePaymentsHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMorePaymentsHitted }) => isFetchReportMorePaymentsHitted
);
export const getIsFetchReportMoreTablesHitted = createSelector(
  [reportMoreSelector],
  ({ isFetchReportMoreTablesHitted }) => isFetchReportMoreTablesHitted
);
