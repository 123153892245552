const TRANSLATION_ACTION_TYPES = {
  RESET_TRANSLATION_REDUCER: "translation/RESET_TRANSLATION_REDUCER",

  SET_USER_ROLES: "translation/SET_USER_ROLES",

  SET_ORDER_STATUSES: "translation/SET_ORDER_STATUSES",

  SET_SUBSCRIPTION_PLANS: "translation/SET_SUBSCRIPTION_PLANS",
  SET_SUBSCRIPTION_CYCLES: "translation/SET_SUBSCRIPTION_CYCLES",
  SET_SUBSCRIPTION_STATUSES: "translation/SET_SUBSCRIPTION_STATUSES",
  SET_SUBSCRIPTION_PERIODS: "translation/SET_SUBSCRIPTION_PERIODS",

  SET_REPORT_EXPORT_TYPES: "translation/SET_REPORT_EXPORT_TYPES",

  SET_DISCOUNT_TYPES: "translation/SET_DISCOUNT_TYPES",

  SET_PRODUCT_TYPES: "translation/SET_PRODUCT_TYPES",
  SET_PRODUCT_OPTION_PRICES: "translation/SET_PRODUCT_OPTION_PRICES",
  SET_PRODUCT_ADDITIONAL_PRICES: "translation/SET_PRODUCT_ADDITIONAL_PRICES",

  SET_DASHBOARD_SUBSCRIBER_TABS: "translation/SET_DASHBOARD_SUBSCRIBER_TABS",
  SET_SUBSCRIPTION_ADMIN_TABS: "translation/SET_SUBSCRIPTION_ADMIN_TABS",
  SET_SUBSCRIPTION_SUBSCRIBER_TABS:
    "translation/SET_SUBSCRIPTION_SUBSCRIBER_TABS",

  SET_PURCHASE_TABS: "translation/SET_PURCHASE_TABS",

  SET_REPORT_PRODUCT_TABS: "translation/SET_REPORT_PRODUCT_TABS",

  PERMISSION_ADMIN_PAGES: "translation/PERMISSION_ADMIN_PAGES",
  PERMISSION_MERCHANT_PAGES: "translation/PERMISSION_MERCHANT_PAGES",
  PERMISSION_SUBSCRIBER_PAGES: "translation/PERMISSION_SUBSCRIBER_PAGES",
};

export default TRANSLATION_ACTION_TYPES;
