import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { parseToBoolean } from "../../utils/parser.utils";
import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  FormRadioCardContainer,
  FormRadioCardIcon,
  FormRadioCardInfo,
  FormRadioCardLower,
  FormRadioCardMark,
  FormRadioCardName,
  FormRadioCardUpper,
} from "./form-radio-card.style";

import { Tick02Icon } from "hugeicons-react";

const FormRadioCard = ({
  cardIcon,
  cardName,
  cardInfo,
  name,
  value,
  isDisabled = false,
  isChecked = false,
  isSolid = false,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const isRightToLeft = useSelector(getIsRightToLeft);

  const handleRadioClick = () => {
    if (parseToBoolean(isDisabled)) return;
    onChange?.({ name, value });
  };

  return (
    <FormRadioCardContainer
      isRightToLeft={isRightToLeft}
      isActive={parseToBoolean(isChecked)}
      isSolid={parseToBoolean(isDisabled) || parseToBoolean(isSolid)}
      onClick={handleRadioClick}
      {...props}
    >
      <FormRadioCardUpper>
        {!isEmpty(cardIcon) && (
          <FormRadioCardIcon>{cardIcon}</FormRadioCardIcon>
        )}
        <FormRadioCardMark>
          <Tick02Icon />
        </FormRadioCardMark>
      </FormRadioCardUpper>
      <FormRadioCardLower>
        <FormRadioCardName>
          {!isEmpty(cardName) ? cardName : t("No name")}
        </FormRadioCardName>
        {!isEmpty(cardInfo) && (
          <FormRadioCardInfo>{cardInfo}</FormRadioCardInfo>
        )}
      </FormRadioCardLower>
    </FormRadioCardContainer>
  );
};

export default FormRadioCard;
