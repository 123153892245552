import TRANSLATION_ACTION_TYPES from "./translation.type";

const TRANSLATION_INITIAL_STATE = {
  userRoles: [],

  orderStatuses: [],

  subscriptionPlans: [],
  subscriptionCycles: [],
  subscriptionStatuses: [],
  subscriptionPeriods: {},

  reportExportTypes: [],

  discountTypes: [],

  productTypes: [],
  productOptionPrices: [],
  productAdditionalPrices: [],

  dashboardSubscriberTabs: [],
  subscriptionAdminTabs: [],
  subscriptionSubscriberTabs: [],

  purchaseTabs: [],

  reportProductTabs: [],

  permissionAdminPages: [],
  permissionMerchantPages: [],
  permissionSubscriberPages: [],
};
export const translationReducer = (
  state = TRANSLATION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case TRANSLATION_ACTION_TYPES.SET_USER_ROLES:
      return { ...state, userRoles: payload };

    case TRANSLATION_ACTION_TYPES.SET_ORDER_STATUSES:
      return { ...state, orderStatuses: payload };

    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_PLANS:
      return { ...state, subscriptionPlans: payload };
    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_CYCLES:
      return { ...state, subscriptionCycles: payload };
    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_STATUSES:
      return { ...state, subscriptionStatuses: payload };
    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_PERIODS:
      return { ...state, subscriptionPeriods: payload };

    case TRANSLATION_ACTION_TYPES.SET_REPORT_EXPORT_TYPES:
      return { ...state, reportExportTypes: payload };

    case TRANSLATION_ACTION_TYPES.SET_DISCOUNT_TYPES:
      return { ...state, discountTypes: payload };

    case TRANSLATION_ACTION_TYPES.SET_PRODUCT_TYPES:
      return { ...state, productTypes: payload };
    case TRANSLATION_ACTION_TYPES.SET_PRODUCT_OPTION_PRICES:
      return { ...state, productOptionPrices: payload };
    case TRANSLATION_ACTION_TYPES.SET_PRODUCT_ADDITIONAL_PRICES:
      return { ...state, productAdditionalPrices: payload };

    case TRANSLATION_ACTION_TYPES.SET_DASHBOARD_SUBSCRIBER_TABS:
      return { ...state, dashboardSubscriberTabs: payload };
    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TABS:
      return { ...state, subscriptionAdminTabs: payload };
    case TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TABS:
      return { ...state, subscriptionSubscriberTabs: payload };

    case TRANSLATION_ACTION_TYPES.SET_PURCHASE_TABS:
      return { ...state, purchaseTabs: payload };

    case TRANSLATION_ACTION_TYPES.SET_REPORT_PRODUCT_TABS:
      return { ...state, reportProductTabs: payload };

    case TRANSLATION_ACTION_TYPES.PERMISSION_ADMIN_PAGES:
      return { ...state, permissionAdminPages: payload };
    case TRANSLATION_ACTION_TYPES.PERMISSION_MERCHANT_PAGES:
      return { ...state, permissionMerchantPages: payload };
    case TRANSLATION_ACTION_TYPES.PERMISSION_SUBSCRIBER_PAGES:
      return { ...state, permissionSubscriberPages: payload };

    case TRANSLATION_ACTION_TYPES.RESET_TRANSLATION_REDUCER:
      return TRANSLATION_INITIAL_STATE;
    default:
      return state;
  }
};
