const RENEWAL_TRANSFER_ACTION_TYPES = {
  RESET_RENEWAL_TRANSFER_REDUCER:
    "renewal-transfer/RESET_RENEWAL_TRANSFER_REDUCER",

  SET_RENEWAL_TRANSFERS: "renewal-transfer/SET_RENEWAL_TRANSFERS",
  SET_RENEWAL_TRANSFER: "renewal-transfer/SET_RENEWAL_TRANSFER",

  SET_IS_RENEWAL_TRANSFERS_HAS_MORE:
    "renewal-transfer/SET_IS_RENEWAL_TRANSFERS_HAS_MORE",

  SET_FETCH_RENEWAL_TRANSFERS_PARAMS:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFERS_PARAMS",
  SET_FETCH_RENEWAL_TRANSFERS_LOADING:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFERS_LOADING",
  SET_FETCH_RENEWAL_TRANSFERS_SUCCESS:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFERS_SUCCESS",
  SET_FETCH_RENEWAL_TRANSFERS_FAILED:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFERS_FAILED",

  SET_FETCH_RENEWAL_TRANSFER_PARAMS:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFER_PARAMS",
  SET_FETCH_RENEWAL_TRANSFER_LOADING:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFER_LOADING",
  SET_FETCH_RENEWAL_TRANSFER_SUCCESS:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFER_SUCCESS",
  SET_FETCH_RENEWAL_TRANSFER_FAILED:
    "renewal-transfer/SET_FETCH_RENEWAL_TRANSFER_FAILED",

  SET_CREATE_RENEWAL_TRANSFER_LOADING:
    "renewal-transfer/SET_CREATE_RENEWAL_TRANSFER_LOADING",
  SET_CREATE_RENEWAL_TRANSFER_SUCCESS:
    "renewal-transfer/SET_CREATE_RENEWAL_TRANSFER_SUCCESS",
  SET_CREATE_RENEWAL_TRANSFER_FAILED:
    "renewal-transfer/SET_CREATE_RENEWAL_TRANSFER_FAILED",

  SET_UPDATE_RENEWAL_TRANSFER_LOADING:
    "renewal-transfer/SET_UPDATE_RENEWAL_TRANSFER_LOADING",
  SET_UPDATE_RENEWAL_TRANSFER_SUCCESS:
    "renewal-transfer/SET_UPDATE_RENEWAL_TRANSFER_SUCCESS",
  SET_UPDATE_RENEWAL_TRANSFER_FAILED:
    "renewal-transfer/SET_UPDATE_RENEWAL_TRANSFER_FAILED",

  SET_DELETE_RENEWAL_TRANSFER_LOADING:
    "renewal-transfer/SET_DELETE_RENEWAL_TRANSFER_LOADING",
  SET_DELETE_RENEWAL_TRANSFER_SUCCESS:
    "renewal-transfer/SET_DELETE_RENEWAL_TRANSFER_SUCCESS",
  SET_DELETE_RENEWAL_TRANSFER_FAILED:
    "renewal-transfer/SET_DELETE_RENEWAL_TRANSFER_FAILED",

  APPEND_RENEWAL_TRANSFERS: "renewal-transfer/APPEND_RENEWAL_TRANSFERS",

  SET_IS_FETCH_RENEWAL_TRANSFERS_HITTED:
    "renewal-transfer/SET_IS_FETCH_RENEWAL_TRANSFERS_HITTED",
  SET_IS_FETCH_RENEWAL_TRANSFER_HITTED:
    "renewal-transfer/SET_IS_FETCH_RENEWAL_TRANSFER_HITTED",
  SET_IS_CREATE_RENEWAL_TRANSFER_HITTED:
    "renewal-transfer/SET_IS_CREATE_RENEWAL_TRANSFER_HITTED",
  SET_IS_UPDATE_RENEWAL_TRANSFER_HITTED:
    "renewal-transfer/SET_IS_UPDATE_RENEWAL_TRANSFER_HITTED",
  SET_IS_DELETE_RENEWAL_TRANSFER_HITTED:
    "renewal-transfer/SET_IS_DELETE_RENEWAL_TRANSFER_HITTED",

  FETCH_RENEWAL_TRANSFERS_START:
    "renewal-transfer/FETCH_RENEWAL_TRANSFERS_START",
  FETCH_RENEWAL_TRANSFER_START: "renewal-transfer/FETCH_RENEWAL_TRANSFER_START",
  CREATE_RENEWAL_TRANSFER_START:
    "renewal-transfer/CREATE_RENEWAL_TRANSFER_START",
  UPDATE_RENEWAL_TRANSFER_START:
    "renewal-transfer/UPDATE_RENEWAL_TRANSFER_START",
  DELETE_RENEWAL_TRANSFER_START:
    "renewal-transfer/DELETE_RENEWAL_TRANSFER_START",
};

export default RENEWAL_TRANSFER_ACTION_TYPES;
