import { createSelector } from "reselect";

const userSupplierSelector = ({ userSupplier }) => userSupplier;

export const getUserSuppliers = createSelector(
  [userSupplierSelector],
  ({ userSuppliers }) => userSuppliers
);
export const getUserSupplier = createSelector(
  [userSupplierSelector],
  ({ userSupplier }) => userSupplier
);

export const getIsUserSuppliersHasMore = createSelector(
  [userSupplierSelector],
  ({ isUserSuppliersHasMore }) => isUserSuppliersHasMore
);

export const getFetchUserSuppliersSearch = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersSearch }) => fetchUserSuppliersSearch
);
export const getFetchUserSuppliersSort = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersSort }) => fetchUserSuppliersSort
);
export const getFetchUserSuppliersKeyBy = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersKeyBy }) => fetchUserSuppliersKeyBy
);
export const getFetchUserSuppliersPage = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersPage }) => fetchUserSuppliersPage
);
export const getFetchUserSuppliersPerPage = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersPerPage }) => fetchUserSuppliersPerPage
);
export const getFetchUserSuppliersIncludes = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersIncludes }) => fetchUserSuppliersIncludes
);
export const getFetchUserSuppliersFilterMarketId = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersFilterMarketId }) => fetchUserSuppliersFilterMarketId
);
export const getFetchUserSuppliersLoading = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersLoading }) => fetchUserSuppliersLoading
);
export const getFetchUserSuppliersSuccess = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersSuccess }) => fetchUserSuppliersSuccess
);
export const getFetchUserSuppliersFailed = createSelector(
  [userSupplierSelector],
  ({ fetchUserSuppliersFailed }) => fetchUserSuppliersFailed
);

export const getFetchUserSupplierLoading = createSelector(
  [userSupplierSelector],
  ({ fetchUserSupplierLoading }) => fetchUserSupplierLoading
);
export const getFetchUserSupplierSuccess = createSelector(
  [userSupplierSelector],
  ({ fetchUserSupplierSuccess }) => fetchUserSupplierSuccess
);
export const getFetchUserSupplierFailed = createSelector(
  [userSupplierSelector],
  ({ fetchUserSupplierFailed }) => fetchUserSupplierFailed
);

export const getCreateUserSupplierLoading = createSelector(
  [userSupplierSelector],
  ({ createUserSupplierLoading }) => createUserSupplierLoading
);
export const getCreateUserSupplierSuccess = createSelector(
  [userSupplierSelector],
  ({ createUserSupplierSuccess }) => createUserSupplierSuccess
);
export const getCreateUserSupplierFailed = createSelector(
  [userSupplierSelector],
  ({ createUserSupplierFailed }) => createUserSupplierFailed
);

export const getUpdateUserSupplierLoading = createSelector(
  [userSupplierSelector],
  ({ updateUserSupplierLoading }) => updateUserSupplierLoading
);
export const getUpdateUserSupplierSuccess = createSelector(
  [userSupplierSelector],
  ({ updateUserSupplierSuccess }) => updateUserSupplierSuccess
);
export const getUpdateUserSupplierFailed = createSelector(
  [userSupplierSelector],
  ({ updateUserSupplierFailed }) => updateUserSupplierFailed
);

export const getDeleteUserSupplierLoading = createSelector(
  [userSupplierSelector],
  ({ deleteUserSupplierLoading }) => deleteUserSupplierLoading
);
export const getDeleteUserSupplierSuccess = createSelector(
  [userSupplierSelector],
  ({ deleteUserSupplierSuccess }) => deleteUserSupplierSuccess
);
export const getDeleteUserSupplierFailed = createSelector(
  [userSupplierSelector],
  ({ deleteUserSupplierFailed }) => deleteUserSupplierFailed
);

export const getIsFetchUserSuppliersHitted = createSelector(
  [userSupplierSelector],
  ({ isFetchUserSuppliersHitted }) => isFetchUserSuppliersHitted
);
export const getIsFetchUserSupplierHitted = createSelector(
  [userSupplierSelector],
  ({ isFetchUserSupplierHitted }) => isFetchUserSupplierHitted
);
export const getIsCreateUserSupplierHitted = createSelector(
  [userSupplierSelector],
  ({ isCreateUserSupplierHitted }) => isCreateUserSupplierHitted
);
export const getIsUpdateUserSupplierHitted = createSelector(
  [userSupplierSelector],
  ({ isUpdateUserSupplierHitted }) => isUpdateUserSupplierHitted
);
export const getIsDeleteUserSupplierHitted = createSelector(
  [userSupplierSelector],
  ({ isDeleteUserSupplierHitted }) => isDeleteUserSupplierHitted
);
