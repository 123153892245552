import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateCalculateRenewalFailed,
  setCreateCalculateRenewalSuccess,
  setCreateRenewalFailed,
  setCreateRenewalSuccess,
  setFetchRenewalFailed,
  setFetchRenewalsFailed,
  setFetchRenewalsSuccess,
  setFetchRenewalSuccess,
} from "../../store/renewal-subscriber/renewal-subscriber.action";
import {
  getCreateCalculateRenewalFailed,
  getCreateCalculateRenewalSuccess,
  getCreateRenewalFailed,
  getCreateRenewalSuccess,
  getFetchRenewalFailed,
  getFetchRenewalsFailed,
  getFetchRenewalsSuccess,
  getFetchRenewalSuccess,
} from "../../store/renewal-subscriber/renewal-subscriber.selector";

const RenewalSubscriberPopup = () => {
  const dispatch = useDispatch();

  const fetchRenewalsSuccess = useSelector(getFetchRenewalsSuccess);
  const fetchRenewalSuccess = useSelector(getFetchRenewalSuccess);
  const createRenewalSuccess = useSelector(getCreateRenewalSuccess);
  const createCalculateRenewalSuccess = useSelector(
    getCreateCalculateRenewalSuccess
  );

  const fetchRenewalsFailed = useSelector(getFetchRenewalsFailed);
  const fetchRenewalFailed = useSelector(getFetchRenewalFailed);
  const createRenewalFailed = useSelector(getCreateRenewalFailed);
  const createCalculateRenewalFailed = useSelector(
    getCreateCalculateRenewalFailed
  );

  useEffect(() => {
    if (
      fetchRenewalsSuccess !== null ||
      fetchRenewalSuccess !== null ||
      createRenewalSuccess !== null ||
      createCalculateRenewalSuccess !== null
    ) {
      if (createRenewalSuccess !== null) {
        showSuccessMessage(createRenewalSuccess);
        dispatch(setIsModalOpen(false));
      }

      if (fetchRenewalsSuccess !== null)
        dispatch(setFetchRenewalsSuccess(null));
      if (fetchRenewalSuccess !== null) dispatch(setFetchRenewalSuccess(null));
      if (createRenewalSuccess !== null)
        dispatch(setCreateRenewalSuccess(null));
      if (createCalculateRenewalSuccess !== null)
        dispatch(setCreateCalculateRenewalSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalsSuccess,
    fetchRenewalSuccess,
    createRenewalSuccess,
    createCalculateRenewalSuccess,
  ]);

  useEffect(() => {
    if (
      fetchRenewalsFailed !== null ||
      fetchRenewalFailed !== null ||
      createRenewalFailed !== null ||
      createCalculateRenewalFailed !== null
    ) {
      showErrorMessage(
        fetchRenewalsFailed ??
          fetchRenewalFailed ??
          createRenewalFailed ??
          createCalculateRenewalFailed
      );

      if (fetchRenewalsFailed !== null) dispatch(setFetchRenewalsFailed(null));
      if (fetchRenewalFailed !== null) dispatch(setFetchRenewalFailed(null));
      if (createRenewalFailed !== null) dispatch(setCreateRenewalFailed(null));
      if (createCalculateRenewalFailed !== null)
        dispatch(setCreateCalculateRenewalFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRenewalsFailed,
    fetchRenewalFailed,
    createRenewalFailed,
    createCalculateRenewalFailed,
  ]);

  return <></>;
};

export default RenewalSubscriberPopup;
