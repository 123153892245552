import { takeLatest, put, all, call, select } from "redux-saga/effects";

import { getParamsWithDefault } from "../../utils/store.utils";

import { getProducts, sortProducts } from "../../api/product.api";

import ORDER_FORM_PRODUCT_ACTION_TYPES from "./order-form-product.type";
import {
  appendBaseProducts,
  setBaseProducts,
  setFetchProductsFailed,
  setFetchProductsLoading,
  setFetchProductsSuccess,
  setIsFetchProductsHitted,
  setIsProductsHasMore,
  setIsSortProductsHitted,
  setSortProductsFailed,
  setSortProductsLoading,
  setSortProductsSuccess,
} from "./order-form-product.action";
import { getFetchProductsParams } from "./order-form-product.selector";

export function* _getFetchProducts() {
  try {
    const fetchProductsParams = yield select(getFetchProductsParams);
    const parameters = getParamsWithDefault(fetchProductsParams);

    yield put(setFetchProductsLoading(true));

    const {
      meta: { message },
      data: { data: products },
    } = yield call(getProducts, parameters);

    yield put(setIsFetchProductsHitted(true));
    yield put(setIsProductsHasMore(products.length > 0));

    if (parameters.page > 1) {
      yield put(appendBaseProducts(products));
    } else {
      yield put(setBaseProducts(products));
    }

    yield put(setFetchProductsSuccess(message));
    yield put(setFetchProductsLoading(false));
  } catch (error) {
    yield put(setFetchProductsFailed(error));
    yield put(setFetchProductsLoading(false));
  }
}
export function* _sortProducts() {
  try {
    yield put(setSortProductsLoading(true));

    const {
      meta: { message },
    } = yield call(sortProducts);

    yield put(setIsSortProductsHitted(true));

    yield put(setSortProductsSuccess(message));
    yield put(setSortProductsLoading(false));
  } catch (error) {
    yield put(setSortProductsFailed(error));
    yield put(setSortProductsLoading(false));
  }
}

export function* onFetchProductsStart() {
  yield takeLatest(
    ORDER_FORM_PRODUCT_ACTION_TYPES.FETCH_PRODUCTS_START,
    _getFetchProducts
  );
}
export function* onSortProductsStart() {
  yield takeLatest(
    ORDER_FORM_PRODUCT_ACTION_TYPES.SORT_PRODUCTS_START,
    _sortProducts
  );
}

export function* orderFormProductSaga() {
  yield all([call(onFetchProductsStart), call(onSortProductsStart)]);
}
