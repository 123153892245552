import { forwardRef } from "react";
import { useSelector } from "react-redux";

import { isEmpty } from "../../utils/validation.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import Spinner, {
  SPINNER_COLORS,
  SPINNER_SIZES,
} from "../spinner/spinner.component";

import {
  ButtonContent,
  ButtonLoading,
  ButtonPrefix,
  ButtonSuffix,
  IconButton,
  TextButton,
  TextIconButton,
} from "./button.style";

export const BUTTON_TYPES = {
  ICON: "ICON",
  TEXT: "TEXT",
  TEXT_ICON: "TEXT_ICON",
};

export const BUTTON_COLORS = {
  PRIMARY: "PRIMARY",
  SUCCESS: "SUCCESS",
  DANGER: "DANGER",
  WARNING: "WARNING",
  SECONDARY: "SECONDARY",
  WHITE: "WHITE",
  LIGHT: "LIGHT",
  GRAY: "GRAY",
  DARK: "DARK",

  LIGHT_PRIMARY: "LIGHT_PRIMARY",
  LIGHT_SUCCESS: "LIGHT_SUCCESS",
  LIGHT_DANGER: "LIGHT_DANGER",
  LIGHT_WARNING: "LIGHT_WARNING",
};

export const BUTTON_SIZES = {
  PX32: "PX32",
  PX40: "PX40",
  PX48: "PX48",

  SMALL: "SMALL",
  NORMAL: "NORMAL",
  LARGE: "LARGE",
};

const getButtonType = (buttonType) =>
  ({
    [BUTTON_TYPES.ICON]: IconButton,
    [BUTTON_TYPES.TEXT]: TextButton,
    [BUTTON_TYPES.TEXT_ICON]: TextIconButton,
  }[buttonType]);

const getSpinnerColor = (buttonColor) => {
  switch (buttonColor) {
    case BUTTON_COLORS.LIGHT_DANGER:
      return SPINNER_COLORS.DANGER;
    case BUTTON_COLORS.LIGHT_PRIMARY:
      return SPINNER_COLORS.PRIMARY;
    case BUTTON_COLORS.LIGHT:
    case BUTTON_COLORS.WHITE:
    case BUTTON_COLORS.SECONDARY:
      return SPINNER_COLORS.DARK;
    default:
      return SPINNER_COLORS.WHITE;
  }
};

const Button = forwardRef(
  (
    {
      buttonType = BUTTON_TYPES.TEXT,
      buttonColor = BUTTON_COLORS.PRIMARY,
      buttonSize = BUTTON_SIZES.NORMAL,
      buttonHoverColor,
      isFullWidth = false,
      isLoading = false,
      isDisabled = false,
      isRoundedSm = false,
      isRoundedMd = false,
      isRoundedLg = false,
      isLeftNotRounded = false,
      isRightNotRounded = false,
      isIconRight = false,
      isBetween = false,
      isCircle = false,
      isNoWrap = false,
      prefixIcon,
      suffixIcon,
      children,
      ...otherProps
    },
    ref
  ) => {
    const isRightToLeft = useSelector(getIsRightToLeft);

    const ButtonContainer = getButtonType(buttonType);
    otherProps.buttonColor = buttonColor;
    otherProps.buttonSize = buttonSize;
    otherProps.buttonHoverColor = buttonHoverColor;
    otherProps.isFullWidth = isFullWidth;
    otherProps.isRoundedSm = isRoundedSm;
    otherProps.isRoundedMd = isRoundedMd;
    otherProps.isRoundedLg = isRoundedLg;
    otherProps.isLeftNotRounded = isLeftNotRounded;
    otherProps.isRightNotRounded = isRightNotRounded;
    otherProps.isIconRight = isIconRight;
    otherProps.isBetween = isBetween;
    otherProps.isNoWrap = isNoWrap;
    otherProps.isCircle = isCircle;
    otherProps.isRightToLeft = isRightToLeft;

    const spinnerColor = getSpinnerColor(buttonColor);

    return (
      <ButtonContainer
        ref={ref}
        disabled={isLoading || isDisabled}
        {...otherProps}
      >
        <ButtonLoading isHide={!isLoading}>
          <Spinner
            spinnerColor={spinnerColor}
            spinnerSize={
              [
                BUTTON_SIZES.PX32,
                BUTTON_SIZES.PX40,
                BUTTON_SIZES.SMALL,
              ].includes(buttonSize)
                ? SPINNER_SIZES.SMALL
                : SPINNER_SIZES.NORMAL
            }
          />
        </ButtonLoading>
        <ButtonContent isHide={isLoading}>
          {buttonType === BUTTON_TYPES.TEXT_ICON && !isEmpty(prefixIcon) && (
            <ButtonPrefix>{prefixIcon}</ButtonPrefix>
          )}
          {children}
          {buttonType === BUTTON_TYPES.TEXT_ICON && !isEmpty(suffixIcon) && (
            <ButtonSuffix>{suffixIcon}</ButtonSuffix>
          )}
        </ButtonContent>
      </ButtonContainer>
    );
  }
);

export default Button;
