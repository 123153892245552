const USER_SUPPLIER_ACTION_TYPES = {
  RESET_USER_SUPPLIER_REDUCER: "user-supplier/RESET_USER_SUPPLIER_REDUCER",

  SET_USER_SUPPLIERS: "user-supplier/SET_USER_SUPPLIERS",
  SET_USER_SUPPLIER: "user-supplier/SET_USER_SUPPLIER",

  SET_IS_USER_SUPPLIERS_HAS_MORE:
    "user-supplier/SET_IS_USER_SUPPLIERS_HAS_MORE",

  SET_FETCH_USER_SUPPLIERS_SEARCH:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_SEARCH",
  SET_FETCH_USER_SUPPLIERS_SORT: "user-supplier/SET_FETCH_USER_SUPPLIERS_SORT",
  SET_FETCH_USER_SUPPLIERS_KEY_BY:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_KEY_BY",
  SET_FETCH_USER_SUPPLIERS_PAGE: "user-supplier/SET_FETCH_USER_SUPPLIERS_PAGE",
  SET_FETCH_USER_SUPPLIERS_PER_PAGE:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_PER_PAGE",
  SET_FETCH_USER_SUPPLIERS_INCLUDES:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_INCLUDES",
  SET_FETCH_USER_SUPPLIERS_FILTER_MARKET_ID:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_FILTER_MARKET_ID",
  SET_FETCH_USER_SUPPLIERS_LOADING:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_LOADING",
  SET_FETCH_USER_SUPPLIERS_SUCCESS:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_SUCCESS",
  SET_FETCH_USER_SUPPLIERS_FAILED:
    "user-supplier/SET_FETCH_USER_SUPPLIERS_FAILED",

  SET_FETCH_USER_SUPPLIER_LOADING:
    "user-supplier/SET_FETCH_USER_SUPPLIER_LOADING",
  SET_FETCH_USER_SUPPLIER_SUCCESS:
    "user-supplier/SET_FETCH_USER_SUPPLIER_SUCCESS",
  SET_FETCH_USER_SUPPLIER_FAILED:
    "user-supplier/SET_FETCH_USER_SUPPLIER_FAILED",

  SET_CREATE_USER_SUPPLIER_LOADING:
    "user-supplier/SET_CREATE_USER_SUPPLIER_LOADING",
  SET_CREATE_USER_SUPPLIER_SUCCESS:
    "user-supplier/SET_CREATE_USER_SUPPLIER_SUCCESS",
  SET_CREATE_USER_SUPPLIER_FAILED:
    "user-supplier/SET_CREATE_USER_SUPPLIER_FAILED",

  SET_UPDATE_USER_SUPPLIER_LOADING:
    "user-supplier/SET_UPDATE_USER_SUPPLIER_LOADING",
  SET_UPDATE_USER_SUPPLIER_SUCCESS:
    "user-supplier/SET_UPDATE_USER_SUPPLIER_SUCCESS",
  SET_UPDATE_USER_SUPPLIER_FAILED:
    "user-supplier/SET_UPDATE_USER_SUPPLIER_FAILED",

  SET_DELETE_USER_SUPPLIER_LOADING:
    "user-supplier/SET_DELETE_USER_SUPPLIER_LOADING",
  SET_DELETE_USER_SUPPLIER_SUCCESS:
    "user-supplier/SET_DELETE_USER_SUPPLIER_SUCCESS",
  SET_DELETE_USER_SUPPLIER_FAILED:
    "user-supplier/SET_DELETE_USER_SUPPLIER_FAILED",

  APPEND_USER_SUPPLIERS: "user-supplier/APPEND_USER_SUPPLIERS",

  SET_IS_FETCH_USER_SUPPLIERS_HITTED:
    "user-supplier/SET_IS_FETCH_USER_SUPPLIERS_HITTED",
  SET_IS_FETCH_USER_SUPPLIER_HITTED:
    "user-supplier/SET_IS_FETCH_USER_SUPPLIER_HITTED",
  SET_IS_CREATE_USER_SUPPLIER_HITTED:
    "user-supplier/SET_IS_CREATE_USER_SUPPLIER_HITTED",
  SET_IS_UPDATE_USER_SUPPLIER_HITTED:
    "user-supplier/SET_IS_UPDATE_USER_SUPPLIER_HITTED",
  SET_IS_DELETE_USER_SUPPLIER_HITTED:
    "user-supplier/SET_IS_DELETE_USER_SUPPLIER_HITTED",

  FETCH_USER_SUPPLIERS_START: "user-supplier/FETCH_USER_SUPPLIERS_START",
  FETCH_USER_SUPPLIER_START: "user-supplier/FETCH_USER_SUPPLIER_START",
  CREATE_USER_SUPPLIER_START: "user-supplier/CREATE_USER_SUPPLIER_START",
  UPDATE_USER_SUPPLIER_START: "user-supplier/UPDATE_USER_SUPPLIER_START",
  DELETE_USER_SUPPLIER_START: "user-supplier/DELETE_USER_SUPPLIER_START",
};

export default USER_SUPPLIER_ACTION_TYPES;
