import { createAction } from "../../utils/store.utils";

import SELECT_CATEGORY_PURCHASE_ACTION_TYPES from "./select-category-purchase.type";

export const setCategories = (categories) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_CATEGORIES,
    categories
  );
export const setSearchCategories = (searchCategories) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES,
    searchCategories
  );

export const appendCategories = (categories) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_CATEGORIES,
    categories
  );
export const appendSearchCategories = (searchCategories) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_SEARCH_CATEGORIES,
    searchCategories
  );

export const setIsCategoriesHasMore = (isCategoriesHasMore) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_CATEGORIES_HAS_MORE,
    isCategoriesHasMore
  );
export const setIsSearchCategoriesHasMore = (isSearchCategoriesHasMore) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_SEARCH_CATEGORIES_HAS_MORE,
    isSearchCategoriesHasMore
  );

export const setFetchCategoriesParams = (fetchCategoriesParams) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_PARAMS,
    fetchCategoriesParams
  );
export const setFetchCategoriesLoading = (fetchCategoriesLoading) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_LOADING,
    fetchCategoriesLoading
  );
export const setFetchCategoriesSuccess = (fetchCategoriesSuccess) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_SUCCESS,
    fetchCategoriesSuccess
  );
export const setFetchCategoriesFailed = (fetchCategoriesFailed) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_FETCH_CATEGORIES_FAILED,
    fetchCategoriesFailed
  );

export const setSearchCategoriesParams = (searchCategoriesParams) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_PARAMS,
    searchCategoriesParams
  );
export const setSearchCategoriesLoading = (searchCategoriesLoading) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_LOADING,
    searchCategoriesLoading
  );
export const setSearchCategoriesSuccess = (searchCategoriesSuccess) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_SUCCESS,
    searchCategoriesSuccess
  );
export const setSearchCategoriesFailed = (searchCategoriesFailed) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_SEARCH_CATEGORIES_FAILED,
    searchCategoriesFailed
  );

export const setAppendCategoriesParams = (appendCategoriesParams) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_PARAMS,
    appendCategoriesParams
  );
export const setAppendCategoriesLoading = (appendCategoriesLoading) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_LOADING,
    appendCategoriesLoading
  );
export const setAppendCategoriesSuccess = (appendCategoriesSuccess) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_SUCCESS,
    appendCategoriesSuccess
  );
export const setAppendCategoriesFailed = (appendCategoriesFailed) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_APPEND_CATEGORIES_FAILED,
    appendCategoriesFailed
  );

export const setIsFetchCategoriesHitted = (isFetchCategoriesHitted) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_FETCH_CATEGORIES_HITTED,
    isFetchCategoriesHitted
  );
export const setIsSearchCategoriesHitted = (isSearchCategoriesHitted) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_SEARCH_CATEGORIES_HITTED,
    isSearchCategoriesHitted
  );
export const setIsAppendCategoriesHitted = (isAppendCategoriesHitted) =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SET_IS_APPEND_CATEGORIES_HITTED,
    isAppendCategoriesHitted
  );

export const fetchCategoriesStart = () =>
  createAction(SELECT_CATEGORY_PURCHASE_ACTION_TYPES.FETCH_CATEGORIES_START);
export const searchCategoriesStart = () =>
  createAction(SELECT_CATEGORY_PURCHASE_ACTION_TYPES.SEARCH_CATEGORIES_START);
export const appendCategoriesStart = () =>
  createAction(SELECT_CATEGORY_PURCHASE_ACTION_TYPES.APPEND_CATEGORIES_START);

export const resetCategoryPurchaseReducer = () =>
  createAction(
    SELECT_CATEGORY_PURCHASE_ACTION_TYPES.RESET_SELECT_CATEGORY_PURCHASE_REDUCER
  );
