import { createSelector } from "reselect";

const orderFormCategorySelector = ({ orderFormCategory }) => orderFormCategory;

export const getCategories = createSelector(
  [orderFormCategorySelector],
  ({ categories }) => categories
);

export const getIsCategoriesHasMore = createSelector(
  [orderFormCategorySelector],
  ({ isCategoriesHasMore }) => isCategoriesHasMore
);

export const getFetchCategoriesParams = createSelector(
  [orderFormCategorySelector],
  ({ fetchCategoriesParams }) => fetchCategoriesParams
);
export const getFetchCategoriesLoading = createSelector(
  [orderFormCategorySelector],
  ({ fetchCategoriesLoading }) => fetchCategoriesLoading
);
export const getFetchCategoriesSuccess = createSelector(
  [orderFormCategorySelector],
  ({ fetchCategoriesSuccess }) => fetchCategoriesSuccess
);
export const getFetchCategoriesFailed = createSelector(
  [orderFormCategorySelector],
  ({ fetchCategoriesFailed }) => fetchCategoriesFailed
);

export const getIsFetchCategoriesHitted = createSelector(
  [orderFormCategorySelector],
  ({ isFetchCategoriesHitted }) => isFetchCategoriesHitted
);
