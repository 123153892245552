import { useSelector } from "react-redux";

import { parseToBoolean } from "../../utils/parser.utils";

import { getIsRightToLeft } from "../../store/global/global.selector";

import {
  FormInputRadioboxContainer,
  FormInputRadioboxField,
  FormInputRadioboxInput,
  FormInputRadioboxMark,
} from "./form-input-radiobox.style";

const FormInputRadiobox = ({
  label,
  isDisabled = false,
  isChecked = false,
  isSolid = false,
  onChange,
  children,
  ...otherProps
}) => {
  const isRightToLeft = useSelector(getIsRightToLeft);

  const handleRadioChange = ({ target: { name, value } }) =>
    onChange?.({ name, value });

  return (
    <FormInputRadioboxContainer isRightToLeft={isRightToLeft} isSolid={isSolid}>
      {label}
      <FormInputRadioboxField>
        {children}
        <FormInputRadioboxInput
          checked={parseToBoolean(isChecked)}
          disabled={parseToBoolean(isDisabled)}
          onChange={handleRadioChange}
          {...otherProps}
        />
        <FormInputRadioboxMark />
      </FormInputRadioboxField>
    </FormInputRadioboxContainer>
  );
};

export default FormInputRadiobox;
