import { parseToBoolean } from "../../utils/parser.utils";

import {
  SUBSCRIPTION_ADMIN_TABS,
  SUBSCRIPTION_SUBSCRIBER_TABS,
  SUBSCRIPTIONS_OPTION_KEYS,
  SUBSCRIPTIONS_KEYS,
} from "../../constants/subscription.constant";

import STATE_SUBSCRIPTION_ACTION_TYPES from "./state-subscription.type";

export const STATE_SUBSCRIPTION_INITIAL_STATE = {
  subscriptionAdminTab: SUBSCRIPTION_ADMIN_TABS.PLANS,
  subscriptionSubscriberTab: SUBSCRIPTION_SUBSCRIBER_TABS.PLANS,

  billingPlan: SUBSCRIPTIONS_KEYS.ADVANCED,
  billingCycle: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,

  isBranchesChanging: false,
  isCalculateLoading: false,
  isTransformLoading: false,

  updatingBranchKey: null,
  updatingItemIndex: null,

  renewalBranchesIds: [],
  renewalPlan: SUBSCRIPTIONS_KEYS.ADVANCED,
  renewalCycle: SUBSCRIPTIONS_OPTION_KEYS.MONTHLY,
  renewalBranches: {},
  renewalItems: [],
  renewalDiscount: 0,
};

export const stateSubscriptionReducer = (
  state = STATE_SUBSCRIPTION_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  const renewalBranches = { ...state.renewalBranches };
  const renewalItems = [...state.renewalItems];

  switch (type) {
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TAB:
      return { ...state, subscriptionAdminTab: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TAB:
      return { ...state, subscriptionSubscriberTab: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLAN:
      return { ...state, billingPlan: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_CYCLE:
      return { ...state, billingCycle: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_BRANCHES_CHANGING:
      return { ...state, isBranchesChanging: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_CALCULATE_LOADING:
      return { ...state, isCalculateLoading: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_TRANSFORM_LOADING:
      return { ...state, isTransformLoading: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATING_BRANCH_KEY:
      return { ...state, updatingBranchKey: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATING_ITEM_INDEX:
      return { ...state, updatingItemIndex: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_BRANCHES_IDS:
      return { ...state, renewalBranchesIds: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_PLAN:
      return { ...state, renewalPlan: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_CYCLE:
      return { ...state, renewalCycle: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_BRANCHES:
      return { ...state, renewalBranches: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_ITEMS:
      return { ...state, renewalItems: payload };
    case STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_DISCOUNT:
      return { ...state, renewalDiscount: payload };

    case STATE_SUBSCRIPTION_ACTION_TYPES.TOGGLE_RENEWAL_BRANCH:
      if (payload in renewalBranches) {
        const { is_selected } = renewalBranches[payload];
        renewalBranches[payload] = {
          ...renewalBranches[payload],
          is_selected: parseToBoolean(is_selected) ? 0 : 1,
        };
      }
      return { ...state, renewalBranches };
    case STATE_SUBSCRIPTION_ACTION_TYPES.UPDATE_RENEWAL_BRANCH:
      if (payload.key in renewalBranches) {
        renewalBranches[payload.key] = {
          ...renewalBranches[payload.key],
          ...payload.value,
        };
      }
      return { ...state, renewalBranches };

    case STATE_SUBSCRIPTION_ACTION_TYPES.APPEND_RENEWAL_ITEM:
      renewalItems.push(payload);
      return { ...state, renewalItems };
    case STATE_SUBSCRIPTION_ACTION_TYPES.UPDATE_RENEWAL_ITEM:
      if (payload.index in renewalItems) {
        renewalItems[payload.index] = payload.value;
      }
      return { ...state, renewalItems };
    case STATE_SUBSCRIPTION_ACTION_TYPES.DELETE_RENEWAL_ITEM:
      if (payload in renewalItems) renewalItems.splice(payload, 1);
      return { ...state, renewalItems };

    case STATE_SUBSCRIPTION_ACTION_TYPES.RESET_RENEWAL_VALUES:
      return {
        ...state,
        renewalPlan: STATE_SUBSCRIPTION_INITIAL_STATE.renewalPlan,
        renewalCycle: STATE_SUBSCRIPTION_INITIAL_STATE.renewalCycle,
        renewalBranches: STATE_SUBSCRIPTION_INITIAL_STATE.renewalBranches,
        renewalItems: STATE_SUBSCRIPTION_INITIAL_STATE.renewalItems,
        renewalDiscount: STATE_SUBSCRIPTION_INITIAL_STATE.renewalDiscount,
      };

    case STATE_SUBSCRIPTION_ACTION_TYPES.RESET_STATE_SUBSCRIPTION_REDUCER:
      return STATE_SUBSCRIPTION_INITIAL_STATE;
    default:
      return state;
  }
};
