import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setFetchProductsFailed,
  setSortProductsFailed,
  setSortProductsSuccess,
} from "../../store/order-form-product/order-form-product.action";
import {
  getFetchProductsFailed,
  getSortProductsFailed,
  getSortProductsSuccess,
} from "../../store/order-form-product/order-form-product.selector";

const OrderFormProductPopup = () => {
  const dispatch = useDispatch();

  const sortProductsSuccess = useSelector(getSortProductsSuccess);

  const fetchProductsFailed = useSelector(getFetchProductsFailed);
  const sortProductsFailed = useSelector(getSortProductsFailed);

  useEffect(() => {
    if (sortProductsSuccess !== null) {
      showSuccessMessage(sortProductsSuccess);
      dispatch(setIsModalOpen(false));
      dispatch(setSortProductsSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortProductsSuccess]);

  useEffect(() => {
    if (fetchProductsFailed !== null || sortProductsFailed !== null) {
      showErrorMessage(fetchProductsFailed ?? sortProductsFailed);

      if (fetchProductsFailed !== null) dispatch(setFetchProductsFailed(null));
      if (sortProductsFailed !== null) dispatch(setSortProductsFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchProductsFailed, sortProductsFailed]);

  return <></>;
};

export default OrderFormProductPopup;
