import apiService from "./api";

export const getProducts = async (params) =>
  (await apiService.innerGet({ urlPath: "/v2/products", params })).data;
export const getProduct = async (productId) =>
  (await apiService.innerGet({ urlPath: `/v2/products/${productId}` })).data;
export const createProduct = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/products", request })).data;
export const updateProduct = async (productId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/products/${productId}`,
      request,
    })
  ).data;
export const deleteProduct = async (productId) =>
  (await apiService.innerDelete({ urlPath: `/v2/products/${productId}` })).data;

export const sortProducts = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/products/sort", request })).data;

export const multiCreateProducts = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/products/multi", request })).data;
export const multiDeleteProducts = async (request) =>
  (await apiService.innerDelete({ urlPath: "/v2/products/multi", request }))
    .data;

export const quickCreateProduct = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/products/quick", request })).data;
export const quickUpdateProduct = async (productId, request) =>
  (
    await apiService.innerPost({
      urlPath: `/v2/products/quick/${productId}`,
      request,
    })
  ).data;
export const quickMultiCreateProducts = async (request) =>
  (await apiService.innerPost({ urlPath: "/v2/products/quick/multi", request }))
    .data;
