import STATE_RESUPPLY_ACTION_TYPES from "./state-resupply.type";

export const STATE_RESUPPLY_INITIAL_STATE = {
  resupplyDialogKey: null,

  resupplyIngredients: [],
  receivedIngredients: {},
};

export const stateResupplyReducer = (
  state = STATE_RESUPPLY_INITIAL_STATE,
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case STATE_RESUPPLY_ACTION_TYPES.SET_RESUPPLY_DIALOG_KEY:
      return { ...state, resupplyDialogKey: payload };

    case STATE_RESUPPLY_ACTION_TYPES.SET_RESUPPLY_INGREDIENTS:
      return { ...state, resupplyIngredients: payload };
    case STATE_RESUPPLY_ACTION_TYPES.SET_RECEIVED_INGREDIENTS:
      return { ...state, receivedIngredients: payload };

    case STATE_RESUPPLY_ACTION_TYPES.APPEND_RESUPPLY_INGREDIENT: {
      const resupplyIngredients = [...state.resupplyIngredients];
      resupplyIngredients.push(payload);

      return { ...state, resupplyIngredients };
    }
    case STATE_RESUPPLY_ACTION_TYPES.UPDATE_RESUPPLY_INGREDIENT: {
      const resupplyIngredients = [...state.resupplyIngredients];
      if (payload.index in resupplyIngredients) {
        resupplyIngredients[payload.index] = payload.value;
      }

      return { ...state, resupplyIngredients };
    }
    case STATE_RESUPPLY_ACTION_TYPES.DELETE_RESUPPLY_INGREDIENT: {
      const resupplyIngredients = [...state.resupplyIngredients];
      if (payload in resupplyIngredients) {
        resupplyIngredients.splice(payload, 1);
      }

      return { ...state, resupplyIngredients };
    }

    case STATE_RESUPPLY_ACTION_TYPES.UPSERT_RECEIVED_INGREDIENT: {
      const receivedIngredients = { ...state.receivedIngredients };
      receivedIngredients[payload.key] = payload.value;

      return { ...state, receivedIngredients };
    }
    case STATE_RESUPPLY_ACTION_TYPES.DELETE_RECEIVED_INGREDIENT: {
      const receivedIngredients = { ...state.receivedIngredients };
      if (payload in receivedIngredients) {
        delete receivedIngredients[payload];
      }

      return { ...state, receivedIngredients };
    }

    case STATE_RESUPPLY_ACTION_TYPES.RESET_STATE_RESUPPLY_REDUCER:
      return STATE_RESUPPLY_INITIAL_STATE;
    default:
      return state;
  }
};
