import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import { PurchaseFormTableItemDetailsLabel } from "./purchase-form-table-item-details.style";

const PurchaseFormTableItemDetails = ({ item }) => {
  const { i18n, t } = useTranslation();

  const { name_en, name_ar } = item ?? {};

  const itemName = item?.[`name_${i18n.language}`] ?? name_en ?? name_ar;

  return (
    <PurchaseFormTableItemDetailsLabel>
      {!isEmpty(itemName) ? itemName : t("No name")}
    </PurchaseFormTableItemDetailsLabel>
  );
};

export default PurchaseFormTableItemDetails;
