import { useSelector } from "react-redux";

import { isEmpty } from "../../utils/validation.utils";

import { getIsHasGettingStarted } from "../../store/component/component.selector";
import { getUser } from "../../store/authentication/authentication.selector";

import GlobalFetch from "./global-fetch.widget";
import GlobalState from "./global-state.widget";
import GlobalPopup from "./global-popup.widget";
import GlobalChatBot from "./global-chatbot.widget";
import GlobalAnnouncement from "./global-announcement.widget";
import GlobalTranslation from "./global-translation.widget";

import SubscriptionPlanDetails from "../subscription-plan-details/subscription-plan-details.widget";
import Toast from "../../components/toast/toast.component";
import GettingStarted from "../getting-started/getting-started.widget";
import ApplicationCompletionOverlay from "../application-completion-overlay/application-completion-overlay.widget";
import Dialog from "../../components/dialog/dialog.component";

const Global = () => {
  const isHasGettingStarted = useSelector(getIsHasGettingStarted);
  const authUser = useSelector(getUser);

  return (
    <>
      <GlobalFetch />
      <GlobalState />
      <GlobalPopup />
      <GlobalChatBot />
      <GlobalAnnouncement />
      <GlobalTranslation />

      <SubscriptionPlanDetails />
      <Toast />
      <Dialog />
      <ApplicationCompletionOverlay />
      {!isEmpty(authUser) && isHasGettingStarted && <GettingStarted />}
    </>
  );
};

export default Global;
