import { createAction } from "../../utils/store.utils";

import RESUPPLY_ACTION_TYPES from "./resupply.type";

export const setResupplies = (resupplies) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_RESUPPLIES, resupplies);
export const setResupply = (resupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_RESUPPLY, resupply);
export const setCreateResupply = (createResupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY, createResupply);
export const setUpdateResupply = (updateResupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY, updateResupply);
export const setReceiveResupply = (receiveResupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY, receiveResupply);
export const setApproveResupply = (approveResupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY, approveResupply);
export const setDeclineResupply = (declineResupply) =>
  createAction(RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY, declineResupply);

export const setIsResuppliesHasMore = (isResuppliesHasMore) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_IS_RESUPPLIES_HAS_MORE,
    isResuppliesHasMore
  );

export const setFetchResuppliesSearch = (fetchResuppliesSearch) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_SEARCH,
    fetchResuppliesSearch
  );
export const setFetchResuppliesPage = (fetchResuppliesPage) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_PAGE,
    fetchResuppliesPage
  );
export const setFetchResuppliesPerPage = (fetchResuppliesPerPage) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_PER_PAGE,
    fetchResuppliesPerPage
  );
export const setFetchResuppliesIncludes = (fetchResuppliesIncludes) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_INCLUDES,
    fetchResuppliesIncludes
  );
export const setFetchResuppliesFilterMarketId = (
  fetchResuppliesFilterMarketId
) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_MARKET_ID,
    fetchResuppliesFilterMarketId
  );
export const setFetchResuppliesFilterBranchId = (
  fetchResuppliesFilterBranchId
) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_BRANCH_ID,
    fetchResuppliesFilterBranchId
  );
export const setFetchResuppliesFilterCreatedBy = (
  fetchResuppliesFilterCreatedBy
) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_CREATED_BY,
    fetchResuppliesFilterCreatedBy
  );
export const setFetchResuppliesFilterStatus = (fetchResuppliesFilterStatus) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_STATUS,
    fetchResuppliesFilterStatus
  );
export const setFetchResuppliesFilterIsReceived = (
  fetchResuppliesFilterIsReceived
) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FILTER_IS_RECEIVED,
    fetchResuppliesFilterIsReceived
  );
export const setFetchResuppliesLoading = (fetchResuppliesLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_LOADING,
    fetchResuppliesLoading
  );
export const setFetchResuppliesSuccess = (fetchResuppliesSuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_SUCCESS,
    fetchResuppliesSuccess
  );
export const setFetchResuppliesFailed = (fetchResuppliesFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLIES_FAILED,
    fetchResuppliesFailed
  );

export const setFetchResupplyLoading = (fetchResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_LOADING,
    fetchResupplyLoading
  );
export const setFetchResupplySuccess = (fetchResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_SUCCESS,
    fetchResupplySuccess
  );
export const setFetchResupplyFailed = (fetchResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_FETCH_RESUPPLY_FAILED,
    fetchResupplyFailed
  );

export const setCreateResupplyLoading = (createResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_LOADING,
    createResupplyLoading
  );
export const setCreateResupplySuccess = (createResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_SUCCESS,
    createResupplySuccess
  );
export const setCreateResupplyFailed = (createResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_CREATE_RESUPPLY_FAILED,
    createResupplyFailed
  );

export const setUpdateResupplyLoading = (updateResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_LOADING,
    updateResupplyLoading
  );
export const setUpdateResupplySuccess = (updateResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_SUCCESS,
    updateResupplySuccess
  );
export const setUpdateResupplyFailed = (updateResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_UPDATE_RESUPPLY_FAILED,
    updateResupplyFailed
  );

export const setDeleteResupplyLoading = (deleteResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_LOADING,
    deleteResupplyLoading
  );
export const setDeleteResupplySuccess = (deleteResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_SUCCESS,
    deleteResupplySuccess
  );
export const setDeleteResupplyFailed = (deleteResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DELETE_RESUPPLY_FAILED,
    deleteResupplyFailed
  );

export const setReceiveResupplyLoading = (receiveResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_LOADING,
    receiveResupplyLoading
  );
export const setReceiveResupplySuccess = (receiveResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_SUCCESS,
    receiveResupplySuccess
  );
export const setReceiveResupplyFailed = (receiveResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_RECEIVE_RESUPPLY_FAILED,
    receiveResupplyFailed
  );

export const setApproveResupplyLoading = (approveResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_LOADING,
    approveResupplyLoading
  );
export const setApproveResupplySuccess = (approveResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_SUCCESS,
    approveResupplySuccess
  );
export const setApproveResupplyFailed = (approveResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_APPROVE_RESUPPLY_FAILED,
    approveResupplyFailed
  );

export const setDeclineResupplyLoading = (declineResupplyLoading) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_LOADING,
    declineResupplyLoading
  );
export const setDeclineResupplySuccess = (declineResupplySuccess) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_SUCCESS,
    declineResupplySuccess
  );
export const setDeclineResupplyFailed = (declineResupplyFailed) =>
  createAction(
    RESUPPLY_ACTION_TYPES.SET_DECLINE_RESUPPLY_FAILED,
    declineResupplyFailed
  );

export const appendResupplies = (resupplies) =>
  createAction(RESUPPLY_ACTION_TYPES.APPEND_RESUPPLIES, resupplies);

export const fetchResuppliesStart = () =>
  createAction(RESUPPLY_ACTION_TYPES.FETCH_RESUPPLIES_START);
export const fetchResupplyStart = (resupplyId) =>
  createAction(RESUPPLY_ACTION_TYPES.FETCH_RESUPPLY_START, resupplyId);
export const createResupplyStart = (request) =>
  createAction(RESUPPLY_ACTION_TYPES.CREATE_RESUPPLY_START, request);
export const updateResupplyStart = (resupplyId, request) =>
  createAction(RESUPPLY_ACTION_TYPES.UPDATE_RESUPPLY_START, {
    resupplyId,
    request,
  });
export const deleteResupplyStart = (resupplyId) =>
  createAction(RESUPPLY_ACTION_TYPES.DELETE_RESUPPLY_START, resupplyId);
export const receiveResupplyStart = (resupplyId, request) =>
  createAction(RESUPPLY_ACTION_TYPES.RECEIVE_RESUPPLY_START, {
    resupplyId,
    request,
  });
export const approveResupplyStart = (resupplyId) =>
  createAction(RESUPPLY_ACTION_TYPES.APPROVE_RESUPPLY_START, resupplyId);
export const declineResupplyStart = (resupplyId) =>
  createAction(RESUPPLY_ACTION_TYPES.DECLINE_RESUPPLY_START, resupplyId);

export const setIsFetchResuppliesHitted = (isFetchResuppliesHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_FETCH_RESUPPLIES_HITTED,
    isFetchResuppliesHitted
  );
export const setIsFetchResupplyHitted = (isFetchResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_FETCH_RESUPPLY_HITTED,
    isFetchResupplyHitted
  );
export const setIsCreateResupplyHitted = (isCreateResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_CREATE_RESUPPLY_HITTED,
    isCreateResupplyHitted
  );
export const setIsUpdateResupplyHitted = (isUpdateResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_UPDATE_RESUPPLY_HITTED,
    isUpdateResupplyHitted
  );
export const setIsDeleteResupplyHitted = (isDeleteResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_DELETE_RESUPPLY_HITTED,
    isDeleteResupplyHitted
  );
export const setIsReceiveResupplyHitted = (isReceiveResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_RECEIVE_RESUPPLY_HITTED,
    isReceiveResupplyHitted
  );
export const setIsApproveResupplyHitted = (isApproveResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_APPROVE_RESUPPLY_HITTED,
    isApproveResupplyHitted
  );
export const setIsDeclineResupplyHitted = (isDeclineResupplyHitted) =>
  createAction(
    RESUPPLY_ACTION_TYPES.IS_DECLINE_RESUPPLY_HITTED,
    isDeclineResupplyHitted
  );

export const resetResupplyReducer = () =>
  createAction(RESUPPLY_ACTION_TYPES.RESET_RESUPPLY_REDUCER);
