const PURCHASE_ACTION_TYPES = {
  RESET_PURCHASE_REDUCER: "purchase/RESET_PURCHASE_REDUCER",

  SET_INGREDIENT_PURCHASES: "purchase/SET_INGREDIENT_PURCHASES",
  SET_ITEM_PURCHASES: "purchase/SET_ITEM_PURCHASES",
  SET_PURCHASE: "purchase/SET_PURCHASE",
  SET_CALCULATE_CREATE_PURCHASE: "purchase/SET_CALCULATE_CREATE_PURCHASE",
  SET_CALCULATE_UPDATE_PURCHASE: "purchase/SET_CALCULATE_UPDATE_PURCHASE",
  SET_CREATE_PURCHASE: "purchase/SET_CREATE_PURCHASE",
  SET_UPDATE_PURCHASE: "purchase/SET_UPDATE_PURCHASE",

  SET_IS_INGREDIENT_PURCHASES_HAS_MORE:
    "purchase/SET_IS_INGREDIENT_PURCHASES_HAS_MORE",
  SET_IS_ITEM_PURCHASES_HAS_MORE: "purchase/SET_IS_ITEM_PURCHASES_HAS_MORE",

  SET_FETCH_INGREDIENT_PURCHASES_SEARCH:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_SEARCH",
  SET_FETCH_INGREDIENT_PURCHASES_SORT:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_SORT",
  SET_FETCH_INGREDIENT_PURCHASES_KEY_BY:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_KEY_BY",
  SET_FETCH_INGREDIENT_PURCHASES_PAGE:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_PAGE",
  SET_FETCH_INGREDIENT_PURCHASES_PER_PAGE:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_PER_PAGE",
  SET_FETCH_INGREDIENT_PURCHASES_INCLUDES:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_INCLUDES",
  SET_FETCH_INGREDIENT_PURCHASES_FILTER_MARKET_ID:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FILTER_MARKET_ID",
  SET_FETCH_INGREDIENT_PURCHASES_FILTER_BRANCH_ID:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FILTER_BRANCH_ID",
  SET_FETCH_INGREDIENT_PURCHASES_FILTER_CATEGORY_ID:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FILTER_CATEGORY_ID",
  SET_FETCH_INGREDIENT_PURCHASES_FILTER_SUPPLIER_ID:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FILTER_SUPPLIER_ID",
  SET_FETCH_INGREDIENT_PURCHASES_FILTER_STATUS:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FILTER_STATUS",
  SET_FETCH_INGREDIENT_PURCHASES_LOADING:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_LOADING",
  SET_FETCH_INGREDIENT_PURCHASES_SUCCESS:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_SUCCESS",
  SET_FETCH_INGREDIENT_PURCHASES_FAILED:
    "purchase/SET_FETCH_INGREDIENT_PURCHASES_FAILED",

  SET_FETCH_ITEM_PURCHASES_SEARCH: "purchase/SET_FETCH_ITEM_PURCHASES_SEARCH",
  SET_FETCH_ITEM_PURCHASES_SORT: "purchase/SET_FETCH_ITEM_PURCHASES_SORT",
  SET_FETCH_ITEM_PURCHASES_KEY_BY: "purchase/SET_FETCH_ITEM_PURCHASES_KEY_BY",
  SET_FETCH_ITEM_PURCHASES_PAGE: "purchase/SET_FETCH_ITEM_PURCHASES_PAGE",
  SET_FETCH_ITEM_PURCHASES_PER_PAGE:
    "purchase/SET_FETCH_ITEM_PURCHASES_PER_PAGE",
  SET_FETCH_ITEM_PURCHASES_INCLUDES:
    "purchase/SET_FETCH_ITEM_PURCHASES_INCLUDES",
  SET_FETCH_ITEM_PURCHASES_FILTER_MARKET_ID:
    "purchase/SET_FETCH_ITEM_PURCHASES_FILTER_MARKET_ID",
  SET_FETCH_ITEM_PURCHASES_FILTER_BRANCH_ID:
    "purchase/SET_FETCH_ITEM_PURCHASES_FILTER_BRANCH_ID",
  SET_FETCH_ITEM_PURCHASES_FILTER_CATEGORY_ID:
    "purchase/SET_FETCH_ITEM_PURCHASES_FILTER_CATEGORY_ID",
  SET_FETCH_ITEM_PURCHASES_FILTER_SUPPLIER_ID:
    "purchase/SET_FETCH_ITEM_PURCHASES_FILTER_SUPPLIER_ID",
  SET_FETCH_ITEM_PURCHASES_FILTER_STATUS:
    "purchase/SET_FETCH_ITEM_PURCHASES_FILTER_STATUS",
  SET_FETCH_ITEM_PURCHASES_LOADING: "purchase/SET_FETCH_ITEM_PURCHASES_LOADING",
  SET_FETCH_ITEM_PURCHASES_SUCCESS: "purchase/SET_FETCH_ITEM_PURCHASES_SUCCESS",
  SET_FETCH_ITEM_PURCHASES_FAILED: "purchase/SET_FETCH_ITEM_PURCHASES_FAILED",

  SET_FETCH_PURCHASE_LOADING: "purchase/SET_FETCH_PURCHASE_LOADING",
  SET_FETCH_PURCHASE_SUCCESS: "purchase/SET_FETCH_PURCHASE_SUCCESS",
  SET_FETCH_PURCHASE_FAILED: "purchase/SET_FETCH_PURCHASE_FAILED",

  SET_CALCULATE_CREATE_PURCHASE_LOADING:
    "purchase/SET_CALCULATE_CREATE_PURCHASE_LOADING",
  SET_CALCULATE_CREATE_PURCHASE_SUCCESS:
    "purchase/SET_CALCULATE_CREATE_PURCHASE_SUCCESS",
  SET_CALCULATE_CREATE_PURCHASE_FAILED:
    "purchase/SET_CALCULATE_CREATE_PURCHASE_FAILED",

  SET_CALCULATE_UPDATE_PURCHASE_LOADING:
    "purchase/SET_CALCULATE_UPDATE_PURCHASE_LOADING",
  SET_CALCULATE_UPDATE_PURCHASE_SUCCESS:
    "purchase/SET_CALCULATE_UPDATE_PURCHASE_SUCCESS",
  SET_CALCULATE_UPDATE_PURCHASE_FAILED:
    "purchase/SET_CALCULATE_UPDATE_PURCHASE_FAILED",

  SET_CREATE_PURCHASE_LOADING: "purchase/SET_CREATE_PURCHASE_LOADING",
  SET_CREATE_PURCHASE_SUCCESS: "purchase/SET_CREATE_PURCHASE_SUCCESS",
  SET_CREATE_PURCHASE_FAILED: "purchase/SET_CREATE_PURCHASE_FAILED",

  SET_UPDATE_PURCHASE_LOADING: "purchase/SET_UPDATE_PURCHASE_LOADING",
  SET_UPDATE_PURCHASE_SUCCESS: "purchase/SET_UPDATE_PURCHASE_SUCCESS",
  SET_UPDATE_PURCHASE_FAILED: "purchase/SET_UPDATE_PURCHASE_FAILED",

  SET_DELETE_PURCHASE_LOADING: "purchase/SET_DELETE_PURCHASE_LOADING",
  SET_DELETE_PURCHASE_SUCCESS: "purchase/SET_DELETE_PURCHASE_SUCCESS",
  SET_DELETE_PURCHASE_FAILED: "purchase/SET_DELETE_PURCHASE_FAILED",

  APPEND_INGREDIENT_PURCHASES: "purchase/APPEND_INGREDIENT_PURCHASES",
  APPEND_ITEM_PURCHASES: "purchase/APPEND_ITEM_PURCHASES",

  SET_IS_FETCH_INGREDIENT_PURCHASES_HITTED:
    "purchase/SET_IS_FETCH_INGREDIENT_PURCHASES_HITTED",
  SET_IS_FETCH_ITEM_PURCHASES_HITTED:
    "purchase/SET_IS_FETCH_ITEM_PURCHASES_HITTED",
  SET_IS_FETCH_PURCHASE_HITTED: "purchase/SET_IS_FETCH_PURCHASE_HITTED",
  SET_IS_CALCULATE_CREATE_PURCHASE_HITTED:
    "purchase/SET_IS_CALCULATE_CREATE_PURCHASE_HITTED",
  SET_IS_CALCULATE_UPDATE_PURCHASE_HITTED:
    "purchase/SET_IS_CALCULATE_UPDATE_PURCHASE_HITTED",
  SET_IS_CREATE_PURCHASE_HITTED: "purchase/SET_IS_CREATE_PURCHASE_HITTED",
  SET_IS_UPDATE_PURCHASE_HITTED: "purchase/SET_IS_UPDATE_PURCHASE_HITTED",
  SET_IS_DELETE_PURCHASE_HITTED: "purchase/SET_IS_DELETE_PURCHASE_HITTED",

  FETCH_INGREDIENT_PURCHASES_START: "purchase/FETCH_INGREDIENT_PURCHASES_START",
  FETCH_ITEM_PURCHASES_START: "purchase/FETCH_ITEM_PURCHASES_START",
  FETCH_PURCHASE_START: "purchase/FETCH_PURCHASE_START",
  CALCULATE_CREATE_PURCHASE_START: "purchase/CALCULATE_CREATE_PURCHASE_START",
  CALCULATE_UPDATE_PURCHASE_START: "purchase/CALCULATE_UPDATE_PURCHASE_START",
  CREATE_PURCHASE_START: "purchase/CREATE_PURCHASE_START",
  UPDATE_PURCHASE_START: "purchase/UPDATE_PURCHASE_START",
  DELETE_PURCHASE_START: "purchase/DELETE_PURCHASE_START",
};

export default PURCHASE_ACTION_TYPES;
