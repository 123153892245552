import { createSelector } from "reselect";

const pdfSelector = ({ pdf }) => pdf;

export const getOrderReceipt = createSelector([pdfSelector], ({ orderReceipt }) => orderReceipt);
export const getOrderNotes = createSelector([pdfSelector], ({ orderNotes }) => orderNotes);
export const getRenewalReceipt = createSelector([pdfSelector], ({ renewalReceipt }) => renewalReceipt);

export const getPdfOrderReceiptIsMultiLang = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptIsMultiLang }) => pdfOrderReceiptIsMultiLang
);
export const getPdfOrderReceiptLoading = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptLoading }) => pdfOrderReceiptLoading
);
export const getPdfOrderReceiptSuccess = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptSuccess }) => pdfOrderReceiptSuccess
);
export const getPdfOrderReceiptFailed = createSelector(
  [pdfSelector],
  ({ pdfOrderReceiptFailed }) => pdfOrderReceiptFailed
);

export const getPdfOrderNotesIsMultiLang = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesIsMultiLang }) => pdfOrderNotesIsMultiLang
);
export const getPdfOrderNotesLoading = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesLoading }) => pdfOrderNotesLoading
);
export const getPdfOrderNotesSuccess = createSelector(
  [pdfSelector],
  ({ pdfOrderNotesSuccess }) => pdfOrderNotesSuccess
);
export const getPdfOrderNotesFailed = createSelector([pdfSelector], ({ pdfOrderNotesFailed }) => pdfOrderNotesFailed);

export const getPdfRenewalReceiptLoading = createSelector(
  [pdfSelector],
  ({ pdfRenewalReceiptLoading }) => pdfRenewalReceiptLoading
);
export const getPdfRenewalReceiptSuccess = createSelector(
  [pdfSelector],
  ({ pdfRenewalReceiptSuccess }) => pdfRenewalReceiptSuccess
);
export const getPdfRenewalReceiptFailed = createSelector(
  [pdfSelector],
  ({ pdfRenewalReceiptFailed }) => pdfRenewalReceiptFailed
);

export const getIsPdfOrderReceiptHitted = createSelector(
  [pdfSelector],
  ({ isPdfOrderReceiptHitted }) => isPdfOrderReceiptHitted
);
export const getIsPdfOrderNotesHitted = createSelector(
  [pdfSelector],
  ({ isPdfOrderNotesHitted }) => isPdfOrderNotesHitted
);
export const getIsPdfRenewalReceiptHitted = createSelector(
  [pdfSelector],
  ({ isPdfRenewalReceiptHitted }) => isPdfRenewalReceiptHitted
);
