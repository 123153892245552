import styled from "styled-components";

export const FormStepHeaderContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

export const FormStepHeaderUpper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 0.75rem;
`;

export const FormStepHeaderLower = styled.div`
  margin-top: 1rem;
`;

export const FormStepHeaderInfo = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const FormStepHeaderToolbar = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormStepHeaderTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 700;
  color: #0e072f;
  margin-bottom: 0.25rem;
`;

export const FormStepHeaderDescription = styled.div`
  font-size: 0.875rem;
  font-weight: normal;
  color: #8d899f;
  margin: 0;
`;

export const FormStepHeaderSeparator = styled.div`
  border-top: 1.5px dashed #c7cad0;
  margin-bottom: 0.5rem;
`;
