import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { showErrorMessage, showSuccessMessage } from "../../utils/popup.utils";

import { setIsModalOpen } from "../../store/component/component.action";
import {
  setCreateUserSupplierFailed,
  setCreateUserSupplierSuccess,
  setDeleteUserSupplierFailed,
  setDeleteUserSupplierSuccess,
  setFetchUserSupplierFailed,
  setFetchUserSuppliersFailed,
  setUpdateUserSupplierFailed,
  setUpdateUserSupplierSuccess,
} from "../../store/user-supplier/user-supplier.action";
import {
  getCreateUserSupplierFailed,
  getCreateUserSupplierSuccess,
  getDeleteUserSupplierFailed,
  getDeleteUserSupplierSuccess,
  getFetchUserSupplierFailed,
  getFetchUserSuppliersFailed,
  getUpdateUserSupplierFailed,
  getUpdateUserSupplierSuccess,
} from "../../store/user-supplier/user-supplier.selector";

const UserSupplierPopup = () => {
  const dispatch = useDispatch();

  const createUserSupplierSuccess = useSelector(getCreateUserSupplierSuccess);
  const updateUserSupplierSuccess = useSelector(getUpdateUserSupplierSuccess);
  const deleteUserSupplierSuccess = useSelector(getDeleteUserSupplierSuccess);

  const fetchUserSuppliersFailed = useSelector(getFetchUserSuppliersFailed);
  const fetchUserSupplierFailed = useSelector(getFetchUserSupplierFailed);
  const createUserSupplierFailed = useSelector(getCreateUserSupplierFailed);
  const updateUserSupplierFailed = useSelector(getUpdateUserSupplierFailed);
  const deleteUserSupplierFailed = useSelector(getDeleteUserSupplierFailed);

  useEffect(() => {
    if (
      createUserSupplierSuccess !== null ||
      updateUserSupplierSuccess !== null ||
      deleteUserSupplierSuccess !== null
    ) {
      showSuccessMessage(
        createUserSupplierSuccess ??
          updateUserSupplierSuccess ??
          deleteUserSupplierSuccess
      );
      dispatch(setIsModalOpen(false));

      if (createUserSupplierSuccess !== null)
        dispatch(setCreateUserSupplierSuccess(null));
      if (updateUserSupplierSuccess !== null)
        dispatch(setUpdateUserSupplierSuccess(null));
      if (deleteUserSupplierSuccess !== null)
        dispatch(setDeleteUserSupplierSuccess(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createUserSupplierSuccess,
    updateUserSupplierSuccess,
    deleteUserSupplierSuccess,
  ]);

  useEffect(() => {
    if (
      fetchUserSuppliersFailed !== null ||
      fetchUserSupplierFailed !== null ||
      createUserSupplierFailed !== null ||
      updateUserSupplierFailed !== null ||
      deleteUserSupplierFailed !== null
    ) {
      showErrorMessage(
        fetchUserSuppliersFailed ??
          fetchUserSupplierFailed ??
          createUserSupplierFailed ??
          updateUserSupplierFailed ??
          deleteUserSupplierFailed
      );

      if (fetchUserSuppliersFailed !== null)
        dispatch(setFetchUserSuppliersFailed(null));
      if (fetchUserSupplierFailed !== null)
        dispatch(setFetchUserSupplierFailed(null));
      if (createUserSupplierFailed !== null)
        dispatch(setCreateUserSupplierFailed(null));
      if (updateUserSupplierFailed !== null)
        dispatch(setUpdateUserSupplierFailed(null));
      if (deleteUserSupplierFailed !== null)
        dispatch(setDeleteUserSupplierFailed(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchUserSuppliersFailed,
    fetchUserSupplierFailed,
    createUserSupplierFailed,
    updateUserSupplierFailed,
    deleteUserSupplierFailed,
  ]);

  return <></>;
};

export default UserSupplierPopup;
