import { createAction } from "../../utils/store.utils";

import RENEWAL_ADMIN_ACTION_TYPES from "./renewal-admin.type";

export const setRenewals = (renewals) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWALS, renewals);
export const setRenewal = (renewal) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL, renewal);
export const setRenewalMarket = (renewalMarket) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_MARKET, renewalMarket);
export const setRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_BRANCHES,
    renewalBranches
  );
export const setRenewalCreate = (renewalCreate) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE, renewalCreate);
export const setRenewalCreateCalculate = (renewalCreateCalculate) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CREATE_CALCULATE,
    renewalCreateCalculate
  );
export const setRenewalCustom = (renewalCustom) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CUSTOM, renewalCustom);
export const setRenewalCustomCalculate = (renewalCustomCalculate) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_CUSTOM_CALCULATE,
    renewalCustomCalculate
  );
export const setRenewalUpdate = (renewalUpdate) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_UPDATE, renewalUpdate);
export const setRenewalUpdateCalculate = (renewalUpdateCalculate) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_RENEWAL_UPDATE_CALCULATE,
    renewalUpdateCalculate
  );

export const appendRenewals = (renewals) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWALS, renewals);
export const appendRenewalBranches = (renewalBranches) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.APPEND_RENEWAL_BRANCHES,
    renewalBranches
  );

export const setIsRenewalsHasMore = (isRenewalsHasMore) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWALS_HAS_MORE,
    isRenewalsHasMore
  );
export const setIsRenewalBranchesHasMore = (isRenewalBranchesHasMore) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_RENEWAL_BRANCHES_HAS_MORE,
    isRenewalBranchesHasMore
  );

export const setFetchRenewalsSearch = (fetchRenewalsSearch) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SEARCH,
    fetchRenewalsSearch
  );
export const setFetchRenewalsSort = (fetchRenewalsSort) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SORT,
    fetchRenewalsSort
  );
export const setFetchRenewalsKeyBy = (fetchRenewalsKeyBy) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_KEY_BY,
    fetchRenewalsKeyBy
  );
export const setFetchRenewalsPage = (fetchRenewalsPage) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_PAGE,
    fetchRenewalsPage
  );
export const setFetchRenewalsPerPage = (fetchRenewalsPerPage) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_PER_PAGE,
    fetchRenewalsPerPage
  );
export const setFetchRenewalsIncludes = (fetchRenewalsIncludes) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_INCLUDES,
    fetchRenewalsIncludes
  );
export const setFetchRenewalsFilterMarketId = (fetchRenewalsFilterMarketId) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_MARKET_ID,
    fetchRenewalsFilterMarketId
  );
export const setFetchRenewalsFilterSubscriptionKeys = (
  fetchRenewalsFilterSubscriptionKeys
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_KEYS,
    fetchRenewalsFilterSubscriptionKeys
  );
export const setFetchRenewalsFilterSubscriptionOptionKeys = (
  fetchRenewalsFilterSubscriptionOptionKeys
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_SUBSCRIPTION_OPTION_KEYS,
    fetchRenewalsFilterSubscriptionOptionKeys
  );
export const setFetchRenewalsFilterStatuses = (fetchRenewalsFilterStatuses) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_STATUSES,
    fetchRenewalsFilterStatuses
  );
export const setFetchRenewalsFilterCreatedBy = (fetchRenewalsFilterCreatedBy) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FILTER_CREATED_BY,
    fetchRenewalsFilterCreatedBy
  );
export const setFetchRenewalsLoading = (fetchRenewalsLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_LOADING,
    fetchRenewalsLoading
  );
export const setFetchRenewalsSuccess = (fetchRenewalsSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_SUCCESS,
    fetchRenewalsSuccess
  );
export const setFetchRenewalsFailed = (fetchRenewalsFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWALS_FAILED,
    fetchRenewalsFailed
  );

export const setFetchRenewalLoading = (fetchRenewalLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_LOADING,
    fetchRenewalLoading
  );
export const setFetchRenewalSuccess = (fetchRenewalSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_SUCCESS,
    fetchRenewalSuccess
  );
export const setFetchRenewalFailed = (fetchRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_FAILED,
    fetchRenewalFailed
  );

export const setFetchRenewalMarketParams = (fetchRenewalMarketParams) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_PARAMS,
    fetchRenewalMarketParams
  );
export const setFetchRenewalMarketLoading = (fetchRenewalMarketLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_LOADING,
    fetchRenewalMarketLoading
  );
export const setFetchRenewalMarketSuccess = (fetchRenewalMarketSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_SUCCESS,
    fetchRenewalMarketSuccess
  );
export const setFetchRenewalMarketFailed = (fetchRenewalMarketFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_MARKET_FAILED,
    fetchRenewalMarketFailed
  );

export const setFetchRenewalBranchesParams = (fetchRenewalBranchesParams) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_PARAMS,
    fetchRenewalBranchesParams
  );
export const setFetchRenewalBranchesLoading = (fetchRenewalBranchesLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_LOADING,
    fetchRenewalBranchesLoading
  );
export const setFetchRenewalBranchesSuccess = (fetchRenewalBranchesSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_SUCCESS,
    fetchRenewalBranchesSuccess
  );
export const setFetchRenewalBranchesFailed = (fetchRenewalBranchesFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_FETCH_RENEWAL_BRANCHES_FAILED,
    fetchRenewalBranchesFailed
  );

export const setCreateRenewalLoading = (createRenewalLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_LOADING,
    createRenewalLoading
  );
export const setCreateRenewalSuccess = (createRenewalSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_SUCCESS,
    createRenewalSuccess
  );
export const setCreateRenewalFailed = (createRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_RENEWAL_FAILED,
    createRenewalFailed
  );

export const setCreateCalculateRenewalLoading = (
  createCalculateRenewalLoading
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_LOADING,
    createCalculateRenewalLoading
  );
export const setCreateCalculateRenewalSuccess = (
  createCalculateRenewalSuccess
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_SUCCESS,
    createCalculateRenewalSuccess
  );
export const setCreateCalculateRenewalFailed = (createCalculateRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CREATE_CALCULATE_RENEWAL_FAILED,
    createCalculateRenewalFailed
  );

export const setCustomRenewalLoading = (customRenewalLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_LOADING,
    customRenewalLoading
  );
export const setCustomRenewalSuccess = (customRenewalSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_SUCCESS,
    customRenewalSuccess
  );
export const setCustomRenewalFailed = (customRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_RENEWAL_FAILED,
    customRenewalFailed
  );

export const setCustomCalculateRenewalLoading = (
  customCalculateRenewalLoading
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_LOADING,
    customCalculateRenewalLoading
  );
export const setCustomCalculateRenewalSuccess = (
  customCalculateRenewalSuccess
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_SUCCESS,
    customCalculateRenewalSuccess
  );
export const setCustomCalculateRenewalFailed = (customCalculateRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_CUSTOM_CALCULATE_RENEWAL_FAILED,
    customCalculateRenewalFailed
  );

export const setUpdateRenewalLoading = (updateRenewalLoading) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_LOADING,
    updateRenewalLoading
  );
export const setUpdateRenewalSuccess = (updateRenewalSuccess) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_SUCCESS,
    updateRenewalSuccess
  );
export const setUpdateRenewalFailed = (updateRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_RENEWAL_FAILED,
    updateRenewalFailed
  );

export const setUpdateCalculateRenewalLoading = (
  updateCalculateRenewalLoading
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_LOADING,
    updateCalculateRenewalLoading
  );
export const setUpdateCalculateRenewalSuccess = (
  updateCalculateRenewalSuccess
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_SUCCESS,
    updateCalculateRenewalSuccess
  );
export const setUpdateCalculateRenewalFailed = (updateCalculateRenewalFailed) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_UPDATE_CALCULATE_RENEWAL_FAILED,
    updateCalculateRenewalFailed
  );

export const setIsFetchRenewalsHitted = (isFetchRenewalsHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWALS_HITTED,
    isFetchRenewalsHitted
  );
export const setIsFetchRenewalHitted = (isFetchRenewalHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_HITTED,
    isFetchRenewalHitted
  );
export const setIsFetchRenewalMarketHitted = (isFetchRenewalMarketHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_MARKET_HITTED,
    isFetchRenewalMarketHitted
  );
export const setIsFetchRenewalBranchesHitted = (isFetchRenewalBranchesHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_FETCH_RENEWAL_BRANCHES_HITTED,
    isFetchRenewalBranchesHitted
  );
export const setIsCreateRenewalHitted = (isCreateRenewalHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CREATE_RENEWAL_HITTED,
    isCreateRenewalHitted
  );
export const setIsCreateCalculateRenewalHitted = (
  isCreateCalculateRenewalHitted
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CREATE_CALCULATE_RENEWAL_HITTED,
    isCreateCalculateRenewalHitted
  );
export const setIsCustomRenewalHitted = (isCustomRenewalHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CUSTOM_RENEWAL_HITTED,
    isCustomRenewalHitted
  );
export const setIsCustomCalculateRenewalHitted = (
  isCustomCalculateRenewalHitted
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_CUSTOM_CALCULATE_RENEWAL_HITTED,
    isCustomCalculateRenewalHitted
  );
export const setIsUpdateRenewalHitted = (isUpdateRenewalHitted) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_UPDATE_RENEWAL_HITTED,
    isUpdateRenewalHitted
  );
export const setIsUpdateCalculateRenewalHitted = (
  isUpdateCalculateRenewalHitted
) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.SET_IS_UPDATE_CALCULATE_RENEWAL_HITTED,
    isUpdateCalculateRenewalHitted
  );

export const fetchRenewalsStart = () =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWALS_START);
export const fetchRenewalStart = () =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_START);
export const fetchRenewalMarketStart = (marketId) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_MARKET_START, marketId);
export const fetchRenewalBranchesStart = () =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.FETCH_RENEWAL_BRANCHES_START);
export const createRenewalStart = (request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.CREATE_RENEWAL_START, request);
export const createCalculateRenewalStart = (request) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.CREATE_CALCULATE_RENEWAL_START,
    request
  );
export const customRenewalStart = (request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.CUSTOM_RENEWAL_START, request);
export const customCalculateRenewalStart = (request) =>
  createAction(
    RENEWAL_ADMIN_ACTION_TYPES.CUSTOM_CALCULATE_RENEWAL_START,
    request
  );
export const updateRenewalStart = (renewalId, request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.UPDATE_RENEWAL_START, {
    renewalId,
    request,
  });
export const updateCalculateRenewalStart = (renewalId, request) =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.UPDATE_CALCULATE_RENEWAL_START, {
    renewalId,
    request,
  });

export const resetRenewalAdminReducer = () =>
  createAction(RENEWAL_ADMIN_ACTION_TYPES.RESET_RENEWAL_ADMIN_REDUCER);
