import { createAction } from "../../utils/store.utils";

import ORDER_FORM_CATEGORY_ACTION_TYPES from "./order-form-category.type";

export const setCategories = (categories) =>
  createAction(ORDER_FORM_CATEGORY_ACTION_TYPES.SET_CATEGORIES, categories);
export const appendCategories = (categories) =>
  createAction(ORDER_FORM_CATEGORY_ACTION_TYPES.APPEND_CATEGORIES, categories);

export const setIsCategoriesHasMore = (isCategoriesHasMore) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_IS_CATEGORIES_HAS_MORE,
    isCategoriesHasMore
  );

export const setFetchCategoriesParams = (fetchCategoriesParams) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_PARAMS,
    fetchCategoriesParams
  );
export const setFetchCategoriesLoading = (fetchCategoriesLoading) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_LOADING,
    fetchCategoriesLoading
  );
export const setFetchCategoriesSuccess = (fetchCategoriesSuccess) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_SUCCESS,
    fetchCategoriesSuccess
  );
export const setFetchCategoriesFailed = (fetchCategoriesFailed) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_FETCH_CATEGORIES_FAILED,
    fetchCategoriesFailed
  );

export const setIsFetchCategoriesHitted = (isFetchCategoriesHitted) =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.SET_IS_FETCH_CATEGORIES_HITTED,
    isFetchCategoriesHitted
  );

export const fetchCategoriesStart = () =>
  createAction(ORDER_FORM_CATEGORY_ACTION_TYPES.FETCH_CATEGORIES_START);

export const resetCategoryReducer = () =>
  createAction(
    ORDER_FORM_CATEGORY_ACTION_TYPES.RESET_ORDER_FORM_CATEGORY_REDUCER
  );
