import { takeLatest, put, all, call } from "redux-saga/effects";
import { saveAs } from "file-saver";

import { getFileNameUrl } from "../../utils/formatter.utils";

import EXPORT_EXCEL_ACTION_TYPES from "./export-excel.type";

import {
  setExportExcelPurchase,
  setExportExcelPurchaseFailed,
  setExportExcelPurchaseLoading,
  setExportExcelPurchaseSuccess,
  setExportExcelReports,
  setExportExcelReportsFailed,
  setExportExcelReportsLoading,
  setExportExcelReportsSuccess,
  setIsExportExcelPurchaseHitted,
  setIsExportExcelReportsHitted,
} from "./export-excel.action";

import { exportExcelPurchase, exportExcelReports } from "../../api/export.api";

export function* _exportExcelReports({ payload: request }) {
  try {
    yield put(setExportExcelReportsLoading(true));

    const {
      meta: { message },
      data: excelReports,
    } = yield call(exportExcelReports, request);

    yield put(setIsExportExcelReportsHitted(true));
    yield put(setExportExcelReports(`${excelReports}?time=${Date.now()}`));

    saveAs(excelReports, getFileNameUrl(excelReports));

    yield put(setExportExcelReportsSuccess(message));
    yield put(setExportExcelReportsLoading(false));
  } catch (error) {
    yield put(setExportExcelReportsFailed(error));
    yield put(setExportExcelReportsLoading(false));
  }
}
export function* _exportExcelPurchase({ payload: request }) {
  try {
    yield put(setExportExcelPurchaseLoading(true));

    const {
      meta: { message },
      data: excelPurchase,
    } = yield call(exportExcelPurchase, request);

    yield put(setIsExportExcelPurchaseHitted(true));
    yield put(setExportExcelPurchase(`${excelPurchase}?time=${Date.now()}`));

    saveAs(excelPurchase, getFileNameUrl(excelPurchase));

    yield put(setExportExcelPurchaseSuccess(message));
    yield put(setExportExcelPurchaseLoading(false));
  } catch (error) {
    yield put(setExportExcelPurchaseFailed(error));
    yield put(setExportExcelPurchaseLoading(false));
  }
}

export function* onExportExcelReportsStart() {
  yield takeLatest(
    EXPORT_EXCEL_ACTION_TYPES.EXPORT_EXCEL_REPORTS_START,
    _exportExcelReports
  );
}
export function* onExportExcelPurchaseStart() {
  yield takeLatest(
    EXPORT_EXCEL_ACTION_TYPES.EXPORT_EXCEL_PURCHASE_START,
    _exportExcelPurchase
  );
}

export function* exportExcelSaga() {
  yield all([
    call(onExportExcelReportsStart),
    call(onExportExcelPurchaseStart),
  ]);
}
