import { useTranslation } from "react-i18next";

import { isEmpty } from "../../utils/validation.utils";

import { PurchaseFormTableIngredientDetailsLabel } from "./purchase-form-table-ingredient-details.style";

const PurchaseFormTableIngredientDetails = ({ ingredient: data }) => {
  const { i18n, t } = useTranslation();

  const { name_en, name_ar } = data?.ingredient ?? {};

  const ingredientName =
    data?.ingredient?.[`name_${i18n.language}`] ?? name_en ?? name_ar;

  return (
    <PurchaseFormTableIngredientDetailsLabel>
      {!isEmpty(ingredientName) ? ingredientName : t("No name")}
    </PurchaseFormTableIngredientDetailsLabel>
  );
};

export default PurchaseFormTableIngredientDetails;
