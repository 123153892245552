import { createAction } from "../../utils/store.utils";

import TRANSLATION_ACTION_TYPES from "./translation.type";

export const setUserRoles = (userRoles) =>
  createAction(TRANSLATION_ACTION_TYPES.SET_USER_ROLES, userRoles);

export const setOrderStatuses = (orderStatuses) =>
  createAction(TRANSLATION_ACTION_TYPES.SET_ORDER_STATUSES, orderStatuses);

export const setSubscriptionPlans = (subscriptionPlans) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_PLANS,
    subscriptionPlans
  );
export const setSubscriptionCycles = (subscriptionCycles) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_CYCLES,
    subscriptionCycles
  );
export const setSubscriptionStatuses = (subscriptionStatuses) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_STATUSES,
    subscriptionStatuses
  );
export const setSubscriptionPeriods = (subscriptionPeriods) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_PERIODS,
    subscriptionPeriods
  );

export const setReportExportTypes = (reportExportTypes) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_REPORT_EXPORT_TYPES,
    reportExportTypes
  );

export const setDiscountTypes = (discountTypes) =>
  createAction(TRANSLATION_ACTION_TYPES.SET_DISCOUNT_TYPES, discountTypes);

export const setProductTypes = (productTypes) =>
  createAction(TRANSLATION_ACTION_TYPES.SET_PRODUCT_TYPES, productTypes);
export const setProductOptionPrices = (productOptionPrices) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_PRODUCT_OPTION_PRICES,
    productOptionPrices
  );
export const setProductAdditionalPrices = (productAdditionalPrices) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_PRODUCT_ADDITIONAL_PRICES,
    productAdditionalPrices
  );

export const setDashboardSubscriberTabs = (dashboardSubscriberTabs) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_DASHBOARD_SUBSCRIBER_TABS,
    dashboardSubscriberTabs
  );
export const setSubscriptionAdminTabs = (subscriptionAdminTabs) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TABS,
    subscriptionAdminTabs
  );
export const setSubscriptionSubscriberTabs = (subscriptionSubscriberTabs) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TABS,
    subscriptionSubscriberTabs
  );

export const setPurchaseTabs = (purchaseTabs) =>
  createAction(TRANSLATION_ACTION_TYPES.SET_PURCHASE_TABS, purchaseTabs);

export const setReportProductTabs = (reportProductTabs) =>
  createAction(
    TRANSLATION_ACTION_TYPES.SET_REPORT_PRODUCT_TABS,
    reportProductTabs
  );

export const setPermissionAdminPages = (permissionAdminPages) =>
  createAction(
    TRANSLATION_ACTION_TYPES.PERMISSION_ADMIN_PAGES,
    permissionAdminPages
  );
export const setPermissionMerchantPages = (permissionMerchantPages) =>
  createAction(
    TRANSLATION_ACTION_TYPES.PERMISSION_MERCHANT_PAGES,
    permissionMerchantPages
  );
export const setPermissionSubscriberPages = (permissionSubscriberPages) =>
  createAction(
    TRANSLATION_ACTION_TYPES.PERMISSION_SUBSCRIBER_PAGES,
    permissionSubscriberPages
  );
