import { createSelector } from "reselect";

const resupplySelector = ({ resupply }) => resupply;

export const getResupplies = createSelector(
  [resupplySelector],
  ({ resupplies }) => resupplies
);
export const getResupply = createSelector(
  [resupplySelector],
  ({ resupply }) => resupply
);
export const getCreateResupply = createSelector(
  [resupplySelector],
  ({ createResupply }) => createResupply
);
export const getUpdateResupply = createSelector(
  [resupplySelector],
  ({ updateResupply }) => updateResupply
);
export const getReceiveResupply = createSelector(
  [resupplySelector],
  ({ receiveResupply }) => receiveResupply
);
export const getApproveResupply = createSelector(
  [resupplySelector],
  ({ approveResupply }) => approveResupply
);
export const getDeclineResupply = createSelector(
  [resupplySelector],
  ({ declineResupply }) => declineResupply
);

export const getIsResuppliesHasMore = createSelector(
  [resupplySelector],
  ({ isResuppliesHasMore }) => isResuppliesHasMore
);

export const getFetchResuppliesSearch = createSelector(
  [resupplySelector],
  ({ fetchResuppliesSearch }) => fetchResuppliesSearch
);
export const getFetchResuppliesSort = createSelector(
  [resupplySelector],
  ({ fetchResuppliesSort }) => fetchResuppliesSort
);
export const getFetchResuppliesKeyBy = createSelector(
  [resupplySelector],
  ({ fetchResuppliesKeyBy }) => fetchResuppliesKeyBy
);
export const getFetchResuppliesPage = createSelector(
  [resupplySelector],
  ({ fetchResuppliesPage }) => fetchResuppliesPage
);
export const getFetchResuppliesPerPage = createSelector(
  [resupplySelector],
  ({ fetchResuppliesPerPage }) => fetchResuppliesPerPage
);
export const getFetchResuppliesIncludes = createSelector(
  [resupplySelector],
  ({ fetchResuppliesIncludes }) => fetchResuppliesIncludes
);
export const getFetchResuppliesFilterMarketId = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFilterMarketId }) => fetchResuppliesFilterMarketId
);
export const getFetchResuppliesFilterBranchId = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFilterBranchId }) => fetchResuppliesFilterBranchId
);
export const getFetchResuppliesFilterCreatedBy = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFilterCreatedBy }) => fetchResuppliesFilterCreatedBy
);
export const getFetchResuppliesFilterStatus = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFilterStatus }) => fetchResuppliesFilterStatus
);
export const getFetchResuppliesFilterIsReceived = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFilterIsReceived }) => fetchResuppliesFilterIsReceived
);
export const getFetchResuppliesLoading = createSelector(
  [resupplySelector],
  ({ fetchResuppliesLoading }) => fetchResuppliesLoading
);
export const getFetchResuppliesSuccess = createSelector(
  [resupplySelector],
  ({ fetchResuppliesSuccess }) => fetchResuppliesSuccess
);
export const getFetchResuppliesFailed = createSelector(
  [resupplySelector],
  ({ fetchResuppliesFailed }) => fetchResuppliesFailed
);

export const getFetchResupplyLoading = createSelector(
  [resupplySelector],
  ({ fetchResupplyLoading }) => fetchResupplyLoading
);
export const getFetchResupplySuccess = createSelector(
  [resupplySelector],
  ({ fetchResupplySuccess }) => fetchResupplySuccess
);
export const getFetchResupplyFailed = createSelector(
  [resupplySelector],
  ({ fetchResupplyFailed }) => fetchResupplyFailed
);

export const getCreateResupplyLoading = createSelector(
  [resupplySelector],
  ({ createResupplyLoading }) => createResupplyLoading
);
export const getCreateResupplySuccess = createSelector(
  [resupplySelector],
  ({ createResupplySuccess }) => createResupplySuccess
);
export const getCreateResupplyFailed = createSelector(
  [resupplySelector],
  ({ createResupplyFailed }) => createResupplyFailed
);

export const getUpdateResupplyLoading = createSelector(
  [resupplySelector],
  ({ updateResupplyLoading }) => updateResupplyLoading
);
export const getUpdateResupplySuccess = createSelector(
  [resupplySelector],
  ({ updateResupplySuccess }) => updateResupplySuccess
);
export const getUpdateResupplyFailed = createSelector(
  [resupplySelector],
  ({ updateResupplyFailed }) => updateResupplyFailed
);

export const getDeleteResupplyLoading = createSelector(
  [resupplySelector],
  ({ deleteResupplyLoading }) => deleteResupplyLoading
);
export const getDeleteResupplySuccess = createSelector(
  [resupplySelector],
  ({ deleteResupplySuccess }) => deleteResupplySuccess
);
export const getDeleteResupplyFailed = createSelector(
  [resupplySelector],
  ({ deleteResupplyFailed }) => deleteResupplyFailed
);

export const getReceiveResupplyLoading = createSelector(
  [resupplySelector],
  ({ receiveResupplyLoading }) => receiveResupplyLoading
);
export const getReceiveResupplySuccess = createSelector(
  [resupplySelector],
  ({ receiveResupplySuccess }) => receiveResupplySuccess
);
export const getReceiveResupplyFailed = createSelector(
  [resupplySelector],
  ({ receiveResupplyFailed }) => receiveResupplyFailed
);

export const getApproveResupplyLoading = createSelector(
  [resupplySelector],
  ({ approveResupplyLoading }) => approveResupplyLoading
);
export const getApproveResupplySuccess = createSelector(
  [resupplySelector],
  ({ approveResupplySuccess }) => approveResupplySuccess
);
export const getApproveResupplyFailed = createSelector(
  [resupplySelector],
  ({ approveResupplyFailed }) => approveResupplyFailed
);

export const getDeclineResupplyLoading = createSelector(
  [resupplySelector],
  ({ declineResupplyLoading }) => declineResupplyLoading
);
export const getDeclineResupplySuccess = createSelector(
  [resupplySelector],
  ({ declineResupplySuccess }) => declineResupplySuccess
);
export const getDeclineResupplyFailed = createSelector(
  [resupplySelector],
  ({ declineResupplyFailed }) => declineResupplyFailed
);

export const getIsFetchResuppliesHitted = createSelector(
  [resupplySelector],
  ({ isFetchResuppliesHitted }) => isFetchResuppliesHitted
);
export const getIsFetchResupplyHitted = createSelector(
  [resupplySelector],
  ({ isFetchResupplyHitted }) => isFetchResupplyHitted
);
export const getIsCreateResupplyHitted = createSelector(
  [resupplySelector],
  ({ isCreateResupplyHitted }) => isCreateResupplyHitted
);
export const getIsUpdateResupplyHitted = createSelector(
  [resupplySelector],
  ({ isUpdateResupplyHitted }) => isUpdateResupplyHitted
);
export const getIsDeleteResupplyHitted = createSelector(
  [resupplySelector],
  ({ isDeleteResupplyHitted }) => isDeleteResupplyHitted
);
export const getIsReceiveResupplyHitted = createSelector(
  [resupplySelector],
  ({ isReceiveResupplyHitted }) => isReceiveResupplyHitted
);
export const getIsApproveResupplyHitted = createSelector(
  [resupplySelector],
  ({ isApproveResupplyHitted }) => isApproveResupplyHitted
);
export const getIsDeclineResupplyHitted = createSelector(
  [resupplySelector],
  ({ isDeclineResupplyHitted }) => isDeclineResupplyHitted
);
