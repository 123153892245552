import { createAction } from "../../utils/store.utils";

import STATE_SUBSCRIPTION_ACTION_TYPES from "./state-subscription.type";

export const setSubscriptionAdminTab = (subscriptionAdminTab) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_ADMIN_TAB,
    subscriptionAdminTab
  );
export const setSubscriptionSubscriberTab = (subscriptionSubscriberTab) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_SUBSCRIPTION_SUBSCRIBER_TAB,
    subscriptionSubscriberTab
  );

export const setBillingPlan = (billingPlan) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_PLAN, billingPlan);
export const setBillingCycle = (billingCycle) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_BILLING_CYCLE, billingCycle);

export const setIsBranchesChanging = (isBranchesChanging) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_BRANCHES_CHANGING,
    isBranchesChanging
  );
export const setIsCalculateLoading = (isCalculateLoading) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_CALCULATE_LOADING,
    isCalculateLoading
  );
export const setIsTransformLoading = (isTransformLoading) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_IS_TRANSFORM_LOADING,
    isTransformLoading
  );

export const setUpdatingBranchKey = (updatingBranchKey) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATING_BRANCH_KEY,
    updatingBranchKey
  );
export const setUpdatingItemIndex = (updatingItemIndex) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_UPDATING_ITEM_INDEX,
    updatingItemIndex
  );

export const setRenewalBranchesIds = (renewalBranchesIds) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_BRANCHES_IDS,
    renewalBranchesIds
  );
export const setRenewalPlan = (renewalPlan) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_PLAN, renewalPlan);
export const setRenewalCycle = (renewalCycle) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_CYCLE, renewalCycle);
export const setRenewalBranches = (renewalBranches) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_BRANCHES,
    renewalBranches
  );
export const setRenewalItems = (renewalItems) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_ITEMS, renewalItems);
export const setRenewalDiscount = (renewalDiscount) =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.SET_RENEWAL_DISCOUNT,
    renewalDiscount
  );

export const toggleRenewalBranch = (key) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.TOGGLE_RENEWAL_BRANCH, key);
export const updateRenewalBranch = (key, value) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.UPDATE_RENEWAL_BRANCH, {
    key,
    value,
  });
export const appendRenewalItem = (value) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.APPEND_RENEWAL_ITEM, value);
export const updateRenewalItem = (index, value) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.UPDATE_RENEWAL_ITEM, {
    index,
    value,
  });
export const deleteRenewalItem = (index) =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.DELETE_RENEWAL_ITEM, index);

export const resetRenewalValues = () =>
  createAction(STATE_SUBSCRIPTION_ACTION_TYPES.RESET_RENEWAL_VALUES);

export const resetStateSubscriptionReducer = () =>
  createAction(
    STATE_SUBSCRIPTION_ACTION_TYPES.RESET_STATE_SUBSCRIPTION_REDUCER
  );
