import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";

import { parseToFloat } from "../utils/parser.utils";

import {
  appendCenterDialog,
  appendLeftDialog,
  appendRightDialog,
  removeCenterDialog,
  removeLeftDialog,
  removeRightDialog,
} from "../store/component/component.action";

export const useLeftDialog = (time = 4000) => {
  const dispatch = useDispatch();

  const removeDialog = (key) => dispatch(removeLeftDialog(key));
  const appendDialog = (value) => {
    const dialogKey = uuid();
    dispatch(appendLeftDialog(dialogKey, { dialogKey, ...value }));
    if (parseToFloat(time) > 0) {
      setTimeout(() => removeDialog(dialogKey), time);
    }

    return dialogKey;
  };

  return { appendDialog, removeDialog };
};
export const useRightDialog = (time = 4000) => {
  const dispatch = useDispatch();

  const removeDialog = (key) => dispatch(removeRightDialog(key));
  const appendDialog = (value) => {
    const dialogKey = uuid();
    dispatch(appendRightDialog(dialogKey, { dialogKey, ...value }));
    if (parseToFloat(time) > 0) {
      setTimeout(() => removeDialog(dialogKey), time);
    }

    return dialogKey;
  };

  return { appendDialog, removeDialog };
};
export const useCenterDialog = (time = 4000) => {
  const dispatch = useDispatch();

  const removeDialog = (key) => dispatch(removeCenterDialog(key));
  const appendDialog = (value) => {
    const dialogKey = uuid();
    dispatch(appendCenterDialog(dialogKey, { dialogKey, ...value }));
    if (parseToFloat(time) > 0) {
      setTimeout(() => removeDialog(dialogKey), time);
    }

    return dialogKey;
  };

  return { appendDialog, removeDialog };
};
