const REPORT_PURCHASE_ACTION_TYPES = {
  RESET_REPORT_PURCHASE_REDUCER:
    "report-purchase/RESET_REPORT_PURCHASE_REDUCER",

  SET_REPORT_TOTALS: "report-purchase/SET_REPORT_TOTALS",
  SET_REPORT_PURCHASES: "report-purchase/SET_REPORT_PURCHASES",
  SET_REPORT_PURCHASE: "report-purchase/SET_REPORT_PURCHASE",

  SET_IS_REPORT_PURCHASES_HAS_MORE:
    "report-purchase/SET_IS_REPORT_PURCHASES_HAS_MORE",

  FETCH_REPORTS_FILTER_MARKET_ID:
    "report-purchase/FETCH_REPORTS_FILTER_MARKET_ID",
  FETCH_REPORTS_FILTER_BRANCH_ID:
    "report-purchase/FETCH_REPORTS_FILTER_BRANCH_ID",
  FETCH_REPORTS_FILTER_SUPPLIER_ID:
    "report-purchase/FETCH_REPORTS_FILTER_SUPPLIER_ID",
  FETCH_REPORTS_FILTER_CATEGORIES_IDS:
    "report-purchase/FETCH_REPORTS_FILTER_CATEGORIES_IDS",
  FETCH_REPORTS_FILTER_TYPES: "report-purchase/FETCH_REPORTS_FILTER_TYPES",
  FETCH_REPORTS_FILTER_STATUSES:
    "report-purchase/FETCH_REPORTS_FILTER_STATUSES",
  FETCH_REPORTS_FILTER_PURCHASE_AT_BEFORE:
    "report-purchase/FETCH_REPORTS_FILTER_PURCHASE_AT_BEFORE",
  FETCH_REPORTS_FILTER_PURCHASE_AT_AFTER:
    "report-purchase/FETCH_REPORTS_FILTER_PURCHASE_AT_AFTER",

  FETCH_REPORT_TOTALS_LOADING: "report-purchase/FETCH_REPORT_TOTALS_LOADING",
  FETCH_REPORT_TOTALS_SUCCESS: "report-purchase/FETCH_REPORT_TOTALS_SUCCESS",
  FETCH_REPORT_TOTALS_FAILED: "report-purchase/FETCH_REPORT_TOTALS_FAILED",

  FETCH_REPORT_PURCHASES_SEARCH:
    "report-purchase/FETCH_REPORT_PURCHASES_SEARCH",
  FETCH_REPORT_PURCHASES_SORT: "report-purchase/FETCH_REPORT_PURCHASES_SORT",
  FETCH_REPORT_PURCHASES_KEY_BY:
    "report-purchase/FETCH_REPORT_PURCHASES_KEY_BY",
  FETCH_REPORT_PURCHASES_PAGE: "report-purchase/FETCH_REPORT_PURCHASES_PAGE",
  FETCH_REPORT_PURCHASES_PER_PAGE:
    "report-purchase/FETCH_REPORT_PURCHASES_PER_PAGE",
  FETCH_REPORT_PURCHASES_LOADING:
    "report-purchase/FETCH_REPORT_PURCHASES_LOADING",
  FETCH_REPORT_PURCHASES_SUCCESS:
    "report-purchase/FETCH_REPORT_PURCHASES_SUCCESS",
  FETCH_REPORT_PURCHASES_FAILED:
    "report-purchase/FETCH_REPORT_PURCHASES_FAILED",

  FETCH_REPORT_PURCHASE_LOADING:
    "report-purchase/FETCH_REPORT_PURCHASE_LOADING",
  FETCH_REPORT_PURCHASE_SUCCESS:
    "report-purchase/FETCH_REPORT_PURCHASE_SUCCESS",
  FETCH_REPORT_PURCHASE_FAILED: "report-purchase/FETCH_REPORT_PURCHASE_FAILED",

  APPEND_REPORT_PURCHASES: "report-purchase/APPEND_REPORT_PURCHASES",

  SET_IS_FETCH_REPORT_TOTALS_HITTED:
    "report-purchase/SET_IS_FETCH_REPORT_TOTALS_HITTED",
  SET_IS_FETCH_REPORT_PURCHASES_HITTED:
    "report-purchase/SET_IS_FETCH_REPORT_PURCHASES_HITTED",
  SET_IS_FETCH_REPORT_PURCHASE_HITTED:
    "report-purchase/SET_IS_FETCH_REPORT_PURCHASE_HITTED",

  FETCH_REPORT_TOTALS_START: "report-purchase/FETCH_REPORT_TOTALS_START",
  FETCH_REPORT_PURCHASES_START: "report-purchase/FETCH_REPORT_PURCHASES_START",
  FETCH_REPORT_PURCHASE_START: "report-purchase/FETCH_REPORT_PURCHASE_START",
};

export default REPORT_PURCHASE_ACTION_TYPES;
