import apiService from "./api";

export const getReportCategories = async (params) =>
  (
    await apiService.innerGet({
      urlPath: "/v2/reports/categories/purchase",
      params,
    })
  ).data;
export const getReportCategory = async (categoryId, params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/reports/categories/purchase/${categoryId}`,
      params,
    })
  ).data;
export const getReportTotals = async (params) =>
  (
    await apiService.innerGet({
      urlPath: `/v2/reports/categories/purchase/totals`,
      params,
    })
  ).data;
