import REPORT_ACTION_TYPES from "./report.type";

export const REPORT_INITIAL_STATE = {
  prevReportTotals: null,
  nextReportTotals: null,

  reportCategories: [],
  reportProducts: [],
  reportOptions: [],
  reportAdditionals: [],
  reportCashiers: [],
  reportPayments: [],
  reportTables: [],

  isReportCategoriesHasMore: true,
  isReportProductsHasMore: true,
  isReportOptionsHasMore: true,
  isReportAdditionalsHasMore: true,
  isReportCashiersHasMore: true,
  isReportPaymentsHasMore: true,
  isReportTablesHasMore: true,

  fetchReportsFilterMarketId: null,
  fetchReportsFilterBranchId: null,
  fetchReportsFilterCashierId: null,
  fetchReportsFilterCustomerId: null,
  fetchReportsFilterCreatedAtAfter: null,
  fetchReportsFilterCreatedAtBefore: null,
  fetchReportsFilterOrderStatuses: null,
  fetchReportsFilterTransactionStatuses: null,
  fetchReportsFilterProductStatuses: null,

  fetchPrevReportTotalsFilterCreatedAtAfter: null,
  fetchPrevReportTotalsFilterCreatedAtBefore: null,
  fetchPrevReportTotalsLoading: false,
  fetchPrevReportTotalsSuccess: null,
  fetchPrevReportTotalsFailed: null,

  fetchNextReportTotalsFilterCreatedAtAfter: null,
  fetchNextReportTotalsFilterCreatedAtBefore: null,
  fetchNextReportTotalsLoading: false,
  fetchNextReportTotalsSuccess: null,
  fetchNextReportTotalsFailed: null,

  fetchReportCategoriesSearch: null,
  fetchReportCategoriesSort: null,
  fetchReportCategoriesKeyBy: null,
  fetchReportCategoriesPage: 1,
  fetchReportCategoriesPerPage: null,
  fetchReportCategoriesLoading: false,
  fetchReportCategoriesSuccess: null,
  fetchReportCategoriesFailed: null,

  fetchReportProductsSearch: null,
  fetchReportProductsSort: null,
  fetchReportProductsKeyBy: null,
  fetchReportProductsPage: 1,
  fetchReportProductsPerPage: null,
  fetchReportProductsLoading: false,
  fetchReportProductsSuccess: null,
  fetchReportProductsFailed: null,

  fetchReportOptionsSearch: null,
  fetchReportOptionsSort: null,
  fetchReportOptionsKeyBy: null,
  fetchReportOptionsPage: 1,
  fetchReportOptionsPerPage: null,
  fetchReportOptionsLoading: false,
  fetchReportOptionsSuccess: null,
  fetchReportOptionsFailed: null,

  fetchReportAdditionalsSearch: null,
  fetchReportAdditionalsSort: null,
  fetchReportAdditionalsKeyBy: null,
  fetchReportAdditionalsPage: 1,
  fetchReportAdditionalsPerPage: null,
  fetchReportAdditionalsLoading: false,
  fetchReportAdditionalsSuccess: null,
  fetchReportAdditionalsFailed: null,

  fetchReportCashiersSearch: null,
  fetchReportCashiersSort: null,
  fetchReportCashiersKeyBy: null,
  fetchReportCashiersPage: 1,
  fetchReportCashiersPerPage: null,
  fetchReportCashiersLoading: false,
  fetchReportCashiersSuccess: null,
  fetchReportCashiersFailed: null,

  fetchReportPaymentsSearch: null,
  fetchReportPaymentsSort: null,
  fetchReportPaymentsKeyBy: null,
  fetchReportPaymentsPage: 1,
  fetchReportPaymentsPerPage: null,
  fetchReportPaymentsFilterPaymentStatuses: null,
  fetchReportPaymentsLoading: false,
  fetchReportPaymentsSuccess: null,
  fetchReportPaymentsFailed: null,

  fetchReportTablesSearch: null,
  fetchReportTablesSort: null,
  fetchReportTablesKeyBy: null,
  fetchReportTablesPage: 1,
  fetchReportTablesPerPage: null,
  fetchReportTablesLoading: false,
  fetchReportTablesSuccess: null,
  fetchReportTablesFailed: null,

  isFetchPrevReportTotalsHitted: false,
  isFetchNextReportTotalsHitted: false,
  isFetchReportCategoriesHitted: false,
  isFetchReportProductsHitted: false,
  isFetchReportOptionsHitted: false,
  isFetchReportAdditionalsHitted: false,
  isFetchReportCashiersHitted: false,
  isFetchReportPaymentsHitted: false,
  isFetchReportTablesHitted: false,
};

export const reportReducer = (state = REPORT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case REPORT_ACTION_TYPES.SET_PREV_REPORT_TOTALS:
      return { ...state, prevReportTotals: payload };
    case REPORT_ACTION_TYPES.SET_NEXT_REPORT_TOTALS:
      return { ...state, nextReportTotals: payload };

    case REPORT_ACTION_TYPES.SET_REPORT_CATEGORIES:
      return { ...state, reportCategories: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_PRODUCTS:
      return { ...state, reportProducts: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_OPTIONS:
      return { ...state, reportOptions: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_ADDITIONALS:
      return { ...state, reportAdditionals: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_CASHIERS:
      return { ...state, reportCashiers: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_PAYMENTS:
      return { ...state, reportPayments: payload };
    case REPORT_ACTION_TYPES.SET_REPORT_TABLES:
      return { ...state, reportTables: payload };

    case REPORT_ACTION_TYPES.SET_IS_REPORT_CATEGORIES_HAS_MORE:
      return { ...state, isReportCategoriesHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_PRODUCTS_HAS_MORE:
      return { ...state, isReportProductsHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_OPTIONS_HAS_MORE:
      return { ...state, isReportOptionsHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_ADDITIONALS_HAS_MORE:
      return { ...state, isReportAdditionalsHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_CASHIERS_HAS_MORE:
      return { ...state, isReportCashiersHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_PAYMENTS_HAS_MORE:
      return { ...state, isReportPaymentsHasMore: payload };
    case REPORT_ACTION_TYPES.SET_IS_REPORT_TABLES_HAS_MORE:
      return { ...state, isReportTablesHasMore: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID:
      return { ...state, fetchReportsFilterMarketId: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID:
      return { ...state, fetchReportsFilterBranchId: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CASHIER_ID:
      return { ...state, fetchReportsFilterCashierId: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CUSTOMER_ID:
      return { ...state, fetchReportsFilterCustomerId: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchReportsFilterCreatedAtBefore: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchReportsFilterCreatedAtAfter: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_ORDER_STATUSES:
      return { ...state, fetchReportsFilterOrderStatuses: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_TRANSACTION_STATUSES:
      return {
        ...state,
        fetchReportsFilterTransactionStatuses: payload,
      };
    case REPORT_ACTION_TYPES.FETCH_REPORTS_FILTER_PRODUCT_STATUSES:
      return {
        ...state,
        fetchReportsFilterProductStatuses: payload,
      };

    case REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchPrevReportTotalsFilterCreatedAtAfter: payload };
    case REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchPrevReportTotalsFilterCreatedAtBefore: payload };
    case REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_LOADING:
      return { ...state, fetchPrevReportTotalsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_SUCCESS:
      return { ...state, fetchPrevReportTotalsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_PREV_REPORT_TOTALS_FAILED:
      return { ...state, fetchPrevReportTotalsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_AFTER:
      return { ...state, fetchNextReportTotalsFilterCreatedAtAfter: payload };
    case REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FILTER_CREATED_AT_BEFORE:
      return { ...state, fetchNextReportTotalsFilterCreatedAtBefore: payload };
    case REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_LOADING:
      return { ...state, fetchNextReportTotalsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_SUCCESS:
      return { ...state, fetchNextReportTotalsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_NEXT_REPORT_TOTALS_FAILED:
      return { ...state, fetchNextReportTotalsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SEARCH:
      return { ...state, fetchReportCategoriesSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SORT:
      return { ...state, fetchReportCategoriesSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_KEY_BY:
      return { ...state, fetchReportCategoriesKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_PAGE:
      return { ...state, fetchReportCategoriesPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_PER_PAGE:
      return { ...state, fetchReportCategoriesPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_LOADING:
      return { ...state, fetchReportCategoriesLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_SUCCESS:
      return { ...state, fetchReportCategoriesSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CATEGORIES_FAILED:
      return { ...state, fetchReportCategoriesFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SEARCH:
      return { ...state, fetchReportProductsSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SORT:
      return { ...state, fetchReportProductsSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_KEY_BY:
      return { ...state, fetchReportProductsKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_PAGE:
      return { ...state, fetchReportProductsPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_PER_PAGE:
      return { ...state, fetchReportProductsPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_LOADING:
      return { ...state, fetchReportProductsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_SUCCESS:
      return { ...state, fetchReportProductsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PRODUCTS_FAILED:
      return { ...state, fetchReportProductsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_SEARCH:
      return { ...state, fetchReportOptionsSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_SORT:
      return { ...state, fetchReportOptionsSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_KEY_BY:
      return { ...state, fetchReportOptionsKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_PAGE:
      return { ...state, fetchReportOptionsPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_PER_PAGE:
      return { ...state, fetchReportOptionsPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_LOADING:
      return { ...state, fetchReportOptionsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_SUCCESS:
      return { ...state, fetchReportOptionsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_OPTIONS_FAILED:
      return { ...state, fetchReportOptionsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_SEARCH:
      return { ...state, fetchReportAdditionalsSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_SORT:
      return { ...state, fetchReportAdditionalsSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_KEY_BY:
      return { ...state, fetchReportAdditionalsKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_PAGE:
      return { ...state, fetchReportAdditionalsPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_PER_PAGE:
      return { ...state, fetchReportAdditionalsPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_LOADING:
      return { ...state, fetchReportAdditionalsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_SUCCESS:
      return { ...state, fetchReportAdditionalsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_ADDITIONALS_FAILED:
      return { ...state, fetchReportAdditionalsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SEARCH:
      return { ...state, fetchReportCashiersSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SORT:
      return { ...state, fetchReportCashiersSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_KEY_BY:
      return { ...state, fetchReportCashiersKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_PAGE:
      return { ...state, fetchReportCashiersPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_PER_PAGE:
      return { ...state, fetchReportCashiersPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_LOADING:
      return { ...state, fetchReportCashiersLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_SUCCESS:
      return { ...state, fetchReportCashiersSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_CASHIERS_FAILED:
      return { ...state, fetchReportCashiersFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SEARCH:
      return { ...state, fetchReportPaymentsSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SORT:
      return { ...state, fetchReportPaymentsSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_KEY_BY:
      return { ...state, fetchReportPaymentsKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_PAGE:
      return { ...state, fetchReportPaymentsPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_PER_PAGE:
      return { ...state, fetchReportPaymentsPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_FILTER_PAYMENT_STATUSES:
      return { ...state, fetchReportPaymentsFilterPaymentStatuses: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_LOADING:
      return { ...state, fetchReportPaymentsLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_SUCCESS:
      return { ...state, fetchReportPaymentsSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_PAYMENTS_FAILED:
      return { ...state, fetchReportPaymentsFailed: payload };

    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SEARCH:
      return { ...state, fetchReportTablesSearch: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SORT:
      return { ...state, fetchReportTablesSort: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_KEY_BY:
      return { ...state, fetchReportTablesKeyBy: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_PAGE:
      return { ...state, fetchReportTablesPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_PER_PAGE:
      return { ...state, fetchReportTablesPerPage: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_LOADING:
      return { ...state, fetchReportTablesLoading: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_SUCCESS:
      return { ...state, fetchReportTablesSuccess: payload };
    case REPORT_ACTION_TYPES.FETCH_REPORT_TABLES_FAILED:
      return { ...state, fetchReportTablesFailed: payload };

    case REPORT_ACTION_TYPES.APPEND_REPORT_CATEGORIES:
      return {
        ...state,
        reportCategories: [...state.reportCategories, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_PRODUCTS:
      return {
        ...state,
        reportProducts: [...state.reportProducts, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_OPTIONS:
      return {
        ...state,
        reportOptions: [...state.reportOptions, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_ADDITIONALS:
      return {
        ...state,
        reportAdditionals: [...state.reportAdditionals, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_CASHIERS:
      return {
        ...state,
        reportCashiers: [...state.reportCashiers, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_PAYMENTS:
      return {
        ...state,
        reportPayments: [...state.reportPayments, ...payload],
      };
    case REPORT_ACTION_TYPES.APPEND_REPORT_TABLES:
      return {
        ...state,
        reportTables: [...state.reportTables, ...payload],
      };

    case REPORT_ACTION_TYPES.SET_IS_FETCH_PREV_REPORT_TOTALS_HITTED:
      return { ...state, isFetchPrevReportTotalsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_NEXT_REPORT_TOTALS_HITTED:
      return { ...state, isFetchNextReportTotalsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_CATEGORIES_HITTED:
      return { ...state, isFetchReportCategoriesHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_PRODUCTS_HITTED:
      return { ...state, isFetchReportProductsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_OPTIONS_HITTED:
      return { ...state, isFetchReportOptionsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_ADDITIONALS_HITTED:
      return { ...state, isFetchReportAdditionalsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_CASHIERS_HITTED:
      return { ...state, isFetchReportCashiersHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_PAYMENTS_HITTED:
      return { ...state, isFetchReportPaymentsHitted: payload };
    case REPORT_ACTION_TYPES.SET_IS_FETCH_REPORT_TABLES_HITTED:
      return { ...state, isFetchReportTablesHitted: payload };

    case REPORT_ACTION_TYPES.RESET_REPORT_REDUCER:
      return REPORT_INITIAL_STATE;
    default:
      return state;
  }
};
