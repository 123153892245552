import { createAction } from "../../utils/store.utils";

import REPORT_PURCHASE_ACTION_TYPES from "./report-purchase.type";

export const setReportTotals = (reportTotals) =>
  createAction(REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_TOTALS, reportTotals);
export const setReportPurchases = (reportPurchases) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_PURCHASES,
    reportPurchases
  );
export const setReportPurchase = (reportPurchase) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_REPORT_PURCHASE,
    reportPurchase
  );

export const setIsReportPurchasesHasMore = (isReportPurchasesHasMore) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_IS_REPORT_PURCHASES_HAS_MORE,
    isReportPurchasesHasMore
  );

export const setFetchReportsFilterMarketId = (fetchReportsFilterMarketId) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_MARKET_ID,
    fetchReportsFilterMarketId
  );
export const setFetchReportsFilterBranchId = (fetchReportsFilterBranchId) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_BRANCH_ID,
    fetchReportsFilterBranchId
  );
export const setFetchReportsFilterSupplierId = (fetchReportsFilterSupplierId) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_SUPPLIER_ID,
    fetchReportsFilterSupplierId
  );
export const setFetchReportsFilterCategoriesIds = (
  fetchReportsFilterCategoriesIds
) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_CATEGORIES_IDS,
    fetchReportsFilterCategoriesIds
  );
export const setFetchReportsFilterTypes = (fetchReportsFilterTypes) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_TYPES,
    fetchReportsFilterTypes
  );
export const setFetchReportsFilterStatuses = (fetchReportsFilterStatuses) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_STATUSES,
    fetchReportsFilterStatuses
  );
export const setFetchReportsFilterPurchaseAtBefore = (
  fetchReportsFilterPurchaseAtBefore
) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_PURCHASE_AT_BEFORE,
    fetchReportsFilterPurchaseAtBefore
  );
export const setFetchReportsFilterPurchaseAtAfter = (
  fetchReportsFilterPurchaseAtAfter
) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORTS_FILTER_PURCHASE_AT_AFTER,
    fetchReportsFilterPurchaseAtAfter
  );

export const setFetchReportTotalsLoading = (fetchReportTotalsLoading) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_LOADING,
    fetchReportTotalsLoading
  );
export const setFetchReportTotalsSuccess = (fetchReportTotalsSuccess) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_SUCCESS,
    fetchReportTotalsSuccess
  );
export const setFetchReportTotalsFailed = (fetchReportTotalsFailed) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_FAILED,
    fetchReportTotalsFailed
  );

export const setFetchReportPurchasesSearch = (fetchReportPurchasesSearch) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SEARCH,
    fetchReportPurchasesSearch
  );
export const setFetchReportPurchasesSort = (fetchReportPurchasesSort) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SORT,
    fetchReportPurchasesSort
  );
export const setFetchReportPurchasesKeyBy = (fetchReportPurchasesKeyBy) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_KEY_BY,
    fetchReportPurchasesKeyBy
  );
export const setFetchReportPurchasesPage = (fetchReportPurchasesPage) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_PAGE,
    fetchReportPurchasesPage
  );
export const setFetchReportPurchasesPerPage = (fetchReportPurchasesPerPage) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_PER_PAGE,
    fetchReportPurchasesPerPage
  );
export const setFetchReportPurchasesLoading = (fetchReportPurchasesLoading) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_LOADING,
    fetchReportPurchasesLoading
  );
export const setFetchReportPurchasesSuccess = (fetchReportPurchasesSuccess) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_SUCCESS,
    fetchReportPurchasesSuccess
  );
export const setFetchReportPurchasesFailed = (fetchReportPurchasesFailed) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_FAILED,
    fetchReportPurchasesFailed
  );

export const setFetchReportPurchaseLoading = (fetchReportPurchaseLoading) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_LOADING,
    fetchReportPurchaseLoading
  );
export const setFetchReportPurchaseSuccess = (fetchReportPurchaseSuccess) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_SUCCESS,
    fetchReportPurchaseSuccess
  );
export const setFetchReportPurchaseFailed = (fetchReportPurchaseFailed) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_FAILED,
    fetchReportPurchaseFailed
  );

export const setIsFetchReportTotalsHitted = (isFetchReportTotalsHitted) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_TOTALS_HITTED,
    isFetchReportTotalsHitted
  );
export const setIsFetchReportPurchasesHitted = (isFetchReportPurchasesHitted) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_PURCHASES_HITTED,
    isFetchReportPurchasesHitted
  );
export const setIsFetchReportPurchaseHitted = (isFetchReportPurchaseHitted) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.SET_IS_FETCH_REPORT_PURCHASE_HITTED,
    isFetchReportPurchaseHitted
  );

export const appendReportPurchases = (reportPurchases) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.APPEND_REPORT_PURCHASES,
    reportPurchases
  );

export const fetchReportTotalsStart = () =>
  createAction(REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_TOTALS_START);
export const fetchReportPurchasesStart = () =>
  createAction(REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASES_START);
export const fetchReportPurchaseStart = (categoryId) =>
  createAction(
    REPORT_PURCHASE_ACTION_TYPES.FETCH_REPORT_PURCHASE_START,
    categoryId
  );

export const resetReportPurchaseReducer = () =>
  createAction(REPORT_PURCHASE_ACTION_TYPES.RESET_REPORT_PURCHASE_REDUCER);
