import { createSelector } from "reselect";

const purchaseSelector = ({ purchase }) => purchase;

export const getIngredientPurchases = createSelector(
  [purchaseSelector],
  ({ ingredientPurchases }) => ingredientPurchases
);
export const getItemPurchases = createSelector(
  [purchaseSelector],
  ({ itemPurchases }) => itemPurchases
);
export const getPurchase = createSelector(
  [purchaseSelector],
  ({ purchase }) => purchase
);
export const getCalculateCreatePurchase = createSelector(
  [purchaseSelector],
  ({ calculateCreatePurchase }) => calculateCreatePurchase
);
export const getCalculateUpdatePurchase = createSelector(
  [purchaseSelector],
  ({ calculateUpdatePurchase }) => calculateUpdatePurchase
);
export const getCreatePurchase = createSelector(
  [purchaseSelector],
  ({ createPurchase }) => createPurchase
);
export const getUpdatePurchase = createSelector(
  [purchaseSelector],
  ({ updatePurchase }) => updatePurchase
);

export const getIsIngredientPurchasesHasMore = createSelector(
  [purchaseSelector],
  ({ isIngredientPurchasesHasMore }) => isIngredientPurchasesHasMore
);
export const getIsItemPurchasesHasMore = createSelector(
  [purchaseSelector],
  ({ isItemPurchasesHasMore }) => isItemPurchasesHasMore
);

export const getFetchIngredientPurchasesSearch = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesSearch }) => fetchIngredientPurchasesSearch
);
export const getFetchIngredientPurchasesSort = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesSort }) => fetchIngredientPurchasesSort
);
export const getFetchIngredientPurchasesKeyBy = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesKeyBy }) => fetchIngredientPurchasesKeyBy
);
export const getFetchIngredientPurchasesPage = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesPage }) => fetchIngredientPurchasesPage
);
export const getFetchIngredientPurchasesPerPage = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesPerPage }) => fetchIngredientPurchasesPerPage
);
export const getFetchIngredientPurchasesIncludes = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesIncludes }) => fetchIngredientPurchasesIncludes
);
export const getFetchIngredientPurchasesFilterMarketId = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFilterMarketId }) =>
    fetchIngredientPurchasesFilterMarketId
);
export const getFetchIngredientPurchasesFilterBranchId = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFilterBranchId }) =>
    fetchIngredientPurchasesFilterBranchId
);
export const getFetchIngredientPurchasesFilterCategoryId = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFilterCategoryId }) =>
    fetchIngredientPurchasesFilterCategoryId
);
export const getFetchIngredientPurchasesFilterSupplierId = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFilterSupplierId }) =>
    fetchIngredientPurchasesFilterSupplierId
);
export const getFetchIngredientPurchasesFilterStatus = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFilterStatus }) =>
    fetchIngredientPurchasesFilterStatus
);
export const getFetchIngredientPurchasesLoading = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesLoading }) => fetchIngredientPurchasesLoading
);
export const getFetchIngredientPurchasesSuccess = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesSuccess }) => fetchIngredientPurchasesSuccess
);
export const getFetchIngredientPurchasesFailed = createSelector(
  [purchaseSelector],
  ({ fetchIngredientPurchasesFailed }) => fetchIngredientPurchasesFailed
);

export const getFetchItemPurchasesSearch = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesSearch }) => fetchItemPurchasesSearch
);
export const getFetchItemPurchasesSort = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesSort }) => fetchItemPurchasesSort
);
export const getFetchItemPurchasesKeyBy = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesKeyBy }) => fetchItemPurchasesKeyBy
);
export const getFetchItemPurchasesPage = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesPage }) => fetchItemPurchasesPage
);
export const getFetchItemPurchasesPerPage = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesPerPage }) => fetchItemPurchasesPerPage
);
export const getFetchItemPurchasesIncludes = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesIncludes }) => fetchItemPurchasesIncludes
);
export const getFetchItemPurchasesFilterMarketId = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFilterMarketId }) => fetchItemPurchasesFilterMarketId
);
export const getFetchItemPurchasesFilterBranchId = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFilterBranchId }) => fetchItemPurchasesFilterBranchId
);
export const getFetchItemPurchasesFilterCategoryId = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFilterCategoryId }) => fetchItemPurchasesFilterCategoryId
);
export const getFetchItemPurchasesFilterSupplierId = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFilterSupplierId }) => fetchItemPurchasesFilterSupplierId
);
export const getFetchItemPurchasesFilterStatus = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFilterStatus }) => fetchItemPurchasesFilterStatus
);
export const getFetchItemPurchasesLoading = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesLoading }) => fetchItemPurchasesLoading
);
export const getFetchItemPurchasesSuccess = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesSuccess }) => fetchItemPurchasesSuccess
);
export const getFetchItemPurchasesFailed = createSelector(
  [purchaseSelector],
  ({ fetchItemPurchasesFailed }) => fetchItemPurchasesFailed
);

export const getFetchPurchaseLoading = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseLoading }) => fetchPurchaseLoading
);
export const getFetchPurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseSuccess }) => fetchPurchaseSuccess
);
export const getFetchPurchaseFailed = createSelector(
  [purchaseSelector],
  ({ fetchPurchaseFailed }) => fetchPurchaseFailed
);

export const getCalculateCreatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ calculateCreatePurchaseLoading }) => calculateCreatePurchaseLoading
);
export const getCalculateCreatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ calculateCreatePurchaseSuccess }) => calculateCreatePurchaseSuccess
);
export const getCalculateCreatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ calculateCreatePurchaseFailed }) => calculateCreatePurchaseFailed
);

export const getCalculateUpdatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ calculateUpdatePurchaseLoading }) => calculateUpdatePurchaseLoading
);
export const getCalculateUpdatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ calculateUpdatePurchaseSuccess }) => calculateUpdatePurchaseSuccess
);
export const getCalculateUpdatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ calculateUpdatePurchaseFailed }) => calculateUpdatePurchaseFailed
);

export const getCreatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ createPurchaseLoading }) => createPurchaseLoading
);
export const getCreatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ createPurchaseSuccess }) => createPurchaseSuccess
);
export const getCreatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ createPurchaseFailed }) => createPurchaseFailed
);

export const getUpdatePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ updatePurchaseLoading }) => updatePurchaseLoading
);
export const getUpdatePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ updatePurchaseSuccess }) => updatePurchaseSuccess
);
export const getUpdatePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ updatePurchaseFailed }) => updatePurchaseFailed
);

export const getDeletePurchaseLoading = createSelector(
  [purchaseSelector],
  ({ deletePurchaseLoading }) => deletePurchaseLoading
);
export const getDeletePurchaseSuccess = createSelector(
  [purchaseSelector],
  ({ deletePurchaseSuccess }) => deletePurchaseSuccess
);
export const getDeletePurchaseFailed = createSelector(
  [purchaseSelector],
  ({ deletePurchaseFailed }) => deletePurchaseFailed
);

export const getIsFetchIngredientPurchasesHitted = createSelector(
  [purchaseSelector],
  ({ isFetchIngredientPurchasesHitted }) => isFetchIngredientPurchasesHitted
);
export const getIsFetchItemPurchasesHitted = createSelector(
  [purchaseSelector],
  ({ isFetchItemPurchasesHitted }) => isFetchItemPurchasesHitted
);
export const getIsFetchPurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isFetchPurchaseHitted }) => isFetchPurchaseHitted
);
export const getIsCalculateCreatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isCalculateCreatePurchaseHitted }) => isCalculateCreatePurchaseHitted
);
export const getIsCalculateUpdatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isCalculateUpdatePurchaseHitted }) => isCalculateUpdatePurchaseHitted
);
export const getIsCreatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isCreatePurchaseHitted }) => isCreatePurchaseHitted
);
export const getIsUpdatePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isUpdatePurchaseHitted }) => isUpdatePurchaseHitted
);
export const getIsDeletePurchaseHitted = createSelector(
  [purchaseSelector],
  ({ isDeletePurchaseHitted }) => isDeletePurchaseHitted
);
