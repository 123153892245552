import { createAction } from "../../utils/store.utils";

import SELECT_SECTOR_ACTION_TYPES from "./select-sector.type";

export const setSectors = (sectors) =>
  createAction(SELECT_SECTOR_ACTION_TYPES.SET_SECTORS, sectors);
export const setSearchSectors = (searchSectors) =>
  createAction(SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS, searchSectors);

export const appendSectors = (sectors) =>
  createAction(SELECT_SECTOR_ACTION_TYPES.APPEND_SECTORS, sectors);
export const appendSearchSectors = (searchSectors) =>
  createAction(SELECT_SECTOR_ACTION_TYPES.APPEND_SEARCH_SECTORS, searchSectors);

export const setIsSectorsHasMore = (isSectorsHasMore) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_IS_SECTORS_HAS_MORE,
    isSectorsHasMore
  );
export const setIsSearchSectorsHasMore = (isSearchSectorsHasMore) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_IS_SEARCH_SECTORS_HAS_MORE,
    isSearchSectorsHasMore
  );

export const setFetchSectorsParams = (fetchSectorsParams) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_PARAMS,
    fetchSectorsParams
  );
export const setFetchSectorsLoading = (fetchSectorsLoading) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_LOADING,
    fetchSectorsLoading
  );
export const setFetchSectorsSuccess = (fetchSectorsSuccess) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_SUCCESS,
    fetchSectorsSuccess
  );
export const setFetchSectorsFailed = (fetchSectorsFailed) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_FETCH_SECTORS_FAILED,
    fetchSectorsFailed
  );

export const setSearchSectorsParams = (searchSectorsParams) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_PARAMS,
    searchSectorsParams
  );
export const setSearchSectorsLoading = (searchSectorsLoading) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_LOADING,
    searchSectorsLoading
  );
export const setSearchSectorsSuccess = (searchSectorsSuccess) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_SUCCESS,
    searchSectorsSuccess
  );
export const setSearchSectorsFailed = (searchSectorsFailed) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_SEARCH_SECTORS_FAILED,
    searchSectorsFailed
  );

export const setAppendSectorsParams = (appendSectorsParams) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_PARAMS,
    appendSectorsParams
  );
export const setAppendSectorsLoading = (appendSectorsLoading) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_LOADING,
    appendSectorsLoading
  );
export const setAppendSectorsSuccess = (appendSectorsSuccess) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_SUCCESS,
    appendSectorsSuccess
  );
export const setAppendSectorsFailed = (appendSectorsFailed) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_APPEND_SECTORS_FAILED,
    appendSectorsFailed
  );

export const setIsFetchSectorsHitted = (isFetchSectorsHitted) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_IS_FETCH_SECTORS_HITTED,
    isFetchSectorsHitted
  );
export const setIsSearchSectorsHitted = (isSearchSectorsHitted) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_IS_SEARCH_SECTORS_HITTED,
    isSearchSectorsHitted
  );
export const setIsAppendSectorsHitted = (isAppendSectorsHitted) =>
  createAction(
    SELECT_SECTOR_ACTION_TYPES.SET_IS_APPEND_SECTORS_HITTED,
    isAppendSectorsHitted
  );

export const fetchSectorsStart = () =>
  createAction(SELECT_SECTOR_ACTION_TYPES.FETCH_SECTORS_START);
export const searchSectorsStart = () =>
  createAction(SELECT_SECTOR_ACTION_TYPES.SEARCH_SECTORS_START);
export const appendSectorsStart = () =>
  createAction(SELECT_SECTOR_ACTION_TYPES.APPEND_SECTORS_START);

export const resetSectorReducer = () =>
  createAction(SELECT_SECTOR_ACTION_TYPES.RESET_SELECT_SECTOR_REDUCER);
