import PDF_ACTION_TYPES from "./pdf.type";
import { createAction } from "../../utils/store.utils";

export const setOrderReceipt = (orderReceipt) => createAction(PDF_ACTION_TYPES.SET_ORDER_RECEIPT, orderReceipt);
export const setOrderNotes = (orderNotes) => createAction(PDF_ACTION_TYPES.SET_ORDER_NOTES, orderNotes);
export const setRenewalReceipt = (renewalReceipt) =>
  createAction(PDF_ACTION_TYPES.SET_RENEWAL_RECEIPT, renewalReceipt);

export const setPdfOrderReceiptIsMultiLang = (pdfOrderReceiptIsMultiLang) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_IS_MULTI_LANG, pdfOrderReceiptIsMultiLang);
export const setPdfOrderReceiptLoading = (pdfOrderReceiptLoading) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_LOADING, pdfOrderReceiptLoading);
export const setPdfOrderReceiptSuccess = (pdfOrderReceiptSuccess) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_SUCCESS, pdfOrderReceiptSuccess);
export const setPdfOrderReceiptFailed = (pdfOrderReceiptFailed) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_RECEIPT_FAILED, pdfOrderReceiptFailed);

export const setPdfOrderNotesIsMultiLang = (pdfOrderNotesIsMultiLang) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_IS_MULTI_LANG, pdfOrderNotesIsMultiLang);
export const setPdfOrderNotesLoading = (pdfOrderNotesLoading) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_LOADING, pdfOrderNotesLoading);
export const setPdfOrderNotesSuccess = (pdfOrderNotesSuccess) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_SUCCESS, pdfOrderNotesSuccess);
export const setPdfOrderNotesFailed = (pdfOrderNotesFailed) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_ORDER_NOTES_FAILED, pdfOrderNotesFailed);

export const setPdfRenewalReceiptLoading = (pdfRenewalReceiptLoading) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_LOADING, pdfRenewalReceiptLoading);
export const setPdfRenewalReceiptSuccess = (pdfRenewalReceiptSuccess) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_SUCCESS, pdfRenewalReceiptSuccess);
export const setPdfRenewalReceiptFailed = (pdfRenewalReceiptFailed) =>
  createAction(PDF_ACTION_TYPES.SET_PDF_RENEWAL_RECEIPT_FAILED, pdfRenewalReceiptFailed);

export const setIsPdfOrderReceiptHitted = (isPdfOrderReceiptHitted) =>
  createAction(PDF_ACTION_TYPES.SET_IS_PDF_ORDER_RECEIPT_HITTED, isPdfOrderReceiptHitted);
export const setIsPdfOrderNotesHitted = (isPdfOrderNotesHitted) =>
  createAction(PDF_ACTION_TYPES.SET_IS_PDF_ORDER_NOTES_HITTED, isPdfOrderNotesHitted);
export const setIsPdfRenewalReceiptHitted = (isPdfRenewalReceiptHitted) =>
  createAction(PDF_ACTION_TYPES.SET_IS_PDF_RENEWAL_RECEIPT_HITTED, isPdfRenewalReceiptHitted);

export const getPdfOrderReceiptStart = (id) => createAction(PDF_ACTION_TYPES.EXPORT_ORDER_RECEIPT_START, id);
export const getPdfOrderNotesStart = (id) => createAction(PDF_ACTION_TYPES.EXPORT_ORDER_NOTES_START, id);
export const getPdfRenewalReceiptStart = (id) =>
  createAction(PDF_ACTION_TYPES.EXPORT_RENEWAL_RECEIPT_START, id);

export const resetPdfReducer = () => createAction(PDF_ACTION_TYPES.RESET_PDF_REDUCER);
