import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { showErrorMessage } from "../../utils/popup.utils";
import { getValidationErrors, rule } from "../../utils/validation.utils";

import { getCurrentMarketId } from "../../store/global/global.selector";
import { createUserSupplierStart } from "../../store/user-supplier/user-supplier.action";

import Responsive from "../../components/responsive/responsive.component";
import Button, {
  BUTTON_COLORS,
  BUTTON_SIZES,
  BUTTON_TYPES,
} from "../../components/button/button.component";
import Dropdown from "../../components/dropdown-v2/dropdown.component";
import FormInput, {
  FORM_INPUT_SIZES,
  FORM_INPUT_TYPES,
} from "../../components/form-input/form-input.component";
import FormLabel, {
  FORM_LABEL_COLORS,
} from "../../components/form-label/form-label.component";

import { ReactComponent as PlusSvg } from "../../assets/icons/Plus1.svg";
import { getCreateUserSupplierLoading } from "../../store/user-supplier/user-supplier.selector";

const defaultValues = { name: "", email: "" };

const SelectSupplierCreateForm = ({ handleDropdownClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currentMarketId = useSelector(getCurrentMarketId);
  const createUserSupplierLoading = useSelector(getCreateUserSupplierLoading);

  const [isEmailValid, setIsEmailValid] = useState(null);

  const [inputValues, setInputValues] = useState(defaultValues);

  const { name, email } = inputValues;

  const handleInputChange = ({ name, value }) => {
    setInputValues({ ...inputValues, [name]: value });
    if (name === "email") setIsEmailValid(/\S+@\S+\.\S+/.test(value));
  };

  const handleFormSubmit = () => {
    const errors = getValidationErrors(inputValues, {
      name: [rule.required()],
      email: [rule.required(), rule.email()],
    });

    if (errors.length > 0) {
      showErrorMessage({ data: errors });
      return;
    }

    const payload = { ...inputValues, market_id: currentMarketId };

    dispatch(createUserSupplierStart(payload));
    handleDropdownClose?.();
  };

  return (
    <Responsive>
      <Responsive.Row rowGap="1rem">
        <Responsive.Col>
          <Dropdown.Form.Header
            title={t("Add New Supplier")}
            description={t("Provide the information below")}
          />
        </Responsive.Col>
        <Responsive.Col md={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Name")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.TEXT}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="name"
            placeholder={t("Enter name")}
            onChange={handleInputChange}
            value={name}
            isDisabled={createUserSupplierLoading}
            isSolid={createUserSupplierLoading}
          />
        </Responsive.Col>
        <Responsive.Col md={6}>
          <FormInput
            label={
              <FormLabel labelColor={FORM_LABEL_COLORS.LIGHT}>
                {t("Email Address")}
              </FormLabel>
            }
            inputType={FORM_INPUT_TYPES.EMAIL}
            inputSize={FORM_INPUT_SIZES.NORMAL}
            name="email"
            placeholder={t("Enter email address")}
            onChange={handleInputChange}
            value={email}
            isValid={isEmailValid}
            isNotValid={!isEmailValid && isEmailValid !== null}
            isDisabled={createUserSupplierLoading}
            isSolid={createUserSupplierLoading}
          />
        </Responsive.Col>
        <Responsive.Col></Responsive.Col>
        <Responsive.Col>
          <Button
            type="button"
            buttonColor={BUTTON_COLORS.DARK}
            buttonSize={BUTTON_SIZES.NORMAL}
            buttonType={BUTTON_TYPES.TEXT_ICON}
            prefixIcon={<PlusSvg />}
            onClick={handleFormSubmit}
            isLoading={createUserSupplierLoading}
          >
            {t("Create Supplier")}
          </Button>
        </Responsive.Col>
      </Responsive.Row>
    </Responsive>
  );
};

export default SelectSupplierCreateForm;
