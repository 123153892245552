import styled from "styled-components";

export const PurchaseFormSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  row-gap: 0.75rem;

  padding: 1.5rem;
  border-radius: 1rem;
  background-color: #f9f9f9;
  border: 1.5px solid #dfdcef;
`;

export const PurchaseFormSummaryInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PurchaseFormSummaryLabel = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  color: #0e072f;
  line-height: 1.5;
  margin: 0;
`;

export const PurchaseFormSummaryValue = styled.p`
  font-size: 0.875rem;
  font-weight: 600;
  color: #9691ac;
  line-height: 1.5;
  margin: 0;
`;
