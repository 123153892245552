import { ORDER_WAITING_DISPLAYS } from "../../constants/order.constant";

import COMPONENT_ACTION_TYPES from "./component.type";

export const COMPONENT_INITIAL_STATE = {
  overlayPosition: null,
  screenWidth: window.innerWidth,
  topnavHeight: 0,
  sidenavWidth: 300,

  isSidenavOpen: false,
  isModalOpen: false,
  currentModal: null,

  currentModals: {},
  currentDialogs: {},

  leftDialogs: {},
  rightDialogs: {},
  centerDialogs: {},

  isHasThanksPage: false,
  isHasGettingStarted: false,

  orderCustomerIpAddress: false,
  isOrderCustomerHideSidebar: false,
  isOrderCustomerHideBackButton: false,
  isOrderCustomerHideTitle: false,
  isOrderCustomerHideTopToolbar: false,

  orderWaitingDisplay: ORDER_WAITING_DISPLAYS.DISPLAY_1,
  isOrderWaitingHideSidebar: false,
  isOrderWaitingHideBackButton: false,
  isOrderWaitingHideTitle: false,
  isOrderWaitingHideTopToolbar: false,
};

export const componentReducer = (state = COMPONENT_INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case COMPONENT_ACTION_TYPES.SET_OVERLAY_POSITION:
      return { ...state, overlayPosition: payload };
    case COMPONENT_ACTION_TYPES.SET_SCREEN_WIDTH:
      return { ...state, screenWidth: payload };
    case COMPONENT_ACTION_TYPES.SET_TOPNAV_HEIGHT:
      return { ...state, topnavHeight: payload };
    case COMPONENT_ACTION_TYPES.SET_SIDENAV_WIDTH:
      return { ...state, sidenavWidth: payload };

    case COMPONENT_ACTION_TYPES.SET_IS_SIDENAV_OPEN:
      return { ...state, isSidenavOpen: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_MODAL_OPEN:
      return { ...state, isModalOpen: payload };
    case COMPONENT_ACTION_TYPES.SET_CURRENT_MODAL:
      return { ...state, currentModal: payload };

    case COMPONENT_ACTION_TYPES.SET_CURRENT_MODALS:
      return { ...state, currentModals: payload };
    case COMPONENT_ACTION_TYPES.APPEND_CURRENT_MODAL:
      const appendModals = { ...state.currentModals };
      appendModals[payload] = true;

      return { ...state, currentModals: appendModals };
    case COMPONENT_ACTION_TYPES.REMOVE_CURRENT_MODAL:
      const removeModals = { ...state.currentModals };
      delete removeModals[payload];

      return { ...state, currentModals: removeModals };

    case COMPONENT_ACTION_TYPES.SET_CURRENT_DIALOGS:
      return { ...state, currentDialogs: payload };
    case COMPONENT_ACTION_TYPES.APPEND_CURRENT_DIALOG: {
      const currentDialogs = { ...state.currentDialogs };
      currentDialogs[payload] = true;

      return { ...state, currentDialogs };
    }
    case COMPONENT_ACTION_TYPES.REMOVE_CURRENT_DIALOG: {
      const currentDialogs = { ...state.currentDialogs };
      delete currentDialogs[payload];

      return { ...state, currentDialogs };
    }

    case COMPONENT_ACTION_TYPES.SET_LEFT_DIALOGS:
      return { ...state, leftDialogs: payload };
    case COMPONENT_ACTION_TYPES.APPEND_LEFT_DIALOG: {
      const leftDialogs = { ...state.leftDialogs };
      leftDialogs[payload.key] = payload.value;

      return { ...state, leftDialogs };
    }
    case COMPONENT_ACTION_TYPES.REMOVE_LEFT_DIALOG: {
      const leftDialogs = { ...state.leftDialogs };
      delete leftDialogs[payload];

      return { ...state, leftDialogs };
    }

    case COMPONENT_ACTION_TYPES.SET_RIGHT_DIALOGS:
      return { ...state, rightDialogs: payload };
    case COMPONENT_ACTION_TYPES.APPEND_RIGHT_DIALOG: {
      const rightDialogs = { ...state.rightDialogs };
      rightDialogs[payload.key] = payload.value;

      return { ...state, rightDialogs };
    }
    case COMPONENT_ACTION_TYPES.REMOVE_RIGHT_DIALOG: {
      const rightDialogs = { ...state.rightDialogs };
      delete rightDialogs[payload];

      return { ...state, rightDialogs };
    }

    case COMPONENT_ACTION_TYPES.SET_CENTER_DIALOGS:
      return { ...state, centerDialogs: payload };
    case COMPONENT_ACTION_TYPES.APPEND_CENTER_DIALOG: {
      const centerDialogs = { ...state.centerDialogs };
      centerDialogs[payload.key] = payload.value;

      return { ...state, centerDialogs };
    }
    case COMPONENT_ACTION_TYPES.REMOVE_CENTER_DIALOG: {
      const centerDialogs = { ...state.centerDialogs };
      delete centerDialogs[payload];

      return { ...state, centerDialogs };
    }

    case COMPONENT_ACTION_TYPES.SET_IS_HAS_THANKS_PAGE:
      return { ...state, isHasThanksPage: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_HAS_GETTING_STARTED:
      return { ...state, isHasGettingStarted: payload };

    case COMPONENT_ACTION_TYPES.SET_ORDER_CUSTOMER_IP_ADDRESS:
      return { ...state, orderCustomerIpAddress: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_SIDEBAR:
      return { ...state, isOrderCustomerHideSidebar: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_BACK_BUTTON:
      return { ...state, isOrderCustomerHideBackButton: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_TITLE:
      return { ...state, isOrderCustomerHideTitle: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_CUSTOMER_HIDE_TOP_TOOLBAR:
      return { ...state, isOrderCustomerHideTopToolbar: payload };

    case COMPONENT_ACTION_TYPES.SET_ORDER_WAITING_DISPLAY:
      return { ...state, orderWaitingDisplay: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_SIDEBAR:
      return { ...state, isOrderWaitingHideSidebar: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_BACK_BUTTON:
      return { ...state, isOrderWaitingHideBackButton: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_TITLE:
      return { ...state, isOrderWaitingHideTitle: payload };
    case COMPONENT_ACTION_TYPES.SET_IS_ORDER_WAITING_HIDE_TOP_TOOLBAR:
      return { ...state, isOrderWaitingHideTopToolbar: payload };

    case COMPONENT_ACTION_TYPES.RESET_COMPONENT_REDUCER:
      return COMPONENT_INITIAL_STATE;
    default:
      return state;
  }
};
