import { createSelector } from "reselect";

const selectCategoryPurchaseSelector = ({ selectCategoryPurchase }) =>
  selectCategoryPurchase;

export const getCategories = createSelector(
  [selectCategoryPurchaseSelector],
  ({ categories }) => categories
);
export const getSearchCategories = createSelector(
  [selectCategoryPurchaseSelector],
  ({ searchCategories }) => searchCategories
);

export const getIsCategoriesHasMore = createSelector(
  [selectCategoryPurchaseSelector],
  ({ isCategoriesHasMore }) => isCategoriesHasMore
);
export const getIsSearchCategoriesHasMore = createSelector(
  [selectCategoryPurchaseSelector],
  ({ isSearchCategoriesHasMore }) => isSearchCategoriesHasMore
);

export const getFetchCategoriesParams = createSelector(
  [selectCategoryPurchaseSelector],
  ({ fetchCategoriesParams }) => fetchCategoriesParams
);
export const getFetchCategoriesLoading = createSelector(
  [selectCategoryPurchaseSelector],
  ({ fetchCategoriesLoading }) => fetchCategoriesLoading
);
export const getFetchCategoriesSuccess = createSelector(
  [selectCategoryPurchaseSelector],
  ({ fetchCategoriesSuccess }) => fetchCategoriesSuccess
);
export const getFetchCategoriesFailed = createSelector(
  [selectCategoryPurchaseSelector],
  ({ fetchCategoriesFailed }) => fetchCategoriesFailed
);

export const getSearchCategoriesParams = createSelector(
  [selectCategoryPurchaseSelector],
  ({ searchCategoriesParams }) => searchCategoriesParams
);
export const getSearchCategoriesLoading = createSelector(
  [selectCategoryPurchaseSelector],
  ({ searchCategoriesLoading }) => searchCategoriesLoading
);
export const getSearchCategoriesSuccess = createSelector(
  [selectCategoryPurchaseSelector],
  ({ searchCategoriesSuccess }) => searchCategoriesSuccess
);
export const getSearchCategoriesFailed = createSelector(
  [selectCategoryPurchaseSelector],
  ({ searchCategoriesFailed }) => searchCategoriesFailed
);

export const getAppendCategoriesParams = createSelector(
  [selectCategoryPurchaseSelector],
  ({ appendCategoriesParams }) => appendCategoriesParams
);
export const getAppendCategoriesLoading = createSelector(
  [selectCategoryPurchaseSelector],
  ({ appendCategoriesLoading }) => appendCategoriesLoading
);
export const getAppendCategoriesSuccess = createSelector(
  [selectCategoryPurchaseSelector],
  ({ appendCategoriesSuccess }) => appendCategoriesSuccess
);
export const getAppendCategoriesFailed = createSelector(
  [selectCategoryPurchaseSelector],
  ({ appendCategoriesFailed }) => appendCategoriesFailed
);

export const getIsFetchCategoriesHitted = createSelector(
  [selectCategoryPurchaseSelector],
  ({ isFetchCategoriesHitted }) => isFetchCategoriesHitted
);
export const getIsSearchCategoriesHitted = createSelector(
  [selectCategoryPurchaseSelector],
  ({ isSearchCategoriesHitted }) => isSearchCategoriesHitted
);
export const getIsAppendCategoriesHitted = createSelector(
  [selectCategoryPurchaseSelector],
  ({ isAppendCategoriesHitted }) => isAppendCategoriesHitted
);
